/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';
import {
  Skill,
  ReadLeadByEmailRequest,
  Lead,
  LeadRequest,
  LeadSkillRequest,
} from '@/protoc/moonlight_pb';

const namespaced = true;

// initial state
const state = {
  leads: [],
  lead: null,
  pending: false,
  listPending: false,
  skillsPending: false,
  skills: null, // for a lead

  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when a create/update operation has been completed
};

const actions = {
  listForManualReview({ commit, rootState }) {
    commit('mutateReset');
    commit('mutateListPending', true);
    grpc.unary(MoonlightService.ListLeadsForManualReview, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutateListPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLeads', res.message.getLeadsList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  update({ commit, state, rootState }, req) {
    commit('mutateReset');

    const lead = new Lead(req.array.slice(0));

    const orig = state.lead;
    commit('mutateLead', lead);

    commit('mutatePending', true);
    commit('mutateRemoveLeadFromCache', lead);

    grpc.unary(MoonlightService.UpdateLead, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: lead,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
          commit('mutateLead', orig);
        }
      },
    });
  },
  create({ commit, state, rootState }, lead) {
    commit('mutateReset');

    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateLead, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: lead,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  bulkCreate({ commit, state, rootState }, leads) {
    commit('mutateReset');

    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateLeads, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: leads,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  readByEmail({
    commit, state, rootState, dispatch,
  }, email) {
    commit('mutateReset');
    commit('mutatePending', true);
    commit('mutateLead', null);
    commit('mutateSkills', null);

    const req = new ReadLeadByEmailRequest();
    req.setEmail(email);

    grpc.unary(MoonlightService.ReadLeadByEmail, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLead', res.message);
          dispatch('listSkills', res.message.getId());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  listSkills({
    commit, state, rootState, dispatch,
  }, leadID) {
    commit('mutateSkillsPending', true);
    const req = new LeadRequest();
    req.setLeadId(leadID);

    grpc.unary(MoonlightService.ListLeadSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutateSkillsPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkills', res.message.getSkillsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // createSkill adds a skill to the current lead
  createSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutateSkillsPending', true);

    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });
    if (!skillMatch) {
      return;
    }

    commit('mutateAddSkillToSkills', skillMatch);
    const insertID = state.skills.length - 1;

    const req = new LeadSkillRequest();
    req.setLeadId(state.lead.getId());
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.CreateLeadSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutateSkillsPending', false);
        commit('mutateSuccess', res.Status === grpc.Code.OK);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillInSkills', insertID);
        }
      },
    });
  },
  // deleteSkill assumes that the lead is loaded and that the skill exists on the lead
  deleteSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutateSkillsPending', true);

    // Copy skill and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origSkill = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.skills.length; i++) {
      if (state.skills[i].getSlug() === slug) {
        origSkill = new Skill(state.skills[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveSkillInSkills', i);
      }
    }

    if (!origSkill) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching skill for id ${slug}`;
    }

    const req = new LeadSkillRequest();
    req.setSkillSlug(slug);
    req.setLeadId(state.lead.getId());

    grpc.unary(MoonlightService.DeleteLeadSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutateSkillsPending', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddSkillToSkills', origSkill);
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateLeads(state, leads) {
    state.leads = leads;
  },
  mutateLead(state, lead) {
    if (lead) {
      state.lead = new Lead(lead.array.slice(0));
      return;
    }

    state.lead = lead;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateListPending(state, pending) {
    state.listPending = pending;
  },
  mutateSkillsPending(state, pending) {
    state.skillsPending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, val) {
    state.success = val;
  },
  mutateRemoveLeadFromCache(state, lead) {
    // remove lead from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.leads.length; i++) {
      if (state.leads[i].getId() === lead.getId()) {
        state.leads.splice(i, 1);
      }
    }
  },
  mutateSkills(state, data) {
    state.skills = data;
  },
  mutateRemoveSkillInSkills(state, index) {
    state.skills.splice(index, 1);
  },
  mutateUpdateSkillInSkills(state, { index, newSkill }) {
    state.skills.splice(index, 1, newSkill);
  },
  mutateAddSkillToSkills(state, newSkill) {
    state.skills.push(newSkill);
  },
};
const getters = {
  getLeadCount(state) {
    if (!state.leads) {
      return null;
    }
    return state.leads.length;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
