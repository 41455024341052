import { guardNotAuthenticated, guardNotActivated, guardAuthenticated } from '@/conf/guards';

const ActivateAccount = () => import('@/views/auth/ActivateAccount');

const RequestPasswordReset = () => import('@/views/auth/RequestPasswordReset');

const ResetPassword = () => import('@/views/auth/ResetPassword');

const Login = () => import('@/views/auth/Login');

const RequestAccountActivation = () => import('@/views/auth/RequestAccountActivation');

const ActivationRequired = () => import('@/views/auth/ActivationRequired');

const ConfirmEmailChange = () => import('@/views/auth/ConfirmEmailChange');

export default [
  {
    path: 'activate',
    name: 'Auth.RequestAccountActivation',
    component: RequestAccountActivation,
  },
  {
    path: 'activate/:token',
    name: 'Auth.ActivateAccount',
    component: ActivateAccount,
    props: true,
  },
  {
    path: 'reset',
    name: 'Auth.RequestPasswordReset',
    component: RequestPasswordReset,
  },
  {
    path: 'reset/:token',
    name: 'Auth.ResetPassword',
    component: ResetPassword,
    props: true,
    beforeEnter: guardNotAuthenticated,
  },
  {
    path: 'login',
    name: 'Auth.Login',
    component: Login,
    beforeEnter: guardNotAuthenticated,
  },
  {
    path: 'activation-required',
    name: 'Auth.ActivationRequired',
    component: ActivationRequired,
    beforeEnter: guardNotActivated,
  },
  {
    path: 'confirm-email-change/:token',
    name: 'Auth.ConfirmEmailChange',
    component: ConfirmEmailChange,
    beforeEnter: guardAuthenticated,
    props: true,
  },
];
