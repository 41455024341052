/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { createClient } from 'contentful';
import * as Sentry from '@sentry/vue';
import _ from 'lodash';

const client = createClient({
  space: 'ye1rfzx6um8a',
  accessToken: '2ddd1df040e918d4b5e16127d125d6d96e73177d1b35107e6b863e1a68d75525',
});

// Has access to unpublished stuff
const previewClient = createClient({
  space: 'ye1rfzx6um8a',
  accessToken: '1d98c9a7a835a05da2aafb80912e255efb3aebcdc96a23e03ffb178c1f9112f2',
  host: 'preview.contentful.com',
});

const namespaced = true;

// initial state
const state = {
  post: null,
  postNotFound: false,
  posts: [],
  homePostsCount: 4,
  postsRecom: [],
};

// getters
const getters = {};

// actions
const actions = {
  getBlogPost({ commit, state, rootState }, slug) {
    // If admin - always query contentful for latest
    if (rootState.auth.currentUser && rootState.auth.currentUser.admin) {
      previewClient
        .getEntries({ content_type: 'post', 'fields.slug': slug })
        .then((response) => {
          if (response.items[0]) {
            commit('mutateBlogPost', { post: response.items[0] });
          } else {
            commit('mutateBlogPostNotFound');
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    } else {
      const cachedPost = _.find([...state.posts, ...state.postsRecom], { fields: { slug } });
      commit('mutateReset');

      if (cachedPost) {
        commit('mutateBlogPost', { post: cachedPost });
      } else {
        client
          .getEntries({ content_type: 'post', 'fields.slug': slug })
          .then((response) => {
            if (response.items[0]) {
              commit('mutateBlogPost', { post: response.items[0] });
            } else {
              commit('mutateBlogPostNotFound');
            }
          })
          .catch((e) => {
            Sentry.captureException(e);
          });
      }
    }

    client
      .getEntries({
        content_type: 'post',
        order: '-fields.published',
        'fields.slug[ne]': slug,
        limit: 3,
      })
      .then((response) => {
        commit('mutateRecommendedPosts', { posts: response.items });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  },
  getBlogPosts({ commit }) {
    client
      .getEntries({
        content_type: 'post',
        order: '-fields.published',
      })
      .then((response) => {
        commit('mutateBlogPosts', { posts: response.items });
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  },
};

// mutations
const mutations = {
  mutateBlogPost(state, { post }) {
    state.post = post;
  },
  mutateBlogPostNotFound(state) {
    state.postNotFound = true;
  },
  mutateBlogPosts(state, { posts }) {
    state.posts = posts;
  },
  mutateRecommendedPosts(state, { posts }) {
    state.postsRecom = posts;
  },
  mutateReset(state) {
    state.post = null;
    state.blogPostNotFound = false;
    state.postsRecom = [];
  },
  mutateShowMore(state) {
    state.homePostsCount += 4;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
