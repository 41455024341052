import {
  guardAuthenticated,
  guardClient,
  guardNotAuthenticated,
} from '@/conf/guards';

// --------------------
// Broadcasts
// --------------------
const BroadcastConfirmation = () => import('@/views/start/BroadcastConfirmation');

// --------------------
// Company
// --------------------
const CompanyActivate = () => import('@/views/start/CompanyActivate');

const CompanyCreateAccount = () => import('@/views/start/CompanyCreateAccount');

const CompanyConfirmEmail = () => import('@/views/start/CompanyConfirmEmail');

const CompanyDetail = () => import('@/views/start/CompanyDetail');

// TODO: old onboarding, remove
const CompanyDetails = () => import('@/views/start/CompanyDetails');

const CompanyHirePreference = () => import('@/views/start/CompanyHirePreference');

const CompanyHow = () => import('@/views/start/CompanyHow');

const CompanyLink = () => import('@/views/start/CompanyLink');

const CompanyNextStep = () => import('@/views/start/CompanyNextStep');

const CompanyOverview = () => import('@/views/start/CompanyOverview');

// TODO: old onboarding, don't remove yet
const ConfirmCompanySignup = () => import('@/views/start/ConfirmCompanySignup');

// TODO: old onboarding, remove
const Skills = () => import('@/views/start/Skills');

// --------------------
// Dev
// --------------------
const DevConfirmEmail = () => import('@/views/start/DevConfirmEmail');

const DevCreateAccount = () => import('@/views/start/DevCreateAccount');

const DevDetail = () => import('@/views/start/DevDetail');

const DevExperience = () => import('@/views/start/DevExperience');

const DevLink = () => import('@/views/start/DevLink');

const DevOverview = () => import('@/views/start/DevOverview');

const DevRate = () => import('@/views/start/DevRate');

const DevReviewProfile = () => import('@/views/start/DevReviewProfile');

const DevSkill = () => import('@/views/start/DevSkill');

const DevStripe = () => import('@/views/start/DevStripe');

export default [
  {
    name: 'Start',
    path: '',
    redirect: { name: 'Start.Company.CreateAccount' },
  },
  {
    name: 'Start.Call',
    path: 'call',
    redirect: { name: 'Start.Finish' },
  },
  {
    beforeEnter: guardNotAuthenticated,
    component: ConfirmCompanySignup,
    name: 'Start.ConfirmCompanySignup',
    path: 'company',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyActivate,
    name: 'Start.Company.Activate',
    path: 'company/activate',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyConfirmEmail,
    name: 'Start.Company.ConfirmEmail',
    path: 'company/confirm-email',
  },
  {
    beforeEnter: guardNotAuthenticated,
    component: CompanyCreateAccount,
    name: 'Start.Company.CreateAccount',
    path: 'company/create',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyDetail,
    name: 'Start.Company.Detail',
    path: 'company/detail',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyHirePreference,
    name: 'Start.Company.HirePreference',
    path: 'company/hire-preference',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyHow,
    name: 'Start.Company.How',
    path: 'company/how',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyLink,
    name: 'Start.Company.Link',
    path: 'company/link',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyNextStep,
    name: 'Start.Company.NextStep',
    path: 'company/next-step',
  },
  {
    beforeEnter: guardAuthenticated,
    component: CompanyOverview,
    name: 'Start.Company.Overview',
    path: 'company/overview',
  },
  {
    beforeEnter: guardAuthenticated,
    component: BroadcastConfirmation,
    name: 'Start.BroadcastConfirmation',
    path: 'confirmed',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevConfirmEmail,
    name: 'Start.Dev.ConfirmEmail',
    path: 'dev/confirm-email',
  },
  {
    beforeEnter: guardNotAuthenticated,
    component: DevCreateAccount,
    name: 'Start.Dev.CreateAccount',
    path: 'dev/create',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevDetail,
    name: 'Start.Dev.Detail',
    path: 'dev/detail',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevExperience,
    name: 'Start.Dev.Experience',
    path: 'dev/experience',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevLink,
    name: 'Start.Dev.Link',
    path: 'dev/link',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevOverview,
    name: 'Start.Dev.Overview',
    path: 'dev/overview',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevRate,
    name: 'Start.Dev.Rate',
    path: 'dev/rate',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevReviewProfile,
    name: 'Start.Dev.ReviewProfile',
    path: 'dev/review-profile',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevSkill,
    name: 'Start.Dev.Skill',
    path: 'dev/skill',
  },
  {
    beforeEnter: guardAuthenticated,
    component: DevStripe,
    name: 'Start.Dev.Stripe',
    path: 'dev/stripe',
  },

  // TODO: old page
  {
    beforeEnter: guardAuthenticated,
    component: CompanyDetails,
    name: 'Start.CompanyDetails',
    path: 'profile',
  },
  // TODO: old page
  {
    beforeEnter: guardClient,
    component: Skills,
    name: 'Start.Skills',
    path: 'skills',
  },
];
