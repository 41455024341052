/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import { Broadcast, UserRequest, BroadcastRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  broadcast: null,
  broadcasts: [],

  pending: false, // overall broadcasts model
  approvalPending: false, // overall broadcasts model

  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  listUserOpenBroadcasts(
    {
      // public method
      commit,
      state,
      rootState,
    },
    userID,
  ) {
    commit('mutateReset');
    commit('mutateResetBroadcasts');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserOpenBroadcasts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcasts', res.message.getBroadcastsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listUserBroadcasts({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetBroadcasts');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserBroadcasts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcasts', res.message.getBroadcastsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listOpenBroadcasts({
    // for showing on company profile
    commit,
    rootState,
  }) {
    commit('mutateReset');
    commit('mutateResetBroadcasts');
    commit('mutatePending', true);

    grpc.unary(MoonlightService.ListOpenBroadcasts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcasts', res.message.getBroadcastsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listOpenApprovedBroadcasts({ commit, rootState }) {
    commit('mutateReset');
    commit('mutateResetBroadcasts');
    commit('mutatePending', true);

    grpc.unary(MoonlightService.listOpenApprovedBroadcasts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcasts', res.message.getBroadcastsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listDeveloperOpenBroadcastMatches({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetBroadcasts');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListDeveloperOpenBroadcastMatches, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcasts', res.message.getBroadcastsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, userID }) {
    commit('mutateReset');

    // Only reset if fetching new user. Prevents flicker.
    if (state.broadcast && state.broadcast.getId() !== id) {
      commit('mutateResetBroadcast');
    }

    // check for cached broadcast
    let cachedBroadcast = null;
    _.forEach(state.broadcasts, (broadcast) => {
      if (broadcast.getId() === id) {
        // cache hit!
        cachedBroadcast = broadcast;
      }
    });

    if (cachedBroadcast) {
      // Data from cache!
      commit('mutateBroadcast', cachedBroadcast);
      // (Do not return - want to make sure we still have updated data)
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new BroadcastRequest();
    req.setId(id);
    req.setUserId(userID);

    grpc.unary(MoonlightService.ReadBroadcast, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateBroadcast', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, broadcast) {
    commit('mutateReset');

    // Cache original val, and revert if save fails.
    // Strategy is to immediately update broadcast, and
    // revert if the update fails
    let origBroadcast;
    _.each(state.broadcasts, (p) => {
      if (p.getId() === broadcast.getId()) {
        origBroadcast = new Broadcast(p.array.slice(0));
      }
    });
    commit('mutateBroadcast', new Broadcast(broadcast.array.slice(0)));
    commit('mutateUpdateBroadcastInCache', new Broadcast(broadcast.array.slice(0)));
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateBroadcast, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new Broadcast(broadcast.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update broadcast with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateBroadcast', res.message);
          commit('mutateUpdateBroadcastInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original broadcast!
          commit('mutateBroadcast', origBroadcast);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;

    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetBroadcasts(state) {
    state.broadcasts = null;
  },
  mutateResetBroadcast(state) {
    state.broadcast = null;
  },
  mutateBroadcasts(state, broadcasts) {
    state.broadcasts = broadcasts;
  },
  mutateBroadcast(state, broadcast) {
    state.broadcast = broadcast;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateUpdateBroadcastInCache(state, broadcast) {
    // remove broadcast from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.broadcasts.length; i++) {
      if (state.broadcasts[i].getId() === broadcast.getId()) {
        state.broadcasts[i] = broadcast;
        // Vue.set(state.broadcasts, i, broadcast);
      }
    }
  },
  mutateAddBroadcastToBroadcasts(state, broadcast) {
    state.broadcasts.push(broadcast);
  },
};

const getters = {
  getUnapprovedBroadcasts(state) {
    const res = [];
    if (state.broadcasts) {
      state.broadcasts.forEach((b) => {
        if (!b.getApproved() && b.getOpenForReview()) {
          res.push(b);
        }
      });
    }
    return res;
  },
  getBroadcastObj(state) {
    if (state.broadcast === null) {
      return null;
    }
    // directly calling toObject occassionally causes issues
    const copy = new Broadcast(state.broadcast.array.slice(0));

    const obj = copy.toObject();
    obj.createdAt = protoTimestampToDate(copy.getCreatedAt());
    obj.closeAt = protoTimestampToDate(copy.getCloseAt());
    return obj;
  },
  getBroadcastObjs(state) {
    const res = [];

    _.each(state.broadcasts, (p) => {
      const obj = new Broadcast(p.array.slice(0)).toObject();
      obj.createdAt = protoTimestampToDate(p.getCreatedAt());
      obj.closeAt = protoTimestampToDate(p.getCloseAt());
      res.push(obj);
    });
    // directly calling toObject occassionally causes issues
    return res;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
