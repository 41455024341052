import { guardPublicJob } from '@/conf/guards';

const BlogHome = () => import('@/views/marketing/BlogHome');

const BlogPost = () => import('@/views/marketing/BlogPost');

const Broadcasts = () => import('@/views/marketing/Broadcasts');

const CityLanding = () => import('@/views/marketing/CityLanding');

const CommunityGuidelines = () => import('@/views/marketing/CommunityGuidelines');

const Components = () => import('@/views/marketing/Components');

const CountryLanding = () => import('@/views/marketing/CountryLanding');

const Debug = () => import('@/views/marketing/Debug');

const Homepage = () => import('@/views/marketing/Homepage');

const Landing = () => import('@/views/marketing/Landing');

const LinkedInLanding = () => import('@/views/marketing/LinkedInLanding');

const NotFound = () => import('@/views/marketing/NotFound');

const PrivacyPolicy = () => import('@/views/marketing/PrivacyPolicy');

const PublicJob = () => import('@/views/marketing/PublicJob');

const ReferralLanding = () => import('@/views/marketing/ReferralLanding');

const RemoteWorkStore = () => import('@/views/marketing/RemoteWorkStore');

const SkillsDirectory = () => import('@/views/marketing/SkillsDirectory');

const TermsOfService = () => import('@/views/marketing/TermsOfService');

export default [
  {
    path: '/',
    component: Homepage,
    name: 'Marketing.Homepage',
  },
  {
    path: '/about',
    children: [
      {
        name: 'Marketing.About',
        path: '',
        redirect: { name: 'Marketing.Homepage' },
      },
      {
        name: 'Marketing.About.Overview',
        path: 'overview',
        redirect: { name: 'Marketing.Homepage' },
      },
      {
        name: 'Marketing.About.HowItWorks',
        path: 'how-it-works',
        redirect: { name: 'Marketing.Homepage' },
      },
      {
        name: 'Marketing.About.Team',
        path: 'team',
        redirect: { name: 'Marketing.Homepage' },
      },
    ],
  },
  {
    path: '/apply/',
    redirect: { name: 'Start.Dev.CreateAccount' },
  },
  {
    // Redirect legacy Start path
    path: '/work/',
    redirect: { name: 'Start.Dev.CreateAccount' },
  },
  {
    path: '/staffjoy/',
    redirect: { name: 'Marketing.Landing', params: { id: 'staffjoy' } },
  },
  {
    name: 'Marketing.Login',
    path: '/login/',
    redirect: { name: 'Auth.Login' },
  },
  {
    path: '/privacy-policy/',
    component: PrivacyPolicy,
    name: 'Marketing.PrivacyPolicy',
  },
  {
    path: 'companies/:companyID/jobs/:jobID',
    name: 'Marketing.PublicJob',
    component: PublicJob,
    props: true,
    beforeEnter: guardPublicJob,
  },
  {
    path: '/terms/',
    component: TermsOfService,
    name: 'Marketing.TermsOfService',
  },
  {
    path: '/community-guidelines/',
    component: CommunityGuidelines,
    name: 'Marketing.CommunityGuidelines',
  },
  {
    path: '/orbit/',
    redirect: { name: 'Onboarding.CreateOrbitAccount' },
  },
  {
    name: 'Marketing.OrbitIssue',
    path: '/orbit/weekly/',
    redirect: { name: 'App.Search' },
  },
  {
    component: Landing,
    name: 'Marketing.Landing',
    path: '/for/:slug',
    props: true,
  },
  {
    component: LinkedInLanding,
    name: 'Marketing.LinkedInLanding',
    path: '/linkedin',
  },
  {
    component: CountryLanding,
    name: 'Marketing.CountryLanding',
    path: '/countries/:slug',
    props: true,
  },
  {
    component: CityLanding,
    name: 'Marketing.CityLanding',
    path: '/cities/:slug',
    props: true,
  },
  {
    component: BlogHome,
    name: 'Marketing.BlogHome',
    path: '/blog/',
  },
  {
    component: BlogPost,
    name: 'Marketing.BlogPost',
    path: '/blog/:slug',
  },
  {
    component: Debug,
    name: 'Marketing.Debug',
    path: '/debug',
  },
  {
    component: Components,
    name: 'Marketing.Components',
    path: '/components',
  },
  {
    component: SkillsDirectory,
    name: 'Marketing.SkillsDirectory',
    path: '/skills-directory',
  },
  {
    component: RemoteWorkStore,
    name: 'Marketing.RemoteWorkStore',
    path: '/remote-work-store',
  },
  {
    component: ReferralLanding,
    name: 'Marketing.ReferralLanding',
    path: '/r/:userID',
    props: true,
  },
  {
    component: Broadcasts,
    name: 'Marketing.Broadcasts',
    path: 'broadcasts',
  },
  // Special: Software Engineering Daily podcast ad goes to Jeff Meyerson's userID
  {
    path: 'sed',
    redirect: {
      name: 'Marketing.ReferralLanding',
      params: { userID: 7631 },
    },
  },
  {
    path: 'sedaily',
    redirect: {
      name: 'Marketing.ReferralLanding',
      params: { userID: 7631 },
    },
  },
  {
    component: NotFound,
    name: 'Marketing.NotFound',
    path: '*',
  },
];
