const Dashboard = () => import('@/views/missioncontrol/Dashboard');

const ApplicantsList = () => import('@/views/missioncontrol/ApplicantsList');

const JobsList = () => import('@/views/missioncontrol/JobsList');

const BroadcastsList = () => import('@/views/missioncontrol/BroadcastsList');

const ApplicantReview = () => import('@/views/missioncontrol/ApplicantReview');

const UsersList = () => import('@/views/missioncontrol/UsersList');

const UserEdit = () => import('@/views/missioncontrol/UserEdit');

const CompaniesList = () => import('@/views/missioncontrol/CompaniesList');

const CompanyEdit = () => import('@/views/missioncontrol/CompanyEdit');

const SkillsList = () => import('@/views/missioncontrol/SkillsList');

const InvoicesList = () => import('@/views/missioncontrol/InvoicesList');

const InvoiceEdit = () => import('@/views/missioncontrol/InvoiceEdit');

const SyncUsers = () => import('@/views/missioncontrol/SyncUsers');

const NewsletterIntro = () => import('@/views/missioncontrol/NewsletterIntro');

const NewsletterStats = () => import('@/views/missioncontrol/NewsletterStats');

const ToolsUpload = () => import('@/views/missioncontrol/ToolsUpload');

const ToolsException = () => import('@/views/missioncontrol/ToolsException');

export default [
  {
    path: '',
    name: 'MissionControl.Dashboard',
    component: Dashboard,
  },
  {
    path: 'applicants',
    name: 'MissionControl.ApplicantsList',
    component: ApplicantsList,
  },
  {
    path: 'projects',
    redirect: 'jobs',
  },
  {
    path: 'jobs',
    name: 'MissionControl.JobsList',
    component: JobsList,
  },
  {
    path: 'broadcasts',
    name: 'MissionControl.BroadcastsList',
    component: BroadcastsList,
  },
  {
    path: 'applications/:id',
    name: 'MissionControl.ApplicantReview',
    component: ApplicantReview,
    props: true,
  },
  {
    path: 'users',
    name: 'MissionControl.UsersList',
    component: UsersList,
  },
  {
    path: 'users/:id',
    name: 'MissionControl.UserEdit',
    component: UserEdit,
    props: true,
  },
  {
    path: 'companies',
    name: 'MissionControl.CompaniesList',
    component: CompaniesList,
  },
  {
    path: 'companies/:id',
    name: 'MissionControl.CompanyEdit',
    component: CompanyEdit,
    props: true,
  },
  {
    path: 'skills',
    name: 'MissionControl.SkillsList',
    component: SkillsList,
  },
  {
    path: 'invoices',
    name: 'MissionControl.InvoicesList',
    component: InvoicesList,
  },
  {
    path: 'invoices/:id',
    name: 'MissionControl.InvoiceEdit',
    component: InvoiceEdit,
    props: true,
  },
  {
    path: 'sync-users',
    name: 'MissionControl.SyncUsers',
    component: SyncUsers,
  },
  {
    path: 'newsletter-intro',
    name: 'MissionControl.NewsletterIntro',
    component: NewsletterIntro,
  },
  {
    path: 'newsletter-stats',
    name: 'MissionControl.NewsletterStats',
    component: NewsletterStats,
  },
  {
    path: 'tools/upload',
    name: 'MissionControl.ToolsUpload',
    component: ToolsUpload,
  },
  {
    path: 'tools/exception',
    name: 'MissionControl.ToolsException',
    component: ToolsException,
  },
];
