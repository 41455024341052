/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import moment from 'moment';
import {
  Invoice, InvoiceRequest, JobRequest, GetInvoiceByID,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  invoice: null,

  invoices: [],

  pending: false, // overall invoices model

  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  listJob({ commit, state, rootState }, { jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetInvoices');
    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListJobInvoices, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateInvoices', res.message.getInvoicesList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listOpen({ commit, rootState }) {
    commit('mutateReset');
    commit('mutateResetInvoices');
    commit('mutatePending', true);

    grpc.unary(MoonlightService.ListOpenInvoices, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateInvoices', res.message.getInvoicesList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { invoiceID, jobID, companyID }) {
    commit('mutateReset');

    // Only reset if fetching new invoice. Prevents flicker.
    if (state.invoice && state.invoice.getId() !== invoiceID) {
      commit('mutateResetInvoice');
    }

    // check for cached invoice
    let cachedInvoice = null;
    _.forEach(state.invoices, (invoice) => {
      if (invoice.getId() === invoiceID) {
        // cache hit!
        cachedInvoice = invoice;
      }
    });

    if (cachedInvoice) {
      // Data from cache!
      commit('mutateInvoice', cachedInvoice);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new InvoiceRequest();
    req.setInvoiceId(invoiceID);
    req.setJobId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ReadInvoice, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateInvoice', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  readByID({
    commit, state, rootState, dispatch,
  }, invoiceID) {
    commit('mutateReset');

    // Only reset if fetching new invoice. Prevents flicker.
    if (state.invoice && state.invoice.getId() !== invoiceID) {
      commit('mutateResetInvoice');
    }

    // check for cached invoice
    let cachedInvoice = null;
    _.forEach(state.invoices, (invoice) => {
      if (invoice.getId() === invoiceID) {
        // cache hit!
        cachedInvoice = invoice;
      }
    });

    if (cachedInvoice) {
      // Data from cache!
      commit('mutateInvoice', cachedInvoice);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new GetInvoiceByID();
    req.setInvoiceId(invoiceID);

    grpc.unary(MoonlightService.ReadInvoiceByID, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateInvoice', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, invoice) {
    commit('mutateReset');

    // Update strategy is different here - do not immediately update
    // (because it's billing)

    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateInvoice, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new Invoice(invoice.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update lineItem with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateInvoice', res.message);
          commit('mutateUpdateInvoiceInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail!
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.pendingSkills = false;

    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetInvoices(state) {
    state.invoices = null;
  },
  mutateResetInvoice(state) {
    state.invoice = null;
  },
  mutateInvoices(state, invoices) {
    state.invoices = invoices;
  },
  mutateInvoice(state, invoice) {
    state.invoice = invoice;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateUpdateInvoiceInCache(state, invoice) {
    // remove invoice from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.invoices.length; i++) {
      if (state.invoices[i].getId() === invoice.getId()) {
        state.invoices[i] = invoice;
        // Vue.set(state.invoices, i, invoice);
      }
    }
  },
  mutateAddInvoiceToInvoices(state, invoice) {
    state.invoices.push(invoice);
  },
  mutateUpdateInvoiceByIndex(state, { ix, data }) {
    state.invoices[ix] = data;
    // Vue.set(state.invoices, ix, data);
  },
  mutateRemoveInvoiceInInvoices(state, ix) {
    state.invoices.splice(ix, 1);
  },
};

const getters = {
  getDeveloperCentsUnpaid(state) {
    let sum = 0;
    _.each(state.invoices, (iv) => {
      if (iv.getPaid() || iv.getVoided()) {
        return;
      }
      sum += iv.getCentsPayout();
    });
    return sum;
  },
  getDeveloperCentsPaid(state) {
    let sum = 0;
    _.each(state.invoices, (iv) => {
      if (!iv.getPaid() || iv.getVoided()) {
        return;
      }
      sum += iv.getCentsPayout();
    });
    return sum;
  },
  getClientCentsUnpaid(state) {
    let sum = 0;
    _.each(state.invoices, (iv) => {
      if (iv.getPaid() || iv.getVoided()) {
        return;
      }
      sum += iv.getCentsPayout() + iv.getCentsFee();
    });
    return sum;
  },
  getClientCentsPaid(state) {
    let sum = 0;
    _.each(state.invoices, (iv) => {
      if (!iv.getPaid() || iv.getVoided()) {
        return;
      }
      sum += iv.getCentsPayout() + iv.getCentsFee();
    });
    return sum;
  },
  getInvoiceObjs(state) {
    const res = [];
    _.each(state.invoices, (i) => {
      const iv = new Invoice(i.array.slice(0)).toObject();
      iv.dueAt = moment(protoTimestampToDate(i.getDueAt())).format('MMMM D, YYYY');
      iv.due = moment(protoTimestampToDate(i.getDueAt()));
      iv.createdAt = moment(protoTimestampToDate(i.getCreatedAt())).format('MMMM D, YYYY');
      iv.updatedat = moment(protoTimestampToDate(i.getUpdatedAt())).format('MMMM D, YYYY');
      iv.paidAt = moment(protoTimestampToDate(i.getPaidAt())).format('MMMM D, YYYY');

      res.push(iv);
    });
    return res;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
