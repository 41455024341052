import store from '@/store';

const defaultRedirectPublic = { name: 'Auth.Login' };

const defaultRedirectAuthenticated = { name: 'App.Dashboard' };

// REMEMBER TO `return` AFTER CALLING `next()`!

export function requireLogin(to) {
  return { name: 'Auth.Login', query: { continue: to.fullPath } };
}

export function smartRedirect() {
  if (!store.getters['auth/getIsLoggedIn']) {
    // Not logged in
    return defaultRedirectPublic;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    return defaultRedirectAuthenticated;
  }

  if (!user.emailConfirmed && !user.companyId) {
    // Not activated
    return { name: 'Onboarding.ConfirmEmail' };
  }

  if (store.getters['auth/getIsClient']) {
    return { name: 'App.Dashboard' };
  }

  if (user.admin) {
    return { name: 'MissionControl.Dashboard' };
  }

  if (user.developer) {
    return defaultRedirectAuthenticated;
  }

  // Check which onboarding stage to send them
  if (!user.headline) {
    return { name: 'Start.Dev.Detail' };
  }

  if (!user.hourlyRateCents || !user.yearsExperience) {
    return { name: 'Start.Dev.Rate' };
  }

  if (!user.bio) {
    return { name: 'Start.Dev.Overview' };
  }

  if (!user.mweStripeUserId) {
    return { name: 'Start.Dev.Stripe' };
  }

  if (!user.openDeveloperApplication) {
    return { name: 'Start.Dev.ReviewProfile' };
  }

  // redirect to profile page
  return { name: 'App.User.Profile', params: { id: store.getters['auth/getCurrentUser'].id } };
}

export function guardNotAuthenticated(to, from, next) {
  if (store.getters['auth/getIsLoggedIn']) {
    next(smartRedirect());
    return;
  }
  next();
}

export function guardAuthenticated(to, from, next) {
  if (store.getters['auth/getIsLoggedIn']) {
    next();
    return;
  }
  next(requireLogin(to));
}

export function guardAdmin(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next();
  }

  if (!user.admin) {
    // not admin
    next(smartRedirect());
    return;
  }
  next();
}

export function guardTractorBeam(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next();
  }

  if (!store.getters['auth/hasTractorBeamAccess']) {
    // not admin
    next(smartRedirect());
    return;
  }
  next();
}

export function guardDeveloper(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next();
  }

  if (!user.developer && !user.admin) {
    // not developer
    next(smartRedirect());
    return;
  }
  next();
}

export function guardClient(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next(smartRedirect());
  }

  if (user.admin) {
    next();
    return;
  }

  if (!user.companyId || user.companyId === 0) {
    next(smartRedirect());
    return;
  }

  next();
}

// Like guardClient, but redirects to developer dashboard
export function guardClientDashboard(to, from, next) {
  const isLoggedIn = store.getters['auth/getIsLoggedIn'];
  const user = store.getters['auth/getCurrentUser'];

  if (!isLoggedIn) {
    next({
      name: 'Marketing.PublicJob',
      params: {
        companyID: to.params.companyID,
        jobID: to.params.jobID,
      },
    });
    return;
  }

  // If user has not loaded - continue
  if (!user) {
    next(smartRedirect());
  }

  if (user.admin) {
    next();
    return;
  }

  if (!user.companyId || user.companyId === 0) {
    if (user.developer) {
      next({
        name: 'App.DeveloperJobDashboard',
        params: {
          companyID: to.params.companyID,
          jobID: to.params.jobID,
          userID: user.id,
        },
      });
      return;
    }

    next(smartRedirect());
    return;
  }

  next();
}

export function guardDevDashboard(to, from, next) {
  const isLoggedIn = store.getters['auth/getIsLoggedIn'];
  const user = store.getters['auth/getCurrentUser'];

  if (!isLoggedIn) {
    next({
      name: 'Auth.Login',
      query: { continue: to.fullPath },
    });
    return;
  }

  // If user has not loaded - continue
  if (!user) {
    next(smartRedirect());
  }

  if (user.admin) {
    next();
    return;
  }

  if (!user.developer && user.companyId > 0) {
    // TODO: What if a company is looking at another companies jobs?
    next({
      name: 'App.CompanyJobDashboard',
      params: {
        companyID: to.params.companyID,
        jobID: to.params.jobID,
      },
    });
    return;
  }

  next();
}

// Guards activation page, where user is logged in but not activated
export function guardNotActivated(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(defaultRedirectPublic);
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next();
  }

  if (user.emailConfirmed) {
    // Confirmed
    next(smartRedirect());
    return;
  }
  next();
}

// Guards user settings page, where only thte user or an admin can access
export function guardSelf(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }

  const user = store.getters['auth/getCurrentUser'];

  // If user has not loaded - continue
  if (!user) {
    next();
  }

  if (user.admin) {
    next();
    return;
  }

  if (user.id === Number(to.params.userID)) {
    next();
    return;
  }

  next(smartRedirect());
}

// guardSettings redirects users to their new settings page
export function guardSettings(to, from, next) {
  if (!store.getters['auth/getIsLoggedIn']) {
    // not logged in
    next(requireLogin(to));
    return;
  }
  const user = store.getters['auth/getCurrentUser'];

  next({
    name: 'App.User.SettingsPage',
    params: {
      userID: user.id,
    },
  });
}

export function guardPublicJob(to, from, next) {
  const isLoggedIn = store.getters['auth/getIsLoggedIn'];
  const user = store.getters['auth/getCurrentUser'];

  if (!isLoggedIn) {
    next();
    return;
  }

  // If user has not loaded - continue
  if (!user) {
    next(smartRedirect());
  }

  if (user.admin) {
    next();
    return;
  }

  if (user.developer) {
    next({
      name: 'App.DeveloperJobDashboard',
      params: {
        companyID: to.params.companyID,
        jobID: to.params.jobID,
        userID: user.id,
      },
    });
    return;
  }

  if (
    user.companyId > 0
    && user.companyId === Number(to.params.companyID)
  ) {
    // TODO: What if a company is looking at another companies jobs?
    next({
      name: 'App.CompanyJobDashboard',
      params: {
        companyID: to.params.companyID,
        jobID: to.params.jobID,
      },
    });
    return;
  }

  next();
}
