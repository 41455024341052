/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  ListLandingPageDevelopersRequest,
  ListCityLandingPageDevelopersRequest,
  ListCountryLandingPageDevelopersRequest,
} from '@/protoc/moonlight_pb';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  // homepage
  homepageDeveloperSummaryObjs: null,

  // landing
  landingDeveloperObjs: null,
  landingFeaturedDeveloperObj: null,
  city: null,
  cities: null,
  countryName: null,

  pending: false,

  errCode: null,
  errMsg: null,
};

const actions = {
  listHomepageDevelopers({ commit, rootState }) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new EmptyPB.Empty();
    grpc.unary(MoonlightService.ListDevelopersForHomepage, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateHomepageDevelopers', res.message.getResultsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listLandingDevelopers({ commit, rootState }, slug) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new ListLandingPageDevelopersRequest();
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.ListLandingPageDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLandingDevelopers', res.message.getDevelopersList());
          commit('mutateLandingFeaturedDeveloper', res.message.getFeaturedDeveloper());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listCityLandingDevelopers({ commit, rootState }, slug) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new ListCityLandingPageDevelopersRequest();
    req.setCitySlug(slug);

    grpc.unary(MoonlightService.ListCityLandingPageDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCity', res.message.getCity());
          commit('mutateLandingDevelopers', res.message.getDevelopersList());
          commit('mutateLandingFeaturedDeveloper', res.message.getFeaturedDeveloper());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listCountryLandingDevelopers({ commit, rootState }, slug) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new ListCountryLandingPageDevelopersRequest();
    req.setCountry(slug.toUpperCase());

    grpc.unary(MoonlightService.ListCountryLandingPageDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCities', res.message.getCitiesList());
          commit('mutateCountryName', res.message.getCountryName());
          commit('mutateLandingDevelopers', res.message.getDevelopersList());
          commit('mutateLandingFeaturedDeveloper', res.message.getFeaturedDeveloper());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
    state.city = null;
    state.cities = null;
    state.countryName = null;
  },
  mutateHomepageDevelopers(state, data) {
    state.homepageDeveloperSummaryObjs = [];
    _.each(data, (datum) => {
      state.homepageDeveloperSummaryObjs.push(datum.toObject());
    });
  },
  mutateLandingDevelopers(state, data) {
    state.landingDeveloperObjs = [];
    _.each(data, (datum) => {
      state.landingDeveloperObjs.push(datum.toObject());
    });
  },
  mutateLandingFeaturedDeveloper(state, data) {
    if (data) {
      state.landingFeaturedDeveloperObj = data.toObject();
    } else {
      state = null;
    }
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateCity(state, data) {
    state.city = data.toObject();
  },
  mutateCities(state, data) {
    state.cities = [];
    _.each(data, (datum) => {
      state.cities.push(datum.toObject());
    });
  },
  mutateCountryName(state, data) {
    state.countryName = data;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
