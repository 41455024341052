/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { grpc } from 'grpc-web-client';
import { each, findIndex } from 'lodash';
import { UserRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  jobs: [],

  pending: false, // overall jobs model

  errCode: null,
  errMsg: null,
};

const actions = {
  list({ commit, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetJobs');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListDeveloperDismissedJobMatches, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJobs', res.message.getJobsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  addDismissedJob({ commit }, { job }) {
    commit('mutateAddDismissedJob', job);
  },
  removeDismissedJob({ commit }, { jobID }) {
    commit('mutateRemoveDismissedJob', jobID);
  },
};

const mutations = {
  mutateAddDismissedJob(state, job) {
    state.jobs.unshift(job);
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateReset(state) {
    state.pending = false;

    state.errCode = null;
    state.errMsg = null;
  },
  mutateRemoveDismissedJob(state, jobID) {
    const index = findIndex(state.jobs, (job) => job.getId() === jobID);

    state.jobs.splice(index, 1);
  },
  mutateResetJobs(state) {
    state.jobs = null;
  },
  mutateJobs(state, jobs) {
    state.jobs = jobs;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
};

const getters = {
  getJobObjs(state) {
    const res = [];

    each(state.jobs, (p) => {
      const data = p.toObject();
      data.createdAt = protoTimestampToDate(p.getCreatedAt());
      data.updatedAt = protoTimestampToDate(p.getUpdatedAt());
      data.workStartedAt = protoTimestampToDate(p.getWorkStartedAt());
      data.finishedAt = protoTimestampToDate(p.getFinishedAt());
      data.canceledAt = protoTimestampToDate(p.getCanceledAt());

      res.push(data);
    });

    return res;
  },
  getJobByID: (state) => (jobID) => {
    const index = findIndex(state.jobs, (job) => job.getId() === Number(jobID));
    return index > -1 ? state.jobs[index] : null;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
