<template>
  <Field
    v-slot="{ handleChange, handleBlur, value, errors, meta }"
    v-model="innerValue"
    :name="name"
    :label="validateLabel || name"
    :rules="validate"
    :validate-on-input="validateOnInput"
  >
    <form-field
      :help="help"
      :label="label"
      :label-for="name"
      :type="(errors && errors[0] && meta.touched) ? 'is-warning' : (meta.touched && meta.valid) ? 'is-success' : null"
      :message="errors && meta.touched ? errors[0] : null"
      :expanded="expanded"
    >
      <o-input
        :id="name"
        :name="name"
        :model-value="value"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :variant="(errors && errors[0] && meta.touched) ? 'warning' : (meta.touched && meta.valid) ? 'success' : null"
        :inputmode="inputmode"
        :autocomplete="autocomplete"
        :data-form-type="dataFormType"
        @update:modelValue="handleChange"
        @blur="handleBlur"
      />
    </form-field>
  </Field>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    // Data for Dashlane password manager https://dashlane.github.io/SAWF/
    dataFormType: {
      type: String,
      required: false,
      default: 'other',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    // help displays below title
    help: {
      type: String,
      required: false,
      default: '',
    },
    initialValue: {
      type: String,
      required: false,
      default: '',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: [Number, String],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    validate: {
      type: [String, Object],
      required: false,
      default: '',
    },
    validateLabel: {
      type: String,
      required: false,
      default: '',
    },
    // validateOnInput shows the error message as helper text while
    // typing. We use it on password, which requires 10 characters
    validateOnInput: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      innerValue: this.modelValue || this.initialValue || '',
    };
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('update:modelValue', newVal);
    },
    // Handles external model changes.
    modelValue(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    handleInput(val) {
      this.$emit('update:modelValue', val);
    },
  },
};
</script>
