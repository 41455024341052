/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
const namespaced = true;

// initial state
const state = {
  title: null,
  overview: null,
  slugs: [],
  seekingContractors: false,
  seekingEmployees: false,
  collectJobType: true,
  spec: null,
};

const actions = {};

const mutations = {
  mutateReset(state) {
    state.title = null;
    state.overview = null;

    state.slugs = [];
    state.seekingContractors = false;
    state.seekingEmployees = false;
    state.collectJobType = true;
    state.spec = null;
  },
  mutateTitle(state, title) {
    state.title = title;
  },
  mutateOverview(state, overview) {
    state.overview = overview;
  },
  mutateSlugs(state, slugs) {
    state.slugs = slugs;
  },
  mutateSeekingContractors(state, seekingContractors) {
    state.seekingContractors = seekingContractors;
  },
  mutateSeekingEmployees(state, seekingEmployees) {
    state.seekingEmployees = seekingEmployees;
  },
  mutateCollectJobType(state, collectJobType) {
    state.collectJobType = collectJobType;
  },
  mutateSpec(state, spec) {
    state.spec = spec;
  },
  mutateRemoveSlugInSlugs(state, index) {
    state.slugs.splice(index, 1);
  },
  mutateUpdateSlugInSlugs(state, { index, newSlug }) {
    state.slugs.splice(index, 1, newSlug);
  },
  mutateAddSlugToSlugs(state, newSlug) {
    state.slugs.push(newSlug);
  },
};

const getters = {
  hasSetJobType(state) {
    return state.seekingContractors || state.seekingEmployees;
  },
  hasSetSkills(state) {
    return state.slugs && state.slugs.length;
  },
  hasSetSpec(state) {
    return state.spec && state.spec.length;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
