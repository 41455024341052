import { createStore as _createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookie from 'js-cookie';
import { isProduction } from '@/helpers';

import blog from './modules/blog';
import auth from './modules/auth';
import applicants from './modules/applicants';
import users from './modules/users';
import usermetas from './modules/usermetas';
import skillsdirectory from './modules/skillsdirectory';
import skills from './modules/skills';
import passwords from './modules/passwords';
import emails from './modules/emails';
import recommendeddevelopers from './modules/recommendeddevelopers';
import companies from './modules/companies';
import companymetas from './modules/companymetas';
import companypaymentmethods from './modules/companypaymentmethods';
import search from './modules/search';
import marketing from './modules/marketing';
import jobsummaries from './modules/jobsummaries';
import jobs from './modules/jobs';
import jobmatches from './modules/jobmatches';
import applications from './modules/applications';
import lineitems from './modules/lineitems';
import invoices from './modules/invoices';
import invoicesummary from './modules/invoicesummary';
import kpis from './modules/kpis';
import leads from './modules/leads';
import referrals from './modules/referrals';
import createjobcache from './modules/createjobcache';
import directhirejobcache from './modules/directhirejobcache';
import createapplicationcache from './modules/createapplicationcache';
import chat from './modules/chat';
import subscriptions from './modules/subscriptions';
import signup from './modules/signup';
import exampledevelopers from './modules/exampledevelopers';
import livestats from './modules/livestats';
import createbroadcastcache from './modules/createbroadcastcache';
import broadcasts from './modules/broadcasts';
import developerjobmatch from './modules/developerjobmatch';
import dismissedjobmatches from './modules/dismissedjobmatches';

const debug = process.env.NODE_ENV !== 'production';

export default _createStore({
  modules: {
    blog,
    auth,
    applicants,
    users,
    usermetas,
    skills,
    passwords,
    emails,
    recommendeddevelopers,
    companies,
    companymetas,
    search,
    marketing,
    companypaymentmethods,
    jobsummaries,
    jobs,
    jobmatches,
    applications,
    lineitems,
    invoices,
    invoicesummary,
    kpis,
    leads,
    referrals,
    createjobcache,
    directhirejobcache,
    createapplicationcache,
    skillsdirectory,
    chat,
    subscriptions,
    signup,
    exampledevelopers,
    livestats,
    createbroadcastcache,
    broadcasts,
    developerjobmatch,
    dismissedjobmatches,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: isProduction() ? 'moonlight-production' : 'moonlight-dev',
      paths: [
        //  Note: Protobuf objs don't decode here with their correct methods
        'auth.session',
      ],
      getState: (key) => {
        const cookieContent = Cookie.get(key);
        if (!cookieContent) {
          return {};
        }
        return JSON.parse(cookieContent);
      },
      setState: (key, state) => {
        let expires = new Date();

        if (state.auth.session) {
          expires = new Date(state.auth.session.valid_until);
        }

        Cookie.set(key, JSON.stringify(state), {
          expires,
          domain: isProduction() ? 'moonlightwork.com' : null,
          secure: isProduction(),
          sameSite: 'strict',
        });
      },
    }),
    createPersistedState({
      key: isProduction() ? 'moonlight-production-user' : 'moonlight-dev-user',
      paths: [
        //  Note: Protobuf objs don't decode here with their correct methods
        'auth.currentUser',
        'auth.company',
        'auth.intercomUserHash',
      ],
    }),
    // Store pending job creation post in a cache
    createPersistedState({
      key: isProduction() ? 'moonlight-production-createjobcache' : 'moonlight-dev-createjobcache',
      paths: [
        'createjobcache.title',
        'createjobcache.overview',
        'createjobcache.slugs',
        'createjobcache.seekingContractor',
        'createjobcache.seekingEmployees',
        'createjobcache.collectJobType',
        'createjobcache.spec',
      ],
    }),
    // Direct hire cache
    createPersistedState({
      key: isProduction()
        ? 'moonlight-production-directhirejobcache'
        : 'moonlight-dev-directhirejobcache',
      paths: ['directhirejobcache.cache'],
      // Uses local storage
    }),
    // create broadcast cache
    createPersistedState({
      key: isProduction()
        ? 'moonlight-production-createbroadcastcache'
        : 'moonlight-dev-createbroadcastcache',
      paths: [
        'createbroadcastcache.seekingEmployment',
        'createbroadcastcache.seekingContracts',
        'createbroadcastcache.hoursAvailablePerWeek',
        'createbroadcastcache.idealJob',
        'createbroadcastcache.bio',
        'createbroadcastcache.recentAccomplishments',
      ],
      // Uses local storage
    }),
    // Proposal cache
    createPersistedState({
      key: isProduction()
        ? 'moonlight-production-createapplicationcache'
        : 'moonlight-dev-createapplicationcache',
      paths: ['createapplicationcache.cache'],
      // uses local storage
    }),
    // Referrals cache
    createPersistedState({
      key: isProduction() ? 'moonlight-production-attribution' : 'moonlight-dev-attribution',
      paths: ['referrals.landingPage', 'referrals.referredByUserID'],
      // uses cookie so it expires after a week
      getState: (key) => {
        const cookieContent = Cookie.get(key);
        if (!cookieContent) {
          return {};
        }
        return JSON.parse(cookieContent);
      },
      setState: (key, state) => {
        Cookie.set(key, JSON.stringify(state), {
          expires: 7, // 7 days
          domain: isProduction() ? 'moonlightwork.com' : null,
          secure: isProduction(),
          sameSite: 'strict',
        });
      },
    }),
  ],
});
