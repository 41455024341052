/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.exportSymbol('proto.moonlight.ActivateCompanyContingencyAgreementRequest', null, global);
goog.exportSymbol('proto.moonlight.Address', null, global);
goog.exportSymbol('proto.moonlight.Application', null, global);
goog.exportSymbol('proto.moonlight.ApplicationRequest', null, global);
goog.exportSymbol('proto.moonlight.ApplicationsList', null, global);
goog.exportSymbol('proto.moonlight.Broadcast', null, global);
goog.exportSymbol('proto.moonlight.BroadcastRequest', null, global);
goog.exportSymbol('proto.moonlight.BroadcastsList', null, global);
goog.exportSymbol('proto.moonlight.BroadcastsListRequest', null, global);
goog.exportSymbol('proto.moonlight.BulkChangeUserActiveStatusRQ', null, global);
goog.exportSymbol('proto.moonlight.BulkCreateLeadsRequest', null, global);
goog.exportSymbol('proto.moonlight.Channel', null, global);
goog.exportSymbol('proto.moonlight.ChannelMessagesList', null, global);
goog.exportSymbol('proto.moonlight.ChannelsList', null, global);
goog.exportSymbol('proto.moonlight.City', null, global);
goog.exportSymbol('proto.moonlight.CityQuery', null, global);
goog.exportSymbol('proto.moonlight.CityRequest', null, global);
goog.exportSymbol('proto.moonlight.CompaniesList', null, global);
goog.exportSymbol('proto.moonlight.Company', null, global);
goog.exportSymbol('proto.moonlight.CompanyBankAccountPaymentMethodRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyCardPaymentMethodRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyChannelLastRead', null, global);
goog.exportSymbol('proto.moonlight.CompanyCheckoutRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyCheckoutSession', null, global);
goog.exportSymbol('proto.moonlight.CompanyJobStats', null, global);
goog.exportSymbol('proto.moonlight.CompanyLink', null, global);
goog.exportSymbol('proto.moonlight.CompanyLinkRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyLinksList', null, global);
goog.exportSymbol('proto.moonlight.CompanyMeta', null, global);
goog.exportSymbol('proto.moonlight.CompanyMetaRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyMetasList', null, global);
goog.exportSymbol('proto.moonlight.CompanyPaymentMethod', null, global);
goog.exportSymbol('proto.moonlight.CompanyPaymentMethodRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanyPaymentMethodsList', null, global);
goog.exportSymbol('proto.moonlight.CompanyRequest', null, global);
goog.exportSymbol('proto.moonlight.CompanySkillRequest', null, global);
goog.exportSymbol('proto.moonlight.ConfirmEmailWithCodeRequest', null, global);
goog.exportSymbol('proto.moonlight.ContingencyAgreement', null, global);
goog.exportSymbol('proto.moonlight.ContingencyAgreementSigningURL', null, global);
goog.exportSymbol('proto.moonlight.CountryTimezonesRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateApplicationRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateBroadcastRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateCompanyLinkRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateCompanyMemberRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateEmailRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateHiringCompanyRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateJobRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateLeadRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateLineItemRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateSkillRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateTransactionalEmailRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateUserExperienceRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateUserLinkRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateUserNewsletterRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateUserRequest', null, global);
goog.exportSymbol('proto.moonlight.CreateUserSessionRequest', null, global);
goog.exportSymbol('proto.moonlight.DeleteCompanyMemberRequest', null, global);
goog.exportSymbol('proto.moonlight.DeleteUserRQ', null, global);
goog.exportSymbol('proto.moonlight.DeveloperJobMatch', null, global);
goog.exportSymbol('proto.moonlight.DeveloperJobMatchList', null, global);
goog.exportSymbol('proto.moonlight.DeveloperJobMatchRequest', null, global);
goog.exportSymbol('proto.moonlight.DeveloperSearch', null, global);
goog.exportSymbol('proto.moonlight.DeveloperSearchCity', null, global);
goog.exportSymbol('proto.moonlight.DeveloperSearchCountry', null, global);
goog.exportSymbol('proto.moonlight.DeveloperSearchSkill', null, global);
goog.exportSymbol('proto.moonlight.DeveloperSummary', null, global);
goog.exportSymbol('proto.moonlight.DevelopersForHomepageResponse', null, global);
goog.exportSymbol('proto.moonlight.DirectInterview', null, global);
goog.exportSymbol('proto.moonlight.Email', null, global);
goog.exportSymbol('proto.moonlight.EmailActivitiesList', null, global);
goog.exportSymbol('proto.moonlight.EmailActivity', null, global);
goog.exportSymbol('proto.moonlight.EmailClick', null, global);
goog.exportSymbol('proto.moonlight.EmailOpen', null, global);
goog.exportSymbol('proto.moonlight.EmailRequest', null, global);
goog.exportSymbol('proto.moonlight.EmailUnsubscribeRequest', null, global);
goog.exportSymbol('proto.moonlight.Event', null, global);
goog.exportSymbol('proto.moonlight.ExampleDevelopersResponse', null, global);
goog.exportSymbol('proto.moonlight.FileUploadURL', null, global);
goog.exportSymbol('proto.moonlight.FilteredCompanyRequest', null, global);
goog.exportSymbol('proto.moonlight.FilteredUserRequest', null, global);
goog.exportSymbol('proto.moonlight.ForgottenPasswordResetRequest', null, global);
goog.exportSymbol('proto.moonlight.FullStorySession', null, global);
goog.exportSymbol('proto.moonlight.FullStorySessionsList', null, global);
goog.exportSymbol('proto.moonlight.GetInvoiceByID', null, global);
goog.exportSymbol('proto.moonlight.GlobalSettings', null, global);
goog.exportSymbol('proto.moonlight.HiringCompany', null, global);
goog.exportSymbol('proto.moonlight.IP', null, global);
goog.exportSymbol('proto.moonlight.InternalUserSession', null, global);
goog.exportSymbol('proto.moonlight.InterviewMessageRequest', null, global);
goog.exportSymbol('proto.moonlight.Invoice', null, global);
goog.exportSymbol('proto.moonlight.InvoiceRequest', null, global);
goog.exportSymbol('proto.moonlight.InvoicesList', null, global);
goog.exportSymbol('proto.moonlight.Job', null, global);
goog.exportSymbol('proto.moonlight.JobRequest', null, global);
goog.exportSymbol('proto.moonlight.JobSkillRequest', null, global);
goog.exportSymbol('proto.moonlight.JobSummariesList', null, global);
goog.exportSymbol('proto.moonlight.JobSummary', null, global);
goog.exportSymbol('proto.moonlight.JobsList', null, global);
goog.exportSymbol('proto.moonlight.KPIs', null, global);
goog.exportSymbol('proto.moonlight.Lead', null, global);
goog.exportSymbol('proto.moonlight.LeadList', null, global);
goog.exportSymbol('proto.moonlight.LeadRequest', null, global);
goog.exportSymbol('proto.moonlight.LeadSkillRequest', null, global);
goog.exportSymbol('proto.moonlight.LineItem', null, global);
goog.exportSymbol('proto.moonlight.LineItemRequest', null, global);
goog.exportSymbol('proto.moonlight.LineItemsList', null, global);
goog.exportSymbol('proto.moonlight.LinkClickCount', null, global);
goog.exportSymbol('proto.moonlight.ListCityLandingPageDevelopersRequest', null, global);
goog.exportSymbol('proto.moonlight.ListCityLandingPageDevelopersResponse', null, global);
goog.exportSymbol('proto.moonlight.ListCountryLandingPageDevelopersRequest', null, global);
goog.exportSymbol('proto.moonlight.ListCountryLandingPageDevelopersResponse', null, global);
goog.exportSymbol('proto.moonlight.ListLandingPageDevelopersRequest', null, global);
goog.exportSymbol('proto.moonlight.ListLandingPageDevelopersResponse', null, global);
goog.exportSymbol('proto.moonlight.ListRequest', null, global);
goog.exportSymbol('proto.moonlight.LiveStatsResponse', null, global);
goog.exportSymbol('proto.moonlight.LoginResponse', null, global);
goog.exportSymbol('proto.moonlight.Message', null, global);
goog.exportSymbol('proto.moonlight.MessageWithContext', null, global);
goog.exportSymbol('proto.moonlight.Newsletter', null, global);
goog.exportSymbol('proto.moonlight.NewsletterCategoryRequest', null, global);
goog.exportSymbol('proto.moonlight.NewsletterIntro', null, global);
goog.exportSymbol('proto.moonlight.NewsletterStats', null, global);
goog.exportSymbol('proto.moonlight.NewsletterStatsList', null, global);
goog.exportSymbol('proto.moonlight.OutreachOAuthURL', null, global);
goog.exportSymbol('proto.moonlight.OutreachTemporaryCode', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetInfo', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetInfoRequest', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetRequest', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetToken', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetTokenRequest', null, global);
goog.exportSymbol('proto.moonlight.PasswordResetWithTokenRequest', null, global);
goog.exportSymbol('proto.moonlight.PublicInvoiceRequest', null, global);
goog.exportSymbol('proto.moonlight.PublicInvoiceSummary', null, global);
goog.exportSymbol('proto.moonlight.ReadLeadByEmailRequest', null, global);
goog.exportSymbol('proto.moonlight.RecommendedDevelopersRequest', null, global);
goog.exportSymbol('proto.moonlight.ReferralPayout', null, global);
goog.exportSymbol('proto.moonlight.ReferralPayoutSummary', null, global);
goog.exportSymbol('proto.moonlight.ScrapeRequest', null, global);
goog.exportSymbol('proto.moonlight.SearchDevelopersRequest', null, global);
goog.exportSymbol('proto.moonlight.SearchDevelopersResponse', null, global);
goog.exportSymbol('proto.moonlight.ShortCreateLeadRequest', null, global);
goog.exportSymbol('proto.moonlight.Skill', null, global);
goog.exportSymbol('proto.moonlight.SkillRequest', null, global);
goog.exportSymbol('proto.moonlight.SkillScores', null, global);
goog.exportSymbol('proto.moonlight.SkillSuggestionModel', null, global);
goog.exportSymbol('proto.moonlight.SkillWithCount', null, global);
goog.exportSymbol('proto.moonlight.SkillsList', null, global);
goog.exportSymbol('proto.moonlight.SkillsWithCountsList', null, global);
goog.exportSymbol('proto.moonlight.SlackGroup', null, global);
goog.exportSymbol('proto.moonlight.SparkpostEmailRequest', null, global);
goog.exportSymbol('proto.moonlight.StripeExpressPaymentsURL', null, global);
goog.exportSymbol('proto.moonlight.Subscription', null, global);
goog.exportSymbol('proto.moonlight.SubscriptionInvoice', null, global);
goog.exportSymbol('proto.moonlight.SubscriptionInvoiceRequest', null, global);
goog.exportSymbol('proto.moonlight.SubscriptionInvoicesList', null, global);
goog.exportSymbol('proto.moonlight.SubscriptionRequest', null, global);
goog.exportSymbol('proto.moonlight.SubscriptionsList', null, global);
goog.exportSymbol('proto.moonlight.TimeSeriesKPI', null, global);
goog.exportSymbol('proto.moonlight.Timezone', null, global);
goog.exportSymbol('proto.moonlight.TimezoneList', null, global);
goog.exportSymbol('proto.moonlight.UpdateUserStripeRequest', null, global);
goog.exportSymbol('proto.moonlight.User', null, global);
goog.exportSymbol('proto.moonlight.UserChannelLastRead', null, global);
goog.exportSymbol('proto.moonlight.UserChannelMessagesRequest', null, global);
goog.exportSymbol('proto.moonlight.UserEmailRequest', null, global);
goog.exportSymbol('proto.moonlight.UserEmailUpdateWithTokenRequest', null, global);
goog.exportSymbol('proto.moonlight.UserExperience', null, global);
goog.exportSymbol('proto.moonlight.UserExperienceRequest', null, global);
goog.exportSymbol('proto.moonlight.UserExperienceSkillRequest', null, global);
goog.exportSymbol('proto.moonlight.UserExperiencesList', null, global);
goog.exportSymbol('proto.moonlight.UserFileUploadRequest', null, global);
goog.exportSymbol('proto.moonlight.UserLink', null, global);
goog.exportSymbol('proto.moonlight.UserLinkRequest', null, global);
goog.exportSymbol('proto.moonlight.UserLinksList', null, global);
goog.exportSymbol('proto.moonlight.UserList', null, global);
goog.exportSymbol('proto.moonlight.UserMeta', null, global);
goog.exportSymbol('proto.moonlight.UserMetaRequest', null, global);
goog.exportSymbol('proto.moonlight.UserMetasList', null, global);
goog.exportSymbol('proto.moonlight.UserPublicStats', null, global);
goog.exportSymbol('proto.moonlight.UserReferralPayoutsList', null, global);
goog.exportSymbol('proto.moonlight.UserRequest', null, global);
goog.exportSymbol('proto.moonlight.UserSearchRequest', null, global);
goog.exportSymbol('proto.moonlight.UserSession', null, global);
goog.exportSymbol('proto.moonlight.UserSessionInfo', null, global);
goog.exportSymbol('proto.moonlight.UserSessionRequest', null, global);
goog.exportSymbol('proto.moonlight.UserSessionsInfoList', null, global);
goog.exportSymbol('proto.moonlight.UserSkillRequest', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListRequest.displayName = 'proto.moonlight.ListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    afterId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListRequest}
 */
proto.moonlight.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListRequest;
  return proto.moonlight.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListRequest}
 */
proto.moonlight.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAfterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAfterId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.moonlight.ListRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.ListRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 after_id = 2;
 * @return {number}
 */
proto.moonlight.ListRequest.prototype.getAfterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.ListRequest.prototype.setAfterId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Event.displayName = 'proto.moonlight.Event';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    targetUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetCompanyId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    targetType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    targetId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    action: jspb.Message.getFieldWithDefault(msg, 9, ""),
    beforeData: jspb.Message.getFieldWithDefault(msg, 10, ""),
    afterData: jspb.Message.getFieldWithDefault(msg, 11, ""),
    authType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    authId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    authz: jspb.Message.getFieldWithDefault(msg, 14, ""),
    metadata: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Event}
 */
proto.moonlight.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Event;
  return proto.moonlight.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Event}
 */
proto.moonlight.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetUserId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetCompanyId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeforeData(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterData(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthz(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActiveUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTargetUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTargetCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTargetType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBeforeData();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAfterData();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAuthType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAuthId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAuthz();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Event.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Event.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 active_user_id = 2;
 * @return {number}
 */
proto.moonlight.Event.prototype.getActiveUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Event.prototype.setActiveUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 target_user_id = 3;
 * @return {number}
 */
proto.moonlight.Event.prototype.getTargetUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Event.prototype.setTargetUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 target_company_id = 4;
 * @return {number}
 */
proto.moonlight.Event.prototype.getTargetCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.Event.prototype.setTargetCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Event.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Event.prototype.setTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.Event.prototype.clearTimestamp = function() {
  this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Event.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string ip = 6;
 * @return {string}
 */
proto.moonlight.Event.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setIp = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string target_type = 7;
 * @return {string}
 */
proto.moonlight.Event.prototype.getTargetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setTargetType = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string target_id = 8;
 * @return {string}
 */
proto.moonlight.Event.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setTargetId = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string action = 9;
 * @return {string}
 */
proto.moonlight.Event.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setAction = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string before_data = 10;
 * @return {string}
 */
proto.moonlight.Event.prototype.getBeforeData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setBeforeData = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string after_data = 11;
 * @return {string}
 */
proto.moonlight.Event.prototype.getAfterData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setAfterData = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string auth_type = 12;
 * @return {string}
 */
proto.moonlight.Event.prototype.getAuthType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setAuthType = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string auth_id = 13;
 * @return {string}
 */
proto.moonlight.Event.prototype.getAuthId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setAuthId = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string authz = 14;
 * @return {string}
 */
proto.moonlight.Event.prototype.getAuthz = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setAuthz = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string metadata = 15;
 * @return {string}
 */
proto.moonlight.Event.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.Event.prototype.setMetadata = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeleteUserRQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeleteUserRQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeleteUserRQ.displayName = 'proto.moonlight.DeleteUserRQ';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeleteUserRQ.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeleteUserRQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeleteUserRQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeleteUserRQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeleteUserRQ}
 */
proto.moonlight.DeleteUserRQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeleteUserRQ;
  return proto.moonlight.DeleteUserRQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeleteUserRQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeleteUserRQ}
 */
proto.moonlight.DeleteUserRQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeleteUserRQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeleteUserRQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeleteUserRQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeleteUserRQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.DeleteUserRQ.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.DeleteUserRQ.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.BulkChangeUserActiveStatusRQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.BulkChangeUserActiveStatusRQ.repeatedFields_, null);
};
goog.inherits(proto.moonlight.BulkChangeUserActiveStatusRQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.BulkChangeUserActiveStatusRQ.displayName = 'proto.moonlight.BulkChangeUserActiveStatusRQ';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.BulkChangeUserActiveStatusRQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.BulkChangeUserActiveStatusRQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    shouldDeactivate: jspb.Message.getFieldWithDefault(msg, 1, false),
    shouldActivate: jspb.Message.getFieldWithDefault(msg, 2, false),
    emailsList: jspb.Message.getRepeatedField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.BulkChangeUserActiveStatusRQ}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.BulkChangeUserActiveStatusRQ;
  return proto.moonlight.BulkChangeUserActiveStatusRQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.BulkChangeUserActiveStatusRQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.BulkChangeUserActiveStatusRQ}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldDeactivate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldActivate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.BulkChangeUserActiveStatusRQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.BulkChangeUserActiveStatusRQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShouldDeactivate();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getShouldActivate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional bool should_deactivate = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.getShouldDeactivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.setShouldDeactivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool should_activate = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.getShouldActivate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.setShouldActivate = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string emails = 3;
 * @return {!Array.<string>}
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.getEmailsList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array.<string>} value */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.setEmailsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.addEmails = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.moonlight.BulkChangeUserActiveStatusRQ.prototype.clearEmailsList = function() {
  this.setEmailsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserRequest.displayName = 'proto.moonlight.UserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserRequest}
 */
proto.moonlight.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserRequest;
  return proto.moonlight.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserRequest}
 */
proto.moonlight.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserFileUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserFileUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserFileUploadRequest.displayName = 'proto.moonlight.UserFileUploadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserFileUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserFileUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserFileUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserFileUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserFileUploadRequest}
 */
proto.moonlight.UserFileUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserFileUploadRequest;
  return proto.moonlight.UserFileUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserFileUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserFileUploadRequest}
 */
proto.moonlight.UserFileUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserFileUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserFileUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserFileUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserFileUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserFileUploadRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserFileUploadRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.moonlight.UserFileUploadRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UserFileUploadRequest.prototype.setFileName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mime_type = 3;
 * @return {string}
 */
proto.moonlight.UserFileUploadRequest.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.UserFileUploadRequest.prototype.setMimeType = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserEmailRequest.displayName = 'proto.moonlight.UserEmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserEmailRequest}
 */
proto.moonlight.UserEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserEmailRequest;
  return proto.moonlight.UserEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserEmailRequest}
 */
proto.moonlight.UserEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserEmailRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserEmailRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.moonlight.UserEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UserEmailRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserEmailUpdateWithTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserEmailUpdateWithTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserEmailUpdateWithTokenRequest.displayName = 'proto.moonlight.UserEmailUpdateWithTokenRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserEmailUpdateWithTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserEmailUpdateWithTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserEmailUpdateWithTokenRequest}
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserEmailUpdateWithTokenRequest;
  return proto.moonlight.UserEmailUpdateWithTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserEmailUpdateWithTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserEmailUpdateWithTokenRequest}
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserEmailUpdateWithTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserEmailUpdateWithTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.moonlight.UserEmailUpdateWithTokenRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserEmailUpdateWithTokenRequest.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.FilteredUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.FilteredUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.FilteredUserRequest.displayName = 'proto.moonlight.FilteredUserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.FilteredUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.FilteredUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.FilteredUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FilteredUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.FilteredUserRequest}
 */
proto.moonlight.FilteredUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.FilteredUserRequest;
  return proto.moonlight.FilteredUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.FilteredUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.FilteredUserRequest}
 */
proto.moonlight.FilteredUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.FilteredUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.FilteredUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.FilteredUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FilteredUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActiveUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 target_user_id = 1;
 * @return {number}
 */
proto.moonlight.FilteredUserRequest.prototype.getTargetUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.FilteredUserRequest.prototype.setTargetUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 active_user_id = 2;
 * @return {number}
 */
proto.moonlight.FilteredUserRequest.prototype.getActiveUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.FilteredUserRequest.prototype.setActiveUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSearchRequest.displayName = 'proto.moonlight.UserSearchRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSearchRequest}
 */
proto.moonlight.UserSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSearchRequest;
  return proto.moonlight.UserSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSearchRequest}
 */
proto.moonlight.UserSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.moonlight.UserSearchRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserSearchRequest.prototype.setQuery = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.User.displayName = 'proto.moonlight.User';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.User.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailConfirmed: jspb.Message.getFieldWithDefault(msg, 4, false),
    admin: jspb.Message.getFieldWithDefault(msg, 5, false),
    deactivated: jspb.Message.getFieldWithDefault(msg, 6, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    headline: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phoneConfirmed: jspb.Message.getFieldWithDefault(msg, 12, false),
    country: jspb.Message.getFieldWithDefault(msg, 13, ""),
    heardThrough: jspb.Message.getFieldWithDefault(msg, 14, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 15, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 16, ""),
    developer: jspb.Message.getFieldWithDefault(msg, 17, false),
    openDeveloperApplication: jspb.Message.getFieldWithDefault(msg, 18, false),
    yearsExperience: jspb.Message.getFieldWithDefault(msg, 19, 0),
    hourlyRateCents: jspb.Message.getFieldWithDefault(msg, 20, 0),
    hoursAvailablePerWeek: jspb.Message.getFieldWithDefault(msg, 21, 0),
    stripeUserId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    stripeAccountType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    termsOfServiceAgreed: (f = msg.getTermsOfServiceAgreed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 27, ""),
    theme: jspb.Message.getFieldWithDefault(msg, 28, ""),
    publicProfile: jspb.Message.getFieldWithDefault(msg, 29, false),
    bio: jspb.Message.getFieldWithDefault(msg, 30, ""),
    subscribedNewSkills: jspb.Message.getFieldWithDefault(msg, 31, false),
    canApplyAfter: (f = msg.getCanApplyAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    companyId: jspb.Message.getFieldWithDefault(msg, 33, 0),
    hasTractorBeamAccess: jspb.Message.getFieldWithDefault(msg, 34, false),
    landingPage: jspb.Message.getFieldWithDefault(msg, 36, ""),
    referredByUserId: jspb.Message.getFieldWithDefault(msg, 37, 0),
    subscribedNewJobs: jspb.Message.getFieldWithDefault(msg, 38, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 39, false),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 40, false),
    slackId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    communityInvitationSent: jspb.Message.getFieldWithDefault(msg, 42, false),
    subscribedReminderLogHours: jspb.Message.getFieldWithDefault(msg, 43, false),
    subscribedNewMessages: jspb.Message.getFieldWithDefault(msg, 44, false),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 45, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 46, ""),
    addressCity: jspb.Message.getFieldWithDefault(msg, 47, ""),
    addressAdministrative: jspb.Message.getFieldWithDefault(msg, 48, ""),
    addressCountry: jspb.Message.getFieldWithDefault(msg, 49, ""),
    addressPostcode: jspb.Message.getFieldWithDefault(msg, 50, ""),
    addressLat: +jspb.Message.getFieldWithDefault(msg, 51, 0.0),
    addressLon: +jspb.Message.getFieldWithDefault(msg, 52, 0.0),
    location: jspb.Message.getFieldWithDefault(msg, 53, ""),
    locationLat: +jspb.Message.getFieldWithDefault(msg, 54, 0.0),
    locationLon: +jspb.Message.getFieldWithDefault(msg, 55, 0.0),
    subscribedNewReferrals: jspb.Message.getFieldWithDefault(msg, 56, false),
    stripeBusinessName: jspb.Message.getFieldWithDefault(msg, 57, ""),
    subscribedNewsletter: jspb.Message.getFieldWithDefault(msg, 58, false),
    approvedAt: (f = msg.getApprovedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    subscribedBroadcasts: jspb.Message.getFieldWithDefault(msg, 60, false),
    pronounSubject: jspb.Message.getFieldWithDefault(msg, 61, ""),
    pronounObject: jspb.Message.getFieldWithDefault(msg, 62, ""),
    referrerUrl: jspb.Message.getFieldWithDefault(msg, 63, ""),
    signupPagePath: jspb.Message.getFieldWithDefault(msg, 64, ""),
    pullRequestStripeUserId: jspb.Message.getFieldWithDefault(msg, 65, ""),
    pullRequestStripeAccountType: jspb.Message.getFieldWithDefault(msg, 66, ""),
    pullRequestStripeBusinessName: jspb.Message.getFieldWithDefault(msg, 67, ""),
    agreedToTermsAt: (f = msg.getAgreedToTermsAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    mweStripeUserId: jspb.Message.getFieldWithDefault(msg, 69, ""),
    mweStripeAccountType: jspb.Message.getFieldWithDefault(msg, 70, ""),
    mweStripeBusinessName: jspb.Message.getFieldWithDefault(msg, 71, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.User}
 */
proto.moonlight.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.User;
  return proto.moonlight.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.User}
 */
proto.moonlight.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailConfirmed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeactivated(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneConfirmed(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeardThrough(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeveloper(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenDeveloperApplication(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearsExperience(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourlyRateCents(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoursAvailablePerWeek(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeUserId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeAccountType(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTermsOfServiceAgreed(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setTheme(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublicProfile(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedNewSkills(value);
      break;
    case 32:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCanApplyAfter(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasTractorBeamAccess(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandingPage(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReferredByUserId(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedNewJobs(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackId(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommunityInvitationSent(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedReminderLogHours(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedNewMessages(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressCity(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdministrative(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressCountry(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressPostcode(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAddressLat(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAddressLon(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocationLat(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLocationLon(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedNewReferrals(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeBusinessName(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedNewsletter(value);
      break;
    case 59:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setApprovedAt(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribedBroadcasts(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setPronounSubject(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setPronounObject(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerUrl(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignupPagePath(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setPullRequestStripeUserId(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setPullRequestStripeAccountType(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setPullRequestStripeBusinessName(value);
      break;
    case 68:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAgreedToTermsAt(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setMweStripeUserId(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setMweStripeAccountType(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setMweStripeBusinessName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailConfirmed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDeactivated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhoneConfirmed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHeardThrough();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDeveloper();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOpenDeveloperApplication();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getYearsExperience();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getHourlyRateCents();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getHoursAvailablePerWeek();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getStripeUserId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getStripeAccountType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTermsOfServiceAgreed();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTheme();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getPublicProfile();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getSubscribedNewSkills();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCanApplyAfter();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
  f = message.getHasTractorBeamAccess();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getLandingPage();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getReferredByUserId();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
  f = message.getSubscribedNewJobs();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getSlackId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getCommunityInvitationSent();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getSubscribedReminderLogHours();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getSubscribedNewMessages();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getAddressCity();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getAddressAdministrative();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getAddressCountry();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getAddressPostcode();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getAddressLat();
  if (f !== 0.0) {
    writer.writeDouble(
      51,
      f
    );
  }
  f = message.getAddressLon();
  if (f !== 0.0) {
    writer.writeDouble(
      52,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getLocationLat();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getLocationLon();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getSubscribedNewReferrals();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getStripeBusinessName();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getSubscribedNewsletter();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getApprovedAt();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubscribedBroadcasts();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getPronounSubject();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getPronounObject();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getReferrerUrl();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getSignupPagePath();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getPullRequestStripeUserId();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getPullRequestStripeAccountType();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getPullRequestStripeBusinessName();
  if (f.length > 0) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getAgreedToTermsAt();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMweStripeUserId();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getMweStripeAccountType();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getMweStripeBusinessName();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.moonlight.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.moonlight.User.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool email_confirmed = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getEmailConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setEmailConfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool admin = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setAdmin = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool deactivated = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getDeactivated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setDeactivated = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.moonlight.User.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.User.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string headline = 9;
 * @return {string}
 */
proto.moonlight.User.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setHeadline = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool phone_confirmed = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getPhoneConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setPhoneConfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string country = 13;
 * @return {string}
 */
proto.moonlight.User.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string heard_through = 14;
 * @return {string}
 */
proto.moonlight.User.prototype.getHeardThrough = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setHeardThrough = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string timezone = 15;
 * @return {string}
 */
proto.moonlight.User.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setTimezone = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string photo = 16;
 * @return {string}
 */
proto.moonlight.User.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPhoto = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool developer = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getDeveloper = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setDeveloper = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool open_developer_application = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getOpenDeveloperApplication = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setOpenDeveloperApplication = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int32 years_experience = 19;
 * @return {number}
 */
proto.moonlight.User.prototype.getYearsExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setYearsExperience = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 hourly_rate_cents = 20;
 * @return {number}
 */
proto.moonlight.User.prototype.getHourlyRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setHourlyRateCents = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 hours_available_per_week = 21;
 * @return {number}
 */
proto.moonlight.User.prototype.getHoursAvailablePerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setHoursAvailablePerWeek = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string stripe_user_id = 23;
 * @return {string}
 */
proto.moonlight.User.prototype.getStripeUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setStripeUserId = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string stripe_account_type = 24;
 * @return {string}
 */
proto.moonlight.User.prototype.getStripeAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setStripeAccountType = function(value) {
  jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional google.protobuf.Timestamp terms_of_service_agreed = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getTermsOfServiceAgreed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setTermsOfServiceAgreed = function(value) {
  jspb.Message.setWrapperField(this, 26, value);
};


proto.moonlight.User.prototype.clearTermsOfServiceAgreed = function() {
  this.setTermsOfServiceAgreed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasTermsOfServiceAgreed = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string phone_number = 27;
 * @return {string}
 */
proto.moonlight.User.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string theme = 28;
 * @return {string}
 */
proto.moonlight.User.prototype.getTheme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setTheme = function(value) {
  jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional bool public_profile = 29;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getPublicProfile = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 29, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setPublicProfile = function(value) {
  jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional string bio = 30;
 * @return {string}
 */
proto.moonlight.User.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setBio = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool subscribed_new_skills = 31;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedNewSkills = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 31, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedNewSkills = function(value) {
  jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional google.protobuf.Timestamp can_apply_after = 32;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getCanApplyAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 32));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setCanApplyAfter = function(value) {
  jspb.Message.setWrapperField(this, 32, value);
};


proto.moonlight.User.prototype.clearCanApplyAfter = function() {
  this.setCanApplyAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasCanApplyAfter = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional int32 company_id = 33;
 * @return {number}
 */
proto.moonlight.User.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional bool has_tractor_beam_access = 34;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getHasTractorBeamAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 34, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setHasTractorBeamAccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional string landing_page = 36;
 * @return {string}
 */
proto.moonlight.User.prototype.getLandingPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setLandingPage = function(value) {
  jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional int32 referred_by_user_id = 37;
 * @return {number}
 */
proto.moonlight.User.prototype.getReferredByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setReferredByUserId = function(value) {
  jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional bool subscribed_new_jobs = 38;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedNewJobs = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 38, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedNewJobs = function(value) {
  jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool seeking_employment = 39;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 39, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool seeking_contracts = 40;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 40, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional string slack_id = 41;
 * @return {string}
 */
proto.moonlight.User.prototype.getSlackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setSlackId = function(value) {
  jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional bool community_invitation_sent = 42;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getCommunityInvitationSent = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 42, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setCommunityInvitationSent = function(value) {
  jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool subscribed_reminder_log_hours = 43;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedReminderLogHours = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 43, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedReminderLogHours = function(value) {
  jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool subscribed_new_messages = 44;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedNewMessages = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 44, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedNewMessages = function(value) {
  jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional string address_line_1 = 45;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressLine1 = function(value) {
  jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string address_line_2 = 46;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressLine2 = function(value) {
  jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string address_city = 47;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressCity = function(value) {
  jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string address_administrative = 48;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressAdministrative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressAdministrative = function(value) {
  jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string address_country = 49;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressCountry = function(value) {
  jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string address_postcode = 50;
 * @return {string}
 */
proto.moonlight.User.prototype.getAddressPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setAddressPostcode = function(value) {
  jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional double address_lat = 51;
 * @return {number}
 */
proto.moonlight.User.prototype.getAddressLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 51, 0.0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setAddressLat = function(value) {
  jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional double address_lon = 52;
 * @return {number}
 */
proto.moonlight.User.prototype.getAddressLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 52, 0.0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setAddressLon = function(value) {
  jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional string location = 53;
 * @return {string}
 */
proto.moonlight.User.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional double location_lat = 54;
 * @return {number}
 */
proto.moonlight.User.prototype.getLocationLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 54, 0.0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setLocationLat = function(value) {
  jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double location_lon = 55;
 * @return {number}
 */
proto.moonlight.User.prototype.getLocationLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 55, 0.0));
};


/** @param {number} value */
proto.moonlight.User.prototype.setLocationLon = function(value) {
  jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional bool subscribed_new_referrals = 56;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedNewReferrals = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 56, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedNewReferrals = function(value) {
  jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional string stripe_business_name = 57;
 * @return {string}
 */
proto.moonlight.User.prototype.getStripeBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setStripeBusinessName = function(value) {
  jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional bool subscribed_newsletter = 58;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedNewsletter = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 58, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedNewsletter = function(value) {
  jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional google.protobuf.Timestamp approved_at = 59;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getApprovedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 59));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setApprovedAt = function(value) {
  jspb.Message.setWrapperField(this, 59, value);
};


proto.moonlight.User.prototype.clearApprovedAt = function() {
  this.setApprovedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasApprovedAt = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional bool subscribed_broadcasts = 60;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.User.prototype.getSubscribedBroadcasts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 60, false));
};


/** @param {boolean} value */
proto.moonlight.User.prototype.setSubscribedBroadcasts = function(value) {
  jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional string pronoun_subject = 61;
 * @return {string}
 */
proto.moonlight.User.prototype.getPronounSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPronounSubject = function(value) {
  jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string pronoun_object = 62;
 * @return {string}
 */
proto.moonlight.User.prototype.getPronounObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPronounObject = function(value) {
  jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string referrer_url = 63;
 * @return {string}
 */
proto.moonlight.User.prototype.getReferrerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setReferrerUrl = function(value) {
  jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string signup_page_path = 64;
 * @return {string}
 */
proto.moonlight.User.prototype.getSignupPagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setSignupPagePath = function(value) {
  jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string pull_request_stripe_user_id = 65;
 * @return {string}
 */
proto.moonlight.User.prototype.getPullRequestStripeUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPullRequestStripeUserId = function(value) {
  jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string pull_request_stripe_account_type = 66;
 * @return {string}
 */
proto.moonlight.User.prototype.getPullRequestStripeAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPullRequestStripeAccountType = function(value) {
  jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string pull_request_stripe_business_name = 67;
 * @return {string}
 */
proto.moonlight.User.prototype.getPullRequestStripeBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setPullRequestStripeBusinessName = function(value) {
  jspb.Message.setProto3StringField(this, 67, value);
};


/**
 * optional google.protobuf.Timestamp agreed_to_terms_at = 68;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.User.prototype.getAgreedToTermsAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 68));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.User.prototype.setAgreedToTermsAt = function(value) {
  jspb.Message.setWrapperField(this, 68, value);
};


proto.moonlight.User.prototype.clearAgreedToTermsAt = function() {
  this.setAgreedToTermsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.User.prototype.hasAgreedToTermsAt = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional string mwe_stripe_user_id = 69;
 * @return {string}
 */
proto.moonlight.User.prototype.getMweStripeUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setMweStripeUserId = function(value) {
  jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional string mwe_stripe_account_type = 70;
 * @return {string}
 */
proto.moonlight.User.prototype.getMweStripeAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setMweStripeAccountType = function(value) {
  jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string mwe_stripe_business_name = 71;
 * @return {string}
 */
proto.moonlight.User.prototype.getMweStripeBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/** @param {string} value */
proto.moonlight.User.prototype.setMweStripeBusinessName = function(value) {
  jspb.Message.setProto3StringField(this, 71, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UpdateUserStripeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UpdateUserStripeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UpdateUserStripeRequest.displayName = 'proto.moonlight.UpdateUserStripeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UpdateUserStripeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UpdateUserStripeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UpdateUserStripeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UpdateUserStripeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UpdateUserStripeRequest}
 */
proto.moonlight.UpdateUserStripeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UpdateUserStripeRequest;
  return proto.moonlight.UpdateUserStripeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UpdateUserStripeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UpdateUserStripeRequest}
 */
proto.moonlight.UpdateUserStripeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UpdateUserStripeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UpdateUserStripeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UpdateUserStripeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UpdateUserStripeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UpdateUserStripeRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UpdateUserStripeRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.moonlight.UpdateUserStripeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UpdateUserStripeRequest.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserList.displayName = 'proto.moonlight.UserList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserList.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.moonlight.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserList}
 */
proto.moonlight.UserList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserList;
  return proto.moonlight.UserList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserList}
 */
proto.moonlight.UserList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.User;
      reader.readMessage(value,proto.moonlight.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array.<!proto.moonlight.User>}
 */
proto.moonlight.UserList.prototype.getUsersList = function() {
  return /** @type{!Array.<!proto.moonlight.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.User, 1));
};


/** @param {!Array.<!proto.moonlight.User>} value */
proto.moonlight.UserList.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.User}
 */
proto.moonlight.UserList.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.User, opt_index);
};


proto.moonlight.UserList.prototype.clearUsersList = function() {
  this.setUsersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.DeveloperSummary.repeatedFields_, null);
};
goog.inherits(proto.moonlight.DeveloperSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperSummary.displayName = 'proto.moonlight.DeveloperSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.DeveloperSummary.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.moonlight.User.toObject(includeInstance, f),
    featuredSkillsList: jspb.Message.toObjectList(msg.getFeaturedSkillsList(),
    proto.moonlight.Skill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.DeveloperSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperSummary;
  return proto.moonlight.DeveloperSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.DeveloperSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.User;
      reader.readMessage(value,proto.moonlight.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.moonlight.Skill;
      reader.readMessage(value,proto.moonlight.Skill.deserializeBinaryFromReader);
      msg.addFeaturedSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.User.serializeBinaryToWriter
    );
  }
  f = message.getFeaturedSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.moonlight.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.moonlight.User}
 */
proto.moonlight.DeveloperSummary.prototype.getUser = function() {
  return /** @type{?proto.moonlight.User} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.User, 1));
};


/** @param {?proto.moonlight.User|undefined} value */
proto.moonlight.DeveloperSummary.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.DeveloperSummary.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.DeveloperSummary.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Skill featured_skills = 2;
 * @return {!Array.<!proto.moonlight.Skill>}
 */
proto.moonlight.DeveloperSummary.prototype.getFeaturedSkillsList = function() {
  return /** @type{!Array.<!proto.moonlight.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Skill, 2));
};


/** @param {!Array.<!proto.moonlight.Skill>} value */
proto.moonlight.DeveloperSummary.prototype.setFeaturedSkillsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.moonlight.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Skill}
 */
proto.moonlight.DeveloperSummary.prototype.addFeaturedSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.moonlight.Skill, opt_index);
};


proto.moonlight.DeveloperSummary.prototype.clearFeaturedSkillsList = function() {
  this.setFeaturedSkillsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateUserRequest.displayName = 'proto.moonlight.CreateUserRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    heardThrough: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recaptchaToken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    inviteToCompanyId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createCompanyWithoutName: jspb.Message.getFieldWithDefault(msg, 12, false),
    googleToken: jspb.Message.getFieldWithDefault(msg, 13, ""),
    signupPage: jspb.Message.getFieldWithDefault(msg, 24, ""),
    landingPage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    referredByUserId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    password: jspb.Message.getFieldWithDefault(msg, 17, ""),
    companyUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    companyDescription: jspb.Message.getFieldWithDefault(msg, 19, ""),
    referrerUrl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    signupPagePath: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateUserRequest}
 */
proto.moonlight.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateUserRequest;
  return proto.moonlight.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateUserRequest}
 */
proto.moonlight.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeardThrough(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecaptchaToken(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInviteToCompanyId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateCompanyWithoutName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoogleToken(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignupPage(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLandingPage(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReferredByUserId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyUrl(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDescription(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferrerUrl(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignupPagePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHeardThrough();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecaptchaToken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInviteToCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCreateCompanyWithoutName();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getGoogleToken();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSignupPage();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getLandingPage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReferredByUserId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCompanyUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCompanyDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getReferrerUrl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSignupPagePath();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string timezone = 3;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setTimezone = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string heard_through = 5;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getHeardThrough = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setHeardThrough = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string recaptcha_token = 7;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getRecaptchaToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setRecaptchaToken = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setPhoneNumber = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string company_name = 10;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 invite_to_company_id = 11;
 * @return {number}
 */
proto.moonlight.CreateUserRequest.prototype.getInviteToCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserRequest.prototype.setInviteToCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool create_company_without_name = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateUserRequest.prototype.getCreateCompanyWithoutName = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.CreateUserRequest.prototype.setCreateCompanyWithoutName = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string google_token = 13;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getGoogleToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setGoogleToken = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string signup_page = 24;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getSignupPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setSignupPage = function(value) {
  jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string landing_page = 14;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getLandingPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setLandingPage = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 referred_by_user_id = 15;
 * @return {number}
 */
proto.moonlight.CreateUserRequest.prototype.getReferredByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserRequest.prototype.setReferredByUserId = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string password = 17;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setPassword = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string company_url = 18;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getCompanyUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setCompanyUrl = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string company_description = 19;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getCompanyDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setCompanyDescription = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string referrer_url = 20;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getReferrerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setReferrerUrl = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string signup_page_path = 21;
 * @return {string}
 */
proto.moonlight.CreateUserRequest.prototype.getSignupPagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserRequest.prototype.setSignupPagePath = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserPublicStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserPublicStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserPublicStats.displayName = 'proto.moonlight.UserPublicStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserPublicStats.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserPublicStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserPublicStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserPublicStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    countHoursWorked: +jspb.Message.getFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserPublicStats}
 */
proto.moonlight.UserPublicStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserPublicStats;
  return proto.moonlight.UserPublicStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserPublicStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserPublicStats}
 */
proto.moonlight.UserPublicStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCountHoursWorked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserPublicStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserPublicStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserPublicStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserPublicStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountHoursWorked();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double count_hours_worked = 1;
 * @return {number}
 */
proto.moonlight.UserPublicStats.prototype.getCountHoursWorked = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.moonlight.UserPublicStats.prototype.setCountHoursWorked = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LoginResponse.displayName = 'proto.moonlight.LoginResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.moonlight.User.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.moonlight.UserSession.toObject(includeInstance, f),
    company: (f = msg.getCompany()) && proto.moonlight.Company.toObject(includeInstance, f),
    intercomUserHash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LoginResponse}
 */
proto.moonlight.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LoginResponse;
  return proto.moonlight.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LoginResponse}
 */
proto.moonlight.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.User;
      reader.readMessage(value,proto.moonlight.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.moonlight.UserSession;
      reader.readMessage(value,proto.moonlight.UserSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 3:
      var value = new proto.moonlight.Company;
      reader.readMessage(value,proto.moonlight.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntercomUserHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.User.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.moonlight.UserSession.serializeBinaryToWriter
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.moonlight.Company.serializeBinaryToWriter
    );
  }
  f = message.getIntercomUserHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.moonlight.User}
 */
proto.moonlight.LoginResponse.prototype.getUser = function() {
  return /** @type{?proto.moonlight.User} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.User, 1));
};


/** @param {?proto.moonlight.User|undefined} value */
proto.moonlight.LoginResponse.prototype.setUser = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.LoginResponse.prototype.clearUser = function() {
  this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.LoginResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserSession session = 2;
 * @return {?proto.moonlight.UserSession}
 */
proto.moonlight.LoginResponse.prototype.getSession = function() {
  return /** @type{?proto.moonlight.UserSession} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.UserSession, 2));
};


/** @param {?proto.moonlight.UserSession|undefined} value */
proto.moonlight.LoginResponse.prototype.setSession = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.moonlight.LoginResponse.prototype.clearSession = function() {
  this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.LoginResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Company company = 3;
 * @return {?proto.moonlight.Company}
 */
proto.moonlight.LoginResponse.prototype.getCompany = function() {
  return /** @type{?proto.moonlight.Company} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Company, 3));
};


/** @param {?proto.moonlight.Company|undefined} value */
proto.moonlight.LoginResponse.prototype.setCompany = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.LoginResponse.prototype.clearCompany = function() {
  this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.LoginResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string intercom_user_hash = 4;
 * @return {string}
 */
proto.moonlight.LoginResponse.prototype.getIntercomUserHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.LoginResponse.prototype.setIntercomUserHash = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.FileUploadURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.FileUploadURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.FileUploadURL.displayName = 'proto.moonlight.FileUploadURL';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.FileUploadURL.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.FileUploadURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.FileUploadURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FileUploadURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servingUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.FileUploadURL}
 */
proto.moonlight.FileUploadURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.FileUploadURL;
  return proto.moonlight.FileUploadURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.FileUploadURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.FileUploadURL}
 */
proto.moonlight.FileUploadURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServingUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.FileUploadURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.FileUploadURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.FileUploadURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FileUploadURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServingUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string upload_url = 1;
 * @return {string}
 */
proto.moonlight.FileUploadURL.prototype.getUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.FileUploadURL.prototype.setUploadUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serving_url = 2;
 * @return {string}
 */
proto.moonlight.FileUploadURL.prototype.getServingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.FileUploadURL.prototype.setServingUrl = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.StripeExpressPaymentsURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.StripeExpressPaymentsURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.StripeExpressPaymentsURL.displayName = 'proto.moonlight.StripeExpressPaymentsURL';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.StripeExpressPaymentsURL.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.StripeExpressPaymentsURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.StripeExpressPaymentsURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.StripeExpressPaymentsURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.StripeExpressPaymentsURL}
 */
proto.moonlight.StripeExpressPaymentsURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.StripeExpressPaymentsURL;
  return proto.moonlight.StripeExpressPaymentsURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.StripeExpressPaymentsURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.StripeExpressPaymentsURL}
 */
proto.moonlight.StripeExpressPaymentsURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.StripeExpressPaymentsURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.StripeExpressPaymentsURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.StripeExpressPaymentsURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.StripeExpressPaymentsURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.moonlight.StripeExpressPaymentsURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.StripeExpressPaymentsURL.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Timezone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Timezone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Timezone.displayName = 'proto.moonlight.Timezone';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Timezone.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Timezone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Timezone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Timezone.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Timezone}
 */
proto.moonlight.Timezone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Timezone;
  return proto.moonlight.Timezone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Timezone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Timezone}
 */
proto.moonlight.Timezone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Timezone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Timezone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Timezone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Timezone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.moonlight.Timezone.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.Timezone.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.moonlight.Timezone.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Timezone.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CountryTimezonesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CountryTimezonesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CountryTimezonesRequest.displayName = 'proto.moonlight.CountryTimezonesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CountryTimezonesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CountryTimezonesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CountryTimezonesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CountryTimezonesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    country: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CountryTimezonesRequest}
 */
proto.moonlight.CountryTimezonesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CountryTimezonesRequest;
  return proto.moonlight.CountryTimezonesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CountryTimezonesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CountryTimezonesRequest}
 */
proto.moonlight.CountryTimezonesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CountryTimezonesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CountryTimezonesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CountryTimezonesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CountryTimezonesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.moonlight.CountryTimezonesRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CountryTimezonesRequest.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.TimezoneList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.TimezoneList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.TimezoneList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.TimezoneList.displayName = 'proto.moonlight.TimezoneList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.TimezoneList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.TimezoneList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.TimezoneList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.TimezoneList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.TimezoneList.toObject = function(includeInstance, msg) {
  var f, obj = {
    timezonesList: jspb.Message.toObjectList(msg.getTimezonesList(),
    proto.moonlight.Timezone.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.TimezoneList}
 */
proto.moonlight.TimezoneList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.TimezoneList;
  return proto.moonlight.TimezoneList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.TimezoneList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.TimezoneList}
 */
proto.moonlight.TimezoneList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Timezone;
      reader.readMessage(value,proto.moonlight.Timezone.deserializeBinaryFromReader);
      msg.addTimezones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.TimezoneList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.TimezoneList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.TimezoneList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.TimezoneList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimezonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Timezone.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Timezone timezones = 1;
 * @return {!Array.<!proto.moonlight.Timezone>}
 */
proto.moonlight.TimezoneList.prototype.getTimezonesList = function() {
  return /** @type{!Array.<!proto.moonlight.Timezone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Timezone, 1));
};


/** @param {!Array.<!proto.moonlight.Timezone>} value */
proto.moonlight.TimezoneList.prototype.setTimezonesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Timezone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Timezone}
 */
proto.moonlight.TimezoneList.prototype.addTimezones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Timezone, opt_index);
};


proto.moonlight.TimezoneList.prototype.clearTimezonesList = function() {
  this.setTimezonesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSession.displayName = 'proto.moonlight.UserSession';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSession.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    admin: jspb.Message.getFieldWithDefault(msg, 3, false),
    token: jspb.Message.getFieldWithDefault(msg, 4, ""),
    validUntil: (f = msg.getValidUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSession}
 */
proto.moonlight.UserSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSession;
  return proto.moonlight.UserSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSession}
 */
proto.moonlight.UserSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValidUntil();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.UserSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserSession.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserSession.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserSession.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool admin = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.UserSession.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.UserSession.prototype.setAdmin = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.moonlight.UserSession.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.UserSession.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp valid_until = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserSession.prototype.getValidUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserSession.prototype.setValidUntil = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.UserSession.prototype.clearValidUntil = function() {
  this.setValidUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserSession.prototype.hasValidUntil = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSessionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserSessionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSessionInfo.displayName = 'proto.moonlight.UserSessionInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSessionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSessionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSessionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    admin: jspb.Message.getFieldWithDefault(msg, 3, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validUntil: (f = msg.getValidUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUsed: (f = msg.getLastUsed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUsedIp: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSessionInfo}
 */
proto.moonlight.UserSessionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSessionInfo;
  return proto.moonlight.UserSessionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSessionInfo}
 */
proto.moonlight.UserSessionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidUntil(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUsed(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUsedIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSessionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSessionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidUntil();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUsed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUsedIp();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.UserSessionInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserSessionInfo.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserSessionInfo.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserSessionInfo.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool admin = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.UserSessionInfo.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.UserSessionInfo.prototype.setAdmin = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserSessionInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserSessionInfo.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.UserSessionInfo.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserSessionInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp valid_until = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserSessionInfo.prototype.getValidUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserSessionInfo.prototype.setValidUntil = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.UserSessionInfo.prototype.clearValidUntil = function() {
  this.setValidUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserSessionInfo.prototype.hasValidUntil = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp last_used = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserSessionInfo.prototype.getLastUsed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserSessionInfo.prototype.setLastUsed = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.moonlight.UserSessionInfo.prototype.clearLastUsed = function() {
  this.setLastUsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserSessionInfo.prototype.hasLastUsed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string last_used_ip = 7;
 * @return {string}
 */
proto.moonlight.UserSessionInfo.prototype.getLastUsedIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.UserSessionInfo.prototype.setLastUsedIp = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.InternalUserSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.InternalUserSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.InternalUserSession.displayName = 'proto.moonlight.InternalUserSession';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.InternalUserSession.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.InternalUserSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.InternalUserSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InternalUserSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    admin: jspb.Message.getFieldWithDefault(msg, 2, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validUntil: (f = msg.getValidUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUsed: (f = msg.getLastUsed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUsedIp: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.InternalUserSession}
 */
proto.moonlight.InternalUserSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.InternalUserSession;
  return proto.moonlight.InternalUserSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.InternalUserSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.InternalUserSession}
 */
proto.moonlight.InternalUserSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidUntil(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUsed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUsedIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.InternalUserSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.InternalUserSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.InternalUserSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InternalUserSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUsed();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUsedIp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.moonlight.InternalUserSession.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.InternalUserSession.prototype.setToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool admin = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.InternalUserSession.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.InternalUserSession.prototype.setAdmin = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.InternalUserSession.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.InternalUserSession.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.InternalUserSession.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.InternalUserSession.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp valid_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.InternalUserSession.prototype.getValidUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.InternalUserSession.prototype.setValidUntil = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.InternalUserSession.prototype.clearValidUntil = function() {
  this.setValidUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.InternalUserSession.prototype.hasValidUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp last_used = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.InternalUserSession.prototype.getLastUsed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.InternalUserSession.prototype.setLastUsed = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.InternalUserSession.prototype.clearLastUsed = function() {
  this.setLastUsed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.InternalUserSession.prototype.hasLastUsed = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string last_used_ip = 6;
 * @return {string}
 */
proto.moonlight.InternalUserSession.prototype.getLastUsedIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.InternalUserSession.prototype.setLastUsedIp = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSessionRequest.displayName = 'proto.moonlight.UserSessionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSessionRequest}
 */
proto.moonlight.UserSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSessionRequest;
  return proto.moonlight.UserSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSessionRequest}
 */
proto.moonlight.UserSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.UserSessionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserSessionRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserSessionRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserSessionRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateUserSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateUserSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateUserSessionRequest.displayName = 'proto.moonlight.CreateUserSessionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateUserSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateUserSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateUserSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recaptchaToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longSession: jspb.Message.getFieldWithDefault(msg, 4, false),
    googleToken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateUserSessionRequest}
 */
proto.moonlight.CreateUserSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateUserSessionRequest;
  return proto.moonlight.CreateUserSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateUserSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateUserSessionRequest}
 */
proto.moonlight.CreateUserSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecaptchaToken(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLongSession(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoogleToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateUserSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateUserSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateUserSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecaptchaToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongSession();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGoogleToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.CreateUserSessionRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserSessionRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.moonlight.CreateUserSessionRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserSessionRequest.prototype.setPassword = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string recaptcha_token = 3;
 * @return {string}
 */
proto.moonlight.CreateUserSessionRequest.prototype.getRecaptchaToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserSessionRequest.prototype.setRecaptchaToken = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool long_session = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateUserSessionRequest.prototype.getLongSession = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.CreateUserSessionRequest.prototype.setLongSession = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string google_token = 5;
 * @return {string}
 */
proto.moonlight.CreateUserSessionRequest.prototype.getGoogleToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserSessionRequest.prototype.setGoogleToken = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSessionsInfoList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserSessionsInfoList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserSessionsInfoList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSessionsInfoList.displayName = 'proto.moonlight.UserSessionsInfoList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserSessionsInfoList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSessionsInfoList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSessionsInfoList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSessionsInfoList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionsInfoList.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.moonlight.UserSessionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSessionsInfoList}
 */
proto.moonlight.UserSessionsInfoList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSessionsInfoList;
  return proto.moonlight.UserSessionsInfoList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSessionsInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSessionsInfoList}
 */
proto.moonlight.UserSessionsInfoList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.UserSessionInfo;
      reader.readMessage(value,proto.moonlight.UserSessionInfo.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSessionsInfoList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSessionsInfoList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSessionsInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSessionsInfoList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.UserSessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserSessionInfo sessions = 1;
 * @return {!Array.<!proto.moonlight.UserSessionInfo>}
 */
proto.moonlight.UserSessionsInfoList.prototype.getSessionsList = function() {
  return /** @type{!Array.<!proto.moonlight.UserSessionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.UserSessionInfo, 1));
};


/** @param {!Array.<!proto.moonlight.UserSessionInfo>} value */
proto.moonlight.UserSessionsInfoList.prototype.setSessionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.UserSessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.UserSessionInfo}
 */
proto.moonlight.UserSessionsInfoList.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.UserSessionInfo, opt_index);
};


proto.moonlight.UserSessionsInfoList.prototype.clearSessionsList = function() {
  this.setSessionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ConfirmEmailWithCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ConfirmEmailWithCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ConfirmEmailWithCodeRequest.displayName = 'proto.moonlight.ConfirmEmailWithCodeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ConfirmEmailWithCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ConfirmEmailWithCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ConfirmEmailWithCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ConfirmEmailWithCodeRequest}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ConfirmEmailWithCodeRequest;
  return proto.moonlight.ConfirmEmailWithCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ConfirmEmailWithCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ConfirmEmailWithCodeRequest}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ConfirmEmailWithCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ConfirmEmailWithCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ConfirmEmailWithCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.ConfirmEmailWithCodeRequest.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ForgottenPasswordResetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ForgottenPasswordResetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ForgottenPasswordResetRequest.displayName = 'proto.moonlight.ForgottenPasswordResetRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ForgottenPasswordResetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ForgottenPasswordResetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ForgottenPasswordResetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ForgottenPasswordResetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recaptchaToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ForgottenPasswordResetRequest}
 */
proto.moonlight.ForgottenPasswordResetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ForgottenPasswordResetRequest;
  return proto.moonlight.ForgottenPasswordResetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ForgottenPasswordResetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ForgottenPasswordResetRequest}
 */
proto.moonlight.ForgottenPasswordResetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecaptchaToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ForgottenPasswordResetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ForgottenPasswordResetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ForgottenPasswordResetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ForgottenPasswordResetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecaptchaToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.ForgottenPasswordResetRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ForgottenPasswordResetRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string recaptcha_token = 2;
 * @return {string}
 */
proto.moonlight.ForgottenPasswordResetRequest.prototype.getRecaptchaToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.ForgottenPasswordResetRequest.prototype.setRecaptchaToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetTokenRequest.displayName = 'proto.moonlight.PasswordResetTokenRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetTokenRequest}
 */
proto.moonlight.PasswordResetTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetTokenRequest;
  return proto.moonlight.PasswordResetTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetTokenRequest}
 */
proto.moonlight.PasswordResetTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.PasswordResetTokenRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.PasswordResetTokenRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetToken.displayName = 'proto.moonlight.PasswordResetToken';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetToken.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    resetToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetToken}
 */
proto.moonlight.PasswordResetToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetToken;
  return proto.moonlight.PasswordResetToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetToken}
 */
proto.moonlight.PasswordResetToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResetToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reset_token = 1;
 * @return {string}
 */
proto.moonlight.PasswordResetToken.prototype.getResetToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetToken.prototype.setResetToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetInfoRequest.displayName = 'proto.moonlight.PasswordResetInfoRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resetToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetInfoRequest}
 */
proto.moonlight.PasswordResetInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetInfoRequest;
  return proto.moonlight.PasswordResetInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetInfoRequest}
 */
proto.moonlight.PasswordResetInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResetToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reset_token = 1;
 * @return {string}
 */
proto.moonlight.PasswordResetInfoRequest.prototype.getResetToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetInfoRequest.prototype.setResetToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetInfo.displayName = 'proto.moonlight.PasswordResetInfo';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetInfo}
 */
proto.moonlight.PasswordResetInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetInfo;
  return proto.moonlight.PasswordResetInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetInfo}
 */
proto.moonlight.PasswordResetInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.PasswordResetInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetInfo.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetWithTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetWithTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetWithTokenRequest.displayName = 'proto.moonlight.PasswordResetWithTokenRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetWithTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetWithTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetWithTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetWithTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resetToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetWithTokenRequest}
 */
proto.moonlight.PasswordResetWithTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetWithTokenRequest;
  return proto.moonlight.PasswordResetWithTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetWithTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetWithTokenRequest}
 */
proto.moonlight.PasswordResetWithTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetWithTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetWithTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetWithTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetWithTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResetToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reset_token = 1;
 * @return {string}
 */
proto.moonlight.PasswordResetWithTokenRequest.prototype.getResetToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetWithTokenRequest.prototype.setResetToken = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.moonlight.PasswordResetWithTokenRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetWithTokenRequest.prototype.setNewPassword = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PasswordResetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PasswordResetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PasswordResetRequest.displayName = 'proto.moonlight.PasswordResetRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PasswordResetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PasswordResetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PasswordResetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oldPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PasswordResetRequest}
 */
proto.moonlight.PasswordResetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PasswordResetRequest;
  return proto.moonlight.PasswordResetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PasswordResetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PasswordResetRequest}
 */
proto.moonlight.PasswordResetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PasswordResetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PasswordResetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PasswordResetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PasswordResetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.PasswordResetRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.PasswordResetRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string old_password = 2;
 * @return {string}
 */
proto.moonlight.PasswordResetRequest.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetRequest.prototype.setOldPassword = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_password = 3;
 * @return {string}
 */
proto.moonlight.PasswordResetRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.PasswordResetRequest.prototype.setNewPassword = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserMetaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserMetaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserMetaRequest.displayName = 'proto.moonlight.UserMetaRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserMetaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserMetaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserMetaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMetaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserMetaRequest}
 */
proto.moonlight.UserMetaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserMetaRequest;
  return proto.moonlight.UserMetaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserMetaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserMetaRequest}
 */
proto.moonlight.UserMetaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserMetaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserMetaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserMetaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMetaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserMetaRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserMetaRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.moonlight.UserMetaRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UserMetaRequest.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserMeta.displayName = 'proto.moonlight.UserMeta';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserMeta}
 */
proto.moonlight.UserMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserMeta;
  return proto.moonlight.UserMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserMeta}
 */
proto.moonlight.UserMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserMeta.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserMeta.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.moonlight.UserMeta.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UserMeta.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.moonlight.UserMeta.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.UserMeta.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserMetasList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserMetasList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserMetasList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserMetasList.displayName = 'proto.moonlight.UserMetasList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserMetasList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserMetasList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserMetasList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserMetasList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMetasList.toObject = function(includeInstance, msg) {
  var f, obj = {
    userMetasList: jspb.Message.toObjectList(msg.getUserMetasList(),
    proto.moonlight.UserMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserMetasList}
 */
proto.moonlight.UserMetasList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserMetasList;
  return proto.moonlight.UserMetasList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserMetasList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserMetasList}
 */
proto.moonlight.UserMetasList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.UserMeta;
      reader.readMessage(value,proto.moonlight.UserMeta.deserializeBinaryFromReader);
      msg.addUserMetas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserMetasList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserMetasList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserMetasList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserMetasList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserMetasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.UserMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserMeta user_metas = 1;
 * @return {!Array.<!proto.moonlight.UserMeta>}
 */
proto.moonlight.UserMetasList.prototype.getUserMetasList = function() {
  return /** @type{!Array.<!proto.moonlight.UserMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.UserMeta, 1));
};


/** @param {!Array.<!proto.moonlight.UserMeta>} value */
proto.moonlight.UserMetasList.prototype.setUserMetasList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.UserMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.UserMeta}
 */
proto.moonlight.UserMetasList.prototype.addUserMetas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.UserMeta, opt_index);
};


proto.moonlight.UserMetasList.prototype.clearUserMetasList = function() {
  this.setUserMetasList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserLinkRequest.displayName = 'proto.moonlight.UserLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserLinkRequest}
 */
proto.moonlight.UserLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserLinkRequest;
  return proto.moonlight.UserLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserLinkRequest}
 */
proto.moonlight.UserLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.UserLinkRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserLinkRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserLinkRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserLinkRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateUserLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateUserLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateUserLinkRequest.displayName = 'proto.moonlight.CreateUserLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateUserLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateUserLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateUserLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateUserLinkRequest}
 */
proto.moonlight.CreateUserLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateUserLinkRequest;
  return proto.moonlight.CreateUserLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateUserLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateUserLinkRequest}
 */
proto.moonlight.CreateUserLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateUserLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateUserLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateUserLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.CreateUserLinkRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserLinkRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.moonlight.CreateUserLinkRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserLinkRequest.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserLink.displayName = 'proto.moonlight.UserLink';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserLink.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserLink}
 */
proto.moonlight.UserLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserLink;
  return proto.moonlight.UserLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserLink}
 */
proto.moonlight.UserLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.UserLink.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserLink.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserLink.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserLink.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.moonlight.UserLink.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.UserLink.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserLinksList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserLinksList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserLinksList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserLinksList.displayName = 'proto.moonlight.UserLinksList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserLinksList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserLinksList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserLinksList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserLinksList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLinksList.toObject = function(includeInstance, msg) {
  var f, obj = {
    userLinksList: jspb.Message.toObjectList(msg.getUserLinksList(),
    proto.moonlight.UserLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserLinksList}
 */
proto.moonlight.UserLinksList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserLinksList;
  return proto.moonlight.UserLinksList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserLinksList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserLinksList}
 */
proto.moonlight.UserLinksList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.UserLink;
      reader.readMessage(value,proto.moonlight.UserLink.deserializeBinaryFromReader);
      msg.addUserLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserLinksList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserLinksList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserLinksList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserLinksList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.UserLink.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserLink user_links = 1;
 * @return {!Array.<!proto.moonlight.UserLink>}
 */
proto.moonlight.UserLinksList.prototype.getUserLinksList = function() {
  return /** @type{!Array.<!proto.moonlight.UserLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.UserLink, 1));
};


/** @param {!Array.<!proto.moonlight.UserLink>} value */
proto.moonlight.UserLinksList.prototype.setUserLinksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.UserLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.UserLink}
 */
proto.moonlight.UserLinksList.prototype.addUserLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.UserLink, opt_index);
};


proto.moonlight.UserLinksList.prototype.clearUserLinksList = function() {
  this.setUserLinksList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillRequest.displayName = 'proto.moonlight.SkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillRequest}
 */
proto.moonlight.SkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillRequest;
  return proto.moonlight.SkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillRequest}
 */
proto.moonlight.SkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.moonlight.SkillRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.SkillRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Skill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Skill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Skill.displayName = 'proto.moonlight.Skill';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Skill.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Skill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Skill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Skill.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Skill}
 */
proto.moonlight.Skill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Skill;
  return proto.moonlight.Skill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Skill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Skill}
 */
proto.moonlight.Skill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Skill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Skill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Skill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Skill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.moonlight.Skill.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.Skill.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.moonlight.Skill.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Skill.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbreviation = 3;
 * @return {string}
 */
proto.moonlight.Skill.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.Skill.prototype.setAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateSkillRequest.displayName = 'proto.moonlight.CreateSkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sendAlert: jspb.Message.getFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateSkillRequest}
 */
proto.moonlight.CreateSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateSkillRequest;
  return proto.moonlight.CreateSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateSkillRequest}
 */
proto.moonlight.CreateSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSendAlert();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.moonlight.CreateSkillRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateSkillRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.moonlight.CreateSkillRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateSkillRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbreviation = 3;
 * @return {string}
 */
proto.moonlight.CreateSkillRequest.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateSkillRequest.prototype.setAbbreviation = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool send_alert = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateSkillRequest.prototype.getSendAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.CreateSkillRequest.prototype.setSendAlert = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SkillsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SkillsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillsList.displayName = 'proto.moonlight.SkillsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SkillsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    proto.moonlight.Skill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillsList}
 */
proto.moonlight.SkillsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillsList;
  return proto.moonlight.SkillsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillsList}
 */
proto.moonlight.SkillsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Skill;
      reader.readMessage(value,proto.moonlight.Skill.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Skill skills = 1;
 * @return {!Array.<!proto.moonlight.Skill>}
 */
proto.moonlight.SkillsList.prototype.getSkillsList = function() {
  return /** @type{!Array.<!proto.moonlight.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Skill, 1));
};


/** @param {!Array.<!proto.moonlight.Skill>} value */
proto.moonlight.SkillsList.prototype.setSkillsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Skill}
 */
proto.moonlight.SkillsList.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Skill, opt_index);
};


proto.moonlight.SkillsList.prototype.clearSkillsList = function() {
  this.setSkillsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillWithCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SkillWithCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillWithCount.displayName = 'proto.moonlight.SkillWithCount';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillWithCount.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillWithCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillWithCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillWithCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    skill: (f = msg.getSkill()) && proto.moonlight.Skill.toObject(includeInstance, f),
    developerCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillWithCount}
 */
proto.moonlight.SkillWithCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillWithCount;
  return proto.moonlight.SkillWithCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillWithCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillWithCount}
 */
proto.moonlight.SkillWithCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Skill;
      reader.readMessage(value,proto.moonlight.Skill.deserializeBinaryFromReader);
      msg.setSkill(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillWithCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillWithCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillWithCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillWithCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkill();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Skill.serializeBinaryToWriter
    );
  }
  f = message.getDeveloperCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Skill skill = 1;
 * @return {?proto.moonlight.Skill}
 */
proto.moonlight.SkillWithCount.prototype.getSkill = function() {
  return /** @type{?proto.moonlight.Skill} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Skill, 1));
};


/** @param {?proto.moonlight.Skill|undefined} value */
proto.moonlight.SkillWithCount.prototype.setSkill = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.SkillWithCount.prototype.clearSkill = function() {
  this.setSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.SkillWithCount.prototype.hasSkill = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 developer_count = 2;
 * @return {number}
 */
proto.moonlight.SkillWithCount.prototype.getDeveloperCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.SkillWithCount.prototype.setDeveloperCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillsWithCountsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SkillsWithCountsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SkillsWithCountsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillsWithCountsList.displayName = 'proto.moonlight.SkillsWithCountsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SkillsWithCountsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillsWithCountsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillsWithCountsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillsWithCountsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillsWithCountsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    proto.moonlight.SkillWithCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillsWithCountsList}
 */
proto.moonlight.SkillsWithCountsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillsWithCountsList;
  return proto.moonlight.SkillsWithCountsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillsWithCountsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillsWithCountsList}
 */
proto.moonlight.SkillsWithCountsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.SkillWithCount;
      reader.readMessage(value,proto.moonlight.SkillWithCount.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillsWithCountsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillsWithCountsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillsWithCountsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillsWithCountsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.SkillWithCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SkillWithCount skills = 1;
 * @return {!Array.<!proto.moonlight.SkillWithCount>}
 */
proto.moonlight.SkillsWithCountsList.prototype.getSkillsList = function() {
  return /** @type{!Array.<!proto.moonlight.SkillWithCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.SkillWithCount, 1));
};


/** @param {!Array.<!proto.moonlight.SkillWithCount>} value */
proto.moonlight.SkillsWithCountsList.prototype.setSkillsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.SkillWithCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.SkillWithCount}
 */
proto.moonlight.SkillsWithCountsList.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.SkillWithCount, opt_index);
};


proto.moonlight.SkillsWithCountsList.prototype.clearSkillsList = function() {
  this.setSkillsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserSkillRequest.displayName = 'proto.moonlight.UserSkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skillSlug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserSkillRequest}
 */
proto.moonlight.UserSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserSkillRequest;
  return proto.moonlight.UserSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserSkillRequest}
 */
proto.moonlight.UserSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserSkillRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserSkillRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string skill_slug = 2;
 * @return {string}
 */
proto.moonlight.UserSkillRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.UserSkillRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateUserExperienceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateUserExperienceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateUserExperienceRequest.displayName = 'proto.moonlight.CreateUserExperienceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateUserExperienceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateUserExperienceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserExperienceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startMonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endMonth: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    current: jspb.Message.getFieldWithDefault(msg, 8, false),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateUserExperienceRequest}
 */
proto.moonlight.CreateUserExperienceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateUserExperienceRequest;
  return proto.moonlight.CreateUserExperienceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateUserExperienceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateUserExperienceRequest}
 */
proto.moonlight.CreateUserExperienceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndYear(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateUserExperienceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateUserExperienceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserExperienceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartMonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEndMonth();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_month = 4;
 * @return {string}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getStartMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setStartMonth = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 start_year = 5;
 * @return {number}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setStartYear = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string end_month = 6;
 * @return {string}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getEndMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setEndMonth = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 end_year = 7;
 * @return {number}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setEndYear = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool current = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setCurrent = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.moonlight.CreateUserExperienceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserExperienceRequest.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserExperience = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserExperience, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserExperience.displayName = 'proto.moonlight.UserExperience';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserExperience.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserExperience.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserExperience} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperience.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startMonth: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endMonth: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    current: jspb.Message.getFieldWithDefault(msg, 8, false),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserExperience}
 */
proto.moonlight.UserExperience.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserExperience;
  return proto.moonlight.UserExperience.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserExperience} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserExperience}
 */
proto.moonlight.UserExperience.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartMonth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartYear(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndYear(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserExperience.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserExperience.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserExperience} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperience.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartMonth();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEndMonth();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.UserExperience.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserExperience.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserExperience.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserExperience.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.moonlight.UserExperience.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.UserExperience.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_month = 4;
 * @return {string}
 */
proto.moonlight.UserExperience.prototype.getStartMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.UserExperience.prototype.setStartMonth = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 start_year = 5;
 * @return {number}
 */
proto.moonlight.UserExperience.prototype.getStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.UserExperience.prototype.setStartYear = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string end_month = 6;
 * @return {string}
 */
proto.moonlight.UserExperience.prototype.getEndMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.UserExperience.prototype.setEndMonth = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 end_year = 7;
 * @return {number}
 */
proto.moonlight.UserExperience.prototype.getEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.UserExperience.prototype.setEndYear = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool current = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.UserExperience.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.UserExperience.prototype.setCurrent = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.moonlight.UserExperience.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.UserExperience.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserExperienceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserExperienceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserExperienceRequest.displayName = 'proto.moonlight.UserExperienceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserExperienceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserExperienceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserExperienceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperienceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserExperienceRequest}
 */
proto.moonlight.UserExperienceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserExperienceRequest;
  return proto.moonlight.UserExperienceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserExperienceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserExperienceRequest}
 */
proto.moonlight.UserExperienceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserExperienceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserExperienceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserExperienceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperienceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.UserExperienceRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserExperienceRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.UserExperienceRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserExperienceRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserExperiencesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserExperiencesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserExperiencesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserExperiencesList.displayName = 'proto.moonlight.UserExperiencesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserExperiencesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserExperiencesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserExperiencesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserExperiencesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperiencesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    userExperiencesList: jspb.Message.toObjectList(msg.getUserExperiencesList(),
    proto.moonlight.UserExperience.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserExperiencesList}
 */
proto.moonlight.UserExperiencesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserExperiencesList;
  return proto.moonlight.UserExperiencesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserExperiencesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserExperiencesList}
 */
proto.moonlight.UserExperiencesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.UserExperience;
      reader.readMessage(value,proto.moonlight.UserExperience.deserializeBinaryFromReader);
      msg.addUserExperiences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserExperiencesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserExperiencesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserExperiencesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperiencesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserExperiencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.UserExperience.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserExperience user_experiences = 1;
 * @return {!Array.<!proto.moonlight.UserExperience>}
 */
proto.moonlight.UserExperiencesList.prototype.getUserExperiencesList = function() {
  return /** @type{!Array.<!proto.moonlight.UserExperience>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.UserExperience, 1));
};


/** @param {!Array.<!proto.moonlight.UserExperience>} value */
proto.moonlight.UserExperiencesList.prototype.setUserExperiencesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.UserExperience=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.UserExperience}
 */
proto.moonlight.UserExperiencesList.prototype.addUserExperiences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.UserExperience, opt_index);
};


proto.moonlight.UserExperiencesList.prototype.clearUserExperiencesList = function() {
  this.setUserExperiencesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserExperienceSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserExperienceSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserExperienceSkillRequest.displayName = 'proto.moonlight.UserExperienceSkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserExperienceSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserExperienceSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserExperienceSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperienceSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userExperienceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    skillSlug: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserExperienceSkillRequest}
 */
proto.moonlight.UserExperienceSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserExperienceSkillRequest;
  return proto.moonlight.UserExperienceSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserExperienceSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserExperienceSkillRequest}
 */
proto.moonlight.UserExperienceSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserExperienceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserExperienceSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserExperienceSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserExperienceSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserExperienceSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserExperienceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.UserExperienceSkillRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.UserExperienceSkillRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_experience_id = 2;
 * @return {number}
 */
proto.moonlight.UserExperienceSkillRequest.prototype.getUserExperienceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserExperienceSkillRequest.prototype.setUserExperienceId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string skill_slug = 3;
 * @return {string}
 */
proto.moonlight.UserExperienceSkillRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.UserExperienceSkillRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Company.displayName = 'proto.moonlight.Company';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tagline: jspb.Message.getFieldWithDefault(msg, 6, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    url: jspb.Message.getFieldWithDefault(msg, 8, ""),
    stripeCustomerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    theme: jspb.Message.getFieldWithDefault(msg, 10, ""),
    noServiceFee: jspb.Message.getFieldWithDefault(msg, 12, false),
    description: jspb.Message.getFieldWithDefault(msg, 13, ""),
    referredByUserId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    centsBalanceFreeProcessing: jspb.Message.getFieldWithDefault(msg, 15, 0),
    legalName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 18, ""),
    addressLine2: jspb.Message.getFieldWithDefault(msg, 19, ""),
    addressCity: jspb.Message.getFieldWithDefault(msg, 20, ""),
    addressAdministrative: jspb.Message.getFieldWithDefault(msg, 21, ""),
    addressCountry: jspb.Message.getFieldWithDefault(msg, 22, ""),
    addressPostcode: jspb.Message.getFieldWithDefault(msg, 23, ""),
    lat: +jspb.Message.getFieldWithDefault(msg, 24, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 25, 0.0),
    paidSubscriber: jspb.Message.getFieldWithDefault(msg, 26, false),
    canceledSubscriber: jspb.Message.getFieldWithDefault(msg, 27, false),
    seekingContractors: jspb.Message.getFieldWithDefault(msg, 28, false),
    seekingEmployees: jspb.Message.getFieldWithDefault(msg, 29, false),
    activeContingencyAgreement: jspb.Message.getFieldWithDefault(msg, 30, false),
    pullRequestStripeCustomerId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    mweStripeCustomerId: jspb.Message.getFieldWithDefault(msg, 32, ""),
    companyTakeRate: +jspb.Message.getFieldWithDefault(msg, 33, 0.0),
    legacySubscription: jspb.Message.getFieldWithDefault(msg, 34, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Company}
 */
proto.moonlight.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Company;
  return proto.moonlight.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Company}
 */
proto.moonlight.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagline(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeCustomerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTheme(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoServiceFee(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReferredByUserId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsBalanceFreeProcessing(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine2(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressCity(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdministrative(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressCountry(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressPostcode(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaidSubscriber(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanceledSubscriber(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContractors(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployees(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveContingencyAgreement(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setPullRequestStripeCustomerId(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setMweStripeCustomerId(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompanyTakeRate(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLegacySubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTagline();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTheme();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNoServiceFee();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReferredByUserId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCentsBalanceFreeProcessing();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddressCity();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAddressAdministrative();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAddressCountry();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAddressPostcode();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getPaidSubscriber();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getCanceledSubscriber();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getSeekingContractors();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getSeekingEmployees();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getActiveContingencyAgreement();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getPullRequestStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMweStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getCompanyTakeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getLegacySubscription();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Company.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.moonlight.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 owner_id = 3;
 * @return {number}
 */
proto.moonlight.Company.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setOwnerId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Company.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Company.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.Company.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Company.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Company.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Company.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.Company.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Company.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string tagline = 6;
 * @return {string}
 */
proto.moonlight.Company.prototype.getTagline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setTagline = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.moonlight.Company.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setLogo = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string url = 8;
 * @return {string}
 */
proto.moonlight.Company.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string stripe_customer_id = 9;
 * @return {string}
 */
proto.moonlight.Company.prototype.getStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setStripeCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string theme = 10;
 * @return {string}
 */
proto.moonlight.Company.prototype.getTheme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setTheme = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool no_service_fee = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getNoServiceFee = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setNoServiceFee = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.moonlight.Company.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 referred_by_user_id = 14;
 * @return {number}
 */
proto.moonlight.Company.prototype.getReferredByUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setReferredByUserId = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 cents_balance_free_processing = 15;
 * @return {number}
 */
proto.moonlight.Company.prototype.getCentsBalanceFreeProcessing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setCentsBalanceFreeProcessing = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string legal_name = 17;
 * @return {string}
 */
proto.moonlight.Company.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setLegalName = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string address_line_1 = 18;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressLine1 = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string address_line_2 = 19;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressLine2 = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string address_city = 20;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressCity = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string address_administrative = 21;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressAdministrative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressAdministrative = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string address_country = 22;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressCountry = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string address_postcode = 23;
 * @return {string}
 */
proto.moonlight.Company.prototype.getAddressPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setAddressPostcode = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional double lat = 24;
 * @return {number}
 */
proto.moonlight.Company.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 24, 0.0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double lon = 25;
 * @return {number}
 */
proto.moonlight.Company.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 25, 0.0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional bool paid_subscriber = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getPaidSubscriber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setPaidSubscriber = function(value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool canceled_subscriber = 27;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getCanceledSubscriber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 27, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setCanceledSubscriber = function(value) {
  jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool seeking_contractors = 28;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getSeekingContractors = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 28, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setSeekingContractors = function(value) {
  jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool seeking_employees = 29;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getSeekingEmployees = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 29, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setSeekingEmployees = function(value) {
  jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool active_contingency_agreement = 30;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getActiveContingencyAgreement = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 30, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setActiveContingencyAgreement = function(value) {
  jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional string pull_request_stripe_customer_id = 31;
 * @return {string}
 */
proto.moonlight.Company.prototype.getPullRequestStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setPullRequestStripeCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string mwe_stripe_customer_id = 32;
 * @return {string}
 */
proto.moonlight.Company.prototype.getMweStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/** @param {string} value */
proto.moonlight.Company.prototype.setMweStripeCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional double company_take_rate = 33;
 * @return {number}
 */
proto.moonlight.Company.prototype.getCompanyTakeRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 33, 0.0));
};


/** @param {number} value */
proto.moonlight.Company.prototype.setCompanyTakeRate = function(value) {
  jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional bool legacy_subscription = 34;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Company.prototype.getLegacySubscription = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 34, false));
};


/** @param {boolean} value */
proto.moonlight.Company.prototype.setLegacySubscription = function(value) {
  jspb.Message.setProto3BooleanField(this, 34, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Subscription.displayName = 'proto.moonlight.Subscription';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endedAt: (f = msg.getEndedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    active: jspb.Message.getFieldWithDefault(msg, 5, false),
    plan: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stripeCustomerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    centsAcv: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cancelAtPeriodEnd: jspb.Message.getFieldWithDefault(msg, 10, false),
    interval: jspb.Message.getFieldWithDefault(msg, 11, ""),
    intervalCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 13, 0),
    annualCommitment: jspb.Message.getFieldWithDefault(msg, 14, false),
    contractUrl: jspb.Message.getFieldWithDefault(msg, 15, ""),
    legacyStripeAccount: jspb.Message.getFieldWithDefault(msg, 16, false),
    pullRequestStripeAccount: jspb.Message.getFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Subscription}
 */
proto.moonlight.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Subscription;
  return proto.moonlight.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Subscription}
 */
proto.moonlight.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndedAt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlan(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeCustomerId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsAcv(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelAtPeriodEnd(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterval(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntervalCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnnualCommitment(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractUrl(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLegacyStripeAccount(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPullRequestStripeAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlan();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCentsAcv();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCancelAtPeriodEnd();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getInterval();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIntervalCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getAnnualCommitment();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getContractUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLegacyStripeAccount();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPullRequestStripeAccount();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.Subscription.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.Subscription.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Subscription.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Subscription.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Subscription.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.Subscription.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Subscription.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp ended_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Subscription.prototype.getEndedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Subscription.prototype.setEndedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.Subscription.prototype.clearEndedAt = function() {
  this.setEndedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Subscription.prototype.hasEndedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool active = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Subscription.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.Subscription.prototype.setActive = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string plan = 6;
 * @return {string}
 */
proto.moonlight.Subscription.prototype.getPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Subscription.prototype.setPlan = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string stripe_customer_id = 7;
 * @return {string}
 */
proto.moonlight.Subscription.prototype.getStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.Subscription.prototype.setStripeCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 payment_method_id = 8;
 * @return {number}
 */
proto.moonlight.Subscription.prototype.getPaymentMethodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.moonlight.Subscription.prototype.setPaymentMethodId = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 cents_acv = 9;
 * @return {number}
 */
proto.moonlight.Subscription.prototype.getCentsAcv = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.moonlight.Subscription.prototype.setCentsAcv = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool cancel_at_period_end = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Subscription.prototype.getCancelAtPeriodEnd = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.moonlight.Subscription.prototype.setCancelAtPeriodEnd = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string interval = 11;
 * @return {string}
 */
proto.moonlight.Subscription.prototype.getInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.moonlight.Subscription.prototype.setInterval = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 interval_count = 12;
 * @return {number}
 */
proto.moonlight.Subscription.prototype.getIntervalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.moonlight.Subscription.prototype.setIntervalCount = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 quantity = 13;
 * @return {number}
 */
proto.moonlight.Subscription.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.moonlight.Subscription.prototype.setQuantity = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool annual_commitment = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Subscription.prototype.getAnnualCommitment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.moonlight.Subscription.prototype.setAnnualCommitment = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string contract_url = 15;
 * @return {string}
 */
proto.moonlight.Subscription.prototype.getContractUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.Subscription.prototype.setContractUrl = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool legacy_stripe_account = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Subscription.prototype.getLegacyStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.moonlight.Subscription.prototype.setLegacyStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool pull_request_stripe_account = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Subscription.prototype.getPullRequestStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.moonlight.Subscription.prototype.setPullRequestStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SubscriptionRequest.displayName = 'proto.moonlight.SubscriptionRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SubscriptionRequest}
 */
proto.moonlight.SubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SubscriptionRequest;
  return proto.moonlight.SubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SubscriptionRequest}
 */
proto.moonlight.SubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.SubscriptionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.SubscriptionRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.SubscriptionRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SubscriptionsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SubscriptionsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SubscriptionsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SubscriptionsList.displayName = 'proto.moonlight.SubscriptionsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SubscriptionsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SubscriptionsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SubscriptionsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SubscriptionsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto.moonlight.Subscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SubscriptionsList}
 */
proto.moonlight.SubscriptionsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SubscriptionsList;
  return proto.moonlight.SubscriptionsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SubscriptionsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SubscriptionsList}
 */
proto.moonlight.SubscriptionsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Subscription;
      reader.readMessage(value,proto.moonlight.Subscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SubscriptionsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SubscriptionsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SubscriptionsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Subscription subscriptions = 1;
 * @return {!Array.<!proto.moonlight.Subscription>}
 */
proto.moonlight.SubscriptionsList.prototype.getSubscriptionsList = function() {
  return /** @type{!Array.<!proto.moonlight.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Subscription, 1));
};


/** @param {!Array.<!proto.moonlight.Subscription>} value */
proto.moonlight.SubscriptionsList.prototype.setSubscriptionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Subscription}
 */
proto.moonlight.SubscriptionsList.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Subscription, opt_index);
};


proto.moonlight.SubscriptionsList.prototype.clearSubscriptionsList = function() {
  this.setSubscriptionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SubscriptionInvoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SubscriptionInvoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SubscriptionInvoice.displayName = 'proto.moonlight.SubscriptionInvoice';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SubscriptionInvoice.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SubscriptionInvoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SubscriptionInvoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chargeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    centsDue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hostedInvoiceUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paid: jspb.Message.getFieldWithDefault(msg, 7, false),
    number: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SubscriptionInvoice}
 */
proto.moonlight.SubscriptionInvoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SubscriptionInvoice;
  return proto.moonlight.SubscriptionInvoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SubscriptionInvoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SubscriptionInvoice}
 */
proto.moonlight.SubscriptionInvoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChargeId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsDue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostedInvoiceUrl(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SubscriptionInvoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SubscriptionInvoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SubscriptionInvoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChargeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCentsDue();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getHostedInvoiceUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaid();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoice.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoice.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoice.prototype.setSubscriptionId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string charge_id = 3;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoice.prototype.getChargeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoice.prototype.setChargeId = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.SubscriptionInvoice.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.SubscriptionInvoice.prototype.setDate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.SubscriptionInvoice.prototype.clearDate = function() {
  this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.SubscriptionInvoice.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 cents_due = 5;
 * @return {number}
 */
proto.moonlight.SubscriptionInvoice.prototype.getCentsDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.SubscriptionInvoice.prototype.setCentsDue = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string hosted_invoice_url = 6;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoice.prototype.getHostedInvoiceUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoice.prototype.setHostedInvoiceUrl = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool paid = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.SubscriptionInvoice.prototype.getPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.moonlight.SubscriptionInvoice.prototype.setPaid = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string number = 8;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoice.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoice.prototype.setNumber = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SubscriptionInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SubscriptionInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SubscriptionInvoiceRequest.displayName = 'proto.moonlight.SubscriptionInvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SubscriptionInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SubscriptionInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SubscriptionInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SubscriptionInvoiceRequest}
 */
proto.moonlight.SubscriptionInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SubscriptionInvoiceRequest;
  return proto.moonlight.SubscriptionInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SubscriptionInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SubscriptionInvoiceRequest}
 */
proto.moonlight.SubscriptionInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SubscriptionInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SubscriptionInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SubscriptionInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.SubscriptionInvoiceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.SubscriptionInvoiceRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SubscriptionInvoicesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SubscriptionInvoicesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SubscriptionInvoicesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SubscriptionInvoicesList.displayName = 'proto.moonlight.SubscriptionInvoicesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SubscriptionInvoicesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SubscriptionInvoicesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SubscriptionInvoicesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SubscriptionInvoicesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoicesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionInvoicesList: jspb.Message.toObjectList(msg.getSubscriptionInvoicesList(),
    proto.moonlight.SubscriptionInvoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SubscriptionInvoicesList}
 */
proto.moonlight.SubscriptionInvoicesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SubscriptionInvoicesList;
  return proto.moonlight.SubscriptionInvoicesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SubscriptionInvoicesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SubscriptionInvoicesList}
 */
proto.moonlight.SubscriptionInvoicesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.SubscriptionInvoice;
      reader.readMessage(value,proto.moonlight.SubscriptionInvoice.deserializeBinaryFromReader);
      msg.addSubscriptionInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SubscriptionInvoicesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SubscriptionInvoicesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SubscriptionInvoicesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SubscriptionInvoicesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.SubscriptionInvoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionInvoice subscription_invoices = 1;
 * @return {!Array.<!proto.moonlight.SubscriptionInvoice>}
 */
proto.moonlight.SubscriptionInvoicesList.prototype.getSubscriptionInvoicesList = function() {
  return /** @type{!Array.<!proto.moonlight.SubscriptionInvoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.SubscriptionInvoice, 1));
};


/** @param {!Array.<!proto.moonlight.SubscriptionInvoice>} value */
proto.moonlight.SubscriptionInvoicesList.prototype.setSubscriptionInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.SubscriptionInvoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.SubscriptionInvoice}
 */
proto.moonlight.SubscriptionInvoicesList.prototype.addSubscriptionInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.SubscriptionInvoice, opt_index);
};


proto.moonlight.SubscriptionInvoicesList.prototype.clearSubscriptionInvoicesList = function() {
  this.setSubscriptionInvoicesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompaniesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.CompaniesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.CompaniesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompaniesList.displayName = 'proto.moonlight.CompaniesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.CompaniesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompaniesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompaniesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompaniesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompaniesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    companiesList: jspb.Message.toObjectList(msg.getCompaniesList(),
    proto.moonlight.Company.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompaniesList}
 */
proto.moonlight.CompaniesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompaniesList;
  return proto.moonlight.CompaniesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompaniesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompaniesList}
 */
proto.moonlight.CompaniesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Company;
      reader.readMessage(value,proto.moonlight.Company.deserializeBinaryFromReader);
      msg.addCompanies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompaniesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompaniesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompaniesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompaniesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompaniesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Company.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Company companies = 1;
 * @return {!Array.<!proto.moonlight.Company>}
 */
proto.moonlight.CompaniesList.prototype.getCompaniesList = function() {
  return /** @type{!Array.<!proto.moonlight.Company>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Company, 1));
};


/** @param {!Array.<!proto.moonlight.Company>} value */
proto.moonlight.CompaniesList.prototype.setCompaniesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Company=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Company}
 */
proto.moonlight.CompaniesList.prototype.addCompanies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Company, opt_index);
};


proto.moonlight.CompaniesList.prototype.clearCompaniesList = function() {
  this.setCompaniesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyRequest.displayName = 'proto.moonlight.CompanyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyRequest}
 */
proto.moonlight.CompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyRequest;
  return proto.moonlight.CompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyRequest}
 */
proto.moonlight.CompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.FilteredCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.FilteredCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.FilteredCompanyRequest.displayName = 'proto.moonlight.FilteredCompanyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.FilteredCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.FilteredCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.FilteredCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FilteredCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activeUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.FilteredCompanyRequest}
 */
proto.moonlight.FilteredCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.FilteredCompanyRequest;
  return proto.moonlight.FilteredCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.FilteredCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.FilteredCompanyRequest}
 */
proto.moonlight.FilteredCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.FilteredCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.FilteredCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.FilteredCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FilteredCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActiveUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.FilteredCompanyRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.FilteredCompanyRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 active_user_id = 2;
 * @return {number}
 */
proto.moonlight.FilteredCompanyRequest.prototype.getActiveUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.FilteredCompanyRequest.prototype.setActiveUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateCompanyMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateCompanyMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateCompanyMemberRequest.displayName = 'proto.moonlight.CreateCompanyMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateCompanyMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateCompanyMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateCompanyMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateCompanyMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userEmail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateCompanyMemberRequest}
 */
proto.moonlight.CreateCompanyMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateCompanyMemberRequest;
  return proto.moonlight.CreateCompanyMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateCompanyMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateCompanyMemberRequest}
 */
proto.moonlight.CreateCompanyMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateCompanyMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateCompanyMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateCompanyMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateCompanyMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_email = 1;
 * @return {string}
 */
proto.moonlight.CreateCompanyMemberRequest.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateCompanyMemberRequest.prototype.setUserEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CreateCompanyMemberRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateCompanyMemberRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.moonlight.CreateCompanyMemberRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateCompanyMemberRequest.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeleteCompanyMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeleteCompanyMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeleteCompanyMemberRequest.displayName = 'proto.moonlight.DeleteCompanyMemberRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeleteCompanyMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeleteCompanyMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeleteCompanyMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeleteCompanyMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeleteCompanyMemberRequest}
 */
proto.moonlight.DeleteCompanyMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeleteCompanyMemberRequest;
  return proto.moonlight.DeleteCompanyMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeleteCompanyMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeleteCompanyMemberRequest}
 */
proto.moonlight.DeleteCompanyMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeleteCompanyMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeleteCompanyMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeleteCompanyMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeleteCompanyMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.DeleteCompanyMemberRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeleteCompanyMemberRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.DeleteCompanyMemberRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeleteCompanyMemberRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyJobStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyJobStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyJobStats.displayName = 'proto.moonlight.CompanyJobStats';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyJobStats.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyJobStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyJobStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyJobStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    countOpenJobs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countOngoingJobs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    countFinishedJobs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyJobStats}
 */
proto.moonlight.CompanyJobStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyJobStats;
  return proto.moonlight.CompanyJobStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyJobStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyJobStats}
 */
proto.moonlight.CompanyJobStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountOpenJobs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountOngoingJobs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountFinishedJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyJobStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyJobStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyJobStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyJobStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountOpenJobs();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCountOngoingJobs();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCountFinishedJobs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 count_open_jobs = 1;
 * @return {number}
 */
proto.moonlight.CompanyJobStats.prototype.getCountOpenJobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyJobStats.prototype.setCountOpenJobs = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 count_ongoing_jobs = 2;
 * @return {number}
 */
proto.moonlight.CompanyJobStats.prototype.getCountOngoingJobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyJobStats.prototype.setCountOngoingJobs = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 count_finished_jobs = 3;
 * @return {number}
 */
proto.moonlight.CompanyJobStats.prototype.getCountFinishedJobs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.CompanyJobStats.prototype.setCountFinishedJobs = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyMetaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyMetaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyMetaRequest.displayName = 'proto.moonlight.CompanyMetaRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyMetaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyMetaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyMetaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMetaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyMetaRequest}
 */
proto.moonlight.CompanyMetaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyMetaRequest;
  return proto.moonlight.CompanyMetaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyMetaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyMetaRequest}
 */
proto.moonlight.CompanyMetaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyMetaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyMetaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyMetaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMetaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyMetaRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyMetaRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.moonlight.CompanyMetaRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CompanyMetaRequest.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyMeta.displayName = 'proto.moonlight.CompanyMeta';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyMeta}
 */
proto.moonlight.CompanyMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyMeta;
  return proto.moonlight.CompanyMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyMeta}
 */
proto.moonlight.CompanyMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyMeta.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyMeta.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.moonlight.CompanyMeta.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CompanyMeta.prototype.setKey = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.moonlight.CompanyMeta.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CompanyMeta.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyMetasList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.CompanyMetasList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.CompanyMetasList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyMetasList.displayName = 'proto.moonlight.CompanyMetasList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.CompanyMetasList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyMetasList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyMetasList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyMetasList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMetasList.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyMetasList: jspb.Message.toObjectList(msg.getCompanyMetasList(),
    proto.moonlight.CompanyMeta.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyMetasList}
 */
proto.moonlight.CompanyMetasList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyMetasList;
  return proto.moonlight.CompanyMetasList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyMetasList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyMetasList}
 */
proto.moonlight.CompanyMetasList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.CompanyMeta;
      reader.readMessage(value,proto.moonlight.CompanyMeta.deserializeBinaryFromReader);
      msg.addCompanyMetas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyMetasList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyMetasList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyMetasList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyMetasList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyMetasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.CompanyMeta.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyMeta company_metas = 1;
 * @return {!Array.<!proto.moonlight.CompanyMeta>}
 */
proto.moonlight.CompanyMetasList.prototype.getCompanyMetasList = function() {
  return /** @type{!Array.<!proto.moonlight.CompanyMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.CompanyMeta, 1));
};


/** @param {!Array.<!proto.moonlight.CompanyMeta>} value */
proto.moonlight.CompanyMetasList.prototype.setCompanyMetasList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.CompanyMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.CompanyMeta}
 */
proto.moonlight.CompanyMetasList.prototype.addCompanyMetas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.CompanyMeta, opt_index);
};


proto.moonlight.CompanyMetasList.prototype.clearCompanyMetasList = function() {
  this.setCompanyMetasList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyLinkRequest.displayName = 'proto.moonlight.CompanyLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyLinkRequest}
 */
proto.moonlight.CompanyLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyLinkRequest;
  return proto.moonlight.CompanyLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyLinkRequest}
 */
proto.moonlight.CompanyLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.CompanyLinkRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyLinkRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CompanyLinkRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyLinkRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateCompanyLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateCompanyLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateCompanyLinkRequest.displayName = 'proto.moonlight.CreateCompanyLinkRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateCompanyLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateCompanyLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateCompanyLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateCompanyLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateCompanyLinkRequest}
 */
proto.moonlight.CreateCompanyLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateCompanyLinkRequest;
  return proto.moonlight.CreateCompanyLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateCompanyLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateCompanyLinkRequest}
 */
proto.moonlight.CreateCompanyLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateCompanyLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateCompanyLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateCompanyLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateCompanyLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CreateCompanyLinkRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateCompanyLinkRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.moonlight.CreateCompanyLinkRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateCompanyLinkRequest.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyLink.displayName = 'proto.moonlight.CompanyLink';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyLink.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyLink}
 */
proto.moonlight.CompanyLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyLink;
  return proto.moonlight.CompanyLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyLink}
 */
proto.moonlight.CompanyLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.CompanyLink.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyLink.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CompanyLink.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyLink.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.moonlight.CompanyLink.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CompanyLink.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyLinksList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.CompanyLinksList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.CompanyLinksList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyLinksList.displayName = 'proto.moonlight.CompanyLinksList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.CompanyLinksList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyLinksList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyLinksList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyLinksList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLinksList.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyLinksList: jspb.Message.toObjectList(msg.getCompanyLinksList(),
    proto.moonlight.CompanyLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyLinksList}
 */
proto.moonlight.CompanyLinksList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyLinksList;
  return proto.moonlight.CompanyLinksList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyLinksList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyLinksList}
 */
proto.moonlight.CompanyLinksList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.CompanyLink;
      reader.readMessage(value,proto.moonlight.CompanyLink.deserializeBinaryFromReader);
      msg.addCompanyLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyLinksList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyLinksList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyLinksList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyLinksList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.CompanyLink.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyLink company_links = 1;
 * @return {!Array.<!proto.moonlight.CompanyLink>}
 */
proto.moonlight.CompanyLinksList.prototype.getCompanyLinksList = function() {
  return /** @type{!Array.<!proto.moonlight.CompanyLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.CompanyLink, 1));
};


/** @param {!Array.<!proto.moonlight.CompanyLink>} value */
proto.moonlight.CompanyLinksList.prototype.setCompanyLinksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.CompanyLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.CompanyLink}
 */
proto.moonlight.CompanyLinksList.prototype.addCompanyLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.CompanyLink, opt_index);
};


proto.moonlight.CompanyLinksList.prototype.clearCompanyLinksList = function() {
  this.setCompanyLinksList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanySkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanySkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanySkillRequest.displayName = 'proto.moonlight.CompanySkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanySkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanySkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanySkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanySkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skillSlug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanySkillRequest}
 */
proto.moonlight.CompanySkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanySkillRequest;
  return proto.moonlight.CompanySkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanySkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanySkillRequest}
 */
proto.moonlight.CompanySkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanySkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanySkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanySkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanySkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanySkillRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanySkillRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string skill_slug = 2;
 * @return {string}
 */
proto.moonlight.CompanySkillRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CompanySkillRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyBankAccountPaymentMethodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyBankAccountPaymentMethodRequest.displayName = 'proto.moonlight.CompanyBankAccountPaymentMethodRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyBankAccountPaymentMethodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyBankAccountPaymentMethodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    plaidPublicToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plaidAccountId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyBankAccountPaymentMethodRequest}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyBankAccountPaymentMethodRequest;
  return proto.moonlight.CompanyBankAccountPaymentMethodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyBankAccountPaymentMethodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyBankAccountPaymentMethodRequest}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaidPublicToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaidAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyBankAccountPaymentMethodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyBankAccountPaymentMethodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlaidPublicToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaidAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string plaid_public_token = 2;
 * @return {string}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.getPlaidPublicToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.setPlaidPublicToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string plaid_account_id = 3;
 * @return {string}
 */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.getPlaidAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CompanyBankAccountPaymentMethodRequest.prototype.setPlaidAccountId = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyCardPaymentMethodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyCardPaymentMethodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyCardPaymentMethodRequest.displayName = 'proto.moonlight.CompanyCardPaymentMethodRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyCardPaymentMethodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyCardPaymentMethodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCardPaymentMethodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stripePublicToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyCardPaymentMethodRequest}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyCardPaymentMethodRequest;
  return proto.moonlight.CompanyCardPaymentMethodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyCardPaymentMethodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyCardPaymentMethodRequest}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePublicToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyCardPaymentMethodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyCardPaymentMethodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCardPaymentMethodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStripePublicToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string stripe_public_token = 2;
 * @return {string}
 */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.getStripePublicToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CompanyCardPaymentMethodRequest.prototype.setStripePublicToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyPaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyPaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyPaymentMethod.displayName = 'proto.moonlight.CompanyPaymentMethod';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyPaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyPaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyPaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    active: jspb.Message.getFieldWithDefault(msg, 3, false),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stripeSourceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expMonth: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    brand: jspb.Message.getFieldWithDefault(msg, 8, ""),
    last4: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bankName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    stripeCustomerId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    legacyStripeAccount: jspb.Message.getFieldWithDefault(msg, 14, false),
    pullRequestStripeAccount: jspb.Message.getFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyPaymentMethod}
 */
proto.moonlight.CompanyPaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyPaymentMethod;
  return proto.moonlight.CompanyPaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyPaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyPaymentMethod}
 */
proto.moonlight.CompanyPaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeSourceId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpYear(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeCustomerId(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLegacyStripeAccount(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPullRequestStripeAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyPaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyPaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyPaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStripeSourceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpMonth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExpYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLegacyStripeAccount();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPullRequestStripeAccount();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethod.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethod.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool active = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.CompanyPaymentMethod.prototype.setActive = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string stripe_source_id = 5;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getStripeSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setStripeSourceId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 exp_month = 6;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getExpMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethod.prototype.setExpMonth = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 exp_year = 7;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getExpYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethod.prototype.setExpYear = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string brand = 8;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setBrand = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string last4 = 9;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setLast4 = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string bank_name = 12;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setBankName = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string stripe_customer_id = 13;
 * @return {string}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.CompanyPaymentMethod.prototype.setStripeCustomerId = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool legacy_stripe_account = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getLegacyStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.moonlight.CompanyPaymentMethod.prototype.setLegacyStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool pull_request_stripe_account = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CompanyPaymentMethod.prototype.getPullRequestStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.moonlight.CompanyPaymentMethod.prototype.setPullRequestStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyPaymentMethodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyPaymentMethodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyPaymentMethodRequest.displayName = 'proto.moonlight.CompanyPaymentMethodRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyPaymentMethodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyPaymentMethodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyPaymentMethodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyPaymentMethodRequest}
 */
proto.moonlight.CompanyPaymentMethodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyPaymentMethodRequest;
  return proto.moonlight.CompanyPaymentMethodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyPaymentMethodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyPaymentMethodRequest}
 */
proto.moonlight.CompanyPaymentMethodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyPaymentMethodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyPaymentMethodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyPaymentMethodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethodRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethodRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CompanyPaymentMethodRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyPaymentMethodRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyPaymentMethodsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.CompanyPaymentMethodsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.CompanyPaymentMethodsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyPaymentMethodsList.displayName = 'proto.moonlight.CompanyPaymentMethodsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.CompanyPaymentMethodsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyPaymentMethodsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyPaymentMethodsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyPaymentMethodsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethodsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodsList: jspb.Message.toObjectList(msg.getMethodsList(),
    proto.moonlight.CompanyPaymentMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyPaymentMethodsList}
 */
proto.moonlight.CompanyPaymentMethodsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyPaymentMethodsList;
  return proto.moonlight.CompanyPaymentMethodsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyPaymentMethodsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyPaymentMethodsList}
 */
proto.moonlight.CompanyPaymentMethodsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.CompanyPaymentMethod;
      reader.readMessage(value,proto.moonlight.CompanyPaymentMethod.deserializeBinaryFromReader);
      msg.addMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyPaymentMethodsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyPaymentMethodsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyPaymentMethodsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyPaymentMethodsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.CompanyPaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompanyPaymentMethod methods = 1;
 * @return {!Array.<!proto.moonlight.CompanyPaymentMethod>}
 */
proto.moonlight.CompanyPaymentMethodsList.prototype.getMethodsList = function() {
  return /** @type{!Array.<!proto.moonlight.CompanyPaymentMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.CompanyPaymentMethod, 1));
};


/** @param {!Array.<!proto.moonlight.CompanyPaymentMethod>} value */
proto.moonlight.CompanyPaymentMethodsList.prototype.setMethodsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.CompanyPaymentMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.CompanyPaymentMethod}
 */
proto.moonlight.CompanyPaymentMethodsList.prototype.addMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.CompanyPaymentMethod, opt_index);
};


proto.moonlight.CompanyPaymentMethodsList.prototype.clearMethodsList = function() {
  this.setMethodsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CityQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CityQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CityQuery.displayName = 'proto.moonlight.CityQuery';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CityQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CityQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CityQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CityQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lat: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    radiusMiles: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CityQuery}
 */
proto.moonlight.CityQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CityQuery;
  return proto.moonlight.CityQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CityQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CityQuery}
 */
proto.moonlight.CityQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadiusMiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CityQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CityQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CityQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CityQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRadiusMiles();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.moonlight.CityQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CityQuery.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double lat = 2;
 * @return {number}
 */
proto.moonlight.CityQuery.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.moonlight.CityQuery.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lon = 3;
 * @return {number}
 */
proto.moonlight.CityQuery.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.moonlight.CityQuery.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 radius_miles = 4;
 * @return {number}
 */
proto.moonlight.CityQuery.prototype.getRadiusMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.CityQuery.prototype.setRadiusMiles = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SearchDevelopersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SearchDevelopersRequest.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SearchDevelopersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SearchDevelopersRequest.displayName = 'proto.moonlight.SearchDevelopersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SearchDevelopersRequest.repeatedFields_ = [1,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SearchDevelopersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SearchDevelopersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SearchDevelopersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SearchDevelopersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillSlugsList: jspb.Message.getRepeatedField(msg, 1),
    exactMatch: jspb.Message.getFieldWithDefault(msg, 2, false),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 5, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 6, false),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.moonlight.CityQuery.toObject, includeInstance),
    countriesList: jspb.Message.getRepeatedField(msg, 9),
    limitToPublicProfiles: jspb.Message.getFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SearchDevelopersRequest}
 */
proto.moonlight.SearchDevelopersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SearchDevelopersRequest;
  return proto.moonlight.SearchDevelopersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SearchDevelopersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SearchDevelopersRequest}
 */
proto.moonlight.SearchDevelopersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSlugs(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExactMatch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 8:
      var value = new proto.moonlight.CityQuery;
      reader.readMessage(value,proto.moonlight.CityQuery.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addCountries(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitToPublicProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SearchDevelopersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SearchDevelopersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SearchDevelopersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SearchDevelopersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExactMatch();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.moonlight.CityQuery.serializeBinaryToWriter
    );
  }
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getLimitToPublicProfiles();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * repeated string skill_slugs = 1;
 * @return {!Array.<string>}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getSkillSlugsList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array.<string>} value */
proto.moonlight.SearchDevelopersRequest.prototype.setSkillSlugsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.moonlight.SearchDevelopersRequest.prototype.addSkillSlugs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.moonlight.SearchDevelopersRequest.prototype.clearSkillSlugsList = function() {
  this.setSkillSlugsList([]);
};


/**
 * optional bool exact_match = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getExactMatch = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.SearchDevelopersRequest.prototype.setExactMatch = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.SearchDevelopersRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 offset = 4;
 * @return {number}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.SearchDevelopersRequest.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool seeking_contracts = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.SearchDevelopersRequest.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool seeking_employment = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.moonlight.SearchDevelopersRequest.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated CityQuery cities = 8;
 * @return {!Array.<!proto.moonlight.CityQuery>}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getCitiesList = function() {
  return /** @type{!Array.<!proto.moonlight.CityQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.CityQuery, 8));
};


/** @param {!Array.<!proto.moonlight.CityQuery>} value */
proto.moonlight.SearchDevelopersRequest.prototype.setCitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.moonlight.CityQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.CityQuery}
 */
proto.moonlight.SearchDevelopersRequest.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.moonlight.CityQuery, opt_index);
};


proto.moonlight.SearchDevelopersRequest.prototype.clearCitiesList = function() {
  this.setCitiesList([]);
};


/**
 * repeated string countries = 9;
 * @return {!Array.<string>}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getCountriesList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array.<string>} value */
proto.moonlight.SearchDevelopersRequest.prototype.setCountriesList = function(value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.moonlight.SearchDevelopersRequest.prototype.addCountries = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


proto.moonlight.SearchDevelopersRequest.prototype.clearCountriesList = function() {
  this.setCountriesList([]);
};


/**
 * optional bool limit_to_public_profiles = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.SearchDevelopersRequest.prototype.getLimitToPublicProfiles = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.moonlight.SearchDevelopersRequest.prototype.setLimitToPublicProfiles = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.RecommendedDevelopersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.RecommendedDevelopersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.RecommendedDevelopersRequest.displayName = 'proto.moonlight.RecommendedDevelopersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.RecommendedDevelopersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.RecommendedDevelopersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.RecommendedDevelopersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 4, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.RecommendedDevelopersRequest}
 */
proto.moonlight.RecommendedDevelopersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.RecommendedDevelopersRequest;
  return proto.moonlight.RecommendedDevelopersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.RecommendedDevelopersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.RecommendedDevelopersRequest}
 */
proto.moonlight.RecommendedDevelopersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.RecommendedDevelopersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.RecommendedDevelopersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.RecommendedDevelopersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.RecommendedDevelopersRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.RecommendedDevelopersRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.RecommendedDevelopersRequest.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool seeking_contracts = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.RecommendedDevelopersRequest.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool seeking_employment = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.RecommendedDevelopersRequest.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.RecommendedDevelopersRequest.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SearchDevelopersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.SearchDevelopersResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.SearchDevelopersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SearchDevelopersResponse.displayName = 'proto.moonlight.SearchDevelopersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.SearchDevelopersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SearchDevelopersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SearchDevelopersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SearchDevelopersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SearchDevelopersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    exactCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inexactCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SearchDevelopersResponse}
 */
proto.moonlight.SearchDevelopersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SearchDevelopersResponse;
  return proto.moonlight.SearchDevelopersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SearchDevelopersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SearchDevelopersResponse}
 */
proto.moonlight.SearchDevelopersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExactCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInexactCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SearchDevelopersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SearchDevelopersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SearchDevelopersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SearchDevelopersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getExactCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInexactCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated DeveloperSummary results = 1;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.SearchDevelopersResponse.prototype.getResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 1));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.SearchDevelopersResponse.prototype.setResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.SearchDevelopersResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.SearchDevelopersResponse.prototype.clearResultsList = function() {
  this.setResultsList([]);
};


/**
 * optional int32 exact_count = 2;
 * @return {number}
 */
proto.moonlight.SearchDevelopersResponse.prototype.getExactCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.SearchDevelopersResponse.prototype.setExactCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 inexact_count = 3;
 * @return {number}
 */
proto.moonlight.SearchDevelopersResponse.prototype.getInexactCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.SearchDevelopersResponse.prototype.setInexactCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperSearch.displayName = 'proto.moonlight.DeveloperSearch';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperSearch}
 */
proto.moonlight.DeveloperSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperSearch;
  return proto.moonlight.DeveloperSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperSearch}
 */
proto.moonlight.DeveloperSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DeveloperSearch.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearch.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperSearch.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearch.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.moonlight.DeveloperSearch.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearch.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.DeveloperSearch.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.DeveloperSearch.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.DeveloperSearch.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.DeveloperSearch.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperSearchSkill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperSearchSkill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperSearchSkill.displayName = 'proto.moonlight.DeveloperSearchSkill';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperSearchSkill.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperSearchSkill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperSearchSkill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchSkill.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerSearchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slug: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperSearchSkill}
 */
proto.moonlight.DeveloperSearchSkill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperSearchSkill;
  return proto.moonlight.DeveloperSearchSkill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperSearchSkill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperSearchSkill}
 */
proto.moonlight.DeveloperSearchSkill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperSearchId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperSearchSkill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperSearchSkill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperSearchSkill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchSkill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperSearchId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DeveloperSearchSkill.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchSkill.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_search_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperSearchSkill.prototype.getDeveloperSearchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchSkill.prototype.setDeveloperSearchId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string slug = 5;
 * @return {string}
 */
proto.moonlight.DeveloperSearchSkill.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.DeveloperSearchSkill.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperSearchCountry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperSearchCountry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperSearchCountry.displayName = 'proto.moonlight.DeveloperSearchCountry';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperSearchCountry.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperSearchCountry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperSearchCountry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchCountry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerSearchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    country: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperSearchCountry}
 */
proto.moonlight.DeveloperSearchCountry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperSearchCountry;
  return proto.moonlight.DeveloperSearchCountry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperSearchCountry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperSearchCountry}
 */
proto.moonlight.DeveloperSearchCountry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperSearchId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperSearchCountry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperSearchCountry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperSearchCountry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchCountry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperSearchId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCountry.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCountry.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_search_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCountry.prototype.getDeveloperSearchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCountry.prototype.setDeveloperSearchId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.moonlight.DeveloperSearchCountry.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.DeveloperSearchCountry.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperSearchCity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperSearchCity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperSearchCity.displayName = 'proto.moonlight.DeveloperSearchCity';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperSearchCity.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperSearchCity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperSearchCity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchCity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerSearchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lat: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    radiusMiles: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperSearchCity}
 */
proto.moonlight.DeveloperSearchCity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperSearchCity;
  return proto.moonlight.DeveloperSearchCity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperSearchCity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperSearchCity}
 */
proto.moonlight.DeveloperSearchCity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperSearchId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadiusMiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperSearchCity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperSearchCity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperSearchCity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperSearchCity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperSearchId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadiusMiles();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCity.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_search_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCity.prototype.getDeveloperSearchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCity.prototype.setDeveloperSearchId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string location_name = 3;
 * @return {string}
 */
proto.moonlight.DeveloperSearchCity.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.DeveloperSearchCity.prototype.setLocationName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double lat = 4;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCity.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCity.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double lon = 5;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCity.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCity.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 radius_miles = 6;
 * @return {number}
 */
proto.moonlight.DeveloperSearchCity.prototype.getRadiusMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperSearchCity.prototype.setRadiusMiles = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DevelopersForHomepageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.DevelopersForHomepageResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.DevelopersForHomepageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DevelopersForHomepageResponse.displayName = 'proto.moonlight.DevelopersForHomepageResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.DevelopersForHomepageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DevelopersForHomepageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DevelopersForHomepageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DevelopersForHomepageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    locationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lat: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    ip: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DevelopersForHomepageResponse}
 */
proto.moonlight.DevelopersForHomepageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DevelopersForHomepageResponse;
  return proto.moonlight.DevelopersForHomepageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DevelopersForHomepageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DevelopersForHomepageResponse}
 */
proto.moonlight.DevelopersForHomepageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DevelopersForHomepageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DevelopersForHomepageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DevelopersForHomepageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated DeveloperSummary results = 1;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.getResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 1));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.DevelopersForHomepageResponse.prototype.setResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.DevelopersForHomepageResponse.prototype.clearResultsList = function() {
  this.setResultsList([]);
};


/**
 * optional string location_name = 2;
 * @return {string}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.DevelopersForHomepageResponse.prototype.setLocationName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double lat = 3;
 * @return {number}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.moonlight.DevelopersForHomepageResponse.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lon = 4;
 * @return {number}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.moonlight.DevelopersForHomepageResponse.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string ip = 5;
 * @return {string}
 */
proto.moonlight.DevelopersForHomepageResponse.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.DevelopersForHomepageResponse.prototype.setIp = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ExampleDevelopersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ExampleDevelopersResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ExampleDevelopersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ExampleDevelopersResponse.displayName = 'proto.moonlight.ExampleDevelopersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ExampleDevelopersResponse.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ExampleDevelopersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ExampleDevelopersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ExampleDevelopersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cityName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    citySlug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reactResultsList: jspb.Message.toObjectList(msg.getReactResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    rubyOnRailsResultsList: jspb.Message.toObjectList(msg.getRubyOnRailsResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    tensorflowResultsList: jspb.Message.toObjectList(msg.getTensorflowResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    swiftResultsList: jspb.Message.toObjectList(msg.getSwiftResultsList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ExampleDevelopersResponse}
 */
proto.moonlight.ExampleDevelopersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ExampleDevelopersResponse;
  return proto.moonlight.ExampleDevelopersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ExampleDevelopersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ExampleDevelopersResponse}
 */
proto.moonlight.ExampleDevelopersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitySlug(value);
      break;
    case 3:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addReactResults(value);
      break;
    case 4:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addRubyOnRailsResults(value);
      break;
    case 5:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addTensorflowResults(value);
      break;
    case 6:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addSwiftResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ExampleDevelopersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ExampleDevelopersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ExampleDevelopersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCitySlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReactResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getRubyOnRailsResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getTensorflowResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getSwiftResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string city_name = 1;
 * @return {string}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setCityName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city_slug = 2;
 * @return {string}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getCitySlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setCitySlug = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DeveloperSummary react_results = 3;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getReactResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 3));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setReactResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.addReactResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ExampleDevelopersResponse.prototype.clearReactResultsList = function() {
  this.setReactResultsList([]);
};


/**
 * repeated DeveloperSummary ruby_on_rails_results = 4;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getRubyOnRailsResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 4));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setRubyOnRailsResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.addRubyOnRailsResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ExampleDevelopersResponse.prototype.clearRubyOnRailsResultsList = function() {
  this.setRubyOnRailsResultsList([]);
};


/**
 * repeated DeveloperSummary tensorflow_results = 5;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getTensorflowResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 5));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setTensorflowResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.addTensorflowResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ExampleDevelopersResponse.prototype.clearTensorflowResultsList = function() {
  this.setTensorflowResultsList([]);
};


/**
 * repeated DeveloperSummary swift_results = 6;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.getSwiftResultsList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 6));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ExampleDevelopersResponse.prototype.setSwiftResultsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ExampleDevelopersResponse.prototype.addSwiftResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ExampleDevelopersResponse.prototype.clearSwiftResultsList = function() {
  this.setSwiftResultsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LiveStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LiveStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LiveStatsResponse.displayName = 'proto.moonlight.LiveStatsResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LiveStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LiveStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LiveStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LiveStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeDeveloperCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    averageYearsExperience: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LiveStatsResponse}
 */
proto.moonlight.LiveStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LiveStatsResponse;
  return proto.moonlight.LiveStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LiveStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LiveStatsResponse}
 */
proto.moonlight.LiveStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveDeveloperCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAverageYearsExperience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LiveStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LiveStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LiveStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LiveStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveDeveloperCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAverageYearsExperience();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 active_developer_count = 1;
 * @return {number}
 */
proto.moonlight.LiveStatsResponse.prototype.getActiveDeveloperCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LiveStatsResponse.prototype.setActiveDeveloperCount = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 average_years_experience = 2;
 * @return {number}
 */
proto.moonlight.LiveStatsResponse.prototype.getAverageYearsExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.LiveStatsResponse.prototype.setAverageYearsExperience = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListLandingPageDevelopersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ListLandingPageDevelopersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListLandingPageDevelopersRequest.displayName = 'proto.moonlight.ListLandingPageDevelopersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListLandingPageDevelopersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListLandingPageDevelopersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListLandingPageDevelopersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListLandingPageDevelopersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillSlug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListLandingPageDevelopersRequest}
 */
proto.moonlight.ListLandingPageDevelopersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListLandingPageDevelopersRequest;
  return proto.moonlight.ListLandingPageDevelopersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListLandingPageDevelopersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListLandingPageDevelopersRequest}
 */
proto.moonlight.ListLandingPageDevelopersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListLandingPageDevelopersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListLandingPageDevelopersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListLandingPageDevelopersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListLandingPageDevelopersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string skill_slug = 1;
 * @return {string}
 */
proto.moonlight.ListLandingPageDevelopersRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ListLandingPageDevelopersRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListLandingPageDevelopersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ListLandingPageDevelopersResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ListLandingPageDevelopersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListLandingPageDevelopersResponse.displayName = 'proto.moonlight.ListLandingPageDevelopersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ListLandingPageDevelopersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListLandingPageDevelopersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListLandingPageDevelopersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListLandingPageDevelopersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuredDeveloper: (f = msg.getFeaturedDeveloper()) && proto.moonlight.DeveloperSummary.toObject(includeInstance, f),
    developersList: jspb.Message.toObjectList(msg.getDevelopersList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListLandingPageDevelopersResponse}
 */
proto.moonlight.ListLandingPageDevelopersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListLandingPageDevelopersResponse;
  return proto.moonlight.ListLandingPageDevelopersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListLandingPageDevelopersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListLandingPageDevelopersResponse}
 */
proto.moonlight.ListLandingPageDevelopersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.setFeaturedDeveloper(value);
      break;
    case 2:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addDevelopers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListLandingPageDevelopersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListLandingPageDevelopersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListLandingPageDevelopersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturedDeveloper();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getDevelopersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeveloperSummary featured_developer = 1;
 * @return {?proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.getFeaturedDeveloper = function() {
  return /** @type{?proto.moonlight.DeveloperSummary} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.DeveloperSummary, 1));
};


/** @param {?proto.moonlight.DeveloperSummary|undefined} value */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.setFeaturedDeveloper = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.ListLandingPageDevelopersResponse.prototype.clearFeaturedDeveloper = function() {
  this.setFeaturedDeveloper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.hasFeaturedDeveloper = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeveloperSummary developers = 2;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.getDevelopersList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 2));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.setDevelopersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListLandingPageDevelopersResponse.prototype.addDevelopers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ListLandingPageDevelopersResponse.prototype.clearDevelopersList = function() {
  this.setDevelopersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListCityLandingPageDevelopersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ListCityLandingPageDevelopersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListCityLandingPageDevelopersRequest.displayName = 'proto.moonlight.ListCityLandingPageDevelopersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListCityLandingPageDevelopersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListCityLandingPageDevelopersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    citySlug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListCityLandingPageDevelopersRequest}
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListCityLandingPageDevelopersRequest;
  return proto.moonlight.ListCityLandingPageDevelopersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListCityLandingPageDevelopersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListCityLandingPageDevelopersRequest}
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitySlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListCityLandingPageDevelopersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListCityLandingPageDevelopersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCitySlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string city_slug = 1;
 * @return {string}
 */
proto.moonlight.ListCityLandingPageDevelopersRequest.prototype.getCitySlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ListCityLandingPageDevelopersRequest.prototype.setCitySlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListCityLandingPageDevelopersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ListCityLandingPageDevelopersResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ListCityLandingPageDevelopersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListCityLandingPageDevelopersResponse.displayName = 'proto.moonlight.ListCityLandingPageDevelopersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListCityLandingPageDevelopersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListCityLandingPageDevelopersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuredDeveloper: (f = msg.getFeaturedDeveloper()) && proto.moonlight.DeveloperSummary.toObject(includeInstance, f),
    developersList: jspb.Message.toObjectList(msg.getDevelopersList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    city: (f = msg.getCity()) && proto.moonlight.City.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListCityLandingPageDevelopersResponse}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListCityLandingPageDevelopersResponse;
  return proto.moonlight.ListCityLandingPageDevelopersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListCityLandingPageDevelopersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListCityLandingPageDevelopersResponse}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.setFeaturedDeveloper(value);
      break;
    case 2:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addDevelopers(value);
      break;
    case 3:
      var value = new proto.moonlight.City;
      reader.readMessage(value,proto.moonlight.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListCityLandingPageDevelopersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListCityLandingPageDevelopersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturedDeveloper();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getDevelopersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.moonlight.City.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeveloperSummary featured_developer = 1;
 * @return {?proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.getFeaturedDeveloper = function() {
  return /** @type{?proto.moonlight.DeveloperSummary} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.DeveloperSummary, 1));
};


/** @param {?proto.moonlight.DeveloperSummary|undefined} value */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.setFeaturedDeveloper = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.clearFeaturedDeveloper = function() {
  this.setFeaturedDeveloper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.hasFeaturedDeveloper = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeveloperSummary developers = 2;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.getDevelopersList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 2));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.setDevelopersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.addDevelopers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.clearDevelopersList = function() {
  this.setDevelopersList([]);
};


/**
 * optional City city = 3;
 * @return {?proto.moonlight.City}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.getCity = function() {
  return /** @type{?proto.moonlight.City} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.City, 3));
};


/** @param {?proto.moonlight.City|undefined} value */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.setCity = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.clearCity = function() {
  this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ListCityLandingPageDevelopersResponse.prototype.hasCity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ListCountryLandingPageDevelopersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListCountryLandingPageDevelopersRequest.displayName = 'proto.moonlight.ListCountryLandingPageDevelopersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListCountryLandingPageDevelopersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    country: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListCountryLandingPageDevelopersRequest}
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListCountryLandingPageDevelopersRequest;
  return proto.moonlight.ListCountryLandingPageDevelopersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListCountryLandingPageDevelopersRequest}
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListCountryLandingPageDevelopersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.moonlight.ListCountryLandingPageDevelopersRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ListCountryLandingPageDevelopersRequest.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ListCountryLandingPageDevelopersResponse.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ListCountryLandingPageDevelopersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ListCountryLandingPageDevelopersResponse.displayName = 'proto.moonlight.ListCountryLandingPageDevelopersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ListCountryLandingPageDevelopersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    featuredDeveloper: (f = msg.getFeaturedDeveloper()) && proto.moonlight.DeveloperSummary.toObject(includeInstance, f),
    developersList: jspb.Message.toObjectList(msg.getDevelopersList(),
    proto.moonlight.DeveloperSummary.toObject, includeInstance),
    citiesList: jspb.Message.toObjectList(msg.getCitiesList(),
    proto.moonlight.City.toObject, includeInstance),
    countryName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ListCountryLandingPageDevelopersResponse}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ListCountryLandingPageDevelopersResponse;
  return proto.moonlight.ListCountryLandingPageDevelopersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ListCountryLandingPageDevelopersResponse}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.setFeaturedDeveloper(value);
      break;
    case 2:
      var value = new proto.moonlight.DeveloperSummary;
      reader.readMessage(value,proto.moonlight.DeveloperSummary.deserializeBinaryFromReader);
      msg.addDevelopers(value);
      break;
    case 3:
      var value = new proto.moonlight.City;
      reader.readMessage(value,proto.moonlight.City.deserializeBinaryFromReader);
      msg.addCities(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ListCountryLandingPageDevelopersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ListCountryLandingPageDevelopersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeaturedDeveloper();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getDevelopersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.moonlight.DeveloperSummary.serializeBinaryToWriter
    );
  }
  f = message.getCitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.moonlight.City.serializeBinaryToWriter
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional DeveloperSummary featured_developer = 1;
 * @return {?proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.getFeaturedDeveloper = function() {
  return /** @type{?proto.moonlight.DeveloperSummary} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.DeveloperSummary, 1));
};


/** @param {?proto.moonlight.DeveloperSummary|undefined} value */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.setFeaturedDeveloper = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.clearFeaturedDeveloper = function() {
  this.setFeaturedDeveloper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.hasFeaturedDeveloper = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeveloperSummary developers = 2;
 * @return {!Array.<!proto.moonlight.DeveloperSummary>}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.getDevelopersList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperSummary, 2));
};


/** @param {!Array.<!proto.moonlight.DeveloperSummary>} value */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.setDevelopersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.moonlight.DeveloperSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperSummary}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.addDevelopers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.moonlight.DeveloperSummary, opt_index);
};


proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.clearDevelopersList = function() {
  this.setDevelopersList([]);
};


/**
 * repeated City cities = 3;
 * @return {!Array.<!proto.moonlight.City>}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.getCitiesList = function() {
  return /** @type{!Array.<!proto.moonlight.City>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.City, 3));
};


/** @param {!Array.<!proto.moonlight.City>} value */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.setCitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.moonlight.City=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.City}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.addCities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.moonlight.City, opt_index);
};


proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.clearCitiesList = function() {
  this.setCitiesList([]);
};


/**
 * optional string country_name = 4;
 * @return {string}
 */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.ListCountryLandingPageDevelopersResponse.prototype.setCountryName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Job.displayName = 'proto.moonlight.Job';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    overview: jspb.Message.getFieldWithDefault(msg, 5, ""),
    spec: jspb.Message.getFieldWithDefault(msg, 6, ""),
    maxHours: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getFieldWithDefault(msg, 9, ""),
    developerAgreed: jspb.Message.getFieldWithDefault(msg, 10, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    workStartedAt: (f = msg.getWorkStartedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    finishedAt: (f = msg.getFinishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    canceledAt: (f = msg.getCanceledAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    approved: jspb.Message.getFieldWithDefault(msg, 16, false),
    type: jspb.Message.getFieldWithDefault(msg, 18, ""),
    seekingContractors: jspb.Message.getFieldWithDefault(msg, 19, false),
    seekingEmployees: jspb.Message.getFieldWithDefault(msg, 20, false),
    salaryCents: jspb.Message.getFieldWithDefault(msg, 21, 0),
    slackGroupId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    payRateCents: jspb.Message.getFieldWithDefault(msg, 23, 0),
    billingRateCents: jspb.Message.getFieldWithDefault(msg, 24, 0),
    jobTakeRate: +jspb.Message.getFieldWithDefault(msg, 26, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Job}
 */
proto.moonlight.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Job;
  return proto.moonlight.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Job}
 */
proto.moonlight.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverview(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpec(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxHours(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeveloperAgreed(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWorkStartedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinishedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCanceledAt(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContractors(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployees(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSalaryCents(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackGroupId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPayRateCents(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBillingRateCents(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJobTakeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOverview();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMaxHours();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeveloperAgreed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWorkStartedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFinishedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCanceledAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSeekingContractors();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getSeekingEmployees();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSalaryCents();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getSlackGroupId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPayRateCents();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getBillingRateCents();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getJobTakeRate();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Job.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.Job.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 developer_id = 3;
 * @return {number}
 */
proto.moonlight.Job.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.moonlight.Job.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string overview = 5;
 * @return {string}
 */
proto.moonlight.Job.prototype.getOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setOverview = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string spec = 6;
 * @return {string}
 */
proto.moonlight.Job.prototype.getSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setSpec = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 max_hours = 7;
 * @return {number}
 */
proto.moonlight.Job.prototype.getMaxHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setMaxHours = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string state = 9;
 * @return {string}
 */
proto.moonlight.Job.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool developer_agreed = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Job.prototype.getDeveloperAgreed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.moonlight.Job.prototype.setDeveloperAgreed = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Job.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Job.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.moonlight.Job.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Job.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Job.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Job.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


proto.moonlight.Job.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Job.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp work_started_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Job.prototype.getWorkStartedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Job.prototype.setWorkStartedAt = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.moonlight.Job.prototype.clearWorkStartedAt = function() {
  this.setWorkStartedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Job.prototype.hasWorkStartedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp finished_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Job.prototype.getFinishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Job.prototype.setFinishedAt = function(value) {
  jspb.Message.setWrapperField(this, 14, value);
};


proto.moonlight.Job.prototype.clearFinishedAt = function() {
  this.setFinishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Job.prototype.hasFinishedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp canceled_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Job.prototype.getCanceledAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Job.prototype.setCanceledAt = function(value) {
  jspb.Message.setWrapperField(this, 15, value);
};


proto.moonlight.Job.prototype.clearCanceledAt = function() {
  this.setCanceledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Job.prototype.hasCanceledAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool approved = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Job.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.moonlight.Job.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string type = 18;
 * @return {string}
 */
proto.moonlight.Job.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool seeking_contractors = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Job.prototype.getSeekingContractors = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.moonlight.Job.prototype.setSeekingContractors = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool seeking_employees = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Job.prototype.getSeekingEmployees = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.moonlight.Job.prototype.setSeekingEmployees = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 salary_cents = 21;
 * @return {number}
 */
proto.moonlight.Job.prototype.getSalaryCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setSalaryCents = function(value) {
  jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string slack_group_id = 22;
 * @return {string}
 */
proto.moonlight.Job.prototype.getSlackGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.moonlight.Job.prototype.setSlackGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int32 pay_rate_cents = 23;
 * @return {number}
 */
proto.moonlight.Job.prototype.getPayRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setPayRateCents = function(value) {
  jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 billing_rate_cents = 24;
 * @return {number}
 */
proto.moonlight.Job.prototype.getBillingRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setBillingRateCents = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional double job_take_rate = 26;
 * @return {number}
 */
proto.moonlight.Job.prototype.getJobTakeRate = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 26, 0.0));
};


/** @param {number} value */
proto.moonlight.Job.prototype.setJobTakeRate = function(value) {
  jspb.Message.setProto3FloatField(this, 26, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.InterviewMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.InterviewMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.InterviewMessageRequest.displayName = 'proto.moonlight.InterviewMessageRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.InterviewMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.InterviewMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.InterviewMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InterviewMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.InterviewMessageRequest}
 */
proto.moonlight.InterviewMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.InterviewMessageRequest;
  return proto.moonlight.InterviewMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.InterviewMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.InterviewMessageRequest}
 */
proto.moonlight.InterviewMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.InterviewMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.InterviewMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.InterviewMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InterviewMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.InterviewMessageRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.InterviewMessageRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_id = 2;
 * @return {number}
 */
proto.moonlight.InterviewMessageRequest.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.InterviewMessageRequest.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DirectInterview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DirectInterview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DirectInterview.displayName = 'proto.moonlight.DirectInterview';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DirectInterview.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DirectInterview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DirectInterview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DirectInterview.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    slackGroupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    archived: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DirectInterview}
 */
proto.moonlight.DirectInterview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DirectInterview;
  return proto.moonlight.DirectInterview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DirectInterview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DirectInterview}
 */
proto.moonlight.DirectInterview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackGroupId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DirectInterview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DirectInterview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DirectInterview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DirectInterview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSlackGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DirectInterview.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DirectInterview.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_id = 2;
 * @return {number}
 */
proto.moonlight.DirectInterview.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DirectInterview.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.DirectInterview.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.DirectInterview.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string slack_group_id = 4;
 * @return {string}
 */
proto.moonlight.DirectInterview.prototype.getSlackGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.DirectInterview.prototype.setSlackGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool archived = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.DirectInterview.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.DirectInterview.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SlackGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SlackGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SlackGroup.displayName = 'proto.moonlight.SlackGroup';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SlackGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SlackGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SlackGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SlackGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    slackGroupId: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SlackGroup}
 */
proto.moonlight.SlackGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SlackGroup;
  return proto.moonlight.SlackGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SlackGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SlackGroup}
 */
proto.moonlight.SlackGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SlackGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SlackGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SlackGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SlackGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlackGroupId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string slack_group_id = 22;
 * @return {string}
 */
proto.moonlight.SlackGroup.prototype.getSlackGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.moonlight.SlackGroup.prototype.setSlackGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.CreateJobRequest.repeatedFields_, null);
};
goog.inherits(proto.moonlight.CreateJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateJobRequest.displayName = 'proto.moonlight.CreateJobRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.CreateJobRequest.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    overview: jspb.Message.getFieldWithDefault(msg, 5, ""),
    spec: jspb.Message.getFieldWithDefault(msg, 6, ""),
    maxHours: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getFieldWithDefault(msg, 9, ""),
    approved: jspb.Message.getFieldWithDefault(msg, 10, false),
    skillSlugsList: jspb.Message.getRepeatedField(msg, 11),
    type: jspb.Message.getFieldWithDefault(msg, 12, ""),
    seekingContractors: jspb.Message.getFieldWithDefault(msg, 13, false),
    seekingEmployees: jspb.Message.getFieldWithDefault(msg, 14, false),
    salaryCents: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateJobRequest}
 */
proto.moonlight.CreateJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateJobRequest;
  return proto.moonlight.CreateJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateJobRequest}
 */
proto.moonlight.CreateJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverview(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpec(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxHours(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSlugs(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContractors(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployees(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSalaryCents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOverview();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMaxHours();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSkillSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSeekingContractors();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSeekingEmployees();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSalaryCents();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CreateJobRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateJobRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 developer_id = 2;
 * @return {number}
 */
proto.moonlight.CreateJobRequest.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateJobRequest.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.moonlight.CreateJobRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.CreateJobRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string overview = 5;
 * @return {string}
 */
proto.moonlight.CreateJobRequest.prototype.getOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CreateJobRequest.prototype.setOverview = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string spec = 6;
 * @return {string}
 */
proto.moonlight.CreateJobRequest.prototype.getSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.CreateJobRequest.prototype.setSpec = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 max_hours = 7;
 * @return {number}
 */
proto.moonlight.CreateJobRequest.prototype.getMaxHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.CreateJobRequest.prototype.setMaxHours = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string state = 9;
 * @return {string}
 */
proto.moonlight.CreateJobRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.CreateJobRequest.prototype.setState = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool approved = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateJobRequest.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.moonlight.CreateJobRequest.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated string skill_slugs = 11;
 * @return {!Array.<string>}
 */
proto.moonlight.CreateJobRequest.prototype.getSkillSlugsList = function() {
  return /** @type {!Array.<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array.<string>} value */
proto.moonlight.CreateJobRequest.prototype.setSkillSlugsList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.moonlight.CreateJobRequest.prototype.addSkillSlugs = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.moonlight.CreateJobRequest.prototype.clearSkillSlugsList = function() {
  this.setSkillSlugsList([]);
};


/**
 * optional string type = 12;
 * @return {string}
 */
proto.moonlight.CreateJobRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.moonlight.CreateJobRequest.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool seeking_contractors = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateJobRequest.prototype.getSeekingContractors = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.moonlight.CreateJobRequest.prototype.setSeekingContractors = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool seeking_employees = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateJobRequest.prototype.getSeekingEmployees = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.moonlight.CreateJobRequest.prototype.setSeekingEmployees = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int64 salary_cents = 15;
 * @return {number}
 */
proto.moonlight.CreateJobRequest.prototype.getSalaryCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.moonlight.CreateJobRequest.prototype.setSalaryCents = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.JobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.JobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.JobRequest.displayName = 'proto.moonlight.JobRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.JobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.JobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.JobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.JobRequest}
 */
proto.moonlight.JobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.JobRequest;
  return proto.moonlight.JobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.JobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.JobRequest}
 */
proto.moonlight.JobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.JobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.JobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.JobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.JobRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.JobRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.JobRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.JobRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.JobsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.JobsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.JobsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.JobsList.displayName = 'proto.moonlight.JobsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.JobsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.JobsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.JobsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.JobsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobsList: jspb.Message.toObjectList(msg.getJobsList(),
    proto.moonlight.Job.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.JobsList}
 */
proto.moonlight.JobsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.JobsList;
  return proto.moonlight.JobsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.JobsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.JobsList}
 */
proto.moonlight.JobsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Job;
      reader.readMessage(value,proto.moonlight.Job.deserializeBinaryFromReader);
      msg.addJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.JobsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.JobsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.JobsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Job.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Job jobs = 1;
 * @return {!Array.<!proto.moonlight.Job>}
 */
proto.moonlight.JobsList.prototype.getJobsList = function() {
  return /** @type{!Array.<!proto.moonlight.Job>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Job, 1));
};


/** @param {!Array.<!proto.moonlight.Job>} value */
proto.moonlight.JobsList.prototype.setJobsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Job=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Job}
 */
proto.moonlight.JobsList.prototype.addJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Job, opt_index);
};


proto.moonlight.JobsList.prototype.clearJobsList = function() {
  this.setJobsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.JobSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.JobSummary.repeatedFields_, null);
};
goog.inherits(proto.moonlight.JobSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.JobSummary.displayName = 'proto.moonlight.JobSummary';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.JobSummary.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.JobSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.JobSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.JobSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    job: (f = msg.getJob()) && proto.moonlight.Job.toObject(includeInstance, f),
    applicationCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    company: (f = msg.getCompany()) && proto.moonlight.Company.toObject(includeInstance, f),
    developer: (f = msg.getDeveloper()) && proto.moonlight.User.toObject(includeInstance, f),
    developerCentsPaid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    developerCentsUnpaid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    developerCentsPending: jspb.Message.getFieldWithDefault(msg, 7, 0),
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.moonlight.Invoice.toObject, includeInstance),
    clientCentsPaid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    clientCentsUnpaid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    clientCentsPending: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.JobSummary}
 */
proto.moonlight.JobSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.JobSummary;
  return proto.moonlight.JobSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.JobSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.JobSummary}
 */
proto.moonlight.JobSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Job;
      reader.readMessage(value,proto.moonlight.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApplicationCount(value);
      break;
    case 3:
      var value = new proto.moonlight.Company;
      reader.readMessage(value,proto.moonlight.Company.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    case 4:
      var value = new proto.moonlight.User;
      reader.readMessage(value,proto.moonlight.User.deserializeBinaryFromReader);
      msg.setDeveloper(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeveloperCentsPaid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeveloperCentsUnpaid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeveloperCentsPending(value);
      break;
    case 8:
      var value = new proto.moonlight.Invoice;
      reader.readMessage(value,proto.moonlight.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientCentsPaid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientCentsUnpaid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientCentsPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.JobSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.JobSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.JobSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Job.serializeBinaryToWriter
    );
  }
  f = message.getApplicationCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.moonlight.Company.serializeBinaryToWriter
    );
  }
  f = message.getDeveloper();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.moonlight.User.serializeBinaryToWriter
    );
  }
  f = message.getDeveloperCentsPaid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDeveloperCentsUnpaid();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDeveloperCentsPending();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.moonlight.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getClientCentsPaid();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getClientCentsUnpaid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getClientCentsPending();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional Job job = 1;
 * @return {?proto.moonlight.Job}
 */
proto.moonlight.JobSummary.prototype.getJob = function() {
  return /** @type{?proto.moonlight.Job} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Job, 1));
};


/** @param {?proto.moonlight.Job|undefined} value */
proto.moonlight.JobSummary.prototype.setJob = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.JobSummary.prototype.clearJob = function() {
  this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.JobSummary.prototype.hasJob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 application_count = 2;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getApplicationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setApplicationCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Company company = 3;
 * @return {?proto.moonlight.Company}
 */
proto.moonlight.JobSummary.prototype.getCompany = function() {
  return /** @type{?proto.moonlight.Company} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Company, 3));
};


/** @param {?proto.moonlight.Company|undefined} value */
proto.moonlight.JobSummary.prototype.setCompany = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.JobSummary.prototype.clearCompany = function() {
  this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.JobSummary.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User developer = 4;
 * @return {?proto.moonlight.User}
 */
proto.moonlight.JobSummary.prototype.getDeveloper = function() {
  return /** @type{?proto.moonlight.User} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.User, 4));
};


/** @param {?proto.moonlight.User|undefined} value */
proto.moonlight.JobSummary.prototype.setDeveloper = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.JobSummary.prototype.clearDeveloper = function() {
  this.setDeveloper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.JobSummary.prototype.hasDeveloper = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 developer_cents_paid = 5;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getDeveloperCentsPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setDeveloperCentsPaid = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 developer_cents_unpaid = 6;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getDeveloperCentsUnpaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setDeveloperCentsUnpaid = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 developer_cents_pending = 7;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getDeveloperCentsPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setDeveloperCentsPending = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated Invoice invoices = 8;
 * @return {!Array.<!proto.moonlight.Invoice>}
 */
proto.moonlight.JobSummary.prototype.getInvoicesList = function() {
  return /** @type{!Array.<!proto.moonlight.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Invoice, 8));
};


/** @param {!Array.<!proto.moonlight.Invoice>} value */
proto.moonlight.JobSummary.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.moonlight.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Invoice}
 */
proto.moonlight.JobSummary.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.moonlight.Invoice, opt_index);
};


proto.moonlight.JobSummary.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};


/**
 * optional int64 client_cents_paid = 9;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getClientCentsPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setClientCentsPaid = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 client_cents_unpaid = 10;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getClientCentsUnpaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setClientCentsUnpaid = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 client_cents_pending = 11;
 * @return {number}
 */
proto.moonlight.JobSummary.prototype.getClientCentsPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.moonlight.JobSummary.prototype.setClientCentsPending = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.JobSummariesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.JobSummariesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.JobSummariesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.JobSummariesList.displayName = 'proto.moonlight.JobSummariesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.JobSummariesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.JobSummariesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.JobSummariesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.JobSummariesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSummariesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    summariesList: jspb.Message.toObjectList(msg.getSummariesList(),
    proto.moonlight.JobSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.JobSummariesList}
 */
proto.moonlight.JobSummariesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.JobSummariesList;
  return proto.moonlight.JobSummariesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.JobSummariesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.JobSummariesList}
 */
proto.moonlight.JobSummariesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.JobSummary;
      reader.readMessage(value,proto.moonlight.JobSummary.deserializeBinaryFromReader);
      msg.addSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.JobSummariesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.JobSummariesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.JobSummariesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSummariesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.JobSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated JobSummary summaries = 1;
 * @return {!Array.<!proto.moonlight.JobSummary>}
 */
proto.moonlight.JobSummariesList.prototype.getSummariesList = function() {
  return /** @type{!Array.<!proto.moonlight.JobSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.JobSummary, 1));
};


/** @param {!Array.<!proto.moonlight.JobSummary>} value */
proto.moonlight.JobSummariesList.prototype.setSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.JobSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.JobSummary}
 */
proto.moonlight.JobSummariesList.prototype.addSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.JobSummary, opt_index);
};


proto.moonlight.JobSummariesList.prototype.clearSummariesList = function() {
  this.setSummariesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.JobSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.JobSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.JobSkillRequest.displayName = 'proto.moonlight.JobSkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.JobSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.JobSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.JobSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    skillSlug: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.JobSkillRequest}
 */
proto.moonlight.JobSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.JobSkillRequest;
  return proto.moonlight.JobSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.JobSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.JobSkillRequest}
 */
proto.moonlight.JobSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.JobSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.JobSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.JobSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.JobSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.moonlight.JobSkillRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.JobSkillRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.JobSkillRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.JobSkillRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string skill_slug = 3;
 * @return {string}
 */
proto.moonlight.JobSkillRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.JobSkillRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperJobMatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperJobMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperJobMatch.displayName = 'proto.moonlight.DeveloperJobMatch';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperJobMatch.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperJobMatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperJobMatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    developerNotified: jspb.Message.getFieldWithDefault(msg, 4, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    matchScore: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    matchScoreAlgorithmVersion: jspb.Message.getFieldWithDefault(msg, 8, 0),
    developerDismissed: jspb.Message.getFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperJobMatch}
 */
proto.moonlight.DeveloperJobMatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperJobMatch;
  return proto.moonlight.DeveloperJobMatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperJobMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperJobMatch}
 */
proto.moonlight.DeveloperJobMatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeveloperNotified(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMatchScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMatchScoreAlgorithmVersion(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeveloperDismissed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperJobMatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperJobMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperJobMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeveloperNotified();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMatchScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMatchScoreAlgorithmVersion();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDeveloperDismissed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatch.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatch.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatch.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatch.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatch.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatch.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool developer_notified = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.DeveloperJobMatch.prototype.getDeveloperNotified = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.DeveloperJobMatch.prototype.setDeveloperNotified = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.DeveloperJobMatch.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.DeveloperJobMatch.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.DeveloperJobMatch.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.DeveloperJobMatch.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.DeveloperJobMatch.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.DeveloperJobMatch.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.moonlight.DeveloperJobMatch.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.DeveloperJobMatch.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double match_score = 7;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatch.prototype.getMatchScore = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatch.prototype.setMatchScore = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 match_score_algorithm_version = 8;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatch.prototype.getMatchScoreAlgorithmVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatch.prototype.setMatchScoreAlgorithmVersion = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool developer_dismissed = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.DeveloperJobMatch.prototype.getDeveloperDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.moonlight.DeveloperJobMatch.prototype.setDeveloperDismissed = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperJobMatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.DeveloperJobMatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperJobMatchRequest.displayName = 'proto.moonlight.DeveloperJobMatchRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperJobMatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperJobMatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperJobMatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperJobMatchRequest}
 */
proto.moonlight.DeveloperJobMatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperJobMatchRequest;
  return proto.moonlight.DeveloperJobMatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperJobMatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperJobMatchRequest}
 */
proto.moonlight.DeveloperJobMatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperJobMatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperJobMatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperJobMatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatchRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatchRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.DeveloperJobMatchRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.DeveloperJobMatchRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.DeveloperJobMatchList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.DeveloperJobMatchList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.DeveloperJobMatchList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.DeveloperJobMatchList.displayName = 'proto.moonlight.DeveloperJobMatchList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.DeveloperJobMatchList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.DeveloperJobMatchList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.DeveloperJobMatchList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.DeveloperJobMatchList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatchList.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchesList: jspb.Message.toObjectList(msg.getMatchesList(),
    proto.moonlight.DeveloperJobMatch.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.DeveloperJobMatchList}
 */
proto.moonlight.DeveloperJobMatchList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.DeveloperJobMatchList;
  return proto.moonlight.DeveloperJobMatchList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.DeveloperJobMatchList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.DeveloperJobMatchList}
 */
proto.moonlight.DeveloperJobMatchList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.DeveloperJobMatch;
      reader.readMessage(value,proto.moonlight.DeveloperJobMatch.deserializeBinaryFromReader);
      msg.addMatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.DeveloperJobMatchList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.DeveloperJobMatchList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.DeveloperJobMatchList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.DeveloperJobMatchList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.DeveloperJobMatch.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeveloperJobMatch matches = 1;
 * @return {!Array.<!proto.moonlight.DeveloperJobMatch>}
 */
proto.moonlight.DeveloperJobMatchList.prototype.getMatchesList = function() {
  return /** @type{!Array.<!proto.moonlight.DeveloperJobMatch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.DeveloperJobMatch, 1));
};


/** @param {!Array.<!proto.moonlight.DeveloperJobMatch>} value */
proto.moonlight.DeveloperJobMatchList.prototype.setMatchesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.DeveloperJobMatch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.DeveloperJobMatch}
 */
proto.moonlight.DeveloperJobMatchList.prototype.addMatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.DeveloperJobMatch, opt_index);
};


proto.moonlight.DeveloperJobMatchList.prototype.clearMatchesList = function() {
  this.setMatchesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Application = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Application, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Application.displayName = 'proto.moonlight.Application';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Application.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Application.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Application} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Application.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    application: jspb.Message.getFieldWithDefault(msg, 5, ""),
    minHours: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxHours: jspb.Message.getFieldWithDefault(msg, 7, 0),
    declined: jspb.Message.getFieldWithDefault(msg, 9, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    seekingContract: jspb.Message.getFieldWithDefault(msg, 12, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 13, false),
    notifiedDeclined: jspb.Message.getFieldWithDefault(msg, 14, false),
    slackGroupId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    payRateCents: jspb.Message.getFieldWithDefault(msg, 16, 0),
    billingRateCents: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Application}
 */
proto.moonlight.Application.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Application;
  return proto.moonlight.Application.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Application} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Application}
 */
proto.moonlight.Application.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplication(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinHours(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxHours(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeclined(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContract(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotifiedDeclined(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackGroupId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPayRateCents(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBillingRateCents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Application.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Application.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Application} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Application.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getApplication();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMinHours();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxHours();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDeclined();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSeekingContract();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getNotifiedDeclined();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSlackGroupId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPayRateCents();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getBillingRateCents();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Application.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.Application.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 job_id = 3;
 * @return {number}
 */
proto.moonlight.Application.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 developer_id = 4;
 * @return {number}
 */
proto.moonlight.Application.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string application = 5;
 * @return {string}
 */
proto.moonlight.Application.prototype.getApplication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.Application.prototype.setApplication = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 min_hours = 6;
 * @return {number}
 */
proto.moonlight.Application.prototype.getMinHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setMinHours = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 max_hours = 7;
 * @return {number}
 */
proto.moonlight.Application.prototype.getMaxHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setMaxHours = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool declined = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Application.prototype.getDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.moonlight.Application.prototype.setDeclined = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Application.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Application.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.Application.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Application.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Application.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Application.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.moonlight.Application.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Application.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool seeking_contract = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Application.prototype.getSeekingContract = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.Application.prototype.setSeekingContract = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool seeking_employment = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Application.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.moonlight.Application.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool notified_declined = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Application.prototype.getNotifiedDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.moonlight.Application.prototype.setNotifiedDeclined = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string slack_group_id = 15;
 * @return {string}
 */
proto.moonlight.Application.prototype.getSlackGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.Application.prototype.setSlackGroupId = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 pay_rate_cents = 16;
 * @return {number}
 */
proto.moonlight.Application.prototype.getPayRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setPayRateCents = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 billing_rate_cents = 17;
 * @return {number}
 */
proto.moonlight.Application.prototype.getBillingRateCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.moonlight.Application.prototype.setBillingRateCents = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateApplicationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateApplicationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateApplicationRequest.displayName = 'proto.moonlight.CreateApplicationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateApplicationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateApplicationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateApplicationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateApplicationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    developerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    application: jspb.Message.getFieldWithDefault(msg, 4, ""),
    minHours: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxHours: jspb.Message.getFieldWithDefault(msg, 6, 0),
    seekingContract: jspb.Message.getFieldWithDefault(msg, 7, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateApplicationRequest}
 */
proto.moonlight.CreateApplicationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateApplicationRequest;
  return proto.moonlight.CreateApplicationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateApplicationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateApplicationRequest}
 */
proto.moonlight.CreateApplicationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplication(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinHours(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxHours(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContract(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateApplicationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateApplicationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateApplicationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateApplicationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getApplication();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMinHours();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxHours();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSeekingContract();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.moonlight.CreateApplicationRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateApplicationRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CreateApplicationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateApplicationRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 developer_id = 3;
 * @return {number}
 */
proto.moonlight.CreateApplicationRequest.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.CreateApplicationRequest.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string application = 4;
 * @return {string}
 */
proto.moonlight.CreateApplicationRequest.prototype.getApplication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.CreateApplicationRequest.prototype.setApplication = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 min_hours = 5;
 * @return {number}
 */
proto.moonlight.CreateApplicationRequest.prototype.getMinHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.CreateApplicationRequest.prototype.setMinHours = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_hours = 6;
 * @return {number}
 */
proto.moonlight.CreateApplicationRequest.prototype.getMaxHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.CreateApplicationRequest.prototype.setMaxHours = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool seeking_contract = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateApplicationRequest.prototype.getSeekingContract = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.moonlight.CreateApplicationRequest.prototype.setSeekingContract = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool seeking_employment = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateApplicationRequest.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.CreateApplicationRequest.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ApplicationsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ApplicationsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ApplicationsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ApplicationsList.displayName = 'proto.moonlight.ApplicationsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ApplicationsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ApplicationsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ApplicationsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ApplicationsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ApplicationsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicationsList: jspb.Message.toObjectList(msg.getApplicationsList(),
    proto.moonlight.Application.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ApplicationsList}
 */
proto.moonlight.ApplicationsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ApplicationsList;
  return proto.moonlight.ApplicationsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ApplicationsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ApplicationsList}
 */
proto.moonlight.ApplicationsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Application;
      reader.readMessage(value,proto.moonlight.Application.deserializeBinaryFromReader);
      msg.addApplications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ApplicationsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ApplicationsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ApplicationsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ApplicationsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Application.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Application applications = 1;
 * @return {!Array.<!proto.moonlight.Application>}
 */
proto.moonlight.ApplicationsList.prototype.getApplicationsList = function() {
  return /** @type{!Array.<!proto.moonlight.Application>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Application, 1));
};


/** @param {!Array.<!proto.moonlight.Application>} value */
proto.moonlight.ApplicationsList.prototype.setApplicationsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Application=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Application}
 */
proto.moonlight.ApplicationsList.prototype.addApplications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Application, opt_index);
};


proto.moonlight.ApplicationsList.prototype.clearApplicationsList = function() {
  this.setApplicationsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ApplicationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ApplicationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ApplicationRequest.displayName = 'proto.moonlight.ApplicationRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ApplicationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ApplicationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ApplicationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ApplicationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ApplicationRequest}
 */
proto.moonlight.ApplicationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ApplicationRequest;
  return proto.moonlight.ApplicationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ApplicationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ApplicationRequest}
 */
proto.moonlight.ApplicationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ApplicationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ApplicationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ApplicationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ApplicationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.ApplicationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.ApplicationRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.ApplicationRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.ApplicationRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.ApplicationRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.ApplicationRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LineItem.displayName = 'proto.moonlight.LineItem';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    date: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hours: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    notes: jspb.Message.getFieldWithDefault(msg, 7, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LineItem}
 */
proto.moonlight.LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LineItem;
  return proto.moonlight.LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LineItem}
 */
proto.moonlight.LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHours(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.LineItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LineItem.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.LineItem.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.LineItem.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.LineItem.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.LineItem.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 invoice_id = 4;
 * @return {number}
 */
proto.moonlight.LineItem.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.LineItem.prototype.setInvoiceId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string date = 5;
 * @return {string}
 */
proto.moonlight.LineItem.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.LineItem.prototype.setDate = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double hours = 6;
 * @return {number}
 */
proto.moonlight.LineItem.prototype.getHours = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.moonlight.LineItem.prototype.setHours = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.moonlight.LineItem.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.LineItem.prototype.setNotes = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.LineItem.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.LineItem.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.LineItem.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.LineItem.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.LineItem.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.LineItem.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.moonlight.LineItem.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.LineItem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateLineItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateLineItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateLineItemRequest.displayName = 'proto.moonlight.CreateLineItemRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateLineItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateLineItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateLineItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateLineItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hours: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    notes: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateLineItemRequest}
 */
proto.moonlight.CreateLineItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateLineItemRequest;
  return proto.moonlight.CreateLineItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateLineItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateLineItemRequest}
 */
proto.moonlight.CreateLineItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHours(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateLineItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateLineItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateLineItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateLineItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHours();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.moonlight.CreateLineItemRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateLineItemRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.CreateLineItemRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateLineItemRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.moonlight.CreateLineItemRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateLineItemRequest.prototype.setDate = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double hours = 4;
 * @return {number}
 */
proto.moonlight.CreateLineItemRequest.prototype.getHours = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.moonlight.CreateLineItemRequest.prototype.setHours = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string notes = 5;
 * @return {string}
 */
proto.moonlight.CreateLineItemRequest.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CreateLineItemRequest.prototype.setNotes = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LineItemsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.LineItemsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.LineItemsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LineItemsList.displayName = 'proto.moonlight.LineItemsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.LineItemsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LineItemsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LineItemsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LineItemsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItemsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.moonlight.LineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LineItemsList}
 */
proto.moonlight.LineItemsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LineItemsList;
  return proto.moonlight.LineItemsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LineItemsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LineItemsList}
 */
proto.moonlight.LineItemsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.LineItem;
      reader.readMessage(value,proto.moonlight.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LineItemsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LineItemsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LineItemsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItemsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.LineItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LineItem line_items = 1;
 * @return {!Array.<!proto.moonlight.LineItem>}
 */
proto.moonlight.LineItemsList.prototype.getLineItemsList = function() {
  return /** @type{!Array.<!proto.moonlight.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.LineItem, 1));
};


/** @param {!Array.<!proto.moonlight.LineItem>} value */
proto.moonlight.LineItemsList.prototype.setLineItemsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.LineItem}
 */
proto.moonlight.LineItemsList.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.LineItem, opt_index);
};


proto.moonlight.LineItemsList.prototype.clearLineItemsList = function() {
  this.setLineItemsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LineItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LineItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LineItemRequest.displayName = 'proto.moonlight.LineItemRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LineItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LineItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LineItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LineItemRequest}
 */
proto.moonlight.LineItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LineItemRequest;
  return proto.moonlight.LineItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LineItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LineItemRequest}
 */
proto.moonlight.LineItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LineItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LineItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LineItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LineItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.LineItemRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LineItemRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.LineItemRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.LineItemRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.LineItemRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.LineItemRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Invoice.displayName = 'proto.moonlight.Invoice';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accessToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    centsPayout: jspb.Message.getFieldWithDefault(msg, 5, 0),
    centsFee: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dueAt: (f = msg.getDueAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paidAt: (f = msg.getPaidAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paid: jspb.Message.getFieldWithDefault(msg, 11, false),
    approved: jspb.Message.getFieldWithDefault(msg, 12, false),
    disputed: jspb.Message.getFieldWithDefault(msg, 13, false),
    voided: jspb.Message.getFieldWithDefault(msg, 14, false),
    stripeChargeId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    developerCheckIn: jspb.Message.getFieldWithDefault(msg, 16, ""),
    feedbackFromDeveloperGoodProgress: jspb.Message.getFieldWithDefault(msg, 17, false),
    feedbackFromDeveloperClearCommunication: jspb.Message.getFieldWithDefault(msg, 18, false),
    feedbackFromDeveloperInterestingWork: jspb.Message.getFieldWithDefault(msg, 19, false),
    feedbackFromDeveloperAlmostFinished: jspb.Message.getFieldWithDefault(msg, 20, false),
    feedbackFromDeveloperSlowProgress: jspb.Message.getFieldWithDefault(msg, 21, false),
    feedbackFromDeveloperUnansweredQuestions: jspb.Message.getFieldWithDefault(msg, 22, false),
    feedbackFromDeveloperScopeCreep: jspb.Message.getFieldWithDefault(msg, 23, false),
    feedbackFromDeveloperUnprofessional: jspb.Message.getFieldWithDefault(msg, 24, false),
    feedbackFromDeveloperExtra: jspb.Message.getFieldWithDefault(msg, 25, ""),
    shareFeedbackFromDeveloper: jspb.Message.getFieldWithDefault(msg, 26, false),
    feedbackFromClientGoodProgress: jspb.Message.getFieldWithDefault(msg, 27, false),
    feedbackFromClientClearCommunication: jspb.Message.getFieldWithDefault(msg, 28, false),
    feedbackFromClientKnowledgeable: jspb.Message.getFieldWithDefault(msg, 29, false),
    feedbackFromClientQualityWork: jspb.Message.getFieldWithDefault(msg, 30, false),
    feedbackFromClientSlowProgress: jspb.Message.getFieldWithDefault(msg, 31, false),
    feedbackFromClientHardToReach: jspb.Message.getFieldWithDefault(msg, 32, false),
    feedbackFromClientQualityIssues: jspb.Message.getFieldWithDefault(msg, 33, false),
    feedbackFromClientUnprofessional: jspb.Message.getFieldWithDefault(msg, 34, false),
    feedbackFromClientExtra: jspb.Message.getFieldWithDefault(msg, 35, ""),
    shareFeedbackFromClient: jspb.Message.getFieldWithDefault(msg, 36, false),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 37, 0),
    centsFreeProcessingApplied: jspb.Message.getFieldWithDefault(msg, 38, 0),
    legacyStripeAccount: jspb.Message.getFieldWithDefault(msg, 39, false),
    pullRequestStripeAccount: jspb.Message.getFieldWithDefault(msg, 40, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Invoice}
 */
proto.moonlight.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Invoice;
  return proto.moonlight.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Invoice}
 */
proto.moonlight.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsPayout(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsFee(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaidAt(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaid(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisputed(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVoided(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeChargeId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeveloperCheckIn(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperGoodProgress(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperClearCommunication(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperInterestingWork(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperAlmostFinished(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperSlowProgress(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperUnansweredQuestions(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperScopeCreep(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromDeveloperUnprofessional(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackFromDeveloperExtra(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShareFeedbackFromDeveloper(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientGoodProgress(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientClearCommunication(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientKnowledgeable(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientQualityWork(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientSlowProgress(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientHardToReach(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientQualityIssues(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFeedbackFromClientUnprofessional(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedbackFromClientExtra(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShareFeedbackFromClient(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodId(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsFreeProcessingApplied(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLegacyStripeAccount(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPullRequestStripeAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCentsPayout();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCentsFee();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDueAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaidAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaid();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDisputed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getVoided();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getStripeChargeId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDeveloperCheckIn();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFeedbackFromDeveloperGoodProgress();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getFeedbackFromDeveloperClearCommunication();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getFeedbackFromDeveloperInterestingWork();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getFeedbackFromDeveloperAlmostFinished();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getFeedbackFromDeveloperSlowProgress();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getFeedbackFromDeveloperUnansweredQuestions();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getFeedbackFromDeveloperScopeCreep();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getFeedbackFromDeveloperUnprofessional();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getFeedbackFromDeveloperExtra();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getShareFeedbackFromDeveloper();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getFeedbackFromClientGoodProgress();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getFeedbackFromClientClearCommunication();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getFeedbackFromClientKnowledgeable();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getFeedbackFromClientQualityWork();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getFeedbackFromClientSlowProgress();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getFeedbackFromClientHardToReach();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getFeedbackFromClientQualityIssues();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getFeedbackFromClientUnprofessional();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getFeedbackFromClientExtra();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getShareFeedbackFromClient();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
  f = message.getCentsFreeProcessingApplied();
  if (f !== 0) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getLegacyStripeAccount();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getPullRequestStripeAccount();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 job_id = 2;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string access_token = 4;
 * @return {string}
 */
proto.moonlight.Invoice.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Invoice.prototype.setAccessToken = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 cents_payout = 5;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getCentsPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setCentsPayout = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 cents_fee = 6;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getCentsFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setCentsFee = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Invoice.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Invoice.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.moonlight.Invoice.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Invoice.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Invoice.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Invoice.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.Invoice.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Invoice.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp due_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Invoice.prototype.getDueAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Invoice.prototype.setDueAt = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.moonlight.Invoice.prototype.clearDueAt = function() {
  this.setDueAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Invoice.prototype.hasDueAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp paid_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Invoice.prototype.getPaidAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Invoice.prototype.setPaidAt = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.Invoice.prototype.clearPaidAt = function() {
  this.setPaidAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Invoice.prototype.hasPaidAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool paid = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setPaid = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool approved = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool disputed = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getDisputed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setDisputed = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool voided = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getVoided = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setVoided = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string stripe_charge_id = 15;
 * @return {string}
 */
proto.moonlight.Invoice.prototype.getStripeChargeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.Invoice.prototype.setStripeChargeId = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string developer_check_in = 16;
 * @return {string}
 */
proto.moonlight.Invoice.prototype.getDeveloperCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.moonlight.Invoice.prototype.setDeveloperCheckIn = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool feedback_from_developer_good_progress = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperGoodProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperGoodProgress = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool feedback_from_developer_clear_communication = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperClearCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperClearCommunication = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool feedback_from_developer_interesting_work = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperInterestingWork = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperInterestingWork = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool feedback_from_developer_almost_finished = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperAlmostFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperAlmostFinished = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool feedback_from_developer_slow_progress = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperSlowProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperSlowProgress = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool feedback_from_developer_unanswered_questions = 22;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperUnansweredQuestions = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 22, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperUnansweredQuestions = function(value) {
  jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool feedback_from_developer_scope_creep = 23;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperScopeCreep = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 23, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperScopeCreep = function(value) {
  jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool feedback_from_developer_unprofessional = 24;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperUnprofessional = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 24, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperUnprofessional = function(value) {
  jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string feedback_from_developer_extra = 25;
 * @return {string}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromDeveloperExtra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.moonlight.Invoice.prototype.setFeedbackFromDeveloperExtra = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool share_feedback_from_developer = 26;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getShareFeedbackFromDeveloper = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 26, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setShareFeedbackFromDeveloper = function(value) {
  jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool feedback_from_client_good_progress = 27;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientGoodProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 27, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientGoodProgress = function(value) {
  jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool feedback_from_client_clear_communication = 28;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientClearCommunication = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 28, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientClearCommunication = function(value) {
  jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool feedback_from_client_knowledgeable = 29;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientKnowledgeable = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 29, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientKnowledgeable = function(value) {
  jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool feedback_from_client_quality_work = 30;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientQualityWork = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 30, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientQualityWork = function(value) {
  jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool feedback_from_client_slow_progress = 31;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientSlowProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 31, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientSlowProgress = function(value) {
  jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool feedback_from_client_hard_to_reach = 32;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientHardToReach = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 32, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientHardToReach = function(value) {
  jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool feedback_from_client_quality_issues = 33;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientQualityIssues = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 33, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientQualityIssues = function(value) {
  jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool feedback_from_client_unprofessional = 34;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientUnprofessional = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 34, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientUnprofessional = function(value) {
  jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional string feedback_from_client_extra = 35;
 * @return {string}
 */
proto.moonlight.Invoice.prototype.getFeedbackFromClientExtra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/** @param {string} value */
proto.moonlight.Invoice.prototype.setFeedbackFromClientExtra = function(value) {
  jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional bool share_feedback_from_client = 36;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getShareFeedbackFromClient = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 36, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setShareFeedbackFromClient = function(value) {
  jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional int32 payment_method_id = 37;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getPaymentMethodId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setPaymentMethodId = function(value) {
  jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional int64 cents_free_processing_applied = 38;
 * @return {number}
 */
proto.moonlight.Invoice.prototype.getCentsFreeProcessingApplied = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/** @param {number} value */
proto.moonlight.Invoice.prototype.setCentsFreeProcessingApplied = function(value) {
  jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional bool legacy_stripe_account = 39;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getLegacyStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 39, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setLegacyStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool pull_request_stripe_account = 40;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Invoice.prototype.getPullRequestStripeAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 40, false));
};


/** @param {boolean} value */
proto.moonlight.Invoice.prototype.setPullRequestStripeAccount = function(value) {
  jspb.Message.setProto3BooleanField(this, 40, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.GlobalSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.GlobalSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.GlobalSettings.displayName = 'proto.moonlight.GlobalSettings';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.GlobalSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.GlobalSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.GlobalSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.GlobalSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settingValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    settingDataType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.GlobalSettings}
 */
proto.moonlight.GlobalSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.GlobalSettings;
  return proto.moonlight.GlobalSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.GlobalSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.GlobalSettings}
 */
proto.moonlight.GlobalSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingDataType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.GlobalSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.GlobalSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.GlobalSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.GlobalSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSettingName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettingValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSettingDataType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.GlobalSettings.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.GlobalSettings.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string setting_name = 2;
 * @return {string}
 */
proto.moonlight.GlobalSettings.prototype.getSettingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.GlobalSettings.prototype.setSettingName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string setting_value = 3;
 * @return {string}
 */
proto.moonlight.GlobalSettings.prototype.getSettingValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.GlobalSettings.prototype.setSettingValue = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string setting_data_type = 4;
 * @return {string}
 */
proto.moonlight.GlobalSettings.prototype.getSettingDataType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.GlobalSettings.prototype.setSettingDataType = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.GlobalSettings.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.GlobalSettings.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.GlobalSettings.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.GlobalSettings.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.InvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.InvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.InvoiceRequest.displayName = 'proto.moonlight.InvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.InvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.InvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.InvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.InvoiceRequest}
 */
proto.moonlight.InvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.InvoiceRequest;
  return proto.moonlight.InvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.InvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.InvoiceRequest}
 */
proto.moonlight.InvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.InvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.InvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.InvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 job_id = 1;
 * @return {number}
 */
proto.moonlight.InvoiceRequest.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.InvoiceRequest.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.InvoiceRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.InvoiceRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 invoice_id = 3;
 * @return {number}
 */
proto.moonlight.InvoiceRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.InvoiceRequest.prototype.setInvoiceId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PublicInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PublicInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PublicInvoiceRequest.displayName = 'proto.moonlight.PublicInvoiceRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PublicInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PublicInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PublicInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PublicInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PublicInvoiceRequest}
 */
proto.moonlight.PublicInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PublicInvoiceRequest;
  return proto.moonlight.PublicInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PublicInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PublicInvoiceRequest}
 */
proto.moonlight.PublicInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PublicInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PublicInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PublicInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PublicInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 invoice_id = 1;
 * @return {number}
 */
proto.moonlight.PublicInvoiceRequest.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.PublicInvoiceRequest.prototype.setInvoiceId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.moonlight.PublicInvoiceRequest.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.PublicInvoiceRequest.prototype.setAccessToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.PublicInvoiceSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.PublicInvoiceSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.PublicInvoiceSummary.displayName = 'proto.moonlight.PublicInvoiceSummary';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.PublicInvoiceSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.PublicInvoiceSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.PublicInvoiceSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PublicInvoiceSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.moonlight.Invoice.toObject(includeInstance, f),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    developerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    job: (f = msg.getJob()) && proto.moonlight.Job.toObject(includeInstance, f),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.moonlight.CompanyPaymentMethod.toObject(includeInstance, f),
    companyLegalName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyAddress: (f = msg.getCompanyAddress()) && proto.moonlight.Address.toObject(includeInstance, f),
    developerAddress: (f = msg.getDeveloperAddress()) && proto.moonlight.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.PublicInvoiceSummary}
 */
proto.moonlight.PublicInvoiceSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.PublicInvoiceSummary;
  return proto.moonlight.PublicInvoiceSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.PublicInvoiceSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.PublicInvoiceSummary}
 */
proto.moonlight.PublicInvoiceSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Invoice;
      reader.readMessage(value,proto.moonlight.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeveloperName(value);
      break;
    case 4:
      var value = new proto.moonlight.Job;
      reader.readMessage(value,proto.moonlight.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 5:
      var value = new proto.moonlight.CompanyPaymentMethod;
      reader.readMessage(value,proto.moonlight.CompanyPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyLegalName(value);
      break;
    case 7:
      var value = new proto.moonlight.Address;
      reader.readMessage(value,proto.moonlight.Address.deserializeBinaryFromReader);
      msg.setCompanyAddress(value);
      break;
    case 8:
      var value = new proto.moonlight.Address;
      reader.readMessage(value,proto.moonlight.Address.deserializeBinaryFromReader);
      msg.setDeveloperAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.PublicInvoiceSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.PublicInvoiceSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.PublicInvoiceSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.PublicInvoiceSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeveloperName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.moonlight.Job.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.moonlight.CompanyPaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getCompanyLegalName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.moonlight.Address.serializeBinaryToWriter
    );
  }
  f = message.getDeveloperAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.moonlight.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.moonlight.Invoice}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getInvoice = function() {
  return /** @type{?proto.moonlight.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Invoice, 1));
};


/** @param {?proto.moonlight.Invoice|undefined} value */
proto.moonlight.PublicInvoiceSummary.prototype.setInvoice = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.PublicInvoiceSummary.prototype.clearInvoice = function() {
  this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.PublicInvoiceSummary.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.PublicInvoiceSummary.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string developer_name = 3;
 * @return {string}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getDeveloperName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.PublicInvoiceSummary.prototype.setDeveloperName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Job job = 4;
 * @return {?proto.moonlight.Job}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getJob = function() {
  return /** @type{?proto.moonlight.Job} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Job, 4));
};


/** @param {?proto.moonlight.Job|undefined} value */
proto.moonlight.PublicInvoiceSummary.prototype.setJob = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.PublicInvoiceSummary.prototype.clearJob = function() {
  this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.PublicInvoiceSummary.prototype.hasJob = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CompanyPaymentMethod payment_method = 5;
 * @return {?proto.moonlight.CompanyPaymentMethod}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getPaymentMethod = function() {
  return /** @type{?proto.moonlight.CompanyPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.CompanyPaymentMethod, 5));
};


/** @param {?proto.moonlight.CompanyPaymentMethod|undefined} value */
proto.moonlight.PublicInvoiceSummary.prototype.setPaymentMethod = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.PublicInvoiceSummary.prototype.clearPaymentMethod = function() {
  this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.PublicInvoiceSummary.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string company_legal_name = 6;
 * @return {string}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getCompanyLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.PublicInvoiceSummary.prototype.setCompanyLegalName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Address company_address = 7;
 * @return {?proto.moonlight.Address}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getCompanyAddress = function() {
  return /** @type{?proto.moonlight.Address} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Address, 7));
};


/** @param {?proto.moonlight.Address|undefined} value */
proto.moonlight.PublicInvoiceSummary.prototype.setCompanyAddress = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.moonlight.PublicInvoiceSummary.prototype.clearCompanyAddress = function() {
  this.setCompanyAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.PublicInvoiceSummary.prototype.hasCompanyAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Address developer_address = 8;
 * @return {?proto.moonlight.Address}
 */
proto.moonlight.PublicInvoiceSummary.prototype.getDeveloperAddress = function() {
  return /** @type{?proto.moonlight.Address} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Address, 8));
};


/** @param {?proto.moonlight.Address|undefined} value */
proto.moonlight.PublicInvoiceSummary.prototype.setDeveloperAddress = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.PublicInvoiceSummary.prototype.clearDeveloperAddress = function() {
  this.setDeveloperAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.PublicInvoiceSummary.prototype.hasDeveloperAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Address.displayName = 'proto.moonlight.Address';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    line1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    administrative: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Address}
 */
proto.moonlight.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Address;
  return proto.moonlight.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Address}
 */
proto.moonlight.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdministrative(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdministrative();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string line_1 = 1;
 * @return {string}
 */
proto.moonlight.Address.prototype.getLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setLine1 = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string line_2 = 2;
 * @return {string}
 */
proto.moonlight.Address.prototype.getLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setLine2 = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.moonlight.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setCity = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string administrative = 4;
 * @return {string}
 */
proto.moonlight.Address.prototype.getAdministrative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setAdministrative = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.moonlight.Address.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postcode = 6;
 * @return {string}
 */
proto.moonlight.Address.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Address.prototype.setPostcode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.GetInvoiceByID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.GetInvoiceByID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.GetInvoiceByID.displayName = 'proto.moonlight.GetInvoiceByID';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.GetInvoiceByID.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.GetInvoiceByID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.GetInvoiceByID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.GetInvoiceByID.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.GetInvoiceByID}
 */
proto.moonlight.GetInvoiceByID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.GetInvoiceByID;
  return proto.moonlight.GetInvoiceByID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.GetInvoiceByID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.GetInvoiceByID}
 */
proto.moonlight.GetInvoiceByID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.GetInvoiceByID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.GetInvoiceByID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.GetInvoiceByID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.GetInvoiceByID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 invoice_id = 1;
 * @return {number}
 */
proto.moonlight.GetInvoiceByID.prototype.getInvoiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.GetInvoiceByID.prototype.setInvoiceId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.InvoicesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.InvoicesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.InvoicesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.InvoicesList.displayName = 'proto.moonlight.InvoicesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.InvoicesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.InvoicesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.InvoicesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.InvoicesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InvoicesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.moonlight.Invoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.InvoicesList}
 */
proto.moonlight.InvoicesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.InvoicesList;
  return proto.moonlight.InvoicesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.InvoicesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.InvoicesList}
 */
proto.moonlight.InvoicesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Invoice;
      reader.readMessage(value,proto.moonlight.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.InvoicesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.InvoicesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.InvoicesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.InvoicesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array.<!proto.moonlight.Invoice>}
 */
proto.moonlight.InvoicesList.prototype.getInvoicesList = function() {
  return /** @type{!Array.<!proto.moonlight.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Invoice, 1));
};


/** @param {!Array.<!proto.moonlight.Invoice>} value */
proto.moonlight.InvoicesList.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Invoice}
 */
proto.moonlight.InvoicesList.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Invoice, opt_index);
};


proto.moonlight.InvoicesList.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.KPIs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.KPIs.repeatedFields_, null);
};
goog.inherits(proto.moonlight.KPIs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.KPIs.displayName = 'proto.moonlight.KPIs';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.KPIs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.KPIs.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.KPIs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.KPIs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.KPIs.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.moonlight.TimeSeriesKPI.toObject, includeInstance),
    totalDevelopersNow: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalDevelopersOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reachedDevelopersNow: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reachedDevelopersOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activeDevelopersNow: jspb.Message.getFieldWithDefault(msg, 7, 0),
    activeDevelopersOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 8, 0),
    jobSeekingDevelopersNow: jspb.Message.getFieldWithDefault(msg, 9, 0),
    jobSeekingDevelopersOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 10, 0),
    centsArrNow: jspb.Message.getFieldWithDefault(msg, 11, 0),
    centsArrOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 12, 0),
    activeSubscribersNow: jspb.Message.getFieldWithDefault(msg, 13, 0),
    activeSubscribersOneWeekAgo: jspb.Message.getFieldWithDefault(msg, 14, 0),
    centsPayoutLastYear: jspb.Message.getFieldWithDefault(msg, 15, 0),
    centsFeeLastYear: jspb.Message.getFieldWithDefault(msg, 16, 0),
    centsPayoutLifetime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    centsFeeLifetime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    uninvoicedCentsPayout: jspb.Message.getFieldWithDefault(msg, 19, 0),
    uninvoicedCentsFee: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.KPIs}
 */
proto.moonlight.KPIs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.KPIs;
  return proto.moonlight.KPIs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.KPIs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.KPIs}
 */
proto.moonlight.KPIs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.TimeSeriesKPI;
      reader.readMessage(value,proto.moonlight.TimeSeriesKPI.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDevelopersNow(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDevelopersOneWeekAgo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReachedDevelopersNow(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReachedDevelopersOneWeekAgo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveDevelopersNow(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveDevelopersOneWeekAgo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobSeekingDevelopersNow(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobSeekingDevelopersOneWeekAgo(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsArrNow(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsArrOneWeekAgo(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveSubscribersNow(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveSubscribersOneWeekAgo(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsPayoutLastYear(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsFeeLastYear(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsPayoutLifetime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsFeeLifetime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUninvoicedCentsPayout(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUninvoicedCentsFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.KPIs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.KPIs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.KPIs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.KPIs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.TimeSeriesKPI.serializeBinaryToWriter
    );
  }
  f = message.getTotalDevelopersNow();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalDevelopersOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReachedDevelopersNow();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getReachedDevelopersOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getActiveDevelopersNow();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getActiveDevelopersOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getJobSeekingDevelopersNow();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getJobSeekingDevelopersOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCentsArrNow();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCentsArrOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getActiveSubscribersNow();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getActiveSubscribersOneWeekAgo();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCentsPayoutLastYear();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getCentsFeeLastYear();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCentsPayoutLifetime();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getCentsFeeLifetime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getUninvoicedCentsPayout();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUninvoicedCentsFee();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
};


/**
 * repeated TimeSeriesKPI data = 1;
 * @return {!Array.<!proto.moonlight.TimeSeriesKPI>}
 */
proto.moonlight.KPIs.prototype.getDataList = function() {
  return /** @type{!Array.<!proto.moonlight.TimeSeriesKPI>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.TimeSeriesKPI, 1));
};


/** @param {!Array.<!proto.moonlight.TimeSeriesKPI>} value */
proto.moonlight.KPIs.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.TimeSeriesKPI=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.TimeSeriesKPI}
 */
proto.moonlight.KPIs.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.TimeSeriesKPI, opt_index);
};


proto.moonlight.KPIs.prototype.clearDataList = function() {
  this.setDataList([]);
};


/**
 * optional int64 total_developers_now = 3;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getTotalDevelopersNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setTotalDevelopersNow = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_developers_one_week_ago = 4;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getTotalDevelopersOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setTotalDevelopersOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 reached_developers_now = 5;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getReachedDevelopersNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setReachedDevelopersNow = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 reached_developers_one_week_ago = 6;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getReachedDevelopersOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setReachedDevelopersOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 active_developers_now = 7;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getActiveDevelopersNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setActiveDevelopersNow = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 active_developers_one_week_ago = 8;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getActiveDevelopersOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setActiveDevelopersOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 job_seeking_developers_now = 9;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getJobSeekingDevelopersNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setJobSeekingDevelopersNow = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 job_seeking_developers_one_week_ago = 10;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getJobSeekingDevelopersOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setJobSeekingDevelopersOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 cents_arr_now = 11;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsArrNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsArrNow = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 cents_arr_one_week_ago = 12;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsArrOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsArrOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 active_subscribers_now = 13;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getActiveSubscribersNow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setActiveSubscribersNow = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 active_subscribers_one_week_ago = 14;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getActiveSubscribersOneWeekAgo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setActiveSubscribersOneWeekAgo = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 cents_payout_last_year = 15;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsPayoutLastYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsPayoutLastYear = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 cents_fee_last_year = 16;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsFeeLastYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsFeeLastYear = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 cents_payout_lifetime = 17;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsPayoutLifetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsPayoutLifetime = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 cents_fee_lifetime = 18;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getCentsFeeLifetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setCentsFeeLifetime = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 uninvoiced_cents_payout = 19;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getUninvoicedCentsPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setUninvoicedCentsPayout = function(value) {
  jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 uninvoiced_cents_fee = 20;
 * @return {number}
 */
proto.moonlight.KPIs.prototype.getUninvoicedCentsFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/** @param {number} value */
proto.moonlight.KPIs.prototype.setUninvoicedCentsFee = function(value) {
  jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.TimeSeriesKPI = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.TimeSeriesKPI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.TimeSeriesKPI.displayName = 'proto.moonlight.TimeSeriesKPI';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.TimeSeriesKPI.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.TimeSeriesKPI.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.TimeSeriesKPI} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.TimeSeriesKPI.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paidCentsPayout: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paidCentsFee: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unpaidCentsPayout: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unpaidCentsFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    centsArr: jspb.Message.getFieldWithDefault(msg, 12, 0),
    totalDevelopers: jspb.Message.getFieldWithDefault(msg, 13, 0),
    reachedDevelopers: jspb.Message.getFieldWithDefault(msg, 14, 0),
    activeDevelopers: jspb.Message.getFieldWithDefault(msg, 15, 0),
    jobSeekingDevelopers: jspb.Message.getFieldWithDefault(msg, 16, 0),
    centsArrMonthToMonth: jspb.Message.getFieldWithDefault(msg, 17, 0),
    centsArrAnnualCommitment: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.TimeSeriesKPI}
 */
proto.moonlight.TimeSeriesKPI.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.TimeSeriesKPI;
  return proto.moonlight.TimeSeriesKPI.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.TimeSeriesKPI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.TimeSeriesKPI}
 */
proto.moonlight.TimeSeriesKPI.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaidCentsPayout(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaidCentsFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidCentsPayout(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnpaidCentsFee(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsArr(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDevelopers(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReachedDevelopers(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveDevelopers(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setJobSeekingDevelopers(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsArrMonthToMonth(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsArrAnnualCommitment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.TimeSeriesKPI.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.TimeSeriesKPI.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.TimeSeriesKPI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.TimeSeriesKPI.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaidCentsPayout();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPaidCentsFee();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnpaidCentsPayout();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnpaidCentsFee();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCentsArr();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTotalDevelopers();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getReachedDevelopers();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getActiveDevelopers();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getJobSeekingDevelopers();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCentsArrMonthToMonth();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getCentsArrAnnualCommitment();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.moonlight.TimeSeriesKPI.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.TimeSeriesKPI.prototype.setDay = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 paid_cents_payout = 2;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getPaidCentsPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setPaidCentsPayout = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 paid_cents_fee = 3;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getPaidCentsFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setPaidCentsFee = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 unpaid_cents_payout = 4;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getUnpaidCentsPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setUnpaidCentsPayout = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 unpaid_cents_fee = 5;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getUnpaidCentsFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setUnpaidCentsFee = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 cents_arr = 12;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getCentsArr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setCentsArr = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 total_developers = 13;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getTotalDevelopers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setTotalDevelopers = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 reached_developers = 14;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getReachedDevelopers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setReachedDevelopers = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 active_developers = 15;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getActiveDevelopers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setActiveDevelopers = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 job_seeking_developers = 16;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getJobSeekingDevelopers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setJobSeekingDevelopers = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 cents_arr_month_to_month = 17;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getCentsArrMonthToMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setCentsArrMonthToMonth = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 cents_arr_annual_commitment = 18;
 * @return {number}
 */
proto.moonlight.TimeSeriesKPI.prototype.getCentsArrAnnualCommitment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.moonlight.TimeSeriesKPI.prototype.setCentsArrAnnualCommitment = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Lead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Lead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Lead.displayName = 'proto.moonlight.Lead';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Lead.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Lead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Lead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Lead.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    complete: jspb.Message.getFieldWithDefault(msg, 2, false),
    disqualified: jspb.Message.getFieldWithDefault(msg, 3, false),
    disqualifiedReason: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ifPersonalDefaultDeveloper: jspb.Message.getFieldWithDefault(msg, 6, false),
    isPersonalEmail: jspb.Message.getFieldWithDefault(msg, 7, false),
    isBusinessEmail: jspb.Message.getFieldWithDefault(msg, 8, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    classification: jspb.Message.getFieldWithDefault(msg, 11, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    manualReviewRequired: jspb.Message.getFieldWithDefault(msg, 13, false),
    userId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    fullName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    location: jspb.Message.getFieldWithDefault(msg, 16, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 17, ""),
    country: jspb.Message.getFieldWithDefault(msg, 18, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 19, ""),
    personalUrl: jspb.Message.getFieldWithDefault(msg, 20, ""),
    githubUrl: jspb.Message.getFieldWithDefault(msg, 21, ""),
    facebookUrl: jspb.Message.getFieldWithDefault(msg, 22, ""),
    twitterUrl: jspb.Message.getFieldWithDefault(msg, 23, ""),
    linkedinUrl: jspb.Message.getFieldWithDefault(msg, 24, ""),
    companyUrl: jspb.Message.getFieldWithDefault(msg, 25, ""),
    sector: jspb.Message.getFieldWithDefault(msg, 26, ""),
    industryGroup: jspb.Message.getFieldWithDefault(msg, 27, ""),
    industry: jspb.Message.getFieldWithDefault(msg, 28, ""),
    subIndustry: jspb.Message.getFieldWithDefault(msg, 29, ""),
    companyDescription: jspb.Message.getFieldWithDefault(msg, 30, ""),
    foundedYear: jspb.Message.getFieldWithDefault(msg, 31, 0),
    crunchbaseUrl: jspb.Message.getFieldWithDefault(msg, 32, ""),
    employeeRange: jspb.Message.getFieldWithDefault(msg, 33, ""),
    raised: jspb.Message.getFieldWithDefault(msg, 34, 0),
    email: jspb.Message.getFieldWithDefault(msg, 35, ""),
    source: jspb.Message.getFieldWithDefault(msg, 36, ""),
    freeEmailProvider: jspb.Message.getFieldWithDefault(msg, 37, false),
    clearbitEnriched: jspb.Message.getFieldWithDefault(msg, 38, false),
    title: jspb.Message.getFieldWithDefault(msg, 39, ""),
    deliverabilityReport: jspb.Message.getFieldWithDefault(msg, 40, ""),
    outreachId: jspb.Message.getFieldWithDefault(msg, 41, 0),
    outreachSequenceStateId: jspb.Message.getFieldWithDefault(msg, 42, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Lead}
 */
proto.moonlight.Lead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Lead;
  return proto.moonlight.Lead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Lead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Lead}
 */
proto.moonlight.Lead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisqualified(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisqualifiedReason(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIfPersonalDefaultDeveloper(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPersonalEmail(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusinessEmail(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassification(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualReviewRequired(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonalUrl(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setGithubUrl(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacebookUrl(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterUrl(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedinUrl(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyUrl(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setSector(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustryGroup(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubIndustry(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDescription(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFoundedYear(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrunchbaseUrl(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmployeeRange(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRaised(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreeEmailProvider(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClearbitEnriched(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliverabilityReport(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutreachId(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutreachSequenceStateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Lead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Lead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Lead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Lead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDisqualified();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisqualifiedReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIfPersonalDefaultDeveloper();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsPersonalEmail();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsBusinessEmail();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClassification();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getManualReviewRequired();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPersonalUrl();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getGithubUrl();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFacebookUrl();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTwitterUrl();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLinkedinUrl();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCompanyUrl();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSector();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getIndustryGroup();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSubIndustry();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getCompanyDescription();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getFoundedYear();
  if (f !== 0) {
    writer.writeInt32(
      31,
      f
    );
  }
  f = message.getCrunchbaseUrl();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getEmployeeRange();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getRaised();
  if (f !== 0) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getFreeEmailProvider();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getClearbitEnriched();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getDeliverabilityReport();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getOutreachId();
  if (f !== 0) {
    writer.writeInt64(
      41,
      f
    );
  }
  f = message.getOutreachSequenceStateId();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool complete = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setComplete = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool disqualified = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getDisqualified = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setDisqualified = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string disqualified_reason = 4;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getDisqualifiedReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setDisqualifiedReason = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool if_personal_default_developer = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getIfPersonalDefaultDeveloper = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setIfPersonalDefaultDeveloper = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_personal_email = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getIsPersonalEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setIsPersonalEmail = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_business_email = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getIsBusinessEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setIsBusinessEmail = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Lead.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Lead.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.moonlight.Lead.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Lead.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Lead.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Lead.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.Lead.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Lead.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string classification = 11;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setClassification = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string company_name = 12;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool manual_review_required = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getManualReviewRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setManualReviewRequired = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 user_id = 14;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string full_name = 15;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string location = 16;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setLocation = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string timezone = 17;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setTimezone = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string country = 18;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string bio = 19;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setBio = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string personal_url = 20;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getPersonalUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setPersonalUrl = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string github_url = 21;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getGithubUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setGithubUrl = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string facebook_url = 22;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getFacebookUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setFacebookUrl = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string twitter_url = 23;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getTwitterUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setTwitterUrl = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string linkedin_url = 24;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getLinkedinUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setLinkedinUrl = function(value) {
  jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string company_url = 25;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getCompanyUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setCompanyUrl = function(value) {
  jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string sector = 26;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getSector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setSector = function(value) {
  jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string industry_group = 27;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getIndustryGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setIndustryGroup = function(value) {
  jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string industry = 28;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setIndustry = function(value) {
  jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string sub_industry = 29;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getSubIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setSubIndustry = function(value) {
  jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string company_description = 30;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getCompanyDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setCompanyDescription = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional int32 founded_year = 31;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getFoundedYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setFoundedYear = function(value) {
  jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional string crunchbase_url = 32;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getCrunchbaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setCrunchbaseUrl = function(value) {
  jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string employee_range = 33;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getEmployeeRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setEmployeeRange = function(value) {
  jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional int64 raised = 34;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getRaised = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setRaised = function(value) {
  jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional string email = 35;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string source = 36;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional bool free_email_provider = 37;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getFreeEmailProvider = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 37, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setFreeEmailProvider = function(value) {
  jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool clearbit_enriched = 38;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Lead.prototype.getClearbitEnriched = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 38, false));
};


/** @param {boolean} value */
proto.moonlight.Lead.prototype.setClearbitEnriched = function(value) {
  jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional string title = 39;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string deliverability_report = 40;
 * @return {string}
 */
proto.moonlight.Lead.prototype.getDeliverabilityReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/** @param {string} value */
proto.moonlight.Lead.prototype.setDeliverabilityReport = function(value) {
  jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional int64 outreach_id = 41;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getOutreachId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setOutreachId = function(value) {
  jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional int64 outreach_sequence_state_id = 42;
 * @return {number}
 */
proto.moonlight.Lead.prototype.getOutreachSequenceStateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/** @param {number} value */
proto.moonlight.Lead.prototype.setOutreachSequenceStateId = function(value) {
  jspb.Message.setProto3IntField(this, 42, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LeadSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LeadSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LeadSkillRequest.displayName = 'proto.moonlight.LeadSkillRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LeadSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LeadSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LeadSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    leadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skillSlug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LeadSkillRequest}
 */
proto.moonlight.LeadSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LeadSkillRequest;
  return proto.moonlight.LeadSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LeadSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LeadSkillRequest}
 */
proto.moonlight.LeadSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LeadSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LeadSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LeadSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeadId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSkillSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 lead_id = 1;
 * @return {number}
 */
proto.moonlight.LeadSkillRequest.prototype.getLeadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LeadSkillRequest.prototype.setLeadId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string skill_slug = 2;
 * @return {string}
 */
proto.moonlight.LeadSkillRequest.prototype.getSkillSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.LeadSkillRequest.prototype.setSkillSlug = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LeadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LeadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LeadRequest.displayName = 'proto.moonlight.LeadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LeadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LeadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LeadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    leadId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LeadRequest}
 */
proto.moonlight.LeadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LeadRequest;
  return proto.moonlight.LeadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LeadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LeadRequest}
 */
proto.moonlight.LeadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LeadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LeadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LeadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeadId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 lead_id = 1;
 * @return {number}
 */
proto.moonlight.LeadRequest.prototype.getLeadId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LeadRequest.prototype.setLeadId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ReadLeadByEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ReadLeadByEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ReadLeadByEmailRequest.displayName = 'proto.moonlight.ReadLeadByEmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ReadLeadByEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ReadLeadByEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ReadLeadByEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReadLeadByEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ReadLeadByEmailRequest}
 */
proto.moonlight.ReadLeadByEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ReadLeadByEmailRequest;
  return proto.moonlight.ReadLeadByEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ReadLeadByEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ReadLeadByEmailRequest}
 */
proto.moonlight.ReadLeadByEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ReadLeadByEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ReadLeadByEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ReadLeadByEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReadLeadByEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.ReadLeadByEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ReadLeadByEmailRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateLeadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateLeadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateLeadRequest.displayName = 'proto.moonlight.CreateLeadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateLeadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateLeadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateLeadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateLeadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ifPersonalDefaultDeveloper: jspb.Message.getFieldWithDefault(msg, 3, false),
    isPersonalEmail: jspb.Message.getFieldWithDefault(msg, 4, false),
    isBusinessEmail: jspb.Message.getFieldWithDefault(msg, 5, false),
    classification: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    source: jspb.Message.getFieldWithDefault(msg, 8, ""),
    title: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateLeadRequest}
 */
proto.moonlight.CreateLeadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateLeadRequest;
  return proto.moonlight.CreateLeadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateLeadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateLeadRequest}
 */
proto.moonlight.CreateLeadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIfPersonalDefaultDeveloper(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPersonalEmail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusinessEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassification(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateLeadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateLeadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateLeadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateLeadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIfPersonalDefaultDeveloper();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsPersonalEmail();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsBusinessEmail();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getClassification();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool if_personal_default_developer = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateLeadRequest.prototype.getIfPersonalDefaultDeveloper = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.CreateLeadRequest.prototype.setIfPersonalDefaultDeveloper = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_personal_email = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateLeadRequest.prototype.getIsPersonalEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.CreateLeadRequest.prototype.setIsPersonalEmail = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_business_email = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateLeadRequest.prototype.getIsBusinessEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.CreateLeadRequest.prototype.setIsBusinessEmail = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string classification = 6;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setClassification = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string company_name = 7;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string source = 8;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string title = 9;
 * @return {string}
 */
proto.moonlight.CreateLeadRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.CreateLeadRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LeadList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.LeadList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.LeadList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LeadList.displayName = 'proto.moonlight.LeadList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.LeadList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LeadList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LeadList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LeadList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadList.toObject = function(includeInstance, msg) {
  var f, obj = {
    leadsList: jspb.Message.toObjectList(msg.getLeadsList(),
    proto.moonlight.Lead.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LeadList}
 */
proto.moonlight.LeadList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LeadList;
  return proto.moonlight.LeadList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LeadList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LeadList}
 */
proto.moonlight.LeadList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Lead;
      reader.readMessage(value,proto.moonlight.Lead.deserializeBinaryFromReader);
      msg.addLeads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LeadList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LeadList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LeadList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LeadList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Lead.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Lead leads = 1;
 * @return {!Array.<!proto.moonlight.Lead>}
 */
proto.moonlight.LeadList.prototype.getLeadsList = function() {
  return /** @type{!Array.<!proto.moonlight.Lead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Lead, 1));
};


/** @param {!Array.<!proto.moonlight.Lead>} value */
proto.moonlight.LeadList.prototype.setLeadsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Lead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Lead}
 */
proto.moonlight.LeadList.prototype.addLeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Lead, opt_index);
};


proto.moonlight.LeadList.prototype.clearLeadsList = function() {
  this.setLeadsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.BulkCreateLeadsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.BulkCreateLeadsRequest.repeatedFields_, null);
};
goog.inherits(proto.moonlight.BulkCreateLeadsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.BulkCreateLeadsRequest.displayName = 'proto.moonlight.BulkCreateLeadsRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.BulkCreateLeadsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.BulkCreateLeadsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.BulkCreateLeadsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BulkCreateLeadsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ifPersonalDefaultDeveloper: jspb.Message.getFieldWithDefault(msg, 1, false),
    source: jspb.Message.getFieldWithDefault(msg, 2, ""),
    leadsList: jspb.Message.toObjectList(msg.getLeadsList(),
    proto.moonlight.ShortCreateLeadRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.BulkCreateLeadsRequest}
 */
proto.moonlight.BulkCreateLeadsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.BulkCreateLeadsRequest;
  return proto.moonlight.BulkCreateLeadsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.BulkCreateLeadsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.BulkCreateLeadsRequest}
 */
proto.moonlight.BulkCreateLeadsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIfPersonalDefaultDeveloper(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 3:
      var value = new proto.moonlight.ShortCreateLeadRequest;
      reader.readMessage(value,proto.moonlight.ShortCreateLeadRequest.deserializeBinaryFromReader);
      msg.addLeads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.BulkCreateLeadsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.BulkCreateLeadsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BulkCreateLeadsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIfPersonalDefaultDeveloper();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.moonlight.ShortCreateLeadRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool if_personal_default_developer = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.getIfPersonalDefaultDeveloper = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.moonlight.BulkCreateLeadsRequest.prototype.setIfPersonalDefaultDeveloper = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string source = 2;
 * @return {string}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.BulkCreateLeadsRequest.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ShortCreateLeadRequest leads = 3;
 * @return {!Array.<!proto.moonlight.ShortCreateLeadRequest>}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.getLeadsList = function() {
  return /** @type{!Array.<!proto.moonlight.ShortCreateLeadRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.ShortCreateLeadRequest, 3));
};


/** @param {!Array.<!proto.moonlight.ShortCreateLeadRequest>} value */
proto.moonlight.BulkCreateLeadsRequest.prototype.setLeadsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.moonlight.ShortCreateLeadRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.ShortCreateLeadRequest}
 */
proto.moonlight.BulkCreateLeadsRequest.prototype.addLeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.moonlight.ShortCreateLeadRequest, opt_index);
};


proto.moonlight.BulkCreateLeadsRequest.prototype.clearLeadsList = function() {
  this.setLeadsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ShortCreateLeadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ShortCreateLeadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ShortCreateLeadRequest.displayName = 'proto.moonlight.ShortCreateLeadRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ShortCreateLeadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ShortCreateLeadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ShortCreateLeadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ShortCreateLeadRequest}
 */
proto.moonlight.ShortCreateLeadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ShortCreateLeadRequest;
  return proto.moonlight.ShortCreateLeadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ShortCreateLeadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ShortCreateLeadRequest}
 */
proto.moonlight.ShortCreateLeadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ShortCreateLeadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ShortCreateLeadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ShortCreateLeadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ShortCreateLeadRequest.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.ShortCreateLeadRequest.prototype.setFullName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_name = 3;
 * @return {string}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.ShortCreateLeadRequest.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.moonlight.ShortCreateLeadRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.ShortCreateLeadRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.HiringCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.HiringCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.HiringCompany.displayName = 'proto.moonlight.HiringCompany';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.HiringCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.HiringCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.HiringCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.HiringCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    source: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.HiringCompany}
 */
proto.moonlight.HiringCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.HiringCompany;
  return proto.moonlight.HiringCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.HiringCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.HiringCompany}
 */
proto.moonlight.HiringCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.HiringCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.HiringCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.HiringCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.HiringCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.HiringCompany.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.HiringCompany.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.HiringCompany.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.HiringCompany.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.moonlight.HiringCompany.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.HiringCompany.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.HiringCompany.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.HiringCompany.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.HiringCompany.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.HiringCompany.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.moonlight.HiringCompany.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.HiringCompany.prototype.setDomain = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.moonlight.HiringCompany.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.HiringCompany.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string source = 6;
 * @return {string}
 */
proto.moonlight.HiringCompany.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.HiringCompany.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateHiringCompanyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateHiringCompanyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateHiringCompanyRequest.displayName = 'proto.moonlight.CreateHiringCompanyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateHiringCompanyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateHiringCompanyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateHiringCompanyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateHiringCompanyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateHiringCompanyRequest}
 */
proto.moonlight.CreateHiringCompanyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateHiringCompanyRequest;
  return proto.moonlight.CreateHiringCompanyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateHiringCompanyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateHiringCompanyRequest}
 */
proto.moonlight.CreateHiringCompanyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateHiringCompanyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateHiringCompanyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateHiringCompanyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateHiringCompanyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.moonlight.CreateHiringCompanyRequest.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateHiringCompanyRequest.prototype.setDomain = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.moonlight.CreateHiringCompanyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateHiringCompanyRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.moonlight.CreateHiringCompanyRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateHiringCompanyRequest.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ReferralPayout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ReferralPayout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ReferralPayout.displayName = 'proto.moonlight.ReferralPayout';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ReferralPayout.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ReferralPayout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ReferralPayout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReferralPayout.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    centsPayout: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ReferralPayout}
 */
proto.moonlight.ReferralPayout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ReferralPayout;
  return proto.moonlight.ReferralPayout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ReferralPayout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ReferralPayout}
 */
proto.moonlight.ReferralPayout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCentsPayout(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ReferralPayout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ReferralPayout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ReferralPayout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReferralPayout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCentsPayout();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.ReferralPayout.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.ReferralPayout.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 company_id = 2;
 * @return {number}
 */
proto.moonlight.ReferralPayout.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.ReferralPayout.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cents_payout = 3;
 * @return {number}
 */
proto.moonlight.ReferralPayout.prototype.getCentsPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.ReferralPayout.prototype.setCentsPayout = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.ReferralPayout.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.ReferralPayout.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.ReferralPayout.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ReferralPayout.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ReferralPayoutSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ReferralPayoutSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ReferralPayoutSummary.displayName = 'proto.moonlight.ReferralPayoutSummary';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ReferralPayoutSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ReferralPayoutSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ReferralPayoutSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReferralPayoutSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    referralPayout: (f = msg.getReferralPayout()) && proto.moonlight.ReferralPayout.toObject(includeInstance, f),
    companyName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ReferralPayoutSummary}
 */
proto.moonlight.ReferralPayoutSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ReferralPayoutSummary;
  return proto.moonlight.ReferralPayoutSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ReferralPayoutSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ReferralPayoutSummary}
 */
proto.moonlight.ReferralPayoutSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.ReferralPayout;
      reader.readMessage(value,proto.moonlight.ReferralPayout.deserializeBinaryFromReader);
      msg.setReferralPayout(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ReferralPayoutSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ReferralPayoutSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ReferralPayoutSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ReferralPayoutSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferralPayout();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.ReferralPayout.serializeBinaryToWriter
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReferralPayout referral_payout = 1;
 * @return {?proto.moonlight.ReferralPayout}
 */
proto.moonlight.ReferralPayoutSummary.prototype.getReferralPayout = function() {
  return /** @type{?proto.moonlight.ReferralPayout} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.ReferralPayout, 1));
};


/** @param {?proto.moonlight.ReferralPayout|undefined} value */
proto.moonlight.ReferralPayoutSummary.prototype.setReferralPayout = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.ReferralPayoutSummary.prototype.clearReferralPayout = function() {
  this.setReferralPayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ReferralPayoutSummary.prototype.hasReferralPayout = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string company_name = 2;
 * @return {string}
 */
proto.moonlight.ReferralPayoutSummary.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.ReferralPayoutSummary.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserReferralPayoutsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.UserReferralPayoutsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.UserReferralPayoutsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserReferralPayoutsList.displayName = 'proto.moonlight.UserReferralPayoutsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.UserReferralPayoutsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserReferralPayoutsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserReferralPayoutsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserReferralPayoutsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserReferralPayoutsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    summariesList: jspb.Message.toObjectList(msg.getSummariesList(),
    proto.moonlight.ReferralPayoutSummary.toObject, includeInstance),
    countCompaniesReferred: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserReferralPayoutsList}
 */
proto.moonlight.UserReferralPayoutsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserReferralPayoutsList;
  return proto.moonlight.UserReferralPayoutsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserReferralPayoutsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserReferralPayoutsList}
 */
proto.moonlight.UserReferralPayoutsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.ReferralPayoutSummary;
      reader.readMessage(value,proto.moonlight.ReferralPayoutSummary.deserializeBinaryFromReader);
      msg.addSummaries(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountCompaniesReferred(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserReferralPayoutsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserReferralPayoutsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserReferralPayoutsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserReferralPayoutsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.ReferralPayoutSummary.serializeBinaryToWriter
    );
  }
  f = message.getCountCompaniesReferred();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ReferralPayoutSummary summaries = 1;
 * @return {!Array.<!proto.moonlight.ReferralPayoutSummary>}
 */
proto.moonlight.UserReferralPayoutsList.prototype.getSummariesList = function() {
  return /** @type{!Array.<!proto.moonlight.ReferralPayoutSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.ReferralPayoutSummary, 1));
};


/** @param {!Array.<!proto.moonlight.ReferralPayoutSummary>} value */
proto.moonlight.UserReferralPayoutsList.prototype.setSummariesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.ReferralPayoutSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.ReferralPayoutSummary}
 */
proto.moonlight.UserReferralPayoutsList.prototype.addSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.ReferralPayoutSummary, opt_index);
};


proto.moonlight.UserReferralPayoutsList.prototype.clearSummariesList = function() {
  this.setSummariesList([]);
};


/**
 * optional int64 count_companies_referred = 2;
 * @return {number}
 */
proto.moonlight.UserReferralPayoutsList.prototype.getCountCompaniesReferred = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserReferralPayoutsList.prototype.setCountCompaniesReferred = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ScrapeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ScrapeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ScrapeRequest.displayName = 'proto.moonlight.ScrapeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ScrapeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ScrapeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ScrapeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ScrapeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ScrapeRequest}
 */
proto.moonlight.ScrapeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ScrapeRequest;
  return proto.moonlight.ScrapeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ScrapeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ScrapeRequest}
 */
proto.moonlight.ScrapeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ScrapeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ScrapeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ScrapeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ScrapeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.moonlight.ScrapeRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ScrapeRequest.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Message.displayName = 'proto.moonlight.Message';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    slackUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isMoonbot: jspb.Message.getFieldWithDefault(msg, 5, false),
    text: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    channelId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    channelType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    slackTimeStamp: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Message}
 */
proto.moonlight.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Message;
  return proto.moonlight.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Message}
 */
proto.moonlight.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackUserId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMoonbot(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackTimeStamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSlackUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsMoonbot();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChannelType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSlackTimeStamp();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.moonlight.Message.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Message.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string source = 2;
 * @return {string}
 */
proto.moonlight.Message.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setSource = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.moonlight.Message.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.Message.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string slack_user_id = 4;
 * @return {string}
 */
proto.moonlight.Message.prototype.getSlackUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setSlackUserId = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_moonbot = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Message.prototype.getIsMoonbot = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.Message.prototype.setIsMoonbot = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string text = 6;
 * @return {string}
 */
proto.moonlight.Message.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setText = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Message.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Message.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 7, value);
};


proto.moonlight.Message.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Message.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string channel_id = 8;
 * @return {string}
 */
proto.moonlight.Message.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string channel_type = 9;
 * @return {string}
 */
proto.moonlight.Message.prototype.getChannelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setChannelType = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string slack_time_stamp = 10;
 * @return {string}
 */
proto.moonlight.Message.prototype.getSlackTimeStamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.moonlight.Message.prototype.setSlackTimeStamp = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.MessageWithContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.MessageWithContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.MessageWithContext.displayName = 'proto.moonlight.MessageWithContext';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.MessageWithContext.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.MessageWithContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.MessageWithContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.MessageWithContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && proto.moonlight.Message.toObject(includeInstance, f),
    photo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAdmin: jspb.Message.getFieldWithDefault(msg, 4, false),
    companyId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deactivated: jspb.Message.getFieldWithDefault(msg, 7, false),
    localizedCreatedAt: (f = msg.getLocalizedCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    companyUrl: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.MessageWithContext}
 */
proto.moonlight.MessageWithContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.MessageWithContext;
  return proto.moonlight.MessageWithContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.MessageWithContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.MessageWithContext}
 */
proto.moonlight.MessageWithContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Message;
      reader.readMessage(value,proto.moonlight.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeactivated(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLocalizedCreatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUrl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.MessageWithContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.MessageWithContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.MessageWithContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.MessageWithContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Message.serializeBinaryToWriter
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeactivated();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLocalizedCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCompanyUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional Message message = 1;
 * @return {?proto.moonlight.Message}
 */
proto.moonlight.MessageWithContext.prototype.getMessage = function() {
  return /** @type{?proto.moonlight.Message} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Message, 1));
};


/** @param {?proto.moonlight.Message|undefined} value */
proto.moonlight.MessageWithContext.prototype.setMessage = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.MessageWithContext.prototype.clearMessage = function() {
  this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.MessageWithContext.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string photo = 2;
 * @return {string}
 */
proto.moonlight.MessageWithContext.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.MessageWithContext.prototype.setPhoto = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.moonlight.MessageWithContext.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.MessageWithContext.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_admin = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.MessageWithContext.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.MessageWithContext.prototype.setIsAdmin = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 company_id = 5;
 * @return {number}
 */
proto.moonlight.MessageWithContext.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.MessageWithContext.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string company_name = 6;
 * @return {string}
 */
proto.moonlight.MessageWithContext.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.MessageWithContext.prototype.setCompanyName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool deactivated = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.MessageWithContext.prototype.getDeactivated = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.moonlight.MessageWithContext.prototype.setDeactivated = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp localized_created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.MessageWithContext.prototype.getLocalizedCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.MessageWithContext.prototype.setLocalizedCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.MessageWithContext.prototype.clearLocalizedCreatedAt = function() {
  this.setLocalizedCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.MessageWithContext.prototype.hasLocalizedCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string user_url = 9;
 * @return {string}
 */
proto.moonlight.MessageWithContext.prototype.getUserUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.moonlight.MessageWithContext.prototype.setUserUrl = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string company_url = 10;
 * @return {string}
 */
proto.moonlight.MessageWithContext.prototype.getCompanyUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.moonlight.MessageWithContext.prototype.setCompanyUrl = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserChannelMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserChannelMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserChannelMessagesRequest.displayName = 'proto.moonlight.UserChannelMessagesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserChannelMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserChannelMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserChannelMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    beforeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updateLastReadTime: jspb.Message.getFieldWithDefault(msg, 5, false),
    eTag: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserChannelMessagesRequest}
 */
proto.moonlight.UserChannelMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserChannelMessagesRequest;
  return proto.moonlight.UserChannelMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserChannelMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserChannelMessagesRequest}
 */
proto.moonlight.UserChannelMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActiveUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeforeId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateLastReadTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setETag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserChannelMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserChannelMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserChannelMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBeforeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUpdateLastReadTime();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getETag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 active_user_id = 2;
 * @return {number}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getActiveUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setActiveUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 before_id = 4;
 * @return {number}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getBeforeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setBeforeId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool update_last_read_time = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getUpdateLastReadTime = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setUpdateLastReadTime = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string e_tag = 6;
 * @return {string}
 */
proto.moonlight.UserChannelMessagesRequest.prototype.getETag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.UserChannelMessagesRequest.prototype.setETag = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ChannelMessagesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ChannelMessagesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ChannelMessagesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ChannelMessagesList.displayName = 'proto.moonlight.ChannelMessagesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ChannelMessagesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ChannelMessagesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ChannelMessagesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ChannelMessagesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ChannelMessagesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.moonlight.MessageWithContext.toObject, includeInstance),
    channel: (f = msg.getChannel()) && proto.moonlight.Channel.toObject(includeInstance, f),
    eTag: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ChannelMessagesList}
 */
proto.moonlight.ChannelMessagesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ChannelMessagesList;
  return proto.moonlight.ChannelMessagesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ChannelMessagesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ChannelMessagesList}
 */
proto.moonlight.ChannelMessagesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.MessageWithContext;
      reader.readMessage(value,proto.moonlight.MessageWithContext.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = new proto.moonlight.Channel;
      reader.readMessage(value,proto.moonlight.Channel.deserializeBinaryFromReader);
      msg.setChannel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setETag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ChannelMessagesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ChannelMessagesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ChannelMessagesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ChannelMessagesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.MessageWithContext.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.moonlight.Channel.serializeBinaryToWriter
    );
  }
  f = message.getETag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated MessageWithContext data = 1;
 * @return {!Array.<!proto.moonlight.MessageWithContext>}
 */
proto.moonlight.ChannelMessagesList.prototype.getDataList = function() {
  return /** @type{!Array.<!proto.moonlight.MessageWithContext>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.MessageWithContext, 1));
};


/** @param {!Array.<!proto.moonlight.MessageWithContext>} value */
proto.moonlight.ChannelMessagesList.prototype.setDataList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.MessageWithContext=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.MessageWithContext}
 */
proto.moonlight.ChannelMessagesList.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.MessageWithContext, opt_index);
};


proto.moonlight.ChannelMessagesList.prototype.clearDataList = function() {
  this.setDataList([]);
};


/**
 * optional Channel channel = 2;
 * @return {?proto.moonlight.Channel}
 */
proto.moonlight.ChannelMessagesList.prototype.getChannel = function() {
  return /** @type{?proto.moonlight.Channel} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Channel, 2));
};


/** @param {?proto.moonlight.Channel|undefined} value */
proto.moonlight.ChannelMessagesList.prototype.setChannel = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.moonlight.ChannelMessagesList.prototype.clearChannel = function() {
  this.setChannel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.ChannelMessagesList.prototype.hasChannel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string e_tag = 3;
 * @return {string}
 */
proto.moonlight.ChannelMessagesList.prototype.getETag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.ChannelMessagesList.prototype.setETag = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Channel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Channel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Channel.displayName = 'proto.moonlight.Channel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Channel.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Channel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Channel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Channel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    jobId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    applicationId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    otherPartyName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastMessageSent: (f = msg.getLastMessageSent()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    archived: jspb.Message.getFieldWithDefault(msg, 9, false),
    activeUserLastRead: (f = msg.getActiveUserLastRead()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    otherPartyLastRead: (f = msg.getOtherPartyLastRead()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unreadMessageCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    canPost: jspb.Message.getFieldWithDefault(msg, 13, false),
    developerId: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Channel}
 */
proto.moonlight.Channel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Channel;
  return proto.moonlight.Channel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Channel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Channel}
 */
proto.moonlight.Channel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApplicationId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherPartyName(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastMessageSent(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActiveUserLastRead(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOtherPartyLastRead(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnreadMessageCount(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPost(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Channel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Channel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Channel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Channel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getJobId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getApplicationId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOtherPartyName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastMessageSent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getActiveUserLastRead();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOtherPartyLastRead();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnreadMessageCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCanPost();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDeveloperId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.moonlight.Channel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.Channel.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.moonlight.Channel.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Channel.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.moonlight.Channel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.Channel.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 company_id = 4;
 * @return {number}
 */
proto.moonlight.Channel.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.Channel.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 job_id = 5;
 * @return {number}
 */
proto.moonlight.Channel.prototype.getJobId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.Channel.prototype.setJobId = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 application_id = 6;
 * @return {number}
 */
proto.moonlight.Channel.prototype.getApplicationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.Channel.prototype.setApplicationId = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string other_party_name = 7;
 * @return {string}
 */
proto.moonlight.Channel.prototype.getOtherPartyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.Channel.prototype.setOtherPartyName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp last_message_sent = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Channel.prototype.getLastMessageSent = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Channel.prototype.setLastMessageSent = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.moonlight.Channel.prototype.clearLastMessageSent = function() {
  this.setLastMessageSent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Channel.prototype.hasLastMessageSent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool archived = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Channel.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.moonlight.Channel.prototype.setArchived = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp active_user_last_read = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Channel.prototype.getActiveUserLastRead = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Channel.prototype.setActiveUserLastRead = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.Channel.prototype.clearActiveUserLastRead = function() {
  this.setActiveUserLastRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Channel.prototype.hasActiveUserLastRead = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp other_party_last_read = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Channel.prototype.getOtherPartyLastRead = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Channel.prototype.setOtherPartyLastRead = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.moonlight.Channel.prototype.clearOtherPartyLastRead = function() {
  this.setOtherPartyLastRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Channel.prototype.hasOtherPartyLastRead = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 unread_message_count = 12;
 * @return {number}
 */
proto.moonlight.Channel.prototype.getUnreadMessageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.moonlight.Channel.prototype.setUnreadMessageCount = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool can_post = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Channel.prototype.getCanPost = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.moonlight.Channel.prototype.setCanPost = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 developer_id = 14;
 * @return {number}
 */
proto.moonlight.Channel.prototype.getDeveloperId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.moonlight.Channel.prototype.setDeveloperId = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ChannelsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.ChannelsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.ChannelsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ChannelsList.displayName = 'proto.moonlight.ChannelsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.ChannelsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ChannelsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ChannelsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ChannelsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ChannelsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.moonlight.Channel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ChannelsList}
 */
proto.moonlight.ChannelsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ChannelsList;
  return proto.moonlight.ChannelsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ChannelsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ChannelsList}
 */
proto.moonlight.ChannelsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Channel;
      reader.readMessage(value,proto.moonlight.Channel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ChannelsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ChannelsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ChannelsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ChannelsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Channel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Channel channels = 1;
 * @return {!Array.<!proto.moonlight.Channel>}
 */
proto.moonlight.ChannelsList.prototype.getChannelsList = function() {
  return /** @type{!Array.<!proto.moonlight.Channel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Channel, 1));
};


/** @param {!Array.<!proto.moonlight.Channel>} value */
proto.moonlight.ChannelsList.prototype.setChannelsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Channel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Channel}
 */
proto.moonlight.ChannelsList.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Channel, opt_index);
};


proto.moonlight.ChannelsList.prototype.clearChannelsList = function() {
  this.setChannelsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.UserChannelLastRead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.UserChannelLastRead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.UserChannelLastRead.displayName = 'proto.moonlight.UserChannelLastRead';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.UserChannelLastRead.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.UserChannelLastRead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.UserChannelLastRead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserChannelLastRead.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastReminderSent: (f = msg.getLastReminderSent()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.UserChannelLastRead}
 */
proto.moonlight.UserChannelLastRead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.UserChannelLastRead;
  return proto.moonlight.UserChannelLastRead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.UserChannelLastRead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.UserChannelLastRead}
 */
proto.moonlight.UserChannelLastRead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastReminderSent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.UserChannelLastRead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.UserChannelLastRead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.UserChannelLastRead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.UserChannelLastRead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastReminderSent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.moonlight.UserChannelLastRead.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.UserChannelLastRead.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.moonlight.UserChannelLastRead.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.UserChannelLastRead.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserChannelLastRead.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserChannelLastRead.prototype.setTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.UserChannelLastRead.prototype.clearTimestamp = function() {
  this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserChannelLastRead.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp last_reminder_sent = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.UserChannelLastRead.prototype.getLastReminderSent = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.UserChannelLastRead.prototype.setLastReminderSent = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.moonlight.UserChannelLastRead.prototype.clearLastReminderSent = function() {
  this.setLastReminderSent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.UserChannelLastRead.prototype.hasLastReminderSent = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyChannelLastRead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyChannelLastRead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyChannelLastRead.displayName = 'proto.moonlight.CompanyChannelLastRead';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyChannelLastRead.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyChannelLastRead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyChannelLastRead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyChannelLastRead.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyChannelLastRead}
 */
proto.moonlight.CompanyChannelLastRead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyChannelLastRead;
  return proto.moonlight.CompanyChannelLastRead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyChannelLastRead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyChannelLastRead}
 */
proto.moonlight.CompanyChannelLastRead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyChannelLastRead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyChannelLastRead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyChannelLastRead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyChannelLastRead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string channel_id = 1;
 * @return {string}
 */
proto.moonlight.CompanyChannelLastRead.prototype.getChannelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CompanyChannelLastRead.prototype.setChannelId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 company_id = 3;
 * @return {number}
 */
proto.moonlight.CompanyChannelLastRead.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.CompanyChannelLastRead.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.CompanyChannelLastRead.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.CompanyChannelLastRead.prototype.setTimestamp = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.moonlight.CompanyChannelLastRead.prototype.clearTimestamp = function() {
  this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.CompanyChannelLastRead.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.IP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.IP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.IP.displayName = 'proto.moonlight.IP';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.IP.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.IP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.IP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.IP.toObject = function(includeInstance, msg) {
  var f, obj = {
    ip: jspb.Message.getFieldWithDefault(msg, 1, ""),
    continent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    administrative: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lat: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    isEu: jspb.Message.getFieldWithDefault(msg, 9, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.IP}
 */
proto.moonlight.IP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.IP;
  return proto.moonlight.IP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.IP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.IP}
 */
proto.moonlight.IP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdministrative(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEu(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.IP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.IP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.IP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.IP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContinent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdministrative();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIsEu();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ip = 1;
 * @return {string}
 */
proto.moonlight.IP.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setIp = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string continent = 2;
 * @return {string}
 */
proto.moonlight.IP.prototype.getContinent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setContinent = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.moonlight.IP.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string administrative = 4;
 * @return {string}
 */
proto.moonlight.IP.prototype.getAdministrative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setAdministrative = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.moonlight.IP.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setCity = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postcode = 6;
 * @return {string}
 */
proto.moonlight.IP.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.IP.prototype.setPostcode = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double lat = 7;
 * @return {number}
 */
proto.moonlight.IP.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.moonlight.IP.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double lon = 8;
 * @return {number}
 */
proto.moonlight.IP.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.moonlight.IP.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool is_eu = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.IP.prototype.getIsEu = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.moonlight.IP.prototype.setIsEu = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.IP.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.IP.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.IP.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.IP.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.IP.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.IP.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.moonlight.IP.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.IP.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CityRequest.displayName = 'proto.moonlight.CityRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CityRequest}
 */
proto.moonlight.CityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CityRequest;
  return proto.moonlight.CityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CityRequest}
 */
proto.moonlight.CityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.moonlight.CityRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CityRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.City = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.City, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.City.displayName = 'proto.moonlight.City';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.City.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.City.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.City} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.City.toObject = function(includeInstance, msg) {
  var f, obj = {
    country: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    population: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lat: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    lon: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    developerCount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.City}
 */
proto.moonlight.City.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.City;
  return proto.moonlight.City.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.City} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.City}
 */
proto.moonlight.City.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPopulation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeveloperCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.City.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.City.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.City} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.City.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPopulation();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDeveloperCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string country = 1;
 * @return {string}
 */
proto.moonlight.City.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.City.prototype.setCountry = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.moonlight.City.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.City.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.moonlight.City.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.City.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 population = 4;
 * @return {number}
 */
proto.moonlight.City.prototype.getPopulation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.City.prototype.setPopulation = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double lat = 5;
 * @return {number}
 */
proto.moonlight.City.prototype.getLat = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.moonlight.City.prototype.setLat = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double lon = 6;
 * @return {number}
 */
proto.moonlight.City.prototype.getLon = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.moonlight.City.prototype.setLon = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int32 developer_count = 7;
 * @return {number}
 */
proto.moonlight.City.prototype.getDeveloperCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.moonlight.City.prototype.setDeveloperCount = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillScores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SkillScores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillScores.displayName = 'proto.moonlight.SkillScores';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillScores.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillScores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillScores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillScores.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoresMap: (f = msg.getScoresMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillScores}
 */
proto.moonlight.SkillScores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillScores;
  return proto.moonlight.SkillScores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillScores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillScores}
 */
proto.moonlight.SkillScores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getScoresMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillScores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillScores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillScores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillScores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoresMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * map<string, double> scores = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.moonlight.SkillScores.prototype.getScoresMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


proto.moonlight.SkillScores.prototype.clearScoresMap = function() {
  this.getScoresMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SkillSuggestionModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SkillSuggestionModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SkillSuggestionModel.displayName = 'proto.moonlight.SkillSuggestionModel';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SkillSuggestionModel.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SkillSuggestionModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SkillSuggestionModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillSuggestionModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillScoresMap: (f = msg.getSkillScoresMap()) ? f.toObject(includeInstance, proto.moonlight.SkillScores.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SkillSuggestionModel}
 */
proto.moonlight.SkillSuggestionModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SkillSuggestionModel;
  return proto.moonlight.SkillSuggestionModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SkillSuggestionModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SkillSuggestionModel}
 */
proto.moonlight.SkillSuggestionModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSkillScoresMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.moonlight.SkillScores.deserializeBinaryFromReader);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SkillSuggestionModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SkillSuggestionModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SkillSuggestionModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SkillSuggestionModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillScoresMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.moonlight.SkillScores.serializeBinaryToWriter);
  }
};


/**
 * map<string, SkillScores> skill_scores = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.moonlight.SkillScores>}
 */
proto.moonlight.SkillSuggestionModel.prototype.getSkillScoresMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.moonlight.SkillScores>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.moonlight.SkillScores));
};


proto.moonlight.SkillSuggestionModel.prototype.clearSkillScoresMap = function() {
  this.getSkillScoresMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Email.displayName = 'proto.moonlight.Email';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sparkpostId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 6, ""),
    body: jspb.Message.getFieldWithDefault(msg, 7, ""),
    secure: jspb.Message.getFieldWithDefault(msg, 8, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    newsletterId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    delivered: jspb.Message.getFieldWithDefault(msg, 12, false),
    error: jspb.Message.getFieldWithDefault(msg, 13, ""),
    category: jspb.Message.getFieldWithDefault(msg, 14, ""),
    triggeredUnsubscribe: jspb.Message.getFieldWithDefault(msg, 15, false),
    triggeredSpamComplaint: jspb.Message.getFieldWithDefault(msg, 16, false),
    unsubscribeToken: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Email}
 */
proto.moonlight.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Email;
  return proto.moonlight.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Email}
 */
proto.moonlight.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSparkpostId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecure(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewsletterId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelivered(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTriggeredUnsubscribe(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTriggeredSpamComplaint(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnsubscribeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getToEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSparkpostId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSecure();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNewsletterId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDelivered();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTriggeredUnsubscribe();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getTriggeredSpamComplaint();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getUnsubscribeToken();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.Email.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Email.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.Email.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Email.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string to_email = 3;
 * @return {string}
 */
proto.moonlight.Email.prototype.getToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setToEmail = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_email = 4;
 * @return {string}
 */
proto.moonlight.Email.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setFromEmail = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sparkpost_id = 5;
 * @return {string}
 */
proto.moonlight.Email.prototype.getSparkpostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setSparkpostId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subject = 6;
 * @return {string}
 */
proto.moonlight.Email.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setSubject = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string body = 7;
 * @return {string}
 */
proto.moonlight.Email.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setBody = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool secure = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Email.prototype.getSecure = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.Email.prototype.setSecure = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Email.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Email.prototype.setUpdatedAt = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.moonlight.Email.prototype.clearUpdatedAt = function() {
  this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Email.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Email.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Email.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 10, value);
};


proto.moonlight.Email.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Email.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 newsletter_id = 11;
 * @return {number}
 */
proto.moonlight.Email.prototype.getNewsletterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.moonlight.Email.prototype.setNewsletterId = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool delivered = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Email.prototype.getDelivered = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.moonlight.Email.prototype.setDelivered = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string error = 13;
 * @return {string}
 */
proto.moonlight.Email.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setError = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string category = 14;
 * @return {string}
 */
proto.moonlight.Email.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool triggered_unsubscribe = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Email.prototype.getTriggeredUnsubscribe = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.moonlight.Email.prototype.setTriggeredUnsubscribe = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool triggered_spam_complaint = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Email.prototype.getTriggeredSpamComplaint = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.moonlight.Email.prototype.setTriggeredSpamComplaint = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string unsubscribe_token = 17;
 * @return {string}
 */
proto.moonlight.Email.prototype.getUnsubscribeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.moonlight.Email.prototype.setUnsubscribeToken = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateEmailRequest.displayName = 'proto.moonlight.CreateEmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secure: jspb.Message.getFieldWithDefault(msg, 4, false),
    newsletterId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    category: jspb.Message.getFieldWithDefault(msg, 14, ""),
    fromName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    fromEmail: jspb.Message.getFieldWithDefault(msg, 16, ""),
    overrideConfirmed: jspb.Message.getFieldWithDefault(msg, 17, false),
    overrideToEmail: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateEmailRequest}
 */
proto.moonlight.CreateEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateEmailRequest;
  return proto.moonlight.CreateEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateEmailRequest}
 */
proto.moonlight.CreateEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecure(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewsletterId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromEmail(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideConfirmed(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideToEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecure();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNewsletterId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFromName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFromEmail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOverrideConfirmed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOverrideToEmail();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.CreateEmailRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateEmailRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setSubject = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string body = 3;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setBody = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool secure = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateEmailRequest.prototype.getSecure = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.moonlight.CreateEmailRequest.prototype.setSecure = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 newsletter_id = 11;
 * @return {number}
 */
proto.moonlight.CreateEmailRequest.prototype.getNewsletterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.moonlight.CreateEmailRequest.prototype.setNewsletterId = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string category = 14;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string from_name = 15;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getFromName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setFromName = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string from_email = 16;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getFromEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setFromEmail = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool override_confirmed = 17;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateEmailRequest.prototype.getOverrideConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 17, false));
};


/** @param {boolean} value */
proto.moonlight.CreateEmailRequest.prototype.setOverrideConfirmed = function(value) {
  jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string override_to_email = 18;
 * @return {string}
 */
proto.moonlight.CreateEmailRequest.prototype.getOverrideToEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.moonlight.CreateEmailRequest.prototype.setOverrideToEmail = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateTransactionalEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateTransactionalEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateTransactionalEmailRequest.displayName = 'proto.moonlight.CreateTransactionalEmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateTransactionalEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateTransactionalEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateTransactionalEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && proto.moonlight.CreateEmailRequest.toObject(includeInstance, f),
    buttonText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    buttonUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateTransactionalEmailRequest}
 */
proto.moonlight.CreateTransactionalEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateTransactionalEmailRequest;
  return proto.moonlight.CreateTransactionalEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateTransactionalEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateTransactionalEmailRequest}
 */
proto.moonlight.CreateTransactionalEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.CreateEmailRequest;
      reader.readMessage(value,proto.moonlight.CreateEmailRequest.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateTransactionalEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateTransactionalEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateTransactionalEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.CreateEmailRequest.serializeBinaryToWriter
    );
  }
  f = message.getButtonText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getButtonUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional CreateEmailRequest email = 1;
 * @return {?proto.moonlight.CreateEmailRequest}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.getEmail = function() {
  return /** @type{?proto.moonlight.CreateEmailRequest} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.CreateEmailRequest, 1));
};


/** @param {?proto.moonlight.CreateEmailRequest|undefined} value */
proto.moonlight.CreateTransactionalEmailRequest.prototype.setEmail = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.CreateTransactionalEmailRequest.prototype.clearEmail = function() {
  this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string button_text = 2;
 * @return {string}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.getButtonText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.CreateTransactionalEmailRequest.prototype.setButtonText = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string button_url = 3;
 * @return {string}
 */
proto.moonlight.CreateTransactionalEmailRequest.prototype.getButtonUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CreateTransactionalEmailRequest.prototype.setButtonUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.EmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailRequest.displayName = 'proto.moonlight.EmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailRequest}
 */
proto.moonlight.EmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailRequest;
  return proto.moonlight.EmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailRequest}
 */
proto.moonlight.EmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.EmailRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.EmailRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailUnsubscribeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.EmailUnsubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailUnsubscribeRequest.displayName = 'proto.moonlight.EmailUnsubscribeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailUnsubscribeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailUnsubscribeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailUnsubscribeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailUnsubscribeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unsubscribeToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailUnsubscribeRequest}
 */
proto.moonlight.EmailUnsubscribeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailUnsubscribeRequest;
  return proto.moonlight.EmailUnsubscribeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailUnsubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailUnsubscribeRequest}
 */
proto.moonlight.EmailUnsubscribeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnsubscribeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailUnsubscribeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailUnsubscribeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailUnsubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailUnsubscribeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUnsubscribeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.EmailUnsubscribeRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.EmailUnsubscribeRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string unsubscribe_token = 2;
 * @return {string}
 */
proto.moonlight.EmailUnsubscribeRequest.prototype.getUnsubscribeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.EmailUnsubscribeRequest.prototype.setUnsubscribeToken = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.SparkpostEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.SparkpostEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.SparkpostEmailRequest.displayName = 'proto.moonlight.SparkpostEmailRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.SparkpostEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.SparkpostEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.SparkpostEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SparkpostEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sparkpostId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.SparkpostEmailRequest}
 */
proto.moonlight.SparkpostEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.SparkpostEmailRequest;
  return proto.moonlight.SparkpostEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.SparkpostEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.SparkpostEmailRequest}
 */
proto.moonlight.SparkpostEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSparkpostId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.SparkpostEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.SparkpostEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.SparkpostEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.SparkpostEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSparkpostId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sparkpost_id = 1;
 * @return {string}
 */
proto.moonlight.SparkpostEmailRequest.prototype.getSparkpostId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.SparkpostEmailRequest.prototype.setSparkpostId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.EmailActivity.repeatedFields_, null);
};
goog.inherits(proto.moonlight.EmailActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailActivity.displayName = 'proto.moonlight.EmailActivity';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.EmailActivity.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && proto.moonlight.Email.toObject(includeInstance, f),
    emailClicksList: jspb.Message.toObjectList(msg.getEmailClicksList(),
    proto.moonlight.EmailClick.toObject, includeInstance),
    emailOpensList: jspb.Message.toObjectList(msg.getEmailOpensList(),
    proto.moonlight.EmailOpen.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailActivity}
 */
proto.moonlight.EmailActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailActivity;
  return proto.moonlight.EmailActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailActivity}
 */
proto.moonlight.EmailActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Email;
      reader.readMessage(value,proto.moonlight.Email.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 2:
      var value = new proto.moonlight.EmailClick;
      reader.readMessage(value,proto.moonlight.EmailClick.deserializeBinaryFromReader);
      msg.addEmailClicks(value);
      break;
    case 3:
      var value = new proto.moonlight.EmailOpen;
      reader.readMessage(value,proto.moonlight.EmailOpen.deserializeBinaryFromReader);
      msg.addEmailOpens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Email.serializeBinaryToWriter
    );
  }
  f = message.getEmailClicksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.moonlight.EmailClick.serializeBinaryToWriter
    );
  }
  f = message.getEmailOpensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.moonlight.EmailOpen.serializeBinaryToWriter
    );
  }
};


/**
 * optional Email email = 1;
 * @return {?proto.moonlight.Email}
 */
proto.moonlight.EmailActivity.prototype.getEmail = function() {
  return /** @type{?proto.moonlight.Email} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Email, 1));
};


/** @param {?proto.moonlight.Email|undefined} value */
proto.moonlight.EmailActivity.prototype.setEmail = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.EmailActivity.prototype.clearEmail = function() {
  this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.EmailActivity.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EmailClick email_clicks = 2;
 * @return {!Array.<!proto.moonlight.EmailClick>}
 */
proto.moonlight.EmailActivity.prototype.getEmailClicksList = function() {
  return /** @type{!Array.<!proto.moonlight.EmailClick>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.EmailClick, 2));
};


/** @param {!Array.<!proto.moonlight.EmailClick>} value */
proto.moonlight.EmailActivity.prototype.setEmailClicksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.moonlight.EmailClick=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.EmailClick}
 */
proto.moonlight.EmailActivity.prototype.addEmailClicks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.moonlight.EmailClick, opt_index);
};


proto.moonlight.EmailActivity.prototype.clearEmailClicksList = function() {
  this.setEmailClicksList([]);
};


/**
 * repeated EmailOpen email_opens = 3;
 * @return {!Array.<!proto.moonlight.EmailOpen>}
 */
proto.moonlight.EmailActivity.prototype.getEmailOpensList = function() {
  return /** @type{!Array.<!proto.moonlight.EmailOpen>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.EmailOpen, 3));
};


/** @param {!Array.<!proto.moonlight.EmailOpen>} value */
proto.moonlight.EmailActivity.prototype.setEmailOpensList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.moonlight.EmailOpen=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.EmailOpen}
 */
proto.moonlight.EmailActivity.prototype.addEmailOpens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.moonlight.EmailOpen, opt_index);
};


proto.moonlight.EmailActivity.prototype.clearEmailOpensList = function() {
  this.setEmailOpensList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailActivitiesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.EmailActivitiesList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.EmailActivitiesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailActivitiesList.displayName = 'proto.moonlight.EmailActivitiesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.EmailActivitiesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailActivitiesList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailActivitiesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailActivitiesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailActivitiesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    emailActivitiesList: jspb.Message.toObjectList(msg.getEmailActivitiesList(),
    proto.moonlight.EmailActivity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailActivitiesList}
 */
proto.moonlight.EmailActivitiesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailActivitiesList;
  return proto.moonlight.EmailActivitiesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailActivitiesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailActivitiesList}
 */
proto.moonlight.EmailActivitiesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.EmailActivity;
      reader.readMessage(value,proto.moonlight.EmailActivity.deserializeBinaryFromReader);
      msg.addEmailActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailActivitiesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailActivitiesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailActivitiesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailActivitiesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmailActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.EmailActivity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EmailActivity email_activities = 1;
 * @return {!Array.<!proto.moonlight.EmailActivity>}
 */
proto.moonlight.EmailActivitiesList.prototype.getEmailActivitiesList = function() {
  return /** @type{!Array.<!proto.moonlight.EmailActivity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.EmailActivity, 1));
};


/** @param {!Array.<!proto.moonlight.EmailActivity>} value */
proto.moonlight.EmailActivitiesList.prototype.setEmailActivitiesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.EmailActivity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.EmailActivity}
 */
proto.moonlight.EmailActivitiesList.prototype.addEmailActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.EmailActivity, opt_index);
};


proto.moonlight.EmailActivitiesList.prototype.clearEmailActivitiesList = function() {
  this.setEmailActivitiesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailOpen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.EmailOpen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailOpen.displayName = 'proto.moonlight.EmailOpen';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailOpen.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailOpen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailOpen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailOpen.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sparkpostEventId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userAgent: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailOpen}
 */
proto.moonlight.EmailOpen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailOpen;
  return proto.moonlight.EmailOpen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailOpen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailOpen}
 */
proto.moonlight.EmailOpen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmailId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSparkpostEventId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailOpen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailOpen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailOpen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailOpen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmailId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSparkpostEventId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserAgent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.EmailOpen.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.EmailOpen.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 email_id = 2;
 * @return {number}
 */
proto.moonlight.EmailOpen.prototype.getEmailId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.EmailOpen.prototype.setEmailId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.moonlight.EmailOpen.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.EmailOpen.prototype.setIp = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.EmailOpen.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.EmailOpen.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.EmailOpen.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.EmailOpen.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sparkpost_event_id = 5;
 * @return {string}
 */
proto.moonlight.EmailOpen.prototype.getSparkpostEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.EmailOpen.prototype.setSparkpostEventId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_agent = 6;
 * @return {string}
 */
proto.moonlight.EmailOpen.prototype.getUserAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.EmailOpen.prototype.setUserAgent = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.EmailClick = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.EmailClick, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.EmailClick.displayName = 'proto.moonlight.EmailClick';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.EmailClick.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.EmailClick.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.EmailClick} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailClick.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    emailId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sparkpostEventId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    linkName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    linkUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userAgent: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.EmailClick}
 */
proto.moonlight.EmailClick.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.EmailClick;
  return proto.moonlight.EmailClick.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.EmailClick} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.EmailClick}
 */
proto.moonlight.EmailClick.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmailId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSparkpostEventId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.EmailClick.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.EmailClick.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.EmailClick} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.EmailClick.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEmailId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSparkpostEventId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLinkName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLinkUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserAgent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.EmailClick.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.EmailClick.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 email_id = 2;
 * @return {number}
 */
proto.moonlight.EmailClick.prototype.getEmailId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.EmailClick.prototype.setEmailId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.moonlight.EmailClick.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.EmailClick.prototype.setIp = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.EmailClick.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.EmailClick.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.EmailClick.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.EmailClick.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sparkpost_event_id = 5;
 * @return {string}
 */
proto.moonlight.EmailClick.prototype.getSparkpostEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.EmailClick.prototype.setSparkpostEventId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string link_name = 6;
 * @return {string}
 */
proto.moonlight.EmailClick.prototype.getLinkName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.EmailClick.prototype.setLinkName = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string link_url = 7;
 * @return {string}
 */
proto.moonlight.EmailClick.prototype.getLinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.EmailClick.prototype.setLinkUrl = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_agent = 8;
 * @return {string}
 */
proto.moonlight.EmailClick.prototype.getUserAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.moonlight.EmailClick.prototype.setUserAgent = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.NewsletterCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.NewsletterCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.NewsletterCategoryRequest.displayName = 'proto.moonlight.NewsletterCategoryRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.NewsletterCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.NewsletterCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.NewsletterCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.NewsletterCategoryRequest}
 */
proto.moonlight.NewsletterCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.NewsletterCategoryRequest;
  return proto.moonlight.NewsletterCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.NewsletterCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.NewsletterCategoryRequest}
 */
proto.moonlight.NewsletterCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.NewsletterCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.NewsletterCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.NewsletterCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.moonlight.NewsletterCategoryRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.NewsletterCategoryRequest.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateUserNewsletterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateUserNewsletterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateUserNewsletterRequest.displayName = 'proto.moonlight.CreateUserNewsletterRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateUserNewsletterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateUserNewsletterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateUserNewsletterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserNewsletterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateUserNewsletterRequest}
 */
proto.moonlight.CreateUserNewsletterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateUserNewsletterRequest;
  return proto.moonlight.CreateUserNewsletterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateUserNewsletterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateUserNewsletterRequest}
 */
proto.moonlight.CreateUserNewsletterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateUserNewsletterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateUserNewsletterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateUserNewsletterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateUserNewsletterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.moonlight.CreateUserNewsletterRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CreateUserNewsletterRequest.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.CreateUserNewsletterRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CreateUserNewsletterRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Newsletter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Newsletter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Newsletter.displayName = 'proto.moonlight.Newsletter';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Newsletter.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Newsletter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Newsletter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Newsletter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intro: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Newsletter}
 */
proto.moonlight.Newsletter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Newsletter;
  return proto.moonlight.Newsletter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Newsletter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Newsletter}
 */
proto.moonlight.Newsletter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntro(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Newsletter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Newsletter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Newsletter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Newsletter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.Newsletter.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Newsletter.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.moonlight.Newsletter.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.Newsletter.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Newsletter.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Newsletter.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.Newsletter.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Newsletter.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.moonlight.Newsletter.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.Newsletter.prototype.setSubject = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string intro = 5;
 * @return {string}
 */
proto.moonlight.Newsletter.prototype.getIntro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.Newsletter.prototype.setIntro = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.NewsletterIntro = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.NewsletterIntro, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.NewsletterIntro.displayName = 'proto.moonlight.NewsletterIntro';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.NewsletterIntro.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.NewsletterIntro.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.NewsletterIntro} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterIntro.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    intro: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.NewsletterIntro}
 */
proto.moonlight.NewsletterIntro.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.NewsletterIntro;
  return proto.moonlight.NewsletterIntro.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.NewsletterIntro} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.NewsletterIntro}
 */
proto.moonlight.NewsletterIntro.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntro(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.NewsletterIntro.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.NewsletterIntro.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.NewsletterIntro} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterIntro.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntro();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.moonlight.NewsletterIntro.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.NewsletterIntro.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string intro = 2;
 * @return {string}
 */
proto.moonlight.NewsletterIntro.prototype.getIntro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.moonlight.NewsletterIntro.prototype.setIntro = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subject = 3;
 * @return {string}
 */
proto.moonlight.NewsletterIntro.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.NewsletterIntro.prototype.setSubject = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.NewsletterStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.NewsletterStats.repeatedFields_, null);
};
goog.inherits(proto.moonlight.NewsletterStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.NewsletterStats.displayName = 'proto.moonlight.NewsletterStats';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.NewsletterStats.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.NewsletterStats.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.NewsletterStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.NewsletterStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    newsletter: (f = msg.getNewsletter()) && proto.moonlight.Newsletter.toObject(includeInstance, f),
    countSent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    countClick: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countOpen: jspb.Message.getFieldWithDefault(msg, 4, 0),
    countUnsubscribed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countError: jspb.Message.getFieldWithDefault(msg, 6, 0),
    linkClickCountsList: jspb.Message.toObjectList(msg.getLinkClickCountsList(),
    proto.moonlight.LinkClickCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.NewsletterStats}
 */
proto.moonlight.NewsletterStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.NewsletterStats;
  return proto.moonlight.NewsletterStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.NewsletterStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.NewsletterStats}
 */
proto.moonlight.NewsletterStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Newsletter;
      reader.readMessage(value,proto.moonlight.Newsletter.deserializeBinaryFromReader);
      msg.setNewsletter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountSent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountClick(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountOpen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountUnsubscribed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountError(value);
      break;
    case 7:
      var value = new proto.moonlight.LinkClickCount;
      reader.readMessage(value,proto.moonlight.LinkClickCount.deserializeBinaryFromReader);
      msg.addLinkClickCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.NewsletterStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.NewsletterStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.NewsletterStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewsletter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.moonlight.Newsletter.serializeBinaryToWriter
    );
  }
  f = message.getCountSent();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCountClick();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCountOpen();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCountUnsubscribed();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCountError();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLinkClickCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.moonlight.LinkClickCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Newsletter newsletter = 1;
 * @return {?proto.moonlight.Newsletter}
 */
proto.moonlight.NewsletterStats.prototype.getNewsletter = function() {
  return /** @type{?proto.moonlight.Newsletter} */ (
    jspb.Message.getWrapperField(this, proto.moonlight.Newsletter, 1));
};


/** @param {?proto.moonlight.Newsletter|undefined} value */
proto.moonlight.NewsletterStats.prototype.setNewsletter = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.moonlight.NewsletterStats.prototype.clearNewsletter = function() {
  this.setNewsletter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.NewsletterStats.prototype.hasNewsletter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 count_sent = 2;
 * @return {number}
 */
proto.moonlight.NewsletterStats.prototype.getCountSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.NewsletterStats.prototype.setCountSent = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 count_click = 3;
 * @return {number}
 */
proto.moonlight.NewsletterStats.prototype.getCountClick = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.moonlight.NewsletterStats.prototype.setCountClick = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 count_open = 4;
 * @return {number}
 */
proto.moonlight.NewsletterStats.prototype.getCountOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.NewsletterStats.prototype.setCountOpen = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 count_unsubscribed = 5;
 * @return {number}
 */
proto.moonlight.NewsletterStats.prototype.getCountUnsubscribed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.moonlight.NewsletterStats.prototype.setCountUnsubscribed = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 count_error = 6;
 * @return {number}
 */
proto.moonlight.NewsletterStats.prototype.getCountError = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.moonlight.NewsletterStats.prototype.setCountError = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated LinkClickCount link_click_counts = 7;
 * @return {!Array.<!proto.moonlight.LinkClickCount>}
 */
proto.moonlight.NewsletterStats.prototype.getLinkClickCountsList = function() {
  return /** @type{!Array.<!proto.moonlight.LinkClickCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.LinkClickCount, 7));
};


/** @param {!Array.<!proto.moonlight.LinkClickCount>} value */
proto.moonlight.NewsletterStats.prototype.setLinkClickCountsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.moonlight.LinkClickCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.LinkClickCount}
 */
proto.moonlight.NewsletterStats.prototype.addLinkClickCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.moonlight.LinkClickCount, opt_index);
};


proto.moonlight.NewsletterStats.prototype.clearLinkClickCountsList = function() {
  this.setLinkClickCountsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.LinkClickCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.LinkClickCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.LinkClickCount.displayName = 'proto.moonlight.LinkClickCount';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.LinkClickCount.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.LinkClickCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.LinkClickCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LinkClickCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    clickCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.LinkClickCount}
 */
proto.moonlight.LinkClickCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.LinkClickCount;
  return proto.moonlight.LinkClickCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.LinkClickCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.LinkClickCount}
 */
proto.moonlight.LinkClickCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClickCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.LinkClickCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.LinkClickCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.LinkClickCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.LinkClickCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClickCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 click_count = 1;
 * @return {number}
 */
proto.moonlight.LinkClickCount.prototype.getClickCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.LinkClickCount.prototype.setClickCount = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.moonlight.LinkClickCount.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.LinkClickCount.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.NewsletterStatsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.NewsletterStatsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.NewsletterStatsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.NewsletterStatsList.displayName = 'proto.moonlight.NewsletterStatsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.NewsletterStatsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.NewsletterStatsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.NewsletterStatsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.NewsletterStatsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterStatsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    newsletterStatsList: jspb.Message.toObjectList(msg.getNewsletterStatsList(),
    proto.moonlight.NewsletterStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.NewsletterStatsList}
 */
proto.moonlight.NewsletterStatsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.NewsletterStatsList;
  return proto.moonlight.NewsletterStatsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.NewsletterStatsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.NewsletterStatsList}
 */
proto.moonlight.NewsletterStatsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.NewsletterStats;
      reader.readMessage(value,proto.moonlight.NewsletterStats.deserializeBinaryFromReader);
      msg.addNewsletterStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.NewsletterStatsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.NewsletterStatsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.NewsletterStatsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.NewsletterStatsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewsletterStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.NewsletterStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NewsletterStats newsletter_stats = 1;
 * @return {!Array.<!proto.moonlight.NewsletterStats>}
 */
proto.moonlight.NewsletterStatsList.prototype.getNewsletterStatsList = function() {
  return /** @type{!Array.<!proto.moonlight.NewsletterStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.NewsletterStats, 1));
};


/** @param {!Array.<!proto.moonlight.NewsletterStats>} value */
proto.moonlight.NewsletterStatsList.prototype.setNewsletterStatsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.NewsletterStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.NewsletterStats}
 */
proto.moonlight.NewsletterStatsList.prototype.addNewsletterStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.NewsletterStats, opt_index);
};


proto.moonlight.NewsletterStatsList.prototype.clearNewsletterStatsList = function() {
  this.setNewsletterStatsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.Broadcast = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.Broadcast, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.Broadcast.displayName = 'proto.moonlight.Broadcast';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.Broadcast.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.Broadcast.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.Broadcast} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Broadcast.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    closeAt: (f = msg.getCloseAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    open: jspb.Message.getFieldWithDefault(msg, 5, false),
    approved: jspb.Message.getFieldWithDefault(msg, 6, false),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 7, false),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 8, false),
    hoursAvailablePerWeek: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idealJob: jspb.Message.getFieldWithDefault(msg, 30, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 31, ""),
    recentAccomplishments: jspb.Message.getFieldWithDefault(msg, 32, ""),
    openForReview: jspb.Message.getFieldWithDefault(msg, 33, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.Broadcast}
 */
proto.moonlight.Broadcast.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.Broadcast;
  return proto.moonlight.Broadcast.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.Broadcast} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.Broadcast}
 */
proto.moonlight.Broadcast.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCloseAt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpen(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoursAvailablePerWeek(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdealJob(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecentAccomplishments(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenForReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.Broadcast.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.Broadcast.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.Broadcast} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.Broadcast.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCloseAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOpen();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHoursAvailablePerWeek();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getIdealJob();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getRecentAccomplishments();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getOpenForReview();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.Broadcast.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.Broadcast.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.Broadcast.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.Broadcast.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Broadcast.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Broadcast.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.Broadcast.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Broadcast.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp close_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.Broadcast.prototype.getCloseAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.Broadcast.prototype.setCloseAt = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.moonlight.Broadcast.prototype.clearCloseAt = function() {
  this.setCloseAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.Broadcast.prototype.hasCloseAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool open = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Broadcast.prototype.getOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.moonlight.Broadcast.prototype.setOpen = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool approved = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Broadcast.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};


/** @param {boolean} value */
proto.moonlight.Broadcast.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool seeking_employment = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Broadcast.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.moonlight.Broadcast.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool seeking_contracts = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Broadcast.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.moonlight.Broadcast.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 hours_available_per_week = 9;
 * @return {number}
 */
proto.moonlight.Broadcast.prototype.getHoursAvailablePerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.moonlight.Broadcast.prototype.setHoursAvailablePerWeek = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string ideal_job = 30;
 * @return {string}
 */
proto.moonlight.Broadcast.prototype.getIdealJob = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.moonlight.Broadcast.prototype.setIdealJob = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string bio = 31;
 * @return {string}
 */
proto.moonlight.Broadcast.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/** @param {string} value */
proto.moonlight.Broadcast.prototype.setBio = function(value) {
  jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string recent_accomplishments = 32;
 * @return {string}
 */
proto.moonlight.Broadcast.prototype.getRecentAccomplishments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/** @param {string} value */
proto.moonlight.Broadcast.prototype.setRecentAccomplishments = function(value) {
  jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional bool open_for_review = 33;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.Broadcast.prototype.getOpenForReview = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 33, false));
};


/** @param {boolean} value */
proto.moonlight.Broadcast.prototype.setOpenForReview = function(value) {
  jspb.Message.setProto3BooleanField(this, 33, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.BroadcastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.BroadcastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.BroadcastRequest.displayName = 'proto.moonlight.BroadcastRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.BroadcastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.BroadcastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.BroadcastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.BroadcastRequest}
 */
proto.moonlight.BroadcastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.BroadcastRequest;
  return proto.moonlight.BroadcastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.BroadcastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.BroadcastRequest}
 */
proto.moonlight.BroadcastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.BroadcastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.BroadcastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.BroadcastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.moonlight.BroadcastRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.BroadcastRequest.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.BroadcastRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.BroadcastRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CreateBroadcastRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CreateBroadcastRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CreateBroadcastRequest.displayName = 'proto.moonlight.CreateBroadcastRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CreateBroadcastRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CreateBroadcastRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CreateBroadcastRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateBroadcastRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 2, false),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 3, false),
    hoursAvailablePerWeek: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idealJob: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 6, ""),
    recentAccomplishments: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CreateBroadcastRequest}
 */
proto.moonlight.CreateBroadcastRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CreateBroadcastRequest;
  return proto.moonlight.CreateBroadcastRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CreateBroadcastRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CreateBroadcastRequest}
 */
proto.moonlight.CreateBroadcastRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHoursAvailablePerWeek(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdealJob(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecentAccomplishments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CreateBroadcastRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CreateBroadcastRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CreateBroadcastRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CreateBroadcastRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHoursAvailablePerWeek();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIdealJob();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRecentAccomplishments();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CreateBroadcastRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool seeking_employment = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.CreateBroadcastRequest.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool seeking_contracts = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.CreateBroadcastRequest.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 hours_available_per_week = 4;
 * @return {number}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getHoursAvailablePerWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.moonlight.CreateBroadcastRequest.prototype.setHoursAvailablePerWeek = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string ideal_job = 5;
 * @return {string}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getIdealJob = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.moonlight.CreateBroadcastRequest.prototype.setIdealJob = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bio = 6;
 * @return {string}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.moonlight.CreateBroadcastRequest.prototype.setBio = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string recent_accomplishments = 7;
 * @return {string}
 */
proto.moonlight.CreateBroadcastRequest.prototype.getRecentAccomplishments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.moonlight.CreateBroadcastRequest.prototype.setRecentAccomplishments = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.BroadcastsListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.BroadcastsListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.BroadcastsListRequest.displayName = 'proto.moonlight.BroadcastsListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.BroadcastsListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.BroadcastsListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.BroadcastsListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastsListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seekingEmployment: jspb.Message.getFieldWithDefault(msg, 2, false),
    seekingContracts: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.BroadcastsListRequest}
 */
proto.moonlight.BroadcastsListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.BroadcastsListRequest;
  return proto.moonlight.BroadcastsListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.BroadcastsListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.BroadcastsListRequest}
 */
proto.moonlight.BroadcastsListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingEmployment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeekingContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.BroadcastsListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.BroadcastsListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.BroadcastsListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastsListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeekingEmployment();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSeekingContracts();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool seeking_employment = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.BroadcastsListRequest.prototype.getSeekingEmployment = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.moonlight.BroadcastsListRequest.prototype.setSeekingEmployment = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool seeking_contracts = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.moonlight.BroadcastsListRequest.prototype.getSeekingContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.moonlight.BroadcastsListRequest.prototype.setSeekingContracts = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.BroadcastsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.BroadcastsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.BroadcastsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.BroadcastsList.displayName = 'proto.moonlight.BroadcastsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.BroadcastsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.BroadcastsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.BroadcastsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.BroadcastsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    broadcastsList: jspb.Message.toObjectList(msg.getBroadcastsList(),
    proto.moonlight.Broadcast.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.BroadcastsList}
 */
proto.moonlight.BroadcastsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.BroadcastsList;
  return proto.moonlight.BroadcastsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.BroadcastsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.BroadcastsList}
 */
proto.moonlight.BroadcastsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.Broadcast;
      reader.readMessage(value,proto.moonlight.Broadcast.deserializeBinaryFromReader);
      msg.addBroadcasts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.BroadcastsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.BroadcastsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.BroadcastsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.BroadcastsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBroadcastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.Broadcast.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Broadcast broadcasts = 1;
 * @return {!Array.<!proto.moonlight.Broadcast>}
 */
proto.moonlight.BroadcastsList.prototype.getBroadcastsList = function() {
  return /** @type{!Array.<!proto.moonlight.Broadcast>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.Broadcast, 1));
};


/** @param {!Array.<!proto.moonlight.Broadcast>} value */
proto.moonlight.BroadcastsList.prototype.setBroadcastsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.Broadcast=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.Broadcast}
 */
proto.moonlight.BroadcastsList.prototype.addBroadcasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.Broadcast, opt_index);
};


proto.moonlight.BroadcastsList.prototype.clearBroadcastsList = function() {
  this.setBroadcastsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyCheckoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyCheckoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyCheckoutRequest.displayName = 'proto.moonlight.CompanyCheckoutRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyCheckoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyCheckoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyCheckoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCheckoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seats: jspb.Message.getFieldWithDefault(msg, 2, 0),
    plan: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyCheckoutRequest}
 */
proto.moonlight.CompanyCheckoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyCheckoutRequest;
  return proto.moonlight.CompanyCheckoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyCheckoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyCheckoutRequest}
 */
proto.moonlight.CompanyCheckoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeats(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyCheckoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyCheckoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyCheckoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCheckoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPlan();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.CompanyCheckoutRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.CompanyCheckoutRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 seats = 2;
 * @return {number}
 */
proto.moonlight.CompanyCheckoutRequest.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.CompanyCheckoutRequest.prototype.setSeats = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string plan = 3;
 * @return {string}
 */
proto.moonlight.CompanyCheckoutRequest.prototype.getPlan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.CompanyCheckoutRequest.prototype.setPlan = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.CompanyCheckoutSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.CompanyCheckoutSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.CompanyCheckoutSession.displayName = 'proto.moonlight.CompanyCheckoutSession';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.CompanyCheckoutSession.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.CompanyCheckoutSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.CompanyCheckoutSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCheckoutSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.CompanyCheckoutSession}
 */
proto.moonlight.CompanyCheckoutSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.CompanyCheckoutSession;
  return proto.moonlight.CompanyCheckoutSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.CompanyCheckoutSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.CompanyCheckoutSession}
 */
proto.moonlight.CompanyCheckoutSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.CompanyCheckoutSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.CompanyCheckoutSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.CompanyCheckoutSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.CompanyCheckoutSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.moonlight.CompanyCheckoutSession.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.CompanyCheckoutSession.prototype.setSessionId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.FullStorySession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.FullStorySession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.FullStorySession.displayName = 'proto.moonlight.FullStorySession';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.FullStorySession.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.FullStorySession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.FullStorySession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FullStorySession.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.FullStorySession}
 */
proto.moonlight.FullStorySession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.FullStorySession;
  return proto.moonlight.FullStorySession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.FullStorySession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.FullStorySession}
 */
proto.moonlight.FullStorySession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.FullStorySession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.FullStorySession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.FullStorySession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FullStorySession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.moonlight.FullStorySession.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.FullStorySession.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 session_id = 2;
 * @return {number}
 */
proto.moonlight.FullStorySession.prototype.getSessionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.FullStorySession.prototype.setSessionId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.moonlight.FullStorySession.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.moonlight.FullStorySession.prototype.setCreatedAt = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.moonlight.FullStorySession.prototype.clearCreatedAt = function() {
  this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.moonlight.FullStorySession.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.moonlight.FullStorySession.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.moonlight.FullStorySession.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.FullStorySessionsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.moonlight.FullStorySessionsList.repeatedFields_, null);
};
goog.inherits(proto.moonlight.FullStorySessionsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.FullStorySessionsList.displayName = 'proto.moonlight.FullStorySessionsList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.moonlight.FullStorySessionsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.FullStorySessionsList.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.FullStorySessionsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.FullStorySessionsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FullStorySessionsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.moonlight.FullStorySession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.FullStorySessionsList}
 */
proto.moonlight.FullStorySessionsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.FullStorySessionsList;
  return proto.moonlight.FullStorySessionsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.FullStorySessionsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.FullStorySessionsList}
 */
proto.moonlight.FullStorySessionsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.moonlight.FullStorySession;
      reader.readMessage(value,proto.moonlight.FullStorySession.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.FullStorySessionsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.FullStorySessionsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.FullStorySessionsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.FullStorySessionsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.moonlight.FullStorySession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FullStorySession sessions = 1;
 * @return {!Array.<!proto.moonlight.FullStorySession>}
 */
proto.moonlight.FullStorySessionsList.prototype.getSessionsList = function() {
  return /** @type{!Array.<!proto.moonlight.FullStorySession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.moonlight.FullStorySession, 1));
};


/** @param {!Array.<!proto.moonlight.FullStorySession>} value */
proto.moonlight.FullStorySessionsList.prototype.setSessionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.moonlight.FullStorySession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.moonlight.FullStorySession}
 */
proto.moonlight.FullStorySessionsList.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.moonlight.FullStorySession, opt_index);
};


proto.moonlight.FullStorySessionsList.prototype.clearSessionsList = function() {
  this.setSessionsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.OutreachTemporaryCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.OutreachTemporaryCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.OutreachTemporaryCode.displayName = 'proto.moonlight.OutreachTemporaryCode';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.OutreachTemporaryCode.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.OutreachTemporaryCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.OutreachTemporaryCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.OutreachTemporaryCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.OutreachTemporaryCode}
 */
proto.moonlight.OutreachTemporaryCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.OutreachTemporaryCode;
  return proto.moonlight.OutreachTemporaryCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.OutreachTemporaryCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.OutreachTemporaryCode}
 */
proto.moonlight.OutreachTemporaryCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.OutreachTemporaryCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.OutreachTemporaryCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.OutreachTemporaryCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.OutreachTemporaryCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.moonlight.OutreachTemporaryCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.OutreachTemporaryCode.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.OutreachOAuthURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.OutreachOAuthURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.OutreachOAuthURL.displayName = 'proto.moonlight.OutreachOAuthURL';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.OutreachOAuthURL.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.OutreachOAuthURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.OutreachOAuthURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.OutreachOAuthURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.OutreachOAuthURL}
 */
proto.moonlight.OutreachOAuthURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.OutreachOAuthURL;
  return proto.moonlight.OutreachOAuthURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.OutreachOAuthURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.OutreachOAuthURL}
 */
proto.moonlight.OutreachOAuthURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.OutreachOAuthURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.OutreachOAuthURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.OutreachOAuthURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.OutreachOAuthURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.moonlight.OutreachOAuthURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.OutreachOAuthURL.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ContingencyAgreementSigningURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ContingencyAgreementSigningURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ContingencyAgreementSigningURL.displayName = 'proto.moonlight.ContingencyAgreementSigningURL';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ContingencyAgreementSigningURL.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ContingencyAgreementSigningURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ContingencyAgreementSigningURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ContingencyAgreementSigningURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ContingencyAgreementSigningURL}
 */
proto.moonlight.ContingencyAgreementSigningURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ContingencyAgreementSigningURL;
  return proto.moonlight.ContingencyAgreementSigningURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ContingencyAgreementSigningURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ContingencyAgreementSigningURL}
 */
proto.moonlight.ContingencyAgreementSigningURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ContingencyAgreementSigningURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ContingencyAgreementSigningURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ContingencyAgreementSigningURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ContingencyAgreementSigningURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.moonlight.ContingencyAgreementSigningURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ContingencyAgreementSigningURL.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ActivateCompanyContingencyAgreementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ActivateCompanyContingencyAgreementRequest.displayName = 'proto.moonlight.ActivateCompanyContingencyAgreementRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ActivateCompanyContingencyAgreementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ActivateCompanyContingencyAgreementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    downloadUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ActivateCompanyContingencyAgreementRequest}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ActivateCompanyContingencyAgreementRequest;
  return proto.moonlight.ActivateCompanyContingencyAgreementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ActivateCompanyContingencyAgreementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ActivateCompanyContingencyAgreementRequest}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ActivateCompanyContingencyAgreementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ActivateCompanyContingencyAgreementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.setCompanyId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.setUserId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string download_url = 3;
 * @return {string}
 */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.moonlight.ActivateCompanyContingencyAgreementRequest.prototype.setDownloadUrl = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.moonlight.ContingencyAgreement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.moonlight.ContingencyAgreement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.moonlight.ContingencyAgreement.displayName = 'proto.moonlight.ContingencyAgreement';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.moonlight.ContingencyAgreement.prototype.toObject = function(opt_includeInstance) {
  return proto.moonlight.ContingencyAgreement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.moonlight.ContingencyAgreement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ContingencyAgreement.toObject = function(includeInstance, msg) {
  var f, obj = {
    downloadUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.moonlight.ContingencyAgreement}
 */
proto.moonlight.ContingencyAgreement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.moonlight.ContingencyAgreement;
  return proto.moonlight.ContingencyAgreement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.moonlight.ContingencyAgreement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.moonlight.ContingencyAgreement}
 */
proto.moonlight.ContingencyAgreement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.moonlight.ContingencyAgreement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.moonlight.ContingencyAgreement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.moonlight.ContingencyAgreement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.moonlight.ContingencyAgreement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string download_url = 1;
 * @return {string}
 */
proto.moonlight.ContingencyAgreement.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.moonlight.ContingencyAgreement.prototype.setDownloadUrl = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.moonlight);
