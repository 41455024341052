/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  CreateJobRequest,
  Job,
  JobRequest,
  UserRequest,
  JobSkillRequest,
  CompanyRequest,
  Skill,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  job: null,
  skills: null,

  jobs: [],

  pending: false, // overall jobs model
  pendingSkills: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  listCompanyJobs(
    {
      // for use by company members
      commit,
      state,
      rootState,
    },
    companyID,
  ) {
    commit('mutateReset');
    commit('mutateResetJobs');
    commit('mutatePending', true);

    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJobs', res.message.getJobsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listCompanyOpenJobs(
    {
      // for showing on company profile
      commit,
      state,
      rootState,
    },
    companyID,
  ) {
    commit('mutateReset');
    commit('mutateResetJobs');
    commit('mutatePending', true);

    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyOpenJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJobs', res.message.getJobsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listDeveloperJobs({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetJobs');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListDeveloperJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJobs', res.message.getJobsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listDeveloperOpenJobMatches({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetJobs');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListDeveloperJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJobs', res.message.getJobsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, companyID }) {
    commit('mutateReset');

    // Only reset if fetching new user. Prevents flicker.
    if (state.job && state.job.getId() !== id) {
      commit('mutateResetJob');
    }

    // Dispatch reads of job skills
    dispatch('listSkills', { id, companyID });

    // check for cached job
    let cachedJob = null;
    _.forEach(state.jobs, (job) => {
      if (job.getId() === id) {
        // cache hit!
        cachedJob = job;
      }
    });

    if (cachedJob) {
      // Data from cache!
      commit('mutateJob', cachedJob);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new JobRequest();
    req.setId(id);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ReadJob, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateJob', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  create({
    commit, state, rootState, dispatch,
  }, req) {
    commit('mutateReset');

    commit('mutateJob', null);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateJob, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new CreateJobRequest(req.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateJob', res.message);
          commit('mutateAddJobToJobs', res.message);
          // Dispatch a list skills bc that's in the create req
          dispatch('listSkills', {
            id: res.message.getId(),
            companyID: res.message.getCompanyId(),
          });
        } else {
          // Fail! Revert to original job!
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, job) {
    commit('mutateReset');

    // Cache original val, and revert if save fails.
    // Strategy is to immediately update job, and
    // revert if the update fails
    let origJob;
    _.each(state.jobs, (p) => {
      if (p.getId() === job.getId()) {
        origJob = new Job(p.array.slice(0));
      }
    });
    commit('mutateJob', new Job(job.array.slice(0)));
    commit('mutateUpdateJobInCache', new Job(job.array.slice(0)));
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateJob, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new Job(job.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update job with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateJob', res.message);
          commit('mutateUpdateJobInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original job!
          commit('mutateJob', origJob);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listSkills is intended to be called internally by read function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listSkills({
    commit, state, rootState, dispatch,
  }, { id, companyID }) {
    commit('mutatePendingSkills', true);
    const req = new JobRequest();
    req.setId(id);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListJobSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkills', res.message.getSkillsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateSkills', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteSkill assumes that the job is loaded and that the skill exists on the job
  deleteSkill({
    commit, state, rootState, dispatch,
  }, slug) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Copy skill and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origSkill = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.skills.length; i++) {
      if (state.skills[i].getSlug() === slug) {
        origSkill = new Skill(state.skills[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveSkillInSkills', i);
      }
    }

    if (!origSkill) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching skill for id ${slug}`;
    }

    const req = new JobSkillRequest();
    req.setSkillSlug(slug);
    req.setJobId(state.job.getId());
    req.setCompanyId(state.job.getCompanyId());

    grpc.unary(MoonlightService.DeleteJobSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddSkillToSkills', origSkill);
        }
      },
    });
  },
  // createSkill adds a skill to the current job
  createSkill({
    commit, state, rootState, dispatch,
  }, slug) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });
    if (!skillMatch) {
      return;
    }

    commit('mutateAddSkillToSkills', skillMatch);
    const insertID = state.skills.length - 1;

    const req = new JobSkillRequest();
    req.setSkillSlug(slug);
    req.setJobId(state.job.getId());
    req.setCompanyId(state.job.getCompanyId());

    grpc.unary(MoonlightService.CreateJobSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        commit('mutateSuccess', res.Status === grpc.Code.OK);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillInSkills', insertID);
        }
      },
    });
  },
  issueInvoice({
    commit, state, rootState, dispatch,
  }, { id, companyID }) {
    commit('mutateReset');

    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(id);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.CreateInvoice, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // fetch invoice
          dispatch('invoices/listJob', { jobID: id, companyID }, { root: true });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.pendingSkills = false;

    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetJobs(state) {
    state.jobs = null;
  },
  mutateResetJob(state) {
    state.job = null;
    state.skills = null;
  },
  mutateJobs(state, jobs) {
    state.jobs = jobs;
  },
  mutateJob(state, job) {
    state.job = job;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutatePendingSkills(state, pending) {
    state.pendingSkills = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateSkills(state, data) {
    state.skills = data;
  },
  mutateUpdateJobInCache(state, job) {
    // remove job from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.jobs.length; i++) {
      if (state.jobs[i].getId() === job.getId()) {
        state.jobs[i] = job;
        // Vue.set(state.jobs, i, job);
      }
    }
  },
  mutateRemoveSkillInSkills(state, index) {
    state.skills.splice(index, 1);
  },
  mutateUpdateSkillInSkills(state, { index, newSkill }) {
    state.skills.splice(index, 1, newSkill);
  },
  mutateAddSkillToSkills(state, newSkill) {
    state.skills.push(newSkill);
  },
  mutateAddJobToJobs(state, job) {
    state.jobs.push(job);
  },
};

const getters = {
  getError(state) {
    if (state.errCode === null && state.errMsg === null) {
      return null;
    }

    return {
      code: state.errCode,
      msg: state.errMsg,
    };
  },
  getJobObj(state) {
    if (state.job === null) {
      return null;
    }
    // directly calling toObject occassionally causes issues
    const copy = new Job(state.job.array.slice(0));

    const obj = copy.toObject();
    obj.createdAt = protoTimestampToDate(copy.getCreatedAt());
    obj.updatedAt = protoTimestampToDate(copy.getUpdatedAt());
    obj.workStartedAt = protoTimestampToDate(copy.getWorkStartedAt());
    obj.finishedAt = protoTimestampToDate(copy.getFinishedAt());
    obj.canceledAt = protoTimestampToDate(copy.getCanceledAt());
    return obj;
  },
  getJobObjs(state) {
    const res = [];

    _.each(state.jobs, (p) => {
      const obj = new Job(p.array.slice(0)).toObject();
      obj.createdAt = protoTimestampToDate(p.getCreatedAt());
      obj.updatedAt = protoTimestampToDate(p.getUpdatedAt());
      obj.workStartedAt = protoTimestampToDate(p.getWorkStartedAt());
      obj.finishedAt = protoTimestampToDate(p.getFinishedAt());
      obj.canceledAt = protoTimestampToDate(p.getCanceledAt());
      res.push(obj);
    });
    // directly calling toObject occassionally causes issues
    return res;
  },
  getOngoingJobObjs(state) {
    // state 'open' or 'active'
    const res = [];

    _.each(state.jobs, (p) => {
      if (p.getState() === 'open' || p.getState() === 'active') {
        const obj = new Job(p.array.slice(0)).toObject();
        obj.createdAt = protoTimestampToDate(p.getCreatedAt());
        obj.updatedAt = protoTimestampToDate(p.getUpdatedAt());
        obj.workStartedAt = protoTimestampToDate(p.getWorkStartedAt());
        obj.finishedAt = protoTimestampToDate(p.getFinishedAt());
        obj.canceledAt = protoTimestampToDate(p.getCanceledAt());
        res.push(obj);
      }
    });
    return res;
  },
  getInactiveJobObjs(state) {
    // state 'canceled' or 'finished'
    const res = [];

    _.each(state.jobs, (p) => {
      if (p.getState() === 'canceled' || p.getState() === 'finished') {
        const obj = new Job(p.array.slice(0)).toObject();
        obj.createdAt = protoTimestampToDate(p.getCreatedAt());
        obj.updatedAt = protoTimestampToDate(p.getUpdatedAt());
        obj.workStartedAt = protoTimestampToDate(p.getWorkStartedAt());
        obj.finishedAt = protoTimestampToDate(p.getFinishedAt());
        obj.canceledAt = protoTimestampToDate(p.getCanceledAt());
        res.push(obj);
      }
    });
    return res;
  },
  getSkills(state) {
    if (!state.skills) {
      return null;
    }

    return state.skills;
  },
  getSkillSlugs(state) {
    const res = [];

    if (!state.skills) {
      return res;
    }

    _.each(state.skills, (sk) => {
      res.push(sk.getSlug());
    });
    return res;
  },
  getIsPaidJob(state) {
    if (!state.job) {
      return false;
    }
    return state.job.getPaid();
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
