import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import { guardAdmin, guardTractorBeam } from '@/conf/guards';

import MarketingRoutes from './marketing';
import OnboardingRoutes from './onboarding';
import AuthRoutes from './auth';
import TractorBeamRoutes from './tractorbeam';
import AppRoutes from './app';
import MissionControlRoutes from './missioncontrol';
import StartRoutes from './start';

const Marketing = () => import('@/views/Marketing');

const App = () => import('@/views/App');

const MissionControl = () => import('@/views/MissionControl');

const TractorBeam = () => import('@/views/TractorBeam');

const Start = () => import('@/views/Start');

const PublicInvoice = () => import('@/views/PublicInvoice');

const Unsubscribe = () => import('@/views/Unsubscribe');

const PublicPaystub = () => import('@/views/PublicPaystub');

const ConnectOutreach = () => import('@/views/ConnectOutreach');

// eslint-disable-next-line import/prefer-default-export
export function createRouter() {
  return _createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/onboarding',
        component: Marketing,
        children: OnboardingRoutes,
      },
      {
        path: '/auth',
        component: Marketing,
        children: AuthRoutes,
      },
      {
        path: '/app',
        component: App,
        children: AppRoutes,
      },
      {
        path: '/mission-control',
        component: MissionControl,
        children: MissionControlRoutes,
        beforeEnter: guardAdmin,
      },
      {
        path: '/tractor-beam',
        component: TractorBeam,
        children: TractorBeamRoutes,
        beforeEnter: guardTractorBeam,
      },
      {
        path: '/start',
        children: StartRoutes,
        component: Start,
      },
      // not a collection of routes - just a single one
      {
        path: '/invoices/:accessToken/number/:id',
        name: 'PublicInvoice',
        component: PublicInvoice,
        props: true,
      },
      {
        path: '/unsubscribe/:userID/:token',
        name: 'Unsubscribe',
        component: Unsubscribe,
        props: true,
      },
      {
        path: '/paystubs/:accessToken/number/:id',
        name: 'PublicPaystub',
        component: PublicPaystub,
        props: true,
      },
      // Admin-only tool for oauth into outreach.io,
      {
        path: '/oauth/outreach',
        name: 'ConnectOutreach',
        component: ConnectOutreach,
        beforeEnter: guardAdmin,
      },
      {
        path: '/',
        component: Marketing,
        children: MarketingRoutes,
      },
    ],
  });
}
