/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import {
  UserLink,
  UserLinkRequest,
  CreateUserLinkRequest,
  BulkChangeUserActiveStatusRQ,
  DeleteUserRQ,
  ListRequest,
  UserRequest,
  FilteredUserRequest,
  UserSearchRequest,
  UserExperienceRequest,
  User,
  UserSkillRequest,
  Skill,
  UpdateUserStripeRequest,
  UserExperience,
  CreateUserExperienceRequest,
  UserExperienceSkillRequest,
  FilteredCompanyRequest,
  JobRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isProduction,
  grpcAuthMetadata,
  getAfterID,
  protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  featuredSkillLimit: 3,
  usersPerPage: 25,
  // All below is fetched for a given user
  user: null,
  skills: null,
  featuredSkills: null,
  links: null,
  experiences: null,
  experienceSkills: {}, // map of experience ID to skills
  company: null,
  statsObj: null,

  referralPayoutSummaries: null,
  countCompaniesReferred: null,

  users: [],

  pending: false, // overall user model
  pendingSkills: false,
  pendingFeaturedSkills: false,
  pendingLinks: false,
  pendingExperiences: false,
  pendingExperienceSkills: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  page: null,
  query: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  list({ commit, state, rootState }, page) {
    commit('mutateReset');
    commit('mutateResetUsers');
    commit('mutatePending', true);

    const req = new ListRequest();
    req.setLimit(state.usersPerPage);
    req.setAfterId(getAfterID(page, state.usersPerPage));

    grpc.unary(MoonlightService.ListUsers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateUsers', {
            users: res.message.getUsersList(),
            page,
            query: null,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  search({ commit, state, rootState }, query) {
    commit('mutateReset');
    commit('mutateResetUsers');
    commit('mutatePending', true);
    commit('mutateQuery', query);

    const req = new UserSearchRequest();
    req.setQuery(query);

    grpc.unary(MoonlightService.SearchUsers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        // race condition detection
        if (state.query !== query) {
          return;
        }

        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateUsers', {
            users: res.message.getUsersList(),
            page: null,
            query,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, readAsUser }) {
    commit('mutateReset');
    commit('mutateResetUser');

    // Allow reading as other user. Useful for "view as public" and modifying permissions.
    if (readAsUser === null || readAsUser === undefined) {
      readAsUser = rootState.auth.currentUser ? rootState.auth.currentUser.id : 0;
    }

    // Dispatch reads of user skills, featured skills, and experiences
    dispatch('listSkills', id);
    dispatch('listFeaturedSkills', id);
    dispatch('listExperiences', id);
    dispatch('listLinks', id);
    dispatch('readStats', id);

    // check for cached user
    let cachedUser = null;
    _.forEach(state.users, (user) => {
      if (user.getId() === id) {
        // cache hit!
        cachedUser = user;
      }
    });
    if (cachedUser) {
      // Data from cache!
      commit('mutateUser', cachedUser);
      dispatch('readCompany', cachedUser.getCompanyId());
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new FilteredUserRequest();
    req.setTargetUserId(id);
    req.setActiveUserId(readAsUser);

    // Uses FilteredReadUser endpoint, which returns partial info for
    // users whose entire profile cannot be fetched (e.g. anonymous developers)
    grpc.unary(MoonlightService.FilteredReadUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateUser', res.message);
          dispatch('readCompany', res.message.getCompanyId());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listUsersWithOpenJobApplications({ commit, state, rootState }, { jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetUsers');
    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListUsersWithOpenJobApplications, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateUsers', {
            users: res.message.getUsersList(),
            page: null,
            query: null,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  bulkChangeUserActiveStatus({
    commit, state, rootState,
  }, { userEmails, shouldActivate, shouldDeactivate }) {
    commit('mutateReset');
    const req = new BulkChangeUserActiveStatusRQ();
    req.setEmailsList(userEmails);
    req.setShouldActivate(shouldActivate);
    req.setShouldDeactivate(shouldDeactivate);

    commit('mutatePending', true);
    grpc.unary(MoonlightService.BulkChangeUserActiveStatus, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          console.error('Error updating user status: ', res);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  delete({
    commit, state, rootState,
  }, user) {
    commit('mutateReset');

    // You have to run `getUser` before `updateUser`, and the IDs must match.
    // The intention is that you're updating the page that you are on, and this
    // adds safety. However, if you can think of a reason to remove this constraint,
    // then go for it!
    if (!state.user || state.user.getId() !== user.getId()) {
      commit('mutateError', {
        code: 4,
        msg: 'target user does not match delete query',
      });
      return;
    }

    commit('mutatePending', true);

    const req = new DeleteUserRQ();
    req.setEmail(state.user.getEmail());

    grpc.unary(MoonlightService.DeleteUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateUser', res.message);
          commit('mutateUpdateUserInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          console.error('Error deleting user: ', res);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, user) {
    commit('mutateReset');

    // You have to run `getUser` before `updateUser`, and the IDs must match.
    // The intention is that you're updating the page that you are on, and this
    // adds safety. However, if you can think of a reason to remove this constraint,
    // then go for it!
    if (!state.user || state.user.getId() !== user.getId()) {
      commit('mutateError', {
        code: 4,
        msg: 'target user does not match update query',
      });
      return;
    }

    // Cache this, and revert if save fails.
    // Strategy is to immediately update user, and
    // revert if the update fails.
    const origUser = new User(state.user.array.slice(0));
    commit('mutateUser', user);
    commit('mutateUpdateUserInCache', user);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new User(state.user.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update user with response, in case there were any side changes
          // (e.g. email being marked as unconfirmed)
          commit('mutateUser', res.message);
          commit('mutateUpdateUserInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);

          // If active user is same as updated user, update that store too
          if (rootState.auth.currentUser.id === res.message.getId()) {
            dispatch('auth/mutateCurrentUser', res.message, { root: true });
          }
          // Dispatch an update so that any nested stuff gets updated, like skills
          dispatch('listExperienceSkills', res.message.getId());
        } else {
          // Fail! Revert to original user!
          commit('mutateUser', origUser);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // updateStripe completes oauth to connect a stripe user ID
  updateStripe({
    commit, state, rootState, dispatch,
  }, code) {
    commit('mutateReset');

    // You have to run `getUser` before `updateUser`

    commit('mutatePending', true);

    const req = new UpdateUserStripeRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setCode(code);

    grpc.unary(MoonlightService.UpdateUserStripe, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update user with response, in case there were any side changes
          // (e.g. email being marked as unconfirmed)
          commit('mutateUser', res.message);
          commit('mutateUpdateUserInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);

          // If active user is same as updated user, update that store too
          if (rootState.auth.currentUser.id === res.message.getId()) {
            dispatch('auth/mutateCurrentUser', res.message, { root: true });
          }
        } else {
          // Fail!
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listSkills is intended to be called internally by readUser function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listSkills({
    commit, state, rootState, dispatch,
  }, userID) {
    commit('mutatePendingSkills', true);
    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkills', res.message.getSkillsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateSkills', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteLink assumes that the user is loaded and that the link exists on the user
  deleteLink({
    commit, state, rootState, dispatch,
  }, { linkID }) {
    commit('mutateReset');
    commit('mutatePendingLinks', true);

    // Copy link and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origLink = null;
    // eslint-disable-next-line
    for (let i = 0; i < state.links.length; i++) {
      if (state.links[i].getId() === linkID) {
        origLink = new UserLink(state.links[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveLinkInLinks', i);
      }
    }

    if (!origLink) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching link for id ${linkID}`;
    }

    const req = new UserLinkRequest();
    req.setId(linkID);
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);

    grpc.unary(MoonlightService.DeleteUserLink, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddLinkToLinks', origLink);
        }
      },
    });
  },
  // createLink adds a link to the current user
  createLink({
    commit, state, rootState, dispatch,
  }, { url }) {
    commit('mutateReset');
    commit('mutatePendingLinks', true);

    // Immediately add link to cache, without ID
    const link = new UserLink();
    link.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    link.setUrl(url);
    commit('mutateAddLinkToLinks', link);
    const insertID = state.links.length - 1;

    const req = new CreateUserLinkRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setUrl(url);

    grpc.unary(MoonlightService.CreateUserLink, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);

        if (res.status === grpc.Code.OK) {
          // Swap out link with one having ID
          commit('mutateUpdateLinkInLinks', {
            index: insertID,
            newLink: res.message,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveLinkInLinks', insertID);
        }
      },
    });
  },
  // listFeaturedSkills is intended to be called internally by readUser function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listFeaturedSkills({
    commit, state, rootState, dispatch,
  }, userID) {
    commit('mutatePendingFeaturedSkills', true);
    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserFeaturedSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingFeaturedSkills', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateFeaturedSkills', res.message.getSkillsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateFeaturedSkills', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listLinks is intended to be called internally by readUser function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listLinks({
    commit, state, rootState, dispatch,
  }, userID) {
    commit('mutatePendingLinks', true);
    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserLinks, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLinks', res.message.getUserLinksList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateLinks', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listExperiences is intended to be called internally by readUser function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listExperiences({
    commit, state, rootState, dispatch,
  }, userID) {
    commit('mutatePendingExperiences', true);
    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserExperiences, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExperiences', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateExperiences', res.message.getUserExperiencesList());
          // fetch skills
          dispatch('listExperienceSkills');
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateExperiences', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listExperienceSkills is intended to be called internally by listExperiences
  // Requires state.Experiences to be populated (because it uses those IDs)
  listExperienceSkills({ commit, state, rootState }) {
    // Use this to keep track of how many requests we have pending
    let requestCount = state.experiences?.length || 0;

    // If no experiences - return
    if (requestCount === 0) {
      return;
    }

    commit('mutatePendingExperienceSkills', true);

    // List skills for reach experience separately (in parallel)
    _.forEach(state.experiences, (experience) => {
      // If it's not in the DB - it doesn't have skills
      if (!experience.getId()) {
        return;
      }
      const req = new UserExperienceRequest();
      req.setUserId(experience.getUserId());
      req.setId(experience.getId());

      grpc.unary(MoonlightService.ListUserExperienceSkills, {
        metadata: grpcAuthMetadata(rootState.auth.session),
        debug: !isProduction(),
        request: req,
        host: grpcHost(),
        onEnd: (res) => {
          // See if this is the last request
          requestCount -= 1;
          if (requestCount <= 0) {
            commit('mutatePendingExperienceSkills', false);
          }

          if (res.status === grpc.Code.OK) {
            commit('mutateAddExperienceSkills', {
              experienceID: experience.getId(),
              skills: res.message.getSkillsList(),
            });
          } else {
            commit('mutateError', {
              code: res.status,
              msg: res.statusMessage,
            });
          }
        },
      });
    });
  },
  // createExperienceSkill adds a skill to the current user's experience
  createExperienceSkill({
    commit, state, rootState, dispatch,
  }, { experienceID, slug }) {
    commit('mutateReset');
    commit('mutatePendingExperienceSkills', true);

    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });
    if (!skillMatch) {
      return;
    }

    commit('mutateAddSkilltoExperienceSkills', { experienceID, skill: skillMatch });
    const insertID = state.experienceSkills[experienceID].length - 1;

    const req = new UserExperienceSkillRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setUserExperienceId(experienceID);
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.CreateUserExperienceSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExperienceSkills', false);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove skill
          commit('mutateRemoveSkillInExperienceSkills', insertID);
        }
      },
    });
  },
  // createExperienceSkill adds a skill to the current user's experience
  deleteExperienceSkill({
    commit, state, rootState, dispatch,
  }, { experienceID, slug }) {
    commit('mutateReset');
    commit('mutatePendingExperienceSkills', true);

    // Immediately remove skill from cache,
    let orig;
    let ix;
    // eslint-disable-next-line
    for (let i = 0; i < state.experienceSkills[experienceID].length; i++) {
      if (state.experienceSkills[experienceID][i].getSlug() === slug) {
        // cache hit!
        orig = state.experienceSkills[experienceID][i];
        ix = i;
      }
    }
    if (!orig) {
      return;
    }

    commit('mutateRemoveSkillInExperienceSkills', { experienceID, index: ix });

    const req = new UserExperienceSkillRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setUserExperienceId(experienceID);
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.DeleteUserExperienceSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExperienceSkills', false);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // re-add skill
          commit('mutateAddSkilltoExperienceSkills', { experienceID, skill: orig });
        }
      },
    });
  },
  // deleteSkill assumes that the user is loaded and that the skill exists on the user
  deleteSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Copy skill and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origSkill = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.skills.length; i++) {
      if (state.skills[i].getSlug() === slug) {
        origSkill = new Skill(state.skills[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveSkillInSkills', i);
      }
    }

    if (!origSkill) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching skill for id ${slug}`;
    }

    const req = new UserSkillRequest();
    req.setSkillSlug(slug);
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);

    grpc.unary(MoonlightService.DeleteUserSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddSkillToSkills', origSkill);
        }
      },
    });
  },
  // createSkill adds a skill to the current user
  createSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });
    if (!skillMatch) {
      return;
    }

    commit('mutateAddSkillToSkills', skillMatch);
    const insertID = state.skills.length - 1;

    const req = new UserSkillRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.CreateUserSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        commit('mutateSuccess', res.Status === grpc.Code.OK);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillInSkills', insertID);
        }
      },
    });
  },
  // createExperience adds an experience to the current user
  createExperience({
    commit, state, rootState, dispatch,
  }, e) {
    return new Promise((resolve, reject) => {
      commit('mutateReset');
      commit('mutatePendingExperiences', true);

      // Strategy: Assume experience is already in cache under id 0

      const req = new CreateUserExperienceRequest();
      // k, this part sucks
      req.setUserId(e.getUserId());
      req.setTitle(e.getTitle());
      req.setStartMonth(e.getStartMonth());
      req.setStartYear(e.getStartYear());
      req.setEndMonth(e.getEndMonth());
      req.setEndYear(e.getEndYear());
      req.setCurrent(e.getCurrent());
      req.setDescription(e.getDescription());

      grpc.unary(MoonlightService.CreateUserExperience, {
        metadata: grpcAuthMetadata(rootState.auth.session),
        debug: !isProduction(),
        request: req,
        host: grpcHost(),
        onEnd: (res) => {
          commit('mutatePendingExperiences', false);
          if (res.status === grpc.Code.OK) {
            // if id 0 in cache - update, otherewise append
            let ix = -1;

            commit('mutateAddExperienceIDToExperienceSkills', res.message.getId());
            // eslint-disable-next-line
            for (let i = 0; i < state.experiences.length; i++) {
              if (state.experiences[i].getId() === 0) {
                ix = i;
              }
            }

            if (ix > -1) {
              commit('mutateUpdateExperienceByIndex', { ix, data: res.message });
            } else {
              commit('mutateAppendExperience', res.message);
            }
            resolve(res);
          } else {
            commit('mutateError', {
              code: res.status,
              msg: res.statusMessage,
            });
            reject(res);
          }
        },
      });
    });
  },
  // updateExperience updates a user experience
  updateExperience({
    commit, state, rootState, dispatch,
  }, e) {
    commit('mutateReset');
    commit('mutatePendingExperiences', true);

    // Strategy: update in place, then revert if fails
    let ix = -1;
    let orig = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.experiences.length; i++) {
      if (state.experiences[i].getId() === e.getId()) {
        ix = i;
        orig = new UserExperience(state.experiences[i].array.slice(0));
      }
    }

    if (ix === -1) {
      return;
    }

    const req = new UserExperience(e.array.slice(0));
    commit('mutateUpdateExperienceByIndex', { ix, data: e });

    grpc.unary(MoonlightService.UpdateUserExperience, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExperiences', false);
        if (res.status !== grpc.Code.OK) {
          // revert
          commit('mutateUpdateExperienceByIndex', { ix, data: orig });

          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteExperience assumes that the user is loaded and that the skill exists on the user
  deleteExperience({
    commit, state, rootState, dispatch,
  }, { id }) {
    commit('mutateReset');
    commit('mutatePendingExperiences', true);

    // Copy experience and immediately remove it from cache. If update fails,
    // re-add it (reverts the change.
    let orig = null;
    // eslint-disable-next-line
    for (let i = 0; i < state.experiences.length; i++) {
      if (state.experiences[i].getId() === id) {
        orig = new UserExperience(state.experiences[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveExperienceInExperiences', i);
      }
    }

    if (!orig) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching experience for id ${id}`;
    }

    if (id === 0) {
      return;
    }

    const req = new UserExperienceRequest();
    req.setId(id);
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);

    grpc.unary(MoonlightService.DeleteUserExperience, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExperiences', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAppendExperience', orig);
        }
      },
    });
  },
  // deleteFeaturedSkill assumes that the user is loaded and that the skill exists on the user
  deleteFeaturedSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingFeaturedSkills', true);

    // Copy skill and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origSkill = null;
    // eslint-disable-next-line
    for (let i = 0; i < state.featuredSkills.length; i++) {
      if (state.featuredSkills[i].getSlug() === slug) {
        origSkill = new Skill(state.featuredSkills[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveSkillInFeaturedSkills', i);
      }
    }

    if (!origSkill) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching featured skill for id ${slug}`;
    }

    const req = new UserSkillRequest();
    req.setSkillSlug(slug);
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);

    grpc.unary(MoonlightService.DeleteUserFeaturedSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingFeaturedSkills', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddSkillToFeaturedSkills', origSkill);
        }
      },
    });
  },
  // createFeaturedSkill adds a skill to the current user
  createFeaturedSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingFeaturedSkills', true);
    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });

    commit('mutateAddSkillToFeaturedSkills', skillMatch);
    const insertID = state.featuredSkills.length - 1;

    const req = new UserSkillRequest();
    req.setUserId(state.user.getId() || rootState.auth.currentUser.id);
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.CreateUserFeaturedSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingFeaturedSkills', false);

        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillInFeaturedSkills', insertID);
        }
      },
    });
  },
  readCompany({
    commit, state, rootState, dispatch,
  }, id) {
    if (!id || id === 0) {
      // no company
      return;
    }

    commit('mutateReset');
    commit('mutatePendingCompany', true);

    let readAsUser;
    if (rootState.auth && rootState.auth.currentUser && rootState.auth.currentUser.id) {
      readAsUser = rootState.auth.currentUser.id;
    } else {
      readAsUser = 0;
    }

    const req = new FilteredCompanyRequest();
    req.setCompanyId(id);
    req.setActiveUserId(readAsUser);

    // Uses FilteredReadCompany endpoint, which returns partial info for
    // companies whose entire profile cannot be fetched (e.g. anonymous companies)
    grpc.unary(MoonlightService.FilteredReadCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingCompany', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCompany', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  sync({ commit, rootState }) {
    commit('mutateReset');
    commit('mutatePending', true);

    grpc.unary(MoonlightService.SyncUsers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  readStats({
    commit, state, rootState, dispatch,
  }, userID) {
    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ReadUserPublicStats, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateStatsObj', res.message.toObject());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listReferrals is intended to be called internally by readUser function.
  // Does not report PermissionDenied error (because user may not have read permission)
  listReferrals({
    commit, state, rootState, dispatch,
  }, userID) {
    commit('mutateReferralPayoutSummaries', null);
    commit('mutateCountCompaniesReferred', null);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserReferralPayouts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateCountCompaniesReferred', res.message.getCountCompaniesReferred());
          commit('mutateReferralPayoutSummaries', res.message.getSummariesList());
        } else if (res.status !== grpc.Code.PermissionDenied) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.pendingSkills = false;
    state.pendingFeaturedSkills = false;
    state.pendingLinks = false;
    state.pendingExperiences = false;
    state.pendingExperienceSkills = false;
    state.pendingCompany = false;

    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetUsers(state) {
    state.users = null;
    state.query = null;
    state.page = null;
  },
  mutateResetUser(state) {
    state.user = null;
    state.skills = null;
    state.featuredSkills = null;
    state.experiences = null;
    state.experienceSkills = {};
    state.company = null;
    state.statsObj = null;
  },
  mutateUsers(state, { users, page, query }) {
    state.users = users;
    state.page = page;
    state.query = query;
  },
  mutateQuery(state, query) {
    state.query = query;
  },
  mutateUser(state, user) {
    state.user = user;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutatePendingSkills(state, pending) {
    state.pendingSkills = pending;
  },
  mutatePendingFeaturedSkills(state, pending) {
    state.pendingFeaturedSkills = pending;
  },
  mutatePendingLinks(state, pending) {
    state.pendingLinks = pending;
  },
  mutatePendingExperiences(state, pending) {
    state.pendingExperiences = pending;
  },
  mutatePendingExperienceSkills(state, pending) {
    state.pendingExperienceSkills = pending;
  },
  mutatePendingCompany(state, pending) {
    state.pendingCompany = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateSkills(state, data) {
    state.skills = data;
  },
  mutateFeaturedSkills(state, data) {
    state.featuredSkills = data;
  },
  mutateLinks(state, data) {
    state.links = data;
  },
  mutateExperiences(state, data) {
    state.experiences = data;
  },
  mutateAddExperienceIDToExperienceSkills(state, id) {
    state.experienceSkills[id] = [];
    // Vue.set(state.experienceSkills, id, []);
  },
  mutateUpdateExperienceByIndex(state, { ix, data }) {
    // allow updating by id so that we can match 0
    state.experiences[ix] = data;
    // Vue.set(state.experiences, ix, data);
  },
  mutateAppendExperience(state, experience) {
    state.experiences.push(experience);
  },
  mutateStatsObj(state, data) {
    state.statsObj = data;
  },
  mutateExperienceSkills(state, data) {
    // data is map { experienceID => []skills }
    state.experienceSkills = data;
  },
  mutateRemoveExperienceInExperiences(state, index) {
    state.experiences.splice(index, 1);
  },
  // mutateAddExperienceSkills adds skills for a given experience
  mutateAddExperienceSkills(state, { experienceID, skills }) {
    state.experienceSkills[experienceID] = skills;
    // Cannot directly modify to fix reactivity!!!
    // https://vuejs.org/v2/guide/list.html#Caveats
    // Vue.set(state.experienceSkills, experienceID, skills);
  },
  // mutateAddExperienceSkills adds skills for a given experience
  mutateAddSkilltoExperienceSkills(state, { experienceID, skill }) {
    state.experienceSkills[experienceID].push(skill);
  },
  mutateRemoveSkillInExperienceSkills(state, { experienceID, index }) {
    state.experienceSkills[experienceID].splice(index, 1);
  },
  mutateUpdateUserInCache(state, user) {
    // remove user from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.users.length; i++) {
      if (state.users[i].getId() === user.getId()) {
        state.users[i] = user;
        // Vue.set(state.users, i, user);
      }
    }
  },
  mutateRemoveLinkInLinks(state, index) {
    state.links.splice(index, 1);
  },
  mutateUpdateLinkInLinks(state, { index, newLink }) {
    state.links.splice(index, 1, newLink);
  },
  mutateAddLinkToLinks(state, newLink) {
    state.links.push(newLink);
  },
  mutateRemoveSkillInSkills(state, index) {
    state.skills.splice(index, 1);
  },
  mutateUpdateSkillInSkills(state, { index, newSkill }) {
    state.skills.splice(index, 1, newSkill);
  },
  mutateAddSkillToSkills(state, newSkill) {
    state.skills.push(newSkill);
  },
  mutateRemoveSkillInFeaturedSkills(state, index) {
    state.featuredSkills.splice(index, 1);
  },
  mutateUpdateSkillInFeaturedSkills(state, { index, newSkill }) {
    state.featuredSkills.splice(index, 1, newSkill);
  },
  mutateAddSkillToFeaturedSkills(state, newSkill) {
    state.featuredSkills.push(newSkill);
  },
  mutateCompany(state, company) {
    state.company = company;
  },
  mutateCountCompaniesReferred(state, count) {
    state.countCompaniesReferred = count;
  },
  mutateReferralPayoutSummaries(state, payouts) {
    state.referralPayoutSummaries = payouts;
  },
};

const getters = {
  getUsers(state) {
    return state.users;
  },
  getExperienceSkills(state) {
    return state.experienceSkills;
  },
  getUserObj(state) {
    if (state.user === null) {
      return null;
    }
    // directly calling toObject occassionally causes issues
    const copy = new User(state.user.array.slice(0));

    const obj = copy.toObject();
    obj.createdAt = protoTimestampToDate(copy.getCreatedAt());
    obj.updatedAt = protoTimestampToDate(copy.getUpdatedAt());
    obj.canApplyAfter = protoTimestampToDate(copy.getCanApplyAfter());
    return obj;
  },
  getCompanyObj(state) {
    if (state.company === null) {
      return null;
    }

    const obj = state.company.toObject();
    obj.createdAt = protoTimestampToDate(obj.getCreatedAt());
    obj.updatedAt = protoTimestampToDate(obj.getUpdatedAt());
    return obj;
  },
  getIsClient(state) {
    if (!state.user) {
      return null;
    }
    return state.user.getCompanyId() && state.user.getCompanyId() > 0;
  },
  getCompanyDisplayName(state) {
    if (!state.company) {
      return null;
    }
    if (state.company.getName()) {
      return state.company.getName();
    }
    return state.company.getTagline();
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
