/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { RecommendedDevelopersRequest } from '@/protoc/moonlight_pb';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  date: null,
  profiles: [],

  pending: false,
  pendingExtension: false,

  seekingContracts: true,
  seekingEmployment: true,

  errCode: null,
  errMsg: null,

  companyID: null,

  limit: 12,
};

const actions = {
  list({ commit, state, rootState }, { companyID, seekingContracts, seekingEmployment }) {
    commit('mutateReset');
    commit('mutateResults', null);
    commit('mutatePending', true);
    commit('mutateSeekingContracts', seekingContracts);
    commit('mutateSeekingEmployment', seekingEmployment);
    commit('mutateCompanyID', companyID);

    const req = new RecommendedDevelopersRequest();

    req.setCompanyId(companyID);
    req.setLimit(state.limit);
    req.setSeekingContracts(seekingContracts);
    req.setSeekingEmployment(seekingEmployment);
    req.setOffset(0); // first query

    grpc.unary(MoonlightService.ListRecommendedDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateResults', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  extend({ commit, state, rootState }) {
    commit('mutateReset');
    commit('mutatePendingExtension', true);

    const req = new RecommendedDevelopersRequest();

    req.setCompanyId(state.companyID);
    req.setLimit(state.limit);
    req.setSeekingContracts(state.seekingContracts);
    req.setSeekingEmployment(state.seekingEmployment);
    req.setOffset(state.profiles.length);

    grpc.unary(MoonlightService.ListRecommendedDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExtension', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateAppendResults', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateResults(state, msg) {
    state.profiles = [];
    if (!msg) {
      return;
    }

    _.each(msg.getResultsList(), (p) => {
      let exists = false;
      _.each(state.profiles, (pp) => {
        if (p.getUser().getId() === pp.user.id) {
          exists = true;
        }
      });
      if (!exists) {
        state.profiles.push(p.toObject());
      }
    });
  },
  mutateAppendResults(state, msg) {
    _.each(msg.getResultsList(), (p) => {
      state.profiles.push(p.toObject());
    });
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutatePendingExtension(state, pending) {
    state.pendingExtension = pending;
  },
  mutateSeekingContracts(state, val) {
    state.seekingContracts = val;
  },
  mutateSeekingEmployment(state, val) {
    state.seekingEmployment = val;
  },
  mutateCompanyID(state, val) {
    state.companyID = val;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
