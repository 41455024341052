import { guardAuthenticated } from '@/conf/guards';

const DevAdmin = () => import('@/views/onboarding/DevAdmin');

const ConnectStripe = () => import('@/views/onboarding/ConnectStripe');

const ConfirmStripe = () => import('@/views/onboarding/ConfirmStripe');

const ConfirmEmail = () => import('@/views/onboarding/ConfirmEmail');

export default [
  {
    path: 'contractor',
    redirect: 'developer',
  },
  {
    path: 'orbit',
    name: 'Onboarding.CreateOrbitAccount',
    redirect: { name: 'Start.Company.CreateAccount' },
  },
  {
    path: 'client',
    name: 'Onboarding.CreateClientAccount',
    redirect: { name: 'Start.Company.CreateAccount' },
  },
  {
    path: 'admin',
    name: 'Onboarding.DevAdmin',
    component: DevAdmin,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'connect-stripe',
    name: 'Onboarding.ConnectStripe',
    component: ConnectStripe,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'confirm-stripe',
    name: 'Onboarding.ConfirmStripe',
    component: ConfirmStripe,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'confirm-email',
    name: 'Onboarding.ConfirmEmail',
    component: ConfirmEmail,
    beforeEnter: guardAuthenticated,
  },
];
