/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import { UserRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

// Right now, this only supports listing. In the future, we could add full CRUD

const namespaced = true;

// initial state
const state = {
  usermetas: [],
  pending: false,
  errCode: null,
  errMsg: null,
};

const actions = {
  list({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserMetas, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateUserMetas', res.message.getUserMetasList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateUserMetas(state, usermetas) {
    state.usermetas = usermetas;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
};

const getters = {
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
