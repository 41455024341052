// eslint-disable-next-line import/prefer-default-export
export function searchSkills(skills, text = '') {
  // Results stitch together three groups of results:
  // 1. Name exact match (go === go)
  // 2. Query is long and is exact abbreviation (e.g. "aws" -> "amazon web services")
  // 3. Name starts with query (Py -> Python)
  // 4. Name contains query (Native -> React Native)
  // 5. Abbreviations contain exact match (golang === golang)
  // 6. Abbreviations starts with (go -> golang)
  // 7. Abbreviation contains query (lang -> golang)
  // Name exact results go in here
  const results = [];

  const nameAbbreviation = [];
  const nameStartingResults = [];
  const nameContainsResults = [];
  const abbreviationExactResults = [];
  const abbreviationStartingResults = [];
  const abbreviationContainsResults = [];

  // queries are lowercase
  const query = text.toLowerCase();

  for (const skill of skills) {
    const name = skill.getName().toLowerCase();
    const abbreviation = skill.getAbbreviation().toLowerCase();

    // Array
    const abbreviations = abbreviation.split(' ');

    if (!query) {
      // No search
      results.push(skill);
    } else if (name === query) {
      // Exact match
      results.push(skill);
    } else if (query.length >= 3 && name.match(/\b(\w)/g).join('') === query) {
      // Abbreviation detection
      nameAbbreviation.push(skill);
    } else if (name.startsWith(query)) {
      // Name starts with query

      // "When one option is the prefix of another, put the shortest one first."
      // e.g. React should come before React Native
      let inserted = false;
      // eslint-disable-next-line
        for (let i = 0; i < nameStartingResults; i++) {
        if (nameStartingResults[i].startsWith(name)) {
          nameStartingResults.splice(skill, i, 0);
          inserted = true;
        }
      }
      if (!inserted) {
        nameStartingResults.push(skill);
      }
    } else if (abbreviations.indexOf(query) > -1) {
      // Abbreviation exact match
      abbreviationExactResults.push(skill);
    } else if (name.includes(query)) {
      // Name includes query
      nameContainsResults.push(skill);
    } else if (abbreviation.startsWith(query)) {
      // Abbreviation starts with query
      abbreviationStartingResults.push(skill);
    } else if (abbreviation.includes(query)) {
      abbreviationContainsResults.push(skill);
    }
  }

  return results.concat(
    nameAbbreviation,
    nameStartingResults,
    abbreviationExactResults,
    nameContainsResults,
    abbreviationStartingResults,
    abbreviationContainsResults,
  );
}
