/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  grpc,
} from 'grpc-web-client';
import {
  PasswordResetRequest,
} from '@/protoc/moonlight_pb';
import {
  MoonlightService,
} from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isProduction,
  grpcAuthMetadata,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  pending: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  update({
    commit,
    state,
    rootState,
    dispatch,
  }, {
    oldPassword,
    newPassword,
  }) {
    // Only updates current user's password! (becuase it requires current password,
    // so only current user should know that . . . )

    commit('mutateReset');
    commit('mutatePending', true);

    const req = new PasswordResetRequest();
    req.setUserId(rootState.auth.currentUser.id);
    req.setOldPassword(oldPassword);
    req.setNewPassword(newPassword);

    grpc.unary(MoonlightService.UpdatePassword, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);

          // If active user is same as updated user, update that store too
          // TODO (Ashish): Noticed that res.message is undefined
          //   it appears to be like that on prod for a while
          //   fix later.
          if (rootState.auth.id === res.message.getId()) {
            commit('auth/mutateCurrentUser', res.message);
          }
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, {
    code,
    msg,
  }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
};

const getters = {
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
