// This module tracks signup and referrals for a user
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
const namespaced = true;

// initial state
const state = {
  landingPage: null,
  referrerURL: null,
  referredByUserID: null,
};

const actions = {
  attribute(
    {
      commit, state, rootState, dispatch,
    },
    { landingPage, referredByUserID, referrerURL },
  ) {
    // Never overwrite lapa if it's set
    if (!state.landingPage) {
      commit('mutateLandingPage', landingPage);
    }

    // same for landing page
    if (!state.referrerURL) {
      commit('mutateReferrerURL', referrerURL);
    }
    // Only overwrite referral if it's set
    if (referredByUserID) {
      commit('mutateReferredByUserID', referredByUserID);
    }
  },
};

const mutations = {
  mutateClear(state) {
    state.landingPage = null;
    state.referredByUserID = null;
  },
  mutateLandingPage(state, data) {
    state.landingPage = data;
  },
  mutateReferrerURL(state, data) {
    state.referrerURL = data;
  },
  mutateReferredByUserID(state, id) {
    state.referredByUserID = id;
  },
};

const getters = {
  getIsReferred(state) {
    return !!state.referredByUserID;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
