/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import { CompanyRequest, SubscriptionRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  subscriptions: [],
  subscriptionInvoices: [],
  // All below is fetched for a given company

  pendingSubscriptions: false, // overall company model
  pendingSubscriptionInvoices: false,

  errCode: null,
  errMsg: null,

  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  list({ commit, state, rootState }, companyID) {
    commit('mutateReset');
    commit('mutatePendingSubscriptions', true);

    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListSubscriptions, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSubscriptions', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSubscriptions', res.message.getSubscriptionsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listInvoices({ commit, state, rootState }, companyID) {
    commit('mutateReset');
    commit('mutatePendingSubscriptionInvoices', true);

    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListSubscriptionInvoices, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSubscriptionInvoices', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSubscriptionInvoices', res.message.getSubscriptionInvoicesList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  cancel({
    commit, state, rootState, dispatch,
  }, { companyID, subscriptionID }) {
    commit('mutateReset');

    commit('mutatePendingSubscription', true);

    const req = new SubscriptionRequest();
    req.setCompanyId(companyID);
    req.setId(subscriptionID);

    grpc.unary(MoonlightService.CancelSubscription, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSubscriptions', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateUpdateSubscriptionInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateClear(state) {
    state.subscriptions = [];
    state.subscriptionInvoices = [];
    state.pendingSubscriptions = false;
    state.pendingSubscriptionInvoices = false;

    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutatePendingSubscriptions(state, pending) {
    state.pendingSubscriptions = pending;
  },
  mutatePendingSubscriptionInvoices(state, pending) {
    state.pendingSubscriptionInvoices = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateSubscriptions(state, data) {
    state.subscriptions = [];
    _.each(data, (datum) => {
      const obj = datum.toObject();
      obj.createdAt = protoTimestampToDate(datum.getCreatedAt());
      obj.endedAt = protoTimestampToDate(datum.getEndedAt());
      state.subscriptions.push(obj);
    });
  },
  mutateSubscriptionInvoices(state, data) {
    state.subscriptionInvoices = [];
    _.each(data, (datum) => {
      const obj = datum.toObject();
      obj.date = protoTimestampToDate(datum.getDate());

      state.subscriptionInvoices.push(obj);
    });
  },

  mutateUpdateSubscriptionInCache(state, sub) {
    const obj = sub.toObject();
    obj.createdAt = protoTimestampToDate(sub.getCreatedAt());
    obj.endedAt = protoTimestampToDate(sub.getEndedAt());

    // remove company from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.subscriptions.length; i++) {
      if (state.subscriptions[i].id === obj.id) {
        state.subscriptions[i] = obj;
        // Vue.set(state.subsriptions, i, obj); <-- THIS HAD A TYPO!!!!
      }
    }
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
