/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  UserSessionRequest,
  UserRequest,
  CompanyRequest,
  ConfirmEmailWithCodeRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isLocal,
  isProduction,
  grpcAuthMetadata,
  protoJSONTimestampToDate,
  TermsLastUpdatedAt,
  protoTimestampObjectToDate,
} from '@/helpers';
import * as Sentry from '@sentry/vue';

const namespaced = true;

const state = {
  company: null,
  confirmationErrCode: null,
  confirmationErrMsg: null,
  confirmationPending: false,
  currentUser: null, // object, not proto
  errCode: null,
  errMsg: null,
  intercomUserHash: null,
  logoutRedirect: null,
  pending: false,
  session: null, // object, not proto

  // Payments
  paymentURL: null,
  paymentURLPending: false, // separate this for cleanliness

  // Upgrade
  upgradeModalJobID: null,
  upgradeModalOpen: false,
};

const mutations = {
  mutateSession(state, sessionProto) {
    if (sessionProto === null) {
      state.session = '';
      return;
    }
    // toObject is not json compliant - need to make some changes
    const session = sessionProto.toObject();
    session.user_id = session.userId;
    delete session.userId;
    session.valid_until = protoJSONTimestampToDate(session.validUntil);
    session.valid_until = protoJSONTimestampToDate(session.validUntil);
    delete session.validUntil;
    state.session = session;
  },
  mutateCurrentUser(state, userProto) {
    // We mutate to an object because protoc doesn't play nicely with cookies.
    // We put user in cookie so that we can access user info in guards if the
    // user info has not been populated yet.
    if (userProto == null) {
      state.currentUser = null;
    } else {
      const u = userProto.toObject();
      u.agreedToTermsAt = protoTimestampObjectToDate(u.agreedToTermsAt);
      state.currentUser = u;
      Sentry.setUser({ id: state.session.userId, email: state.currentUser.email });
    }
  },
  mutateEmailConfirmed(state) {
    if (state.currentUser) {
      const clone = { ...state.currentUser };
      clone.emailConfirmed = true;
      state.currentUser = clone;
    }
  },
  mutateCompany(state, companyProto) {
    // We mutate to an object because protoc doesn't play nicely with cookies.
    // We put user in cookie so that we can access user info in guards if the
    // user info has not been populated yet.
    if (companyProto) {
      state.company = companyProto.toObject();
    } else {
      state.company = '';
    }
  },
  mutateIntercomUserHash(state, hash) {
    state.intercomUserHash = hash;
  },
  mutateLogin(state, loginResponse) {
    const userProto = loginResponse.getUser();
    if (userProto) {
      state.currentUser = userProto.toObject();
    } else {
      state.currentUser = '';
    }

    const sessionProto = loginResponse.getSession();
    if (sessionProto) {
      // toObject is not json compliant - need to make some changes
      const session = sessionProto.toObject();
      Sentry.setUser({ id: session.userId, email: state.currentUser.email });

      session.user_id = session.userId;
      delete session.userId;
      session.valid_until = protoJSONTimestampToDate(session.validUntil);
      delete session.validUntil;
      state.session = session;
    } else {
      state.session = '';
    }

    const companyProto = loginResponse.getCompany();
    if (companyProto) {
      state.company = companyProto.toObject();
    } else {
      state.company = '';
    }

    state.intercomUserHash = loginResponse.getIntercomUserHash();
  },
  mutateUpgradeModalJobID(state, id) {
    state.upgradeModalJobID = id;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateConfirmationError(state, { code, msg }) {
    state.confirmationErrCode = code;
    state.confirmationErrMsg = msg;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateConfirmationPending(state, pending) {
    state.confirmationPending = pending;
  },
  mutatePaymentURLPending(state, pending) {
    state.paymentURLPending = pending;
  },
  mutatePaymentURL(state, url) {
    state.paymentURL = url;
  },
  // clears errors and pending state
  mutateReset(state) {
    state.errCode = null;
    state.errMsg = null;
    state.pending = false;
    state.paymentURLPending = false;
  },
  mutateResetConfirmation(state) {
    state.confirmationErrCode = null;
    state.confirmationErrMsg = null;
    state.confirmationPending = false;
  },
  mutateLogout(state) {
    // Delete local token
    state.currentUser = '';
    state.session = '';
    state.company = '';
    state.intercomUserHash = '';
    // Unset the user in Sentry logs
    Sentry.setUser({ id: '', email: '' });
  },
  mutateLogoutRedirect(state, logoutRedirect) {
    state.logoutRedirect = logoutRedirect;
  },
  mutateUpgradeModalOpen(state, val) {
    state.upgradeModalOpen = val;
  },
};

const actions = {
  createUser({ commit, state, getters }, createUserRequestProto) {
    commit('mutateReset');
    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateUser, {
      debug: !isProduction(),
      request: createUserRequestProto,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLogin', res.message);
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  createEmailConfirmationCode({ commit, state, rootState }) {
    commit('mutateReset');

    const userReq = new UserRequest();
    userReq.setUserId(state.session.user_id);

    grpc.unary(MoonlightService.CreateEmailConfirmationCode, {
      debug: !isProduction(),
      request: userReq,
      metadata: grpcAuthMetadata(rootState.auth.session),
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status !== grpc.Code.OK && res.status !== 8) {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  confirmEmailWithConfirmationCode({ rootState, commit, state }, code) {
    commit('mutateResetConfirmation');
    commit('mutateConfirmationPending', true);

    const req = new ConfirmEmailWithCodeRequest();
    req.setUserId(state.session.user_id);
    req.setCode(code);

    grpc.unary(MoonlightService.ConfirmEmailWithConfirmationCode, {
      debug: !isProduction(),
      request: req,
      metadata: grpcAuthMetadata(rootState.auth.session),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutateConfirmationPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateEmailConfirmed');
        } else {
          commit('mutateConfirmationError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  confirmEmailConfirmationCode({ commit, state }) {
    commit('mutateReset');
    commit('mutateConfirmationPending', true);

    const userReq = new UserRequest();
    userReq.setUserId(state.session.user_id);

    grpc.unary(MoonlightService.CreateEmailConfirmationCode, {
      debug: !isProduction(),
      request: userReq,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  logout(
    {
      commit,
      getters,
      rootState,
    },
    logoutRedirect = { name: 'Auth.Login' },
  ) {
    // action logout is different than mutate because
    // it attempts to delete the session on the server.
    // If user is currently logged in - this is the preferred
    // logout method.
    if (!getters.getIsLoggedIn) {
      return;
    }

    commit('mutateReset');
    commit('mutatePending', true);

    // Backup signout
    setTimeout(() => commit('mutateLogout'), 2000);

    // Delete session at api
    const req = new UserSessionRequest();
    req.setUserId(getters.getSession.user_id);
    req.setId(getters.getSession.id);

    // Delete the current session so that it cannot be reused
    grpc.unary(MoonlightService.DeleteUserSession, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: () => {
        commit('mutatePending', false);
        commit('mutateLogout');
        commit('mutateLogoutRedirect', logoutRedirect);
      },
    });
  },
  refreshCompany({ commit, rootState, state }) {
    // This is used for account upgrades
    // Poll company
    const companyReq = new CompanyRequest();
    companyReq.setCompanyId(state.currentUser.companyId);

    grpc.unary(MoonlightService.ReadCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: companyReq,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateCompany', res.message);
        } else {
          // TODO - send a message saying API is having trouble?
        }
      },
    });
  },
  refresh({
    commit, getters, rootState, state,
  }) {
    if (!getters.getIsLoggedIn) {
      return;
    }

    // 1. Make sure session is still valid. If it isn't - log out and redirect to login
    const seshReq = new UserSessionRequest();
    seshReq.setUserId(getters.getSession.user_id);
    seshReq.setId(getters.getSession.id);
    grpc.unary(MoonlightService.ReadUserSession, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: seshReq,
      host: grpcHost(),
      onEnd: (res) => {
        // If session not found - logout.
        // (Don't logout if there's a load balancer error or something)
        // If auth is invalid - then it will show provided_auth_invalid with bad request
        if (res.status === grpc.Code.NotFound || res.statusMessage === 'provided_auth_invalid') {
          // Auth no longer valid. Logout.
          commit('mutateLogout');
        }
      },
    });

    // 2. Update user
    const userReq = new UserRequest();
    userReq.setUserId(state.session.user_id);

    grpc.unary(MoonlightService.ReadUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: userReq,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateCurrentUser', res.message);
        } else {
          // TODO - send a message saying API is having trouble?
        }
      },
    });

    // Update company
    if (state.currentUser.companyId === 0) {
      // User might have been removed from company
      commit('mutateCompany', '');
      return;
    }

    // Poll company
    const companyReq = new CompanyRequest();
    companyReq.setCompanyId(state.currentUser.companyId);

    grpc.unary(MoonlightService.ReadCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: companyReq,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateCompany', res.message);
        } else {
          // TODO - send a message saying API is having trouble?
        }
      },
    });
  },
  // Set up this action so that it can be called externally
  mutateCurrentUser({ commit, state, rootState }, userProto) {
    commit('mutateCurrentUser', userProto);
  },
  createPaymentURL({ commit, state, rootState }) {
    commit('mutateReset');
    commit('mutatePaymentURLPending', true);
    const req = new UserRequest();
    req.setUserId(state.currentUser.id);

    grpc.unary(MoonlightService.CreateStripeExpressPaymentsURL, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePaymentURLPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutatePaymentURL', res.message.getUrl());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  createPullRequestPaymentURL({ commit, state, rootState }) {
    commit('mutateReset');
    commit('mutatePaymentURLPending', true);
    const req = new UserRequest();
    req.setUserId(state.currentUser.id);

    grpc.unary(MoonlightService.CreatePullRequestStripeExpressPaymentsURL, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePaymentURLPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutatePaymentURL', res.message.getUrl());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  createLegacyPaymentURL({ commit, state, rootState }) {
    commit('mutateReset');
    commit('mutatePaymentURLPending', true);
    const req = new UserRequest();
    req.setUserId(state.currentUser.id);

    grpc.unary(MoonlightService.CreateLegacyStripeExpressPaymentsURL, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePaymentURLPending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutatePaymentURL', res.message.getUrl());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
};

const getters = {
  getLogoutRedirect(state) {
    return state.logoutRedirect;
  },
  getIsLoggedIn(state) {
    return state.session !== null && state.session !== '';
  },
  getSession(state) {
    return state.session;
  },
  getCurrentUser(state) {
    return state.currentUser;
  },
  getCurrentUserID(state) {
    if (state.currentUser) {
      return state.currentUser.id;
    }
    return null;
  },
  getCurrentCompanyID(state) {
    if (state.currentUser && state.currentUser.companyId > 0) {
      return Number(state.currentUser.companyId);
    }
    return null;
  },
  getCompanyIsReferred(state) {
    if (state.company) {
      return !!state.company.referredByUserId;
    }
    return false;
  },
  getCompanyName(state) {
    if (state.company) {
      return state.company.name;
    }
    return null;
  },
  getIsOnLegacySubscription(state) {
    if (state.company) {
      return state.company.legacySubscription;
    }
    return true;
  },
  getIsPaidSubscriber(state) {
    if (state.company) {
      return state.company.paidSubscriber;
    }
    return false;
  },
  getHasActiveContingencyAgreement(state) {
    if (state.company) {
      return state.company.activeContingencyAgreement;
    }
    return false;
  },
  getHasFullAccess(state) {
    // admin, paid subscriber, or active contingency agreement
    if (!state.currentUser) {
      return false;
    }
    if (state.currentUser.admin) {
      return true;
    }

    if (state.company) {
      return (
        state.company.activeContingencyAgreement
        || (
          state.company.legacySubscription && state.company.paidSubscriber
        )
          || (!state.company.legacySubscription && state.company.companyTakeRate)
      );
    }

    return false;
  },
  getIsCanceledSubscriber(state) {
    if (state.company) {
      return state.company.canceledSubscriber;
    }
    return false;
  },
  getCompanyObj(state) {
    if (state.company) {
      return state.company;
    }
    return null;
  },
  getFullName(state) {
    if (!state.currentUser) {
      return null;
    }
    return state.currentUser.fullName;
  },
  getEmail(state) {
    if (!state.currentUser) {
      return null;
    }
    return state.currentUser.email;
  },
  getFirstName(state) {
    if (!state.currentUser) {
      return null;
    }
    const names = state.currentUser.fullName.split(' ');
    if (names.length > 1) {
      return names.slice(0, -1).join(' ');
    }
    return state.currentUser.fullName;
  },
  getLastName(state) {
    if (!state.currentUser) {
      return null;
    }
    const names = state.currentUser.fullName.split(' ');
    if (names.length > 1) {
      return names.slice(1).join(' ');
    }
    return '';
  },
  getIsClient(state) {
    if (!state.currentUser) {
      return null;
    }
    return state.currentUser.companyId && state.currentUser.companyId > 0;
  },
  requireEmailConfirmation(state) {
    if (!state.currentUser) {
      return false;
    }
    return !state.currentUser.emailConfirmed;
  },
  getIsDeveloper(state) {
    if (!state.currentUser) {
      return null;
    }
    return state.currentUser.developer;
  },
  hasTractorBeamAccess(state) {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.admin || state.currentUser.hasTractorBeamAccess;
  },
  isAdmin(state) {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.admin;
  },
  promptToSubscribe(state) {
    // whether current user needs to set up a subscription
    if (!state.currentUser || !state.company) {
      return false;
    }
    if (state.currentUser.admin) {
      return false;
    }
    return (
      state.currentUser.companyId > 0
      && (
        state.company.legacySubscription
        && !state.company.paidSubscriber
        && !state.company.activeContingencyAgreement
      )
    );
  },
  blockingActionRequired(state) {
    // whether current user needs to (1) reconnect stripe or (2) agree to Terms of Service
    // that justifies locking them out until completed
    if (state.currentUser) {
      // Developers without a Stripe account must reconnect
      // eslint-disable-next-line
      if (state.currentUser.developer && !state.currentUser.mweStripeUserId) {
        return true;
      }

      // Users who have not agreed to Terms of Service must agree
      if (state.currentUser.agreedToTermsAt < TermsLastUpdatedAt) {
        return true;
      }
    }
    return false;
  },
  hasPaymentsConfigured(state) {
    if (!state.currentUser) {
      return false;
    }
    return !!state.currentUser.mweStripeUserId;
  },
  stripeBusinessName(state) {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.stripeBusinessName;
  },
  heardThroughBroadcasts(state) {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.heardThrough === 'broadcasts';
  },
  getIsFreeEmailProvider(state) {
    if (!state.currentUser) {
      return false;
    }
    const providers = [
      'gmail.com',
      'googlemail.com',
      'protonmail.com',
      'pm.me',
      'yahoo.com',
      'yahoo.co.uk',
      'outlook.com',
      'hotmail.com',
      'mail.com',
      'aol.com',
      'yandex.ru',
      'icloud.com',
      'fastmail.com',
    ]; // protonmail

    let match = false;
    _.each(providers, (p) => {
      if (state.currentUser.email.endsWith(`@${p}`)) {
        match = true;
      }
    });
    return match;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
