/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import { UserRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';
// Note - "applicants" means "developers who have applied to join"

const namespaced = true;

// initial state
const state = {
  applicant: null,
  applicants: [],
  pending: false,
  notFound: false,
  errCode: null,
  errMsg: null,
  successMsg: null, // Watch this to know when an applicant has been approved/denied
};

const actions = {
  listApplicants({ commit, rootState }) {
    commit('mutateReset');

    grpc.unary(MoonlightService.ListApplicants, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateApplicants', res.message.getUsersList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  readApplicant({ commit, state, rootState }, id) {
    commit('mutateReset');
    commit('mutateApplicant', null);

    // Fetch user
    commit('mutatePending', true);
    const req = new UserRequest();
    req.setUserId(id);

    grpc.unary(MoonlightService.ReadUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          if (!res.message.getOpenDeveloperApplication()) {
            // Not a developer applicant
            commit('mutateError', {
              code: 4,
              msg: 'User does not have an open developer applicant',
            });
          } else {
            commit('mutateApplicant', res.message);
          }
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  updateDecision({ commit, state, rootState }, decision) {
    // approved is a bool of whether applicant accepted
    commit('mutateReset');

    if (!state.applicant) {
      commit('mutateError', { code: 4, msg: 'no target applicant' });
      return;
    }

    commit('mutatePending', true);
    commit('mutateRemoveApplicantFromCache', state.applicant);

    const req = state.applicant;
    req.setOpenDeveloperApplication(false);

    if (decision === 'approved') {
      req.setDeveloper(true);
    } else if (decision === 'denied') {
      // THIS IS WHERE WE SAY YOU CAN REAPPLY IN 3 MONTHS
      const ts = req.getCanApplyAfter();

      // calculate as date
      const d = new Date();
      d.setMonth(d.getMonth() + 3);
      ts.setSeconds(Math.floor(d.getTime() / 1000));

      req.setCanApplyAfter(ts);
    } else if (decision !== 'request-more-info') {
      // eslint-disable-next-line
      throw 'invalid decision';
    }

    grpc.unary(MoonlightService.UpdateUser, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          const name = res.message.getFullName();
          if (decision === 'approved') {
            commit('mutateSuccessMsg', `${name} approved 👏`);
          } else if (decision === 'denied') {
            commit('mutateSuccessMsg', `${name} denied 🛑`);
          } else {
            commit('mutateSuccessMsg', `More info requested from ${name} ✌️`);
          }
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.successMsg = null;
  },
  mutateApplicants(state, applicants) {
    state.applicants = applicants;
  },
  mutateApplicant(state, applicant) {
    state.applicant = applicant;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccessMsg(state, msg) {
    state.successMsg = msg;
  },
  mutateRemoveApplicantFromCache(state, applicant) {
    // remove user from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.applicants.length; i++) {
      if (state.applicants[i].getId() === applicant.getId()) {
        state.applicants.splice(i, 1);
      }
    }
  },
};
const getters = {
  getApplicants(state) {
    return state.applicants;
  },
  getPending(state) {
    return state.pending;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
