/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  CreateLineItemRequest,
  LineItem,
  LineItemRequest,
  JobRequest,
  InvoiceRequest,
  PublicInvoiceRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  lineItem: null,

  lineItems: [],

  pending: false, // overall lineItems model

  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  listJobLineItems({ commit, state, rootState }, { jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetLineItems');
    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListJobLineItems, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLineItems', res.message.getLineItemsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listJobUninvoicedLineItems({ commit, state, rootState }, { jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetLineItems');
    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListJobLineItems, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLineItems', res.message.getLineItemsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listInvoiceLineItems({ commit, state, rootState }, { invoiceID, jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetLineItems');
    commit('mutatePending', true);

    const req = new InvoiceRequest();
    req.setInvoiceId(invoiceID);
    req.setJobId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListInvoiceLineItems, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLineItems', res.message.getLineItemsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listInvoiceLineItemsWithAccessToken({ commit, state, rootState }, { invoiceID, accessToken }) {
    commit('mutateReset');
    commit('mutateResetLineItems');
    commit('mutatePending', true);

    const req = new PublicInvoiceRequest();
    req.setInvoiceId(invoiceID);
    req.setAccessToken(accessToken);

    grpc.unary(MoonlightService.ListInvoiceLineItemsWithAccessToken, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLineItems', res.message.getLineItemsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, jobID, companyID }) {
    commit('mutateReset');

    // Only reset if fetching new line item. Prevents flicker.
    if (state.lineItem && state.lineItem.getId() !== id) {
      commit('mutateResetLineItem');
    }

    // check for cached lineItem
    let cachedLineItem = null;
    _.forEach(state.lineItems, (lineItem) => {
      if (lineItem.getId() === id) {
        // cache hit!
        cachedLineItem = lineItem;
      }
    });

    if (cachedLineItem) {
      // Data from cache!
      commit('mutateLineItem', cachedLineItem);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new LineItemRequest();
    req.setId(id);
    req.setJobId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ReadLineItem, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLineItem', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  create({
    commit, state, rootState, dispatch,
  }, req) {
    commit('mutateReset');

    commit('mutateLineItem', null);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateLineItem, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new CreateLineItemRequest(req.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateLineItem', res.message);

          // Strategy: Check cache for line item with id 0 and replace
          // otherwise - append
          let ix = -1;

          // eslint-disable-next-line
          for (let i = 0; i < state.lineItems.length; i++) {
            if (state.lineItems[i].getId() === 0) {
              ix = i;
            }
          }

          if (ix > -1) {
            commit('mutateUpdateLineItemByIndex', { ix, data: res.message });
          } else {
            commit('mutateAddLineItemToLineItems', res.message);
          }
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original lineItem!
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, lineItem) {
    commit('mutateReset');

    // Cache original val, and revert if save fails.
    // Strategy is to immediately update lineItem, and
    // revert if the update fails.
    let origLineItem;
    _.each(state.lineItems, (li) => {
      if (li.getId() === lineItem.getId()) {
        origLineItem = new LineItem(li.array.slice(0));
      }
    });
    commit('mutateLineItem', lineItem);
    commit('mutateUpdateLineItemInCache', lineItem);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateLineItem, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new LineItem(lineItem.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update lineItem with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateLineItem', res.message);
          commit('mutateUpdateLineItemInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original lineItem!
          commit('mutateLineItem', origLineItem);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  delete({
    commit, state, rootState, dispatch,
  }, id) {
    commit('mutateReset');
    commit('mutatePending', true);

    // Copy application and immediately remove it from cache. If update fails,
    // re-add it (reverts the change).
    let orig = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.lineItems.length; i++) {
      if (state.lineItems[i].getId() === id) {
        orig = new LineItem(state.lineItems[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveLineItemInLineItems', i);
        commit('mutateLineItem', null);
      }
    }

    // If not created - it's not on API
    if (id === 0) {
      return;
    }

    if (!orig) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching application for id ${id}`;
    }

    const req = new LineItemRequest();
    req.setId(id);
    req.setJobId(orig.getJobId());
    req.setCompanyId(orig.getCompanyId());

    grpc.unary(MoonlightService.DeleteLineItem, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddLineItemToLineItems', orig);
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.pendingSkills = false;

    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetLineItems(state) {
    state.lineItems = null;
  },
  mutateResetLineItem(state) {
    state.lineItem = null;
  },
  mutateLineItems(state, lineItems) {
    state.lineItems = lineItems;
  },
  mutateLineItem(state, lineItem) {
    state.lineItem = lineItem;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutatePendingSkills(state, pending) {
    state.pendingSkills = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateUpdateLineItemInCache(state, lineItem) {
    // remove lineItem from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.lineItems.length; i++) {
      if (state.lineItems[i].getId() === lineItem.getId()) {
        state.lineItems[i] = lineItem;
        // Vue.set(state.lineItems, i, lineItem);
      }
    }
  },
  mutateAddLineItemToLineItems(state, lineItem) {
    state.lineItems.push(lineItem);
  },
  mutateUpdateLineItemByIndex(state, { ix, data }) {
    state.lineItems[ix] = data;
    // Vue.set(state.lineItems, ix, data);
  },
  mutateRemoveLineItemInLineItems(state, ix) {
    state.lineItems.splice(ix, 1);
  },
};

const getters = {
  getUninvoicedHours(state) {
    if (!state.lineItems) {
      return null;
    }

    let res = 0.0;
    _.each(state.lineItems, (li) => {
      if (li.getInvoiceId() === 0) {
        res += li.getHours();
      }
    });
    return res;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
