// package: moonlight
// file: moonlight.proto

var jspb = require("google-protobuf");
var moonlight_pb = require("./moonlight_pb");
var google_protobuf_timestamp_pb = require("google-protobuf/google/protobuf/timestamp_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var MoonlightService = {
  serviceName: "moonlight.MoonlightService"
};
MoonlightService.CheckHealth = {
  methodName: "CheckHealth",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.DeleteUser = {
  methodName: "DeleteUser",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.DeleteUserRQ,
  responseType: moonlight_pb.User
};
MoonlightService.BulkChangeUserActiveStatus = {
  methodName: "BulkChangeUserActiveStatus",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.BulkChangeUserActiveStatusRQ,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUser = {
  methodName: "CreateUser",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateUserRequest,
  responseType: moonlight_pb.LoginResponse
};
MoonlightService.ListUsers = {
  methodName: "ListUsers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ListRequest,
  responseType: moonlight_pb.UserList
};
MoonlightService.ReadUser = {
  methodName: "ReadUser",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.User
};
MoonlightService.FilteredReadUser = {
  methodName: "FilteredReadUser",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.FilteredUserRequest,
  responseType: moonlight_pb.User
};
MoonlightService.UpdateUser = {
  methodName: "UpdateUser",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.User,
  responseType: moonlight_pb.User
};
MoonlightService.ListApplicants = {
  methodName: "ListApplicants",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.UserList
};
MoonlightService.SearchUsers = {
  methodName: "SearchUsers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSearchRequest,
  responseType: moonlight_pb.UserList
};
MoonlightService.CreateUserFileUploadURL = {
  methodName: "CreateUserFileUploadURL",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserFileUploadRequest,
  responseType: moonlight_pb.FileUploadURL
};
MoonlightService.CreateUserEmailChangeEmail = {
  methodName: "CreateUserEmailChangeEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserEmailRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateEmailConfirmationCode = {
  methodName: "CreateEmailConfirmationCode",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ConfirmEmailWithConfirmationCode = {
  methodName: "ConfirmEmailWithConfirmationCode",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ConfirmEmailWithCodeRequest,
  responseType: moonlight_pb.User
};
MoonlightService.UpdateUserEmailWithToken = {
  methodName: "UpdateUserEmailWithToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserEmailUpdateWithTokenRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.UpdateUserStripe = {
  methodName: "UpdateUserStripe",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UpdateUserStripeRequest,
  responseType: moonlight_pb.User
};
MoonlightService.CreateStripeExpressPaymentsURL = {
  methodName: "CreateStripeExpressPaymentsURL",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.StripeExpressPaymentsURL
};
MoonlightService.CreateLegacyStripeExpressPaymentsURL = {
  methodName: "CreateLegacyStripeExpressPaymentsURL",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.StripeExpressPaymentsURL
};
MoonlightService.CreatePullRequestStripeExpressPaymentsURL = {
  methodName: "CreatePullRequestStripeExpressPaymentsURL",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.StripeExpressPaymentsURL
};
MoonlightService.SyncUsers = {
  methodName: "SyncUsers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ReadUserPublicStats = {
  methodName: "ReadUserPublicStats",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserPublicStats
};
MoonlightService.ListTimezones = {
  methodName: "ListTimezones",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.TimezoneList
};
MoonlightService.ListCountryTimezones = {
  methodName: "ListCountryTimezones",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CountryTimezonesRequest,
  responseType: moonlight_pb.TimezoneList
};
MoonlightService.CreateUserSession = {
  methodName: "CreateUserSession",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateUserSessionRequest,
  responseType: moonlight_pb.LoginResponse
};
MoonlightService.ListUserSessions = {
  methodName: "ListUserSessions",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserSessionsInfoList
};
MoonlightService.ReadUserSession = {
  methodName: "ReadUserSession",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSessionRequest,
  responseType: moonlight_pb.UserSessionInfo
};
MoonlightService.DeleteUserSession = {
  methodName: "DeleteUserSession",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSessionRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.DeleteAllUserSessions = {
  methodName: "DeleteAllUserSessions",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreatePasswordResetEmail = {
  methodName: "CreatePasswordResetEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ForgottenPasswordResetRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreatePasswordResetToken = {
  methodName: "CreatePasswordResetToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PasswordResetTokenRequest,
  responseType: moonlight_pb.PasswordResetToken
};
MoonlightService.ReadPasswordResetInfo = {
  methodName: "ReadPasswordResetInfo",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PasswordResetInfoRequest,
  responseType: moonlight_pb.PasswordResetInfo
};
MoonlightService.UpdatePasswordWithToken = {
  methodName: "UpdatePasswordWithToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PasswordResetWithTokenRequest,
  responseType: moonlight_pb.LoginResponse
};
MoonlightService.UpdatePassword = {
  methodName: "UpdatePassword",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PasswordResetRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserMeta = {
  methodName: "CreateUserMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserMeta,
  responseType: moonlight_pb.UserMeta
};
MoonlightService.ListUserMetas = {
  methodName: "ListUserMetas",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserMetasList
};
MoonlightService.ReadUserMeta = {
  methodName: "ReadUserMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserMetaRequest,
  responseType: moonlight_pb.UserMeta
};
MoonlightService.UpdateUserMeta = {
  methodName: "UpdateUserMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserMeta,
  responseType: moonlight_pb.UserMeta
};
MoonlightService.CreateOrUpdateUserMeta = {
  methodName: "CreateOrUpdateUserMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserMeta,
  responseType: moonlight_pb.UserMeta
};
MoonlightService.DeleteUserMeta = {
  methodName: "DeleteUserMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserMetaRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserLink = {
  methodName: "CreateUserLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateUserLinkRequest,
  responseType: moonlight_pb.UserLink
};
MoonlightService.ListUserLinks = {
  methodName: "ListUserLinks",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserLinksList
};
MoonlightService.ReadUserLink = {
  methodName: "ReadUserLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserLinkRequest,
  responseType: moonlight_pb.UserLink
};
MoonlightService.UpdateUserLink = {
  methodName: "UpdateUserLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserLink,
  responseType: moonlight_pb.UserLink
};
MoonlightService.DeleteUserLink = {
  methodName: "DeleteUserLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserLinkRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateSkill = {
  methodName: "CreateSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateSkillRequest,
  responseType: moonlight_pb.Skill
};
MoonlightService.ListSkills = {
  methodName: "ListSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.ListSkillsWithCounts = {
  methodName: "ListSkillsWithCounts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.SkillsWithCountsList
};
MoonlightService.ReadSkill = {
  methodName: "ReadSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SkillRequest,
  responseType: moonlight_pb.Skill
};
MoonlightService.UpdateSkill = {
  methodName: "UpdateSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Skill,
  responseType: moonlight_pb.Skill
};
MoonlightService.DeleteSkill = {
  methodName: "DeleteSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserSkill = {
  methodName: "CreateUserSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListUserSkills = {
  methodName: "ListUserSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteUserSkill = {
  methodName: "DeleteUserSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserFeaturedSkill = {
  methodName: "CreateUserFeaturedSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListUserFeaturedSkills = {
  methodName: "ListUserFeaturedSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteUserFeaturedSkill = {
  methodName: "DeleteUserFeaturedSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserExperience = {
  methodName: "CreateUserExperience",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateUserExperienceRequest,
  responseType: moonlight_pb.UserExperience
};
MoonlightService.ListUserExperiences = {
  methodName: "ListUserExperiences",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserExperiencesList
};
MoonlightService.ReadUserExperience = {
  methodName: "ReadUserExperience",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperienceRequest,
  responseType: moonlight_pb.UserExperience
};
MoonlightService.UpdateUserExperience = {
  methodName: "UpdateUserExperience",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperience,
  responseType: moonlight_pb.UserExperience
};
MoonlightService.DeleteUserExperience = {
  methodName: "DeleteUserExperience",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperienceRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateUserExperienceSkill = {
  methodName: "CreateUserExperienceSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperienceSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListUserExperienceSkills = {
  methodName: "ListUserExperienceSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperienceRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteUserExperienceSkill = {
  methodName: "DeleteUserExperienceSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserExperienceSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ReadCompany = {
  methodName: "ReadCompany",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.Company
};
MoonlightService.UpdateCompany = {
  methodName: "UpdateCompany",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Company,
  responseType: moonlight_pb.Company
};
MoonlightService.ListCompanies = {
  methodName: "ListCompanies",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ListRequest,
  responseType: moonlight_pb.CompaniesList
};
MoonlightService.FilteredReadCompany = {
  methodName: "FilteredReadCompany",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.FilteredCompanyRequest,
  responseType: moonlight_pb.Company
};
MoonlightService.ReadCompanyJobStats = {
  methodName: "ReadCompanyJobStats",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.CompanyJobStats
};
MoonlightService.CreateCompanyMember = {
  methodName: "CreateCompanyMember",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateCompanyMemberRequest,
  responseType: moonlight_pb.User
};
MoonlightService.ListCompanyMembers = {
  methodName: "ListCompanyMembers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.UserList
};
MoonlightService.DeleteCompanyMember = {
  methodName: "DeleteCompanyMember",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.DeleteCompanyMemberRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateCompanyMeta = {
  methodName: "CreateCompanyMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyMeta,
  responseType: moonlight_pb.CompanyMeta
};
MoonlightService.ListCompanyMetas = {
  methodName: "ListCompanyMetas",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.CompanyMetasList
};
MoonlightService.ReadCompanyMeta = {
  methodName: "ReadCompanyMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyMetaRequest,
  responseType: moonlight_pb.CompanyMeta
};
MoonlightService.UpdateCompanyMeta = {
  methodName: "UpdateCompanyMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyMeta,
  responseType: moonlight_pb.CompanyMeta
};
MoonlightService.DeleteCompanyMeta = {
  methodName: "DeleteCompanyMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyMetaRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateOrUpdateCompanyMeta = {
  methodName: "CreateOrUpdateCompanyMeta",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyMeta,
  responseType: moonlight_pb.CompanyMeta
};
MoonlightService.CreateCompanyLink = {
  methodName: "CreateCompanyLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateCompanyLinkRequest,
  responseType: moonlight_pb.CompanyLink
};
MoonlightService.ListCompanyLinks = {
  methodName: "ListCompanyLinks",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.CompanyLinksList
};
MoonlightService.ReadCompanyLink = {
  methodName: "ReadCompanyLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyLinkRequest,
  responseType: moonlight_pb.CompanyLink
};
MoonlightService.UpdateCompanyLink = {
  methodName: "UpdateCompanyLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyLink,
  responseType: moonlight_pb.CompanyLink
};
MoonlightService.DeleteCompanyLink = {
  methodName: "DeleteCompanyLink",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyLinkRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateCompanySkill = {
  methodName: "CreateCompanySkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanySkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListCompanySkills = {
  methodName: "ListCompanySkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteCompanySkill = {
  methodName: "DeleteCompanySkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanySkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateCompanyBankAccountPaymentMethod = {
  methodName: "CreateCompanyBankAccountPaymentMethod",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyBankAccountPaymentMethodRequest,
  responseType: moonlight_pb.CompanyPaymentMethod
};
MoonlightService.CreateCompanyCardPaymentMethod = {
  methodName: "CreateCompanyCardPaymentMethod",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyCardPaymentMethodRequest,
  responseType: moonlight_pb.CompanyPaymentMethod
};
MoonlightService.CreateCompanyPaymentMethod = {
  methodName: "CreateCompanyPaymentMethod",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyPaymentMethod,
  responseType: moonlight_pb.CompanyPaymentMethod
};
MoonlightService.ListCompanyPaymentMethods = {
  methodName: "ListCompanyPaymentMethods",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.CompanyPaymentMethodsList
};
MoonlightService.ReadCompanyPaymentMethod = {
  methodName: "ReadCompanyPaymentMethod",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyPaymentMethodRequest,
  responseType: moonlight_pb.CompanyPaymentMethod
};
MoonlightService.UpdateCompanyPaymentMethod = {
  methodName: "UpdateCompanyPaymentMethod",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyPaymentMethod,
  responseType: moonlight_pb.CompanyPaymentMethod
};
MoonlightService.SearchDevelopers = {
  methodName: "SearchDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SearchDevelopersRequest,
  responseType: moonlight_pb.SearchDevelopersResponse
};
MoonlightService.ListRecommendedDevelopers = {
  methodName: "ListRecommendedDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.RecommendedDevelopersRequest,
  responseType: moonlight_pb.SearchDevelopersResponse
};
MoonlightService.ListLandingPageDevelopers = {
  methodName: "ListLandingPageDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ListLandingPageDevelopersRequest,
  responseType: moonlight_pb.ListLandingPageDevelopersResponse
};
MoonlightService.ListCityLandingPageDevelopers = {
  methodName: "ListCityLandingPageDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ListCityLandingPageDevelopersRequest,
  responseType: moonlight_pb.ListCityLandingPageDevelopersResponse
};
MoonlightService.ListCountryLandingPageDevelopers = {
  methodName: "ListCountryLandingPageDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ListCountryLandingPageDevelopersRequest,
  responseType: moonlight_pb.ListCountryLandingPageDevelopersResponse
};
MoonlightService.ListDevelopersForHomepage = {
  methodName: "ListDevelopersForHomepage",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.DevelopersForHomepageResponse
};
MoonlightService.ListExampleDevelopers = {
  methodName: "ListExampleDevelopers",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.ExampleDevelopersResponse
};
MoonlightService.ReadLiveStats = {
  methodName: "ReadLiveStats",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.LiveStatsResponse
};
MoonlightService.CreateJob = {
  methodName: "CreateJob",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateJobRequest,
  responseType: moonlight_pb.Job
};
MoonlightService.ReadJob = {
  methodName: "ReadJob",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.Job
};
MoonlightService.UpdateJob = {
  methodName: "UpdateJob",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Job,
  responseType: moonlight_pb.Job
};
MoonlightService.ListCompanyJobs = {
  methodName: "ListCompanyJobs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListCompanyJobSummaries = {
  methodName: "ListCompanyJobSummaries",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.JobSummariesList
};
MoonlightService.ListCompanyOpenJobs = {
  methodName: "ListCompanyOpenJobs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListDeveloperJobs = {
  methodName: "ListDeveloperJobs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListDeveloperJobSummaries = {
  methodName: "ListDeveloperJobSummaries",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.JobSummariesList
};
MoonlightService.ListDeveloperOpenJobMatches = {
  methodName: "ListDeveloperOpenJobMatches",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListDeveloperDismissedJobMatches = {
  methodName: "ListDeveloperDismissedJobMatches",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListUnapprovedJobs = {
  methodName: "ListUnapprovedJobs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.JobsList
};
MoonlightService.ListOngoingJobs = {
  methodName: "ListOngoingJobs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.JobSummariesList
};
MoonlightService.CreateJobSkill = {
  methodName: "CreateJobSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListJobSkills = {
  methodName: "ListJobSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteJobSkill = {
  methodName: "DeleteJobSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateJobApplication = {
  methodName: "CreateJobApplication",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateApplicationRequest,
  responseType: moonlight_pb.Application
};
MoonlightService.UpdateJobApplication = {
  methodName: "UpdateJobApplication",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Application,
  responseType: moonlight_pb.Application
};
MoonlightService.ReadJobApplication = {
  methodName: "ReadJobApplication",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ApplicationRequest,
  responseType: moonlight_pb.Application
};
MoonlightService.DeleteJobApplication = {
  methodName: "DeleteJobApplication",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ApplicationRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListJobApplications = {
  methodName: "ListJobApplications",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.ApplicationsList
};
MoonlightService.ListDeveloperOpenApplications = {
  methodName: "ListDeveloperOpenApplications",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.ApplicationsList
};
MoonlightService.ListUsersWithOpenJobApplications = {
  methodName: "ListUsersWithOpenJobApplications",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.UserList
};
MoonlightService.CreateSlackGroupForJobApplication = {
  methodName: "CreateSlackGroupForJobApplication",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ApplicationRequest,
  responseType: moonlight_pb.Application
};
MoonlightService.ReadDeveloperJobMatch = {
  methodName: "ReadDeveloperJobMatch",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.DeveloperJobMatchRequest,
  responseType: moonlight_pb.DeveloperJobMatch
};
MoonlightService.UpdateDeveloperJobMatch = {
  methodName: "UpdateDeveloperJobMatch",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.DeveloperJobMatch,
  responseType: moonlight_pb.DeveloperJobMatch
};
MoonlightService.CreateLineItem = {
  methodName: "CreateLineItem",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateLineItemRequest,
  responseType: moonlight_pb.LineItem
};
MoonlightService.UpdateLineItem = {
  methodName: "UpdateLineItem",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LineItem,
  responseType: moonlight_pb.LineItem
};
MoonlightService.ReadLineItem = {
  methodName: "ReadLineItem",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LineItemRequest,
  responseType: moonlight_pb.LineItem
};
MoonlightService.DeleteLineItem = {
  methodName: "DeleteLineItem",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LineItemRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListJobLineItems = {
  methodName: "ListJobLineItems",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.LineItemsList
};
MoonlightService.ListJobUninvoicedLineItems = {
  methodName: "ListJobUninvoicedLineItems",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.LineItemsList
};
MoonlightService.CreateInvoice = {
  methodName: "CreateInvoice",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.Invoice
};
MoonlightService.UpdateInvoice = {
  methodName: "UpdateInvoice",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Invoice,
  responseType: moonlight_pb.Invoice
};
MoonlightService.ReadInvoice = {
  methodName: "ReadInvoice",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.InvoiceRequest,
  responseType: moonlight_pb.Invoice
};
MoonlightService.ReadInvoiceWithAccessToken = {
  methodName: "ReadInvoiceWithAccessToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PublicInvoiceRequest,
  responseType: moonlight_pb.PublicInvoiceSummary
};
MoonlightService.ReadInvoiceByID = {
  methodName: "ReadInvoiceByID",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.GetInvoiceByID,
  responseType: moonlight_pb.Invoice
};
MoonlightService.ListJobInvoices = {
  methodName: "ListJobInvoices",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.JobRequest,
  responseType: moonlight_pb.InvoicesList
};
MoonlightService.ListOpenInvoices = {
  methodName: "ListOpenInvoices",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.InvoicesList
};
MoonlightService.ListInvoiceLineItems = {
  methodName: "ListInvoiceLineItems",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.InvoiceRequest,
  responseType: moonlight_pb.LineItemsList
};
MoonlightService.ListInvoiceLineItemsWithAccessToken = {
  methodName: "ListInvoiceLineItemsWithAccessToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.PublicInvoiceRequest,
  responseType: moonlight_pb.LineItemsList
};
MoonlightService.GetKPIs = {
  methodName: "GetKPIs",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.KPIs
};
MoonlightService.CreateLead = {
  methodName: "CreateLead",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateLeadRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateLeads = {
  methodName: "CreateLeads",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.BulkCreateLeadsRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.UpdateLead = {
  methodName: "UpdateLead",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Lead,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListLeadsForManualReview = {
  methodName: "ListLeadsForManualReview",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.LeadList
};
MoonlightService.ValidEmail = {
  methodName: "ValidEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserEmailRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ReadLeadByEmail = {
  methodName: "ReadLeadByEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ReadLeadByEmailRequest,
  responseType: moonlight_pb.Lead
};
MoonlightService.CreateLeadSkill = {
  methodName: "CreateLeadSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LeadSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListLeadSkills = {
  methodName: "ListLeadSkills",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LeadRequest,
  responseType: moonlight_pb.SkillsList
};
MoonlightService.DeleteLeadSkill = {
  methodName: "DeleteLeadSkill",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.LeadSkillRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateHiringCompany = {
  methodName: "CreateHiringCompany",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateHiringCompanyRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListUserReferralPayouts = {
  methodName: "ListUserReferralPayouts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.UserReferralPayoutsList
};
MoonlightService.ScrapeHNWhoIsHiring = {
  methodName: "ScrapeHNWhoIsHiring",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ScrapeRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ScrapeHNWhoWantsToBeHired = {
  methodName: "ScrapeHNWhoWantsToBeHired",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ScrapeRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ScrapeHNFreelancerSeekingFreelancer = {
  methodName: "ScrapeHNFreelancerSeekingFreelancer",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ScrapeRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateMessage = {
  methodName: "CreateMessage",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Message,
  responseType: moonlight_pb.Message
};
MoonlightService.ListUserChannels = {
  methodName: "ListUserChannels",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.ChannelsList
};
MoonlightService.ListUserChannelMessages = {
  methodName: "ListUserChannelMessages",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserChannelMessagesRequest,
  responseType: moonlight_pb.ChannelMessagesList
};
MoonlightService.ReadOrCreateSlackGroup = {
  methodName: "ReadOrCreateSlackGroup",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.InterviewMessageRequest,
  responseType: moonlight_pb.SlackGroup
};
MoonlightService.ArchiveDirectInterviewSlackGroup = {
  methodName: "ArchiveDirectInterviewSlackGroup",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.InterviewMessageRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.NotifyNewMessages = {
  methodName: "NotifyNewMessages",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListSubscriptions = {
  methodName: "ListSubscriptions",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.SubscriptionsList
};
MoonlightService.SyncSubscription = {
  methodName: "SyncSubscription",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SubscriptionRequest,
  responseType: moonlight_pb.Subscription
};
MoonlightService.CancelSubscription = {
  methodName: "CancelSubscription",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SubscriptionRequest,
  responseType: moonlight_pb.Subscription
};
MoonlightService.ListSubscriptionInvoices = {
  methodName: "ListSubscriptionInvoices",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.SubscriptionInvoicesList
};
MoonlightService.SyncSubscriptionInvoice = {
  methodName: "SyncSubscriptionInvoice",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SubscriptionInvoiceRequest,
  responseType: moonlight_pb.SubscriptionInvoice
};
MoonlightService.CreateCompanyCheckoutSession = {
  methodName: "CreateCompanyCheckoutSession",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyCheckoutRequest,
  responseType: moonlight_pb.CompanyCheckoutSession
};
MoonlightService.ReadSkillSuggestionModel = {
  methodName: "ReadSkillSuggestionModel",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.SkillSuggestionModel
};
MoonlightService.CreateTransactionalEmail = {
  methodName: "CreateTransactionalEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateTransactionalEmailRequest,
  responseType: moonlight_pb.Email
};
MoonlightService.CreateBasicEmail = {
  methodName: "CreateBasicEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateEmailRequest,
  responseType: moonlight_pb.Email
};
MoonlightService.UpdateEmail = {
  methodName: "UpdateEmail",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Email,
  responseType: moonlight_pb.Email
};
MoonlightService.ReadEmailBySparkpostID = {
  methodName: "ReadEmailBySparkpostID",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.SparkpostEmailRequest,
  responseType: moonlight_pb.Email
};
MoonlightService.ListUserEmailActivities = {
  methodName: "ListUserEmailActivities",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.EmailActivitiesList
};
MoonlightService.Unsubscribe = {
  methodName: "Unsubscribe",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.EmailUnsubscribeRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateEmailClick = {
  methodName: "CreateEmailClick",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.EmailClick,
  responseType: moonlight_pb.EmailClick
};
MoonlightService.CreateEmailOpen = {
  methodName: "CreateEmailOpen",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.EmailOpen,
  responseType: moonlight_pb.EmailOpen
};
MoonlightService.ReadNewsletterIntro = {
  methodName: "ReadNewsletterIntro",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.NewsletterCategoryRequest,
  responseType: moonlight_pb.NewsletterIntro
};
MoonlightService.UpdateNewsletterIntro = {
  methodName: "UpdateNewsletterIntro",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.NewsletterIntro,
  responseType: moonlight_pb.NewsletterIntro
};
MoonlightService.CreateNewsletter = {
  methodName: "CreateNewsletter",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.NewsletterCategoryRequest,
  responseType: moonlight_pb.Newsletter
};
MoonlightService.CreateTestNewsletter = {
  methodName: "CreateTestNewsletter",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateUserNewsletterRequest,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.ListNewsletterStats = {
  methodName: "ListNewsletterStats",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.NewsletterStatsList
};
MoonlightService.ReadBroadcast = {
  methodName: "ReadBroadcast",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.BroadcastRequest,
  responseType: moonlight_pb.Broadcast
};
MoonlightService.CreateBroadcast = {
  methodName: "CreateBroadcast",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CreateBroadcastRequest,
  responseType: moonlight_pb.Broadcast
};
MoonlightService.UpdateBroadcast = {
  methodName: "UpdateBroadcast",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.Broadcast,
  responseType: moonlight_pb.Broadcast
};
MoonlightService.ListUserOpenBroadcasts = {
  methodName: "ListUserOpenBroadcasts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.BroadcastsList
};
MoonlightService.ListUserBroadcasts = {
  methodName: "ListUserBroadcasts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.BroadcastsList
};
MoonlightService.ListOpenBroadcasts = {
  methodName: "ListOpenBroadcasts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.BroadcastsList
};
MoonlightService.ListOpenApprovedBroadcasts = {
  methodName: "ListOpenApprovedBroadcasts",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.BroadcastsListRequest,
  responseType: moonlight_pb.BroadcastsList
};
MoonlightService.ListUserFullStorySessions = {
  methodName: "ListUserFullStorySessions",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.FullStorySessionsList
};
MoonlightService.CheckOutreachConnection = {
  methodName: "CheckOutreachConnection",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateOutreachOAuthURL = {
  methodName: "CreateOutreachOAuthURL",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: moonlight_pb.OutreachOAuthURL
};
MoonlightService.CreateOutreachToken = {
  methodName: "CreateOutreachToken",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.OutreachTemporaryCode,
  responseType: google_protobuf_empty_pb.Empty
};
MoonlightService.CreateCompanyContingencyAgreement = {
  methodName: "CreateCompanyContingencyAgreement",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.UserRequest,
  responseType: moonlight_pb.ContingencyAgreementSigningURL
};
MoonlightService.ActivateCompanyContingencyAgreement = {
  methodName: "ActivateCompanyContingencyAgreement",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.ActivateCompanyContingencyAgreementRequest,
  responseType: moonlight_pb.Company
};
MoonlightService.ReadCompanyContingencyAgreement = {
  methodName: "ReadCompanyContingencyAgreement",
  service: MoonlightService,
  requestStream: false,
  responseStream: false,
  requestType: moonlight_pb.CompanyRequest,
  responseType: moonlight_pb.ContingencyAgreement
};
module.exports = {
  MoonlightService: MoonlightService,
};

