/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  directory: [],

  pending: false,

  errCode: null,
  errMsg: null,
};

const actions = {
  // listSkills is permission public! Don't forget!
  list({ commit, rootState }) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new EmptyPB.Empty();
    grpc.unary(MoonlightService.ListSkillsWithCounts, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateDirectory', res.message.toObject().skillsList);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateDirectory(state, obj) {
    state.directory = obj;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
};

const getters = {
  getSkillsSortedAlphabetically(state) {
    const directoryCopy = _.cloneDeep(state.directory);

    directoryCopy.sort((a, b) => {
      const textA = a.skill.name.toUpperCase();
      const textB = b.skill.name.toUpperCase();
      // eslint-disable-next-line
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return directoryCopy;
  },
  search: (state, getters) => (query) => {
    const results = [];
    const objs = getters.getSkillsSortedAlphabetically;

    // No query just returns alphabetically-sorted skills
    if (!query) {
      return objs;
    }

    _.forEach(objs, (obj) => {
      if (!query) {
        // No search
        results.push(obj);
      } else if (obj.skill.name.toLowerCase() === query.toLowerCase()) {
        // Exact match - push to front (lets us find things like "C" easier)
        results.unshift(obj);
      } else if (obj.skill.name.toLowerCase().includes(query.toLowerCase())) {
        results.push(obj);
      } else if (obj.skill.abbreviation.toLowerCase().includes(query.toLowerCase())) {
        // Match in abbreviations
        results.push(obj);
      }
    });

    return results;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
