<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isProduction, isPrerendering, getSegmentWriteKey } from '@/helpers';

export default {
  data() {
    return {
      title: 'Moonlight',
      longDesc: "Join Moonlight's professional community of software developers and designers. Find and work with quality candidates based on their experience, location, and availability!",
      shortDesc: 'Professional community of software developers',
      intercomVisible: false,
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { vmid: 'description', name: 'description', content: this.longDesc },
        { vmid: 'application-name', name: 'application-name', content: this.title },
        { vmid: 'twitter:title', name: 'twitter:title', content: this.title },
        { vmid: 'twitter:site', name: 'twitter:site', content: '@moonlight_work' },
        { vmid: 'twitter:description', name: 'twitter:description', content: this.shortDesc },
        // Google+ / Schema.org
        { itemprop: 'name', content: this.title },
        { itemprop: 'description', content: this.longDesc },
        // Facebook / Open Graph
        { property: 'og:title', content: this.title },
      ],
    };
  },
  computed: mapState({
    currentUser: (state) => state.auth.currentUser,
    intercomUserHash: (state) => state.auth.intercomUserHash,
    intercomAppID() {
      if (isProduction()) {
        return 'o8eup5pb';
      }
      return 'c0tv6y5h'; // test app
    },
    logoutRedirect: (state) => state.auth.logoutRedirect,
    session: (state) => state.auth.session,
  }),
  watch: {
    // Watch session, and if user logs out - redirect to login
    session(val) {
      if (val === null || val === '') {
        this.$oruga.notification.open({
          ...this.$cfg.toastCfg,
          message: '✌️ you have been logged out - bye!',
        });
        this.$router.push(this.logoutRedirect);
      }
    },
    currentUser() {
      this.toggleIntercom();

      // Segment
      if (window.analytics) {
        const self = this;
        window.analytics.ready(() => {
          if (self.$store.getters['auth/getIsLoggedIn']) {
            window.analytics.identify(self.currentUser.id, self.currentUser);
            if (self.$store.getters['auth/getCurrentCompanyID']) {
              window.analytics.group(self.$store.getters['auth/getCurrentCompanyID'], self.$store.getters['auth/getCompanyObj']);
            }
          } else {
            window.analytics.reset();
          }
        });
      }
    },
    $route() {
      // Attribution - route is not always available on mount
      if (!this.$store.getters['auth/getIsLoggedIn'] && this.$route.name !== null) {
        this.$store.dispatch('referrals/attribute', {
          landingPage: this.$route.fullPath,
          referredByUserID: this.$route.query.referredByUserID,
          referrerURL: document.referrer,
        });
      }

      if (window.analytics) {
        // Wait 200 ms for page to render before calling 'page'
        setTimeout(() => window.analytics.page(), 200);
      }
    },
  },
  mounted() {
    if (!isPrerendering()) {
      // Attribution - route is not always available on mount
      if (!this.$store.getters['auth/getIsLoggedIn'] && this.$route.name !== null) {
        this.$store.dispatch('referrals/attribute', {
          landingPage: this.$route.fullPath,
          referredByUserID: this.$route.query.referredByUserID,
          referrerURL: document.referrer,
        });
      }

      /* eslint-disable */
      const self = this;
      /* START SEGMENT.com */
      !(function() {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console && console.error && console.error('Segment snippet included twice.');
          else {
            analytics.invoked = !0;
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
            ];
            analytics.factory = function(t) {
              return function() {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics;
              };
            };
            for (var t = 0; t < analytics.methods.length; t++) {
              var e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }
            analytics.load = function(t, e) {
              var n = document.createElement('script');
              n.type = 'text/javascript';
              n.async = !0;
              n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
              var a = document.getElementsByTagName('script')[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
            analytics.load(getSegmentWriteKey());
            analytics.debug(!isProduction());
            analytics.page();

            if (self.currentUser) {
              analytics.identify(self.currentUser.id, self.currentUser);
              if (self.$store.getters['auth/getCurrentCompanyID']) {
                analytics.group(
                  self.$store.getters['auth/getCurrentCompanyID'],
                  self.$store.getters['auth/getCompanyObj'],
                );
              }
            }
          }
      })();
      /* eslint-enable */
      /* END SEGMENT.COM */
    }
    /* eslint-enable */

    // Poll for valid session and user updates every 10 seconds
    this.$store.dispatch('auth/refresh');
    setInterval(() => {
      if (!document.hidden) {
        this.$store.dispatch('auth/refresh');
      }
    }, 30 * 1000);

    // Poll for skills list (permission public) every 60 seconds
    this.$store.dispatch('skills/list');

    const self = this;
    setInterval(() => {
      if (!document.hidden) {
        self.$store.dispatch('skills/list');
      }
    }, 60 * 1000);

    // Load skill suggestion model after 2 seconds
    setTimeout(() => {
      self.$store.dispatch('skills/readSuggestionModel');
    }, 2000);

    if (!isPrerendering()) {
      this.toggleIntercom();
    }
  },
  methods: {
    toggleIntercom() {
      const self = this;
      if (!window.Intercom) {
        // wait 5 sec then try again
        setTimeout(() => self.toggleIntercom(), 5000);
        return;
      }
      window.Intercom('onShow', () => {
        self.intercomVisible = true;
      });
      window.Intercom('onHide', () => {
        self.intercomVisible = false;
      });
      if (this.intercomUserHash && !this.intercomVisible) {
        window.Intercom('boot', {
          user_id: this.currentUser.id,
          user_hash: this.intercomUserHash,
          name: this.currentUser.name,
          email: this.currentUser.email,
          app_id: this.intercomAppID,
        });
      } else if (this.currentUser && this.intercomVisible) {
        window.Intercom('update', {
          userId: this.currentUser.id,
          user_hash: this.intercomUserHash,
          name: this.currentUser.name,
          email: this.currentUser.email,
          appId: this.intercomAppID,
        });
      } else {
        // logged out; start a fresh session
        window.Intercom('boot', {
          app_id: this.intercomAppID,
        });
      }
    },
  },
};
</script>

<style src="./styles/custom-properties.css"></style>
<style src="./styles/base.scss" lang="scss"></style>
<style src="./styles/tailwindcss.css" lang="postcss"></style>

<style lang="scss" scoped>
[id='app'] {
  min-height: 100vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
