/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  // homepage
  activeDeveloperCount: null,
  averageYearsExperience: null,

  pending: false,

  errCode: null,
  errMsg: null,
};

const actions = {
  read({ commit, rootState }) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new EmptyPB.Empty();
    grpc.unary(MoonlightService.ReadLiveStats, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateResults', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.activeDeveloperCount = null;
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateResults(state, msg) {
    state.activeDeveloperCount = msg.getActiveDeveloperCount();
    state.averageYearsExperience = msg.getAverageYearsExperience();
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
};

const getters = {
  loaded(state) {
    return !!state.activeDeveloperCount;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
