/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  grpc,
} from 'grpc-web-client';
import {
  PublicInvoiceRequest,
} from '@/protoc/moonlight_pb';
import {
  MoonlightService,
} from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isProduction,
  grpcAuthMetadata,
  protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  summaryObj: null,
  errCode: null,
  pending: false,
  errMsg: null,
};

const actions = {

  read({
    commit,
    state,
    rootState,
    dispatch,
  }, { id, accessToken }) {
    commit('mutateReset');

    commit('mutatePending', true);
    const req = new PublicInvoiceRequest();
    req.setInvoiceId(id);
    req.setAccessToken(accessToken);

    grpc.unary(MoonlightService.ReadInvoiceWithAccessToken, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateInvoice', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.summaryObj = null;
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateInvoice(state, res) {
    const ivs = res.toObject();
    ivs.invoice.dueAt = protoTimestampToDate(res.getInvoice().getDueAt());
    ivs.invoice.createdAt = protoTimestampToDate(res.getInvoice().getCreatedAt());
    ivs.invoice.paidAt = protoTimestampToDate(res.getInvoice().getPaidAt());
    ivs.invoice.updatedAt = protoTimestampToDate(res.getInvoice().getUpdatedAt());
    state.summaryObj = ivs;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, {
    code,
    msg,
  }) {
    state.errCode = code;
    state.errMsg = msg;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
