import _ from 'lodash';
import TSProto from 'google-protobuf/google/protobuf/timestamp_pb';
import countries from '@/conf/country.json';

// TermsLastUpdatedAt is the last time the terms of service were updated, which
// should compare to the `terms_of_service_agreed` user field.
// This should always match the eponomous variable in `pkg/server/users.go`!
// Note: Month is zero-indexed!!!
export const TermsLastUpdatedAt = new Date(Date.UTC(2023, 9, 31, 2));

/* eslint-disable-next-line */
const isUrlOptionalProtocol = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export function isProduction() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];

  if (domain.endsWith('moonlightwork.com')) {
    return true;
  }

  if (domain.endsWith('sitemirror-prod')) {
    return true;
  }

  return false;
}

export function isLocal() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];

  return domain.endsWith('.local') && !domain.endsWith('moonlight.local');
}

export function isMnltDev() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];

  if (domain.endsWith('mnlt.dev')) {
    return true;
  }

  if (isLocal()) {
    return true;
  }

  if (domain.endsWith('sitemirror-namespace')) {
    return true;
  }

  return false;
}

export function isUrlWithOrWithoutProtocol(string) {
  const match = string.match(isUrlOptionalProtocol);
  if (!match) {
    return false;
  }
  return true;
}

/**
 * is-url open source
 * From https://github.com/segmentio/is-url/blob/master/index.js
 * Loosely validate a URL `string`.
 *
 * @param {String} string
 * @return {Boolean}
 */
export function isUrl(string) {
  /**
   * RegExps.
   * A URL must match #1 and then at least one of #2/#3.
   * Use two levels of REs to avoid REDOS.
   */
  /* eslint-disable no-useless-escape */
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  if (typeof string !== 'string') {
    return false;
  }

  const match = string.match(protocolAndDomainRE);

  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];

  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol)
    || nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
}

export function renderUserLoc(loc) {
  try {
    if (!loc || !loc.includes(', ')) {
      return loc;
    }
    const locs = loc.split(', ');
    const country = locs[1].trim();
    locs[1] = countries[country] ? countries[country] : country;
    return locs.join(', ');
  } catch (e) {
    console.warn('[warn] Defaulting to original location. Reason: ', e);
    return loc;
  }
}

// mnltDevNamespaceCoreDomain
// returns "staging.mnlt.dev" from "www.staging.mnlt.dev"
export function mnltDevNamespaceCoreDomain() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];

  // Case: Local development server
  if (domain.endsWith('.local')) {
    return `${domain.split('.')[0]}.mnlt.dev`;
  }

  if (!domain.endsWith('mnlt.dev')) {
    // Error, shouldn't get here
    return '';
  }

  const domainParts = domain.split('.');
  if (domainParts.length !== 4) {
    // error - should never happen for namespace
    return '';
  }

  return domainParts.slice(1, 4).join('.');
}

export function mnltDevNamespace() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];

  if (domain.endsWith('.local')) {
    return domain.split('.')[0];
  }

  if (!domain.endsWith('mnlt.dev')) {
    return '';
  }

  return domain.split('.')[1];
}

// Useful vs. baseURL in dev - where you may wanna go to localhost instead of moonlight.local
export function currentBaseURL() {
  return window.location.href
    .split('/')
    .slice(0, 3)
    .join('/');
}

// For marketing route that are displayed without the usual header and footer
export function isFullScreenMarketingRoute(routeName) {
  return routeName === 'Marketing.LinkedInLanding';
}

export function isPrerendering() {
  const url = window.location.href.toLowerCase();
  const domain = url.split('/')[2].split(':')[0];
  if (domain.endsWith('sitemirror-prod')) {
    return true;
  }
  if (domain.endsWith('sitemirror-local')) {
    return true;
  }
  if (domain.endsWith('sitemirror-namespace')) {
    return true;
  }
  return false;
}

export function grpcHost() {
  if (isProduction()) {
    // production prerendering can go outside the firewall, unless we start getting ratelimited
    return 'https://www.moonlightwork.com';
  }

  if (isPrerendering()) {
    return 'http://website';
  }

  if (isMnltDev()) {
    const subDomain = 'https://api';
    return subDomain.concat('.', mnltDevNamespaceCoreDomain());
  }

  return 'http://www.moonlight.local';
}

export function grpcAuthMetadata(session) {
  if (session) {
    return { 'authorization-json': JSON.stringify(session) };
  }
  return {};
}

export function validEmail(email) {
  if (typeof email !== 'string') {
    return false;
  }
  const lastAtPos = email.lastIndexOf('@');
  const lastDotPos = email.lastIndexOf('.');
  return (
    lastAtPos < lastDotPos
    && lastAtPos > 0
    && email.indexOf('@@') === -1
    && lastDotPos > 2
    && email.length - lastDotPos > 2
  );
}

export function protoTimestampToDate(ts) {
  // timestamp has seconds and nanoseconds since epoch
  let millisecSinceEpoch = 0;
  millisecSinceEpoch += ts.getSeconds() * 1000;
  millisecSinceEpoch += ts.getNanos() / 1000000;
  return new Date(millisecSinceEpoch);
}

export function protoTimestampNow() {
  const ts = new TSProto.Timestamp();
  ts.fromDate(new Date());
  return ts;
}

export function protoTimestampObjectToDate(ts) {
  // like previous function - but after you call .toObject() on it
  // timestamp has seconds and nanoseconds since epoch
  let millisecSinceEpoch = 0;
  millisecSinceEpoch += ts.seconds * 1000;
  millisecSinceEpoch += ts.nanos / 1000000;
  return new Date(millisecSinceEpoch);
}

// for json-encoded protoc
export function protoJSONTimestampToDate(ts) {
  // timestamp has seconds and nanoseconds since epoch
  if (!ts) {
    return null;
  }
  let millisecSinceEpoch = 0;
  millisecSinceEpoch += ts.seconds * 1000;
  millisecSinceEpoch += ts.nanos / 1000000;
  return new Date(millisecSinceEpoch);
}

// This is public key
export function recaptchaSiteKey() {
  if (isProduction()) {
    return '6Lc5eyoUAAAAAGOHjj4qdbk2a4QYSFxdCBXG9OuL';
  }
  return '6LcUeSoUAAAAAKcDGww0NbGw-5VVqPBaPK_2Ezhl';
}

export function stripeKey() {
  if (isProduction()) {
    return 'pk_live_vQ2gNQVSZaXO2s6LlBhneCnl00HTIzrSOc';
  }
  return 'pk_test_p6v3b6kYyqBMHQV3SoR1zWSe00W5fq9stB';
}

export function stripeKeyMWE() {
  if (isProduction()) {
    return 'pk_live_51LjnkWJbVgoxF9kjKrMTJntDhNy1iym16AMLEJGMn2AzdexRnrqqG4Anh4iBSAFVv30n2zL34WqTiJT7QgUfavoW00v5FTNou2';
  }
  return 'pk_test_51LjnkWJbVgoxF9kjRolsPPG5M8FIoxX4ZiCmOIo4xxqj1P0l6J704R2nzZnSsNFZNQxj7MbYK1U6Kx0nJuX5NKcy00Q8FhxSok';
}

export function plaidEnv() {
  if (isProduction()) {
    return 'development';
  }
  return 'sandbox';
}

export function googleClientID() {
  if (isProduction()) {
    return '780370321340-jb771fv2rgsjg5kf2mhbge21ijfkuvve.apps.googleusercontent.com';
  } if (isMnltDev()) {
    return '475068116044-id6i3hhe0iad3k1np1cc3sba44aaulf8.apps.googleusercontent.com';
  }
  return '780370321340-55jdpip44dhabdi0r2ulshfdm55qg7bo.apps.googleusercontent.com';
}

// rough helper function for pagination. Assumes that
// ids start at 1, and continue.
export function getAfterID(pageNumber, resultsPerPage) {
  return (pageNumber - 1) * resultsPerPage;
}

// Generates serving url. Args are key/val.
export function imgixURL(sourceURL, args) {
  const base = 'https://storage.googleapis.com/moonlightwork/';
  let url = `https://moonlightwork.imgix.net/${sourceURL.replace(base, '')}?`;
  _.forOwn(args, (value, key) => {
    url += `${key}=${encodeURIComponent(value)}&`;
  });
  return url;
}

export function containsEncodedComponents(string) {
  return (decodeURI(string) !== decodeURIComponent(string));
}

export function baseUrl() {
  if (isProduction()) {
    return 'https://www.moonlightwork.com';
  }

  if (isMnltDev()) {
    const url = window.location.href.toLowerCase();
    const domain = url.split('/')[2].split(':')[0];

    if (domain.endsWith('.local')) {
      // frontent-dev
      return `http://${mnltDevNamespace()}.local:8080`;
    }
    const base = 'https://www.';
    return base.concat(mnltDevNamespace(), '.mnlt.dev');
  }

  return 'http://www.moonlight.local';
}

export function referralSuffix(userID) {
  return `?referredByUserID=${userID}}`;
}

export function deepLinkSlackGroup(groupID) {
  if (isProduction()) {
    return `https://moonlight-community.slack.com/app_redirect?channel=${groupID}`;
  }
  return `https://moonlightlocal-cmmnty.slack.com/app_redirect?channel=${groupID}`;
}

export function deepLinkSlackUser(userID) {
  if (isProduction()) {
    return `https://moonlight-community.slack.com/team/${userID}`;
  }
  return `https://moonlightlocal-cmmnty.slack.com/team/${userID}`;
}

export function getSegmentWriteKey() {
  if (isProduction()) {
    return 'F9k6D7nEXJAXk4uHcc37SRDoZa7t5dWu';
  }
  return '7khglSUQ6IQIPCDD1yE90cfbdOiWHucg';
}

export function slackSignupURL() {
  if (isProduction()) {
    return 'https://join.slack.com/t/moonlight-community/shared_invite/enQtMzg4ODczMjMxMDU4LTUzY2NjYmQ3YzRjNDYxNmQ0YTIxMDc3N2YwNDM5M2ZjM2NhNDg0MGIzNDdkYTk2NmNlZDYyNjE4ZjY2ZWM5M2I';
  }
  return 'https://join.slack.com/t/moonlightlocal-cmmnty/shared_invite/enQtMzg4MzI5MTM0NDgxLTI3YTRmNGI4MmY0NTRhMmQ0ODdmYzhiYWRmNjVkNmM3ODc5NjRiMWQwYjI1MGEzNWI0YzBkNWViODlhYjc4ZGQ';
}

export function centsToDollars(val) {
  return `$ ${(parseInt(val, 10) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function firstName(val) {
  if (!val) {
    return '';
  }
  const names = val.trim().split(' ');
  if (names.length > 1) {
    return names.slice(0, -1).join(' ');
  }
  return val;
}

/**
 * For marketing pages, there are different calendly events that we
 * want to send to different types of users
 * (logged in, non-paying client, paying client)
 * @param{vnode} vueComp is needed to access the global store
 * @return{string} A full URL for calendly
 */
export function marketingCalendlyUrl(vueComp) {
  // As the default, go to the main Calendly page that lists public calendars
  let result = '';
  const isLoggedIn = vueComp.$store.getters['auth/getIsLoggedIn'];
  const isDev = vueComp.$store.getters['auth/getIsDeveloper'];

  if (isLoggedIn && isDev) {
    result = 'moonlight-introduction';
  } else {
    result = 'moonlight-introduction';
  }

  return `https://calendly.com/damon-moonlight/${result}`;
}

export const testimonialList = [
  {
    person: 'Abe',
    title: 'CEO of Atlas Ai',
    company: 'Atlas AI',
    quote:
        'It has been a pleasure to work with the Moonlight Work platform, and we are already seeing an impact to our business in the form of faster time to fill engineering contractor positions and a higher quality talent pool. Furthermore the Moonlight Work team oversaw a fast and high quality onboarding process to the platform and has been immediately responsive to our questions and requests for support.',
    logo: 'AtlasAi.png',
  },
  {
    person: 'Jonathan',
    title: 'Founding Partner of Axion',
    company: 'Jonathan',
    quote:
        'When our firm needed help converting a remote sensing research project into an operational spin out we leaned on Moonlight developers (really, Ai wizards) to leverage newly available satellite and aerial imagery. The developers’ success was a key part of the new entity’s strategic product development and took our team in a whole new direction.',
    logo: 'Axion.png',
  },
  {
    person: 'Ro',
    title: 'Cofounder & CEO of Coast',
    company: 'Coast',
    quote:
        'The high quality of candidates differentiates Moonlight. We made two full-time and multiple contract hires in the first month on the platform. ',
    logo: 'Coast.png',
  },
  {
    person: 'Blake',
    title: 'Product at Engine',
    company: 'Engine logo',
    quote:
        'Moonlight is going to be written into our success story for sure. Hiring through the Moonlight platform has helped us speed up areas of our platform in a crucial time without derailing us from our roadmap in other places.',
    logo: 'Engine.png',
  },
  {
    person: 'Lee',
    title: 'Founder of Gingr',
    company: 'Gingr',
    quote:
        'We worked with the primary author of the Staffjoy open-source project to get a scheduling integration running. The whole system was set up on Google Cloud in about a day, allowing us to move forward quickly with the project.',
    logo: 'Gingr.png',
  },
  {
    person: 'Brian',
    title: 'Founder of HDP Health',
    company: 'HDP Health',
    quote:
        'We were building a new feature and needed to move from Aptible to AWS, but had no idea how to do it.  We found a HIPAA-specialized contractor on Moonlight, which otherwise would have been expensive and taken 2 months of interviewing.',
    logo: 'HdpHealth.png',
  },
  {
    person: 'Rachel',
    title: 'Founder of Millie',
    company: 'Millie',
    quote:
      'Within a week and a half of the initial positing, we had already started ramping up our developer. The candidates were all high quality and the transparency around pricing was super helpful going into a project like this. Highly recommend!',
    logo: 'Millie.png',
  },
  {
    person: 'Margaret',
    title: 'Founder & CEO of Mobius Materials',
    company: 'Mobius Materials',
    quote:
        'We interviewed 3 engineers on Moonlight -- all of whom met a very high quality bar. It allowed us to hire a more senior person than we could manage to hire full time and move a lot faster. Ultimately this meant we could postpone a full time hire for 9 months, saving us almost $100K.',
    logo: 'Mobius.png',
  },
  {
    person: 'Taylor',
    title: 'Founder of Sched',
    company: 'Sched',
    quote:
        'We found an incredibly talented developer who was a technical and cultural fit for our team. For our next hire, I would immediately start with Moonlight before wasting time and thousands of dollars on the other job boards.',
    logo: 'Sched.png',
  },
  {
    person: 'Dan',
    title: 'Co-founder of Ulta',
    company: 'Ulta',
    quote:
        'Our company needed to rapidly onboard a small gaggle of data engineers to support a major initiative with an insurance industry partner. Moonlight presented us with several senior candidate options narrowed down to fit both our and our partner\'s needs exactly. We connected and began engagements in just a few days and the engineers have just knocked it out of the park. We\'re all-in on Moonlight.',
    logo: 'UltaIO.png',
  },
  {
    person: 'Madelin',
    title: 'Founder of Walden',
    company: 'Walden',
    quote:
      'I needed help building the MVP for Hello Walden. I quickly found a developer and we have been working together ever since! The hour tracking and payments were easy, and I got weekly feedback. I will definitely be using Moonlight again for my next hire.',
    logo: 'Walden.png',
  },
];
