/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import { SearchDevelopersRequest, CityQuery } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

const strict = false;

// initial state
const state = {
  limit: 12,

  slugs: [],
  exact: false,
  results: [],

  exactCount: null,
  inexactCount: null,
  seekingContracts: true,
  seekingEmployment: true,

  lat: null,
  lon: null,
  location: null, // name
  radiusMiles: 50,

  pending: false,
  pendingExtension: false,
  errCode: null,
  errMsg: null,
};

const actions = {
  search(
    { commit, state, rootState },
    {
      skills, exact, seekingContracts, seekingEmployment, location,
    },
  ) {
    commit('mutateReset');
    commit('mutateResults', []);
    commit('mutatePending', true);
    commit('mutateSlugs', skills);
    commit('mutateExact', exact);
    commit('mutateSeekingContracts', seekingContracts);
    commit('mutateSeekingEmployment', seekingEmployment);

    if (location) {
      commit('mutateLocation', location.slice());
    } else {
      commit('mutateLocation', null);
    }

    const req = new SearchDevelopersRequest();
    _.forEach(skills, (slug) => {
      req.addSkillSlugs(slug);
    });

    req.setExactMatch(exact);
    req.setLimit(state.limit);
    req.setSeekingContracts(seekingContracts);
    req.setSeekingEmployment(seekingEmployment);
    req.setOffset(0); // first query

    if (state.location) {
      state.location.forEach((l) => {
        if (l.type === 'country') {
          req.addCountries(l.country.toUpperCase());
        } else if (l.type === 'city') {
          const city = new CityQuery();
          city.setName(l.name);
          city.setLat(l.lat);
          city.setLon(l.lon);
          city.setRadiusMiles(state.radiusMiles);
          req.addCities(city);
        }
      });
    }

    grpc.unary(MoonlightService.SearchDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateResults', res.message.getResultsList());
          commit('mutateExactCount', res.message.getExactCount());
          commit('mutateInexactCount', res.message.getInexactCount());
          if (window.analytics) {
            window.analytics.ready(() => {
              window.analytics.track('Search');
            });
          }
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  count({ commit, state, rootState }, skills) {
    const exact = false;
    commit('mutateReset');
    commit('mutateResults', []);
    commit('mutatePending', true);
    commit('mutateSlugs', skills);
    commit('mutateExact', exact);

    // this is not a location-enabled endpoint right now.

    const req = new SearchDevelopersRequest();
    _.forEach(skills, (slug) => {
      req.addSkillSlugs(slug);
    });

    req.setExactMatch(exact);
    req.setLimit(0); // do not return any results
    req.setOffset(0); // first query
    req.setSeekingContracts(true);
    req.setSeekingEmployment(true);

    grpc.unary(MoonlightService.SearchDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateResults', res.message.getResultsList());
          commit('mutateExactCount', res.message.getExactCount());
          commit('mutateInexactCount', res.message.getInexactCount());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  extend({ commit, state, rootState }) {
    if (state.pendingExtension) {
      return;
    }

    commit('mutateReset');
    commit('mutatePendingExtension', true);

    const req = new SearchDevelopersRequest();
    _.forEach(state.slugs, (slug) => {
      req.addSkillSlugs(slug);
    });

    req.setExactMatch(state.exact);
    req.setLimit(state.limit);
    req.setOffset(state.results.length);
    req.setSeekingContracts(state.seekingContracts);
    req.setSeekingEmployment(state.seekingEmployment);

    if (state.location) {
      state.location.forEach((l) => {
        if (l.type === 'country') {
          req.addCountries(l.country.toUpperCase());
        } else if (l.type === 'city') {
          const city = new CityQuery();
          city.setName(l.name);
          city.setLat(l.lat);
          city.setLon(l.lon);
          city.setRadiusMiles(state.radiusMiles);

          req.addCities(city);
        }
      });
    }

    grpc.unary(MoonlightService.SearchDevelopers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingExtension', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateAppendResults', res.message.getResultsList());
          commit('mutateExactCount', res.message.getExactCount());
          commit('mutateInexactCount', res.message.getInexactCount());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
  },
  mutateResults(state, results) {
    state.results = [];
    _.each(results, (result) => {
      state.results.push(result.toObject());
    });
  },
  mutateAppendResults(state, results) {
    _.each(results, (result) => {
      state.results.push(result.toObject());
    });
  },
  mutateSlugs(state, slugs) {
    state.slugs = slugs;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateExact(state, exact) {
    state.exact = exact;
  },
  mutateExactCount(state, count) {
    state.exactCount = count;
  },
  mutateInexactCount(state, count) {
    state.inexactCount = count;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutatePendingExtension(state, pending) {
    state.pendingExtension = pending;
  },
  mutateSeekingContracts(state, val) {
    state.seekingContracts = val;
  },
  mutateSeekingEmployment(state, val) {
    state.seekingEmployment = val;
  },
  mutateLocation(state, val) {
    state.location = val;
  },
  mutateLat(state, val) {
    state.lat = val;
  },
  mutateLon(state, val) {
    state.lon = val;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  strict,
};
