import {
  guardAuthenticated,
  guardClient,
  guardClientDashboard,
  guardDevDashboard,
  guardDeveloper,
  guardSelf,
  guardSettings,
} from '@/conf/guards';

const Dashboard = () => import('@/views/app/Dashboard');

const Search = () => import('@/views/app/Search');

const UserProfile = () => import('@/views/app/UserProfile');

const CompanyProfile = () => import('@/views/app/CompanyProfile');

const CompanyJobs = () => import('@/views/app/CompanyJobs');

const CompanyJobDashboard = () => import('@/views/app/CompanyJobDashboard');

const DeveloperJobs = () => import('@/views/app/DeveloperJobs');

const DeveloperJobDashboard = () => import('@/views/app/DeveloperJobDashboard');

const UserSettings = () => import('@/views/app/UserSettings');

const Refer = () => import('@/views/app/Refer');

const ConfirmSubscription = () => import('@/views/app/ConfirmSubscription');

const ConfirmAgreement = () => import('@/views/app/ConfirmAgreement');

const AddUserSkill = () => import('@/views/app/AddUserSkill');

const Chat = () => import('@/views/app/Chat');

const ChatEmptyChannel = () => import('@/views/app/ChatEmptyChannel');

const ChatChannel = () => import('@/views/app/ChatChannel');

const Post = () => import('@/views/app/Post');

const PostStart = () => import('@/views/app/post/PostStart');

const PostJobType = () => import('@/views/app/post/PostJobType');

const PostSkills = () => import('@/views/app/post/PostSkills');

const PostSpec = () => import('@/views/app/post/PostSpec');

const PostFinish = () => import('@/views/app/post/PostFinish');

const Broadcast = () => import('@/views/app/Broadcast');

const BroadcastStart = () => import('@/views/app/broadcast/BroadcastStart');

const BroadcastSubmit = () => import('@/views/app/broadcast/BroadcastSubmit');

const BroadcastBio = () => import('@/views/app/broadcast/BroadcastBio');

const BroadcastConfirmation = () => import('@/views/app/broadcast/BroadcastConfirmation');

const BroadcastIdealJob = () => import('@/views/app/broadcast/BroadcastIdealJob');

const BroadcastVisibility = () => import('@/views/app/broadcast/BroadcastVisibility');

const BroadcastJobType = () => import('@/views/app/broadcast/BroadcastJobType');

const BroadcastRecentAccomplishments = () => import('@/views/app/broadcast/BroadcastRecentAccomplishments');

export default [
  {
    path: '',
    name: 'App.Dashboard',
    component: Dashboard,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'search',
    name: 'App.Search',
    component: Search,
    beforeEnter: guardClient,
  },
  {
    path: 'post/:companyID/',
    component: Post,
    props: true,
    beforeEnter: guardClient,
    // TODO: https://router.vuejs.org/guide/migration/#named-children-routes-with-an-empty-path-no-longer-appends-a-slash
    children: [
      { path: '', component: PostStart, name: 'App.Post.Start' },
      { path: 'type', component: PostJobType, name: 'App.Post.JobType' },
      { path: 'skills', component: PostSkills, name: 'App.Post.Skills' },
      { path: 'spec', component: PostSpec, name: 'App.Post.Spec' },
      { path: 'finish', component: PostFinish, name: 'App.Post.Finish' },
    ],
  },
  {
    path: 'broadcast/:userID/',
    component: Broadcast,
    props: true,
    beforeEnter: guardDeveloper,
    children: [
      { path: '', component: BroadcastStart, name: 'App.Broadcast.Start' },
      { path: 'visibility', component: BroadcastVisibility, name: 'App.Broadcast.Visibility' },
      { path: 'submit', component: BroadcastSubmit, name: 'App.Broadcast.Submit' },
      { path: 'bio', component: BroadcastBio, name: 'App.Broadcast.Bio' },
      {
        path: 'confirmation/:id',
        component: BroadcastConfirmation,
        props: true,
        name: 'App.Broadcast.Confirmation',
      },
      {
        path: 'ideal-job',
        component: BroadcastIdealJob,
        name: 'App.Broadcast.IdealJob',
      },
      {
        path: 'job-type',
        component: BroadcastJobType,
        name: 'App.Broadcast.JobType',
      },
      {
        path: 'recent-accomplishments',
        component: BroadcastRecentAccomplishments,
        name: 'App.Broadcast.RecentAccomplishments',
      },
    ],
  },
  {
    path: 'users/:id',
    name: 'App.User',
    // redirect: 'users/:id/profile',
    redirect: (to) => ({ name: 'App.User.Profile', params: to.params }),
  },
  {
    path: 'users/:id/profile',
    name: 'App.User.Profile',
    component: UserProfile,
    props: true,
  },
  {
    path: 'settings',
    name: 'App.User.Settings',
    beforeEnter: guardSettings,
  },
  {
    path: 'users/:userID/settings',
    name: 'App.User.SettingsPage',
    component: UserSettings,
    props: (route) => ({
      userID: Number(route.params.userID),
    }),
    beforeEnter: guardSelf,
  },
  {
    path: 'companies/:id',
    name: 'App.CompanyProfile',
    component: CompanyProfile,
    props: true,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'companies/:companyID/projects',
    // redirect: 'companies/:companyID/jobs',
    redirect: (to) => ({ name: 'App.CompanyJobs', params: to.params }),
  },
  {
    path: 'companies/:companyID/jobs',
    name: 'App.CompanyJobs',
    component: CompanyJobs,
    props: true,
    beforeEnter: guardClient,
  },
  {
    path: 'users/:userID/projects',
    // redirect: 'users/:userID/jobs',
    redirect: (to) => ({ name: 'App.DeveloperJobs', params: to.params }),
  },
  {
    path: 'users/:userID/jobs',
    name: 'App.DeveloperJobs',
    component: DeveloperJobs,
    props: true,
    beforeEnter: guardDeveloper,
  },
  {
    path: 'companies/:companyID/projects/:jobID',
    // redirect: 'companies/:companyID/jobs/:jobID',
    redirect: (to) => ({ name: 'App.CompanyJobDashboard', params: to.params }),
  },
  {
    path: 'companies/:companyID/jobs/:jobID',
    name: 'App.CompanyJobDashboard',
    component: CompanyJobDashboard,
    props: true,
    beforeEnter: guardClientDashboard,
  },
  {
    path: 'users/:userID/projects/companies/:companyID/projects/:jobID',
    // redirect: 'users/:userID/jobs/companies/:companyID/jobs/:jobID',
    redirect: (to) => ({ name: 'App.DeveloperJobDashboard', params: to.params }),
  },
  {
    // Have job in path so it matches sidebar
    path: 'users/:userID/jobs/companies/:companyID/jobs/:jobID',
    name: 'App.DeveloperJobDashboard',
    component: DeveloperJobDashboard,
    props: true,
    beforeEnter: guardDevDashboard,
  },
  {
    path: 'users/:id/add-skill/:slug',
    name: 'App.AddUserSkill',
    component: AddUserSkill,
    props: true,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'users/:id/chat/',
    component: Chat,
    props: true,
    children: [
      { path: '', component: ChatEmptyChannel, name: 'App.Chat' },
      {
        path: ':channelID', component: ChatChannel, name: 'App.Chat.Channel', props: true,
      },
    ],
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'refer',
    name: 'App.Refer',
    component: Refer,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'confirm-subscription',
    name: 'App.ConfirmSubscription',
    component: ConfirmSubscription,
    beforeEnter: guardAuthenticated,
  },
  {
    path: 'confirm-agreement',
    name: 'App.ConfirmAgreement',
    component: ConfirmAgreement,
    beforeEnter: guardAuthenticated,
  },
];
