import { getCurrentInstance } from 'vue';
import { useHead } from '@unhead/vue';

/**
 * This is a custom mixin to inject the right
 * title tag without flashing.
 * It's similar to the official one
 * from https://github.com/unjs/unhead/blob/main/packages/vue/src/VueHeadMixin.ts
 * except that it adds a custom suffix to the page title.
 * Using `titleTemplate` from the library causes flashing
 * during page transitions / route changes.
 */
// eslint-disable-next-line import/prefer-default-export
export const mnltVueHeadMixin = {
  created() {
    const instance = getCurrentInstance();
    if (!instance) return;

    const options = instance.type;
    if (!options || !('head' in options)) return;

    const source = typeof options.head === 'function'
      ? options.head.call(instance.proxy)
      : options.head;

    const head = { ...source };
    if (head.title && !head.omitTemplate) {
      head.title = `${head.title} | Moonlight`;
    }
    useHead(head);
  },
};
