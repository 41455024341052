<template>
  <div>
    <o-loading :active="true" :icon-size="getIconSize" />
  </div>
</template>

<script>

export default {
  name: 'Spinner',
  props: {
    size: {
      // in pixels
      type: Number,
      required: true,
    },
    strokeWidth: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  computed: {
    sizePx() {
      return `${this.size}px`;
    },
    getIconSize() {
      if (this.size < 50) {
        return 'small';
      }
      if (this.size > 70) {
        return 'large';
      }
      return 'medium';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
