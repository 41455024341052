/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';
import { JobSummary, CompanyRequest, UserRequest } from '@/protoc/moonlight_pb';

const namespaced = true;

// initial state
const state = {
  summaries: [], // summaries has both job and some data

  pending: false,
  matchesPending: false,
  notFound: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an job has been approved/denied
};

const actions = {
  listOngoing({ commit, rootState }) {
    commit('mutateReset');
    commit('mutateSummaries', []);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.ListOngoingJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new EmptyPB.Empty(),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSummaries', res.message.getSummariesList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  listCompany({ commit, rootState }, id) {
    commit('mutateReset');
    commit('mutateSummaries', []);
    commit('mutatePending', true);
    const req = new CompanyRequest();
    req.setCompanyId(id);

    grpc.unary(MoonlightService.ListCompanyJobSummaries, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSummaries', res.message.getSummariesList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
  listDeveloper({ commit, rootState }, id) {
    commit('mutateReset');
    commit('mutateSummaries', []);

    const req = new UserRequest();
    req.setUserId(id);
    commit('mutateMatchesPending', true);

    grpc.unary(MoonlightService.ListDeveloperJobSummaries, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateSummaries', res.message.getSummariesList());
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
        commit('mutateMatchesPending', false);
      },
    });
  },
  approve({ commit, state, rootState }, jobID) {
    commit('mutateReset');

    let p;
    _.each(state.summaries, (s) => {
      if (s.getJob().getId() === jobID) {
        p = s.getJob();
      }
    });
    if (p === null) {
      commit('mutateError', { code: 1, msg: 'failed to find matching job' });
      return;
    }

    p.setApproved(true);

    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateJob, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: p,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
          commit('mutateUpdateJobInCache', res.message);
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateSummaries(state, jobs) {
    state.summaries = jobs;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateMatchesPending(state, pending) {
    state.matchesPending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, val) {
    state.success = val;
  },
  mutateUpdateJobInCache(state, job) {
    // remove user from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.summaries.length; i++) {
      if (state.summaries[i].getJob().getId() === job.getId()) {
        const update = new JobSummary(state.summaries[i].array.slice(0));
        update.setJob(job);
        state.summaries[i] = update;
        // Vue.set(state.summaries, i, update);
      }
    }
  },
};

const getters = {
  getSummaryObjs(state) {
    const res = [];

    _.each(state.summaries, (s) => {
      const data = s.toObject();
      data.job.createdAt = protoTimestampToDate(s.getJob().getCreatedAt());
      data.job.updatedAt = protoTimestampToDate(s.getJob().getUpdatedAt());
      data.job.workStartedAt = protoTimestampToDate(s.getJob().getWorkStartedAt());
      data.job.finishedAt = protoTimestampToDate(s.getJob().getFinishedAt());
      data.job.canceledAt = protoTimestampToDate(s.getJob().getCanceledAt());

      res.push(data);
    });
    return res;
  },
  getUnapprovedCount(state) {
    let count = 0;

    _.each(state.summaries, (s) => {
      const data = s.toObject();
      if (!data.job.approved) {
        count += 1;
      }
    });
    return count;
  },
  getPending(state) {
    return state.pending;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
