const Add = () => import('@/views/tractorbeam/Add');

const Review = () => import('@/views/tractorbeam/Review');

const BulkAdd = () => import('@/views/tractorbeam/BulkAdd');

const Lookup = () => import('@/views/tractorbeam/Lookup');

const HNWhosHiring = () => import('@/views/tractorbeam/scrape/HNWhosHiring');

const HNWhoWantsToBeHired = () => import('@/views/tractorbeam/scrape/HNWhoWantsToBeHired');

const HNFreelance = () => import('@/views/tractorbeam/scrape/HNFreelance');

export default [
  {
    path: '',
    name: 'TractorBeam.Add',
    component: Add,
  },
  {
    path: 'review',
    name: 'TractorBeam.Review',
    component: Review,
  },
  {
    path: 'bulk',
    name: 'TractorBeam.BulkAdd',
    component: BulkAdd,
  },
  {
    path: 'lookup/:email',
    name: 'TractorBeam.Lookup',
    component: Lookup,
    props: true,
  },
  {
    path: 'leads/:email',
    // redirect: 'lookup/:email',
    redirect: (to) => ({ name: 'TractorBeam.Lookup', params: to.params }),
  },
  {
    path: 'scrape/hn-whos-hiring',
    name: 'TractorBeam.Scrape.HNWhosHiring',
    component: HNWhosHiring,
  },
  {
    path: 'scrape/hn-who-wants-to-be-hired',
    name: 'TractorBeam.Scrape.HNWhoWantsToBeHired',
    component: HNWhoWantsToBeHired,
  },
  {
    path: 'scrape/hn-freelance',
    name: 'TractorBeam.Scrape.HNFreelance',
    component: HNFreelance,
  },
];
