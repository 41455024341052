/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
const namespaced = true;

const idealJobDefault = '<p>I like working on . . .</p><p>I work best when . . .</p><p>My ideal job would be . . .</p>';
const bioDefault = '<p>Specialities, interests, education, . . .</p>';
const recentAccomplishmentsDefault = '<p>I built . . .<p>';

// initial state
const state = {
  seekingEmployment: null,
  seekingContracts: null,
  hoursAvailablePerWeek: 40,
  idealJob: idealJobDefault,
  bio: bioDefault,
  recentAccomplishments: recentAccomplishmentsDefault,
};

const actions = {};

const mutations = {
  mutateReset(state) {
    state.seekingEmployment = null;
    state.seekingContracts = null;
    state.hoursAvailablePerWeek = 40;
    state.idealJob = idealJobDefault;
    state.bio = bioDefault;
    state.recentAccomplishments = recentAccomplishmentsDefault;
  },
  mutateSeekingEmployment(state, val) {
    state.seekingEmployment = val;
  },
  mutateSeekingContracts(state, val) {
    state.seekingContracts = val;
  },
  mutateHoursAvailablePerWeek(state, val) {
    state.hoursAvailablePerWeek = val;
  },
  mutateIdealJob(state, val) {
    state.idealJob = val;
  },
  mutateBio(state, val) {
    state.bio = val;
  },
  mutateRecentAccomplishments(state, val) {
    state.recentAccomplishments = val;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
