/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  Company,
  CompanyLink,
  CompanyLinkRequest,
  CreateCompanyLinkRequest,
  ListRequest,
  CompanyRequest,
  FilteredCompanyRequest,
  CompanySkillRequest,
  Skill,
  CreateCompanyMemberRequest,
  DeleteCompanyMemberRequest,
  User,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isProduction,
  grpcAuthMetadata,
  getAfterID,
  protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  companiesPerPage: 25,
  // All below is fetched for a given company
  company: null,
  skills: null,
  links: null,
  members: null,
  statsObj: null,

  companies: [],
  companyOpenJobs: [],

  pending: false, // overall company model
  pendingSkills: false,
  pendingLinks: false,
  pendingMembers: false,
  pendingStats: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  page: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  list({ commit, state, rootState }, page) {
    commit('mutateReset');
    commit('mutateResetCompanies');
    commit('mutatePending', true);

    const req = new ListRequest();
    req.setLimit(state.companiesPerPage);
    req.setAfterId(getAfterID(page, state.companiesPerPage));

    grpc.unary(MoonlightService.ListCompanies, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCompanies', {
            companies: res.message.getCompaniesList(),
            page,
            query: null,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, readAsUser }) {
    commit('mutateReset');

    // Only reset if fetching new user. Prevents flicker.
    if (state.company && state.company.getId() !== id) {
      commit('mutateResetCompany');
    }

    // Allow reading as other user. Useful for "view as public" and modifying permissions.
    if (readAsUser === null || readAsUser === undefined) {
      readAsUser = rootState.auth.currentUser ? rootState.auth.currentUser.id : 0;
    }

    // Dispatch reads of company skills, links, and members
    dispatch('listSkills', id);
    dispatch('readStats', id);
    dispatch('listCompanyOpenJobs', id);

    // View as anonymous requires special handling because not
    // all endpoints support it.
    if (readAsUser > 0) {
      dispatch('listLinks', id);
      dispatch('listMembers', id);
    } else {
      commit('mutateLinks', []);
      commit('mutateMembers', []);
    }

    // check for cached company
    let cachedCompany = null;
    _.forEach(state.companies, (company) => {
      if (company.getId() === id) {
        // cache hit!
        cachedCompany = company;
      }
    });
    if (cachedCompany) {
      // Data from cache!
      commit('mutateCompany', cachedCompany);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new FilteredCompanyRequest();
    req.setCompanyId(id);
    req.setActiveUserId(readAsUser);

    // Uses FilteredReadCompany endpoint, which returns partial info for
    // companies whose entire profile cannot be fetched (e.g. anonymous developers)
    grpc.unary(MoonlightService.FilteredReadCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCompany', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  filteredRead({
    commit, state, rootState, dispatch,
  }, { id, readAsUser }) {
    commit('mutateReset');

    // Only reset if fetching new user. Prevents flicker.
    if (state.company && state.company.getId() !== id) {
      commit('mutateResetCompany');
    }

    // Dispatch reads of company skills
    dispatch('listSkills', id);

    // View as anonymous requires special handling because not
    // all endpoints support it.
    if (readAsUser > 0) {
      dispatch('listLinks', id);
      dispatch('listMembers', id);
    } else {
      commit('mutateLinks', []);
      commit('mutateMembers', []);
    }

    // check for cached company
    let cachedCompany = null;
    _.forEach(state.companies, (company) => {
      if (company.getId() === id) {
        // cache hit!
        cachedCompany = company;
      }
    });
    if (cachedCompany) {
      // Data from cache!
      commit('mutateCompany', cachedCompany);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new FilteredCompanyRequest();
    req.setCompanyId(id);
    req.setActiveUserId(readAsUser);

    // Uses FilteredReadCompany endpoint, which returns partial info for
    // companies whose entire profile cannot be fetched (e.g. anonymous developers)
    grpc.unary(MoonlightService.FilteredReadCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateCompany', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, company) {
    commit('mutateReset');

    // You have to run `getCompany` before `updateCompany`, and the IDs must match.
    // The intention is that you're updating the page that you are on, and this
    // adds safety. However, if you can think of a reason to remove this constraint,
    // then go for it!
    if (!state.company || state.company.getId() !== company.getId()) {
      commit('mutateError', {
        code: 4,
        msg: 'target company does not match update query',
      });
      return;
    }

    // Cache this, and revert if save fails.
    // Strategy is to immediately update company, and
    // revert if the update fails.
    const origCompany = new Company(state.company.array.slice(0));
    commit('mutateCompany', company);
    commit('mutateUpdateCompanyInCache', company);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateCompany, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new Company(state.company.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update company with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateCompany', res.message);
          commit('mutateUpdateCompanyInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);

          // If active company is same as updated company, update that store too
          if (
            rootState.auth.activeUser
            && rootState.auth.activeUser.companyId === res.message.getId()
          ) {
            this.$store.commit('auth/mutateCompany', res.message);
          }
        } else {
          // Fail! Revert to original company!
          commit('mutateCompany', origCompany);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listSkills is intended to be called internally by readCompany function.
  // Does not report PermissionDenied error (because company may not have read permission)
  listSkills({
    commit, state, rootState, dispatch,
  }, companyID) {
    commit('mutatePendingSkills', true);
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanySkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkills', res.message.getSkillsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateSkills', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listCompanyOpenJobs is intended to be called internally by read function to
  // get all company jobs that are open and ready for display
  listCompanyOpenJobs({
    commit, state, rootState, dispatch,
  }, companyID) {
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyOpenJobs, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateCompanyOpenJobs', res.message.getJobsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateCompanyOpenJobs', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteLink assumes that the company is loaded and that the link exists on the company
  deleteLink({
    commit, state, rootState, dispatch,
  }, { linkID }) {
    commit('mutateReset');
    commit('mutatePendingLinks', true);

    // Copy link and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origLink = null;
    // eslint-disable-next-line
    for (let i = 0; i < state.links.length; i++) {
      if (state.links[i].getId() === linkID) {
        origLink = new CompanyLink(state.links[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveLinkInLinks', i);
      }
    }

    if (!origLink) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching link for id ${linkID}`;
    }

    const req = new CompanyLinkRequest();
    req.setId(linkID);
    req.setCompanyId(state.company.getId());

    grpc.unary(MoonlightService.DeleteCompanyLink, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddLinkToLinks', origLink);
        }
      },
    });
  },
  // createLink adds a link to the current company
  createLink({
    commit, state, rootState, dispatch,
  }, { url }) {
    commit('mutateReset');
    commit('mutatePendingLinks', true);

    // Immediately add link to cache, without ID
    const link = new CompanyLink();
    link.setCompanyId(state.company.getId());
    link.setUrl(url);
    commit('mutateAddLinkToLinks', link);
    const insertID = state.links.length - 1;

    const req = new CreateCompanyLinkRequest();
    req.setCompanyId(state.company.getId());
    req.setUrl(url);

    grpc.unary(MoonlightService.CreateCompanyLink, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);

        if (res.status === grpc.Code.OK) {
          // Swap out link with one having ID
          commit('mutateUpdateLinkInLinks', {
            index: insertID,
            newLink: res.message,
          });
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveLinkInLinks', insertID);
        }
      },
    });
  },
  // listFeaturedSkills is intended to be called internally by readCompany function.
  // Does not report PermissionDenied error (because company may not have read permission)
  listFeaturedSkills({
    commit, state, rootState, dispatch,
  }, companyID) {
    commit('mutatePendingFeaturedSkills', true);
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyFeaturedSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingFeaturedSkills', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateFeaturedSkills', res.message.getSkillsList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateFeaturedSkills', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listLinks is intended to be called internally by readCompany function.
  // Does not report PermissionDenied error (because company may not have read permission)
  listLinks({
    commit, state, rootState, dispatch,
  }, companyID) {
    commit('mutatePendingLinks', true);
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyLinks, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingLinks', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateLinks', res.message.getCompanyLinksList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateLinks', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // listExperiences is intended to be called internally by readCompany function.
  // Does not report PermissionDenied error (because company may not have read permission)
  listMembers({
    commit, state, rootState, dispatch,
  }, companyID) {
    commit('mutatePendingMembers', true);
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyMembers, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingMembers', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateMembers', res.message.getUsersList());
        } else if (res.status === grpc.Code.PermissionDenied) {
          // ignore this error - currentUser may not have read permission
          commit('mutateMembers', []);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteSkill assumes that the company is loaded and that the skill exists on the company
  deleteSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Copy skill and immediately remove it from cache. If update fails,
    // re-add it (revertsthe change.
    let origSkill = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.skills.length; i++) {
      if (state.skills[i].getSlug() === slug) {
        origSkill = new Skill(state.skills[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveSkillInSkills', i);
      }
    }

    if (!origSkill) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching skill for id ${slug}`;
    }

    const req = new CompanySkillRequest();
    req.setSkillSlug(slug);
    req.setCompanyId(state.company.getId());

    grpc.unary(MoonlightService.DeleteCompanySkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddSkillToSkills', origSkill);
        }
      },
    });
  },
  // createSkill adds a skill to the current company
  createSkill({
    commit, state, rootState, dispatch,
  }, { slug }) {
    commit('mutateReset');
    commit('mutatePendingSkills', true);

    // Immediately add skill to cache, without ID
    let skillMatch;
    _.forEach(rootState.skills.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        skillMatch = skill;
      }
    });
    if (!skillMatch) {
      return;
    }

    commit('mutateAddSkillToSkills', skillMatch);
    const insertID = state.skills.length - 1;

    const req = new CompanySkillRequest();
    req.setCompanyId(state.company.getId());
    req.setSkillSlug(slug);

    grpc.unary(MoonlightService.CreateCompanySkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingSkills', false);
        commit('mutateSuccess', res.Status === grpc.Code.OK);
        // no action needed if success
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillInSkills', insertID);
        }
      },
    });
  },
  // createExperience adds an experience to the current company
  createMember({
    commit, state, rootState, dispatch,
  }, { email, name }) {
    commit('mutateReset');
    commit('mutatePendingMembers', true);

    const req = new CreateCompanyMemberRequest();
    req.setCompanyId(state.company.getId());
    req.setUserEmail(email);
    req.setFullName(name);

    grpc.unary(MoonlightService.CreateCompanyMember, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingMembers', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateAppendMember', res.message);
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // deleteMember assumes that the company is loaded and that the member exists on the company
  deleteMember({
    commit, state, rootState, dispatch,
  }, { id }) {
    commit('mutateReset');
    commit('mutatePendingMembers', true);

    // Copy member and immediately remove it from cache. If delete fails,
    // re-add it (reverts the change).
    let orig = null;
    // eslint-disable-next-line
    for (let i = 0; i < state.members.length; i++) {
      if (state.members[i].getId() === id) {
        orig = new User(state.members[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveMemberInMembers', i);
      }
    }

    if (!orig) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching member for id ${id}`;
    }

    if (id === 0) {
      return;
    }

    const req = new DeleteCompanyMemberRequest();
    req.setUserId(id);
    req.setCompanyId(state.company.getId());

    grpc.unary(MoonlightService.DeleteCompanyMember, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingMembers', false);
        // If ok status - then no further action required!
        if (res.status === grpc.Code.OK) {
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAppendMember', orig);
        }
      },
    });
  },
  readStats({
    commit, state, rootState, dispatch,
  }, companyID) {
    commit('mutateStatsObj', null);
    commit('mutatePendingStats', true);
    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ReadCompanyJobStats, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingStats', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateStatsObj', res.message.toObject());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.pendingSkills = false;
    state.pendingLinks = false;
    state.pendingMembers = false;
    state.pendingStats = false;

    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetCompanies(state) {
    state.companies = null;
    state.page = null;
  },
  mutateResetCompany(state) {
    state.company = null;
    state.skills = null;
    state.links = null;
    state.members = null;
  },
  mutateCompanies(state, { companies, page }) {
    state.companies = companies;
    state.page = page;
  },
  mutateCompany(state, company) {
    state.company = company;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutatePendingSkills(state, pending) {
    state.pendingSkills = pending;
  },
  mutatePendingLinks(state, pending) {
    state.pendingLinks = pending;
  },
  mutatePendingMembers(state, pending) {
    state.pendingMembers = pending;
  },
  mutatePendingStats(state, pending) {
    state.pendingnStats = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateSkills(state, data) {
    state.skills = data;
  },
  mutateLinks(state, data) {
    state.links = data;
  },
  mutateMembers(state, data) {
    state.members = data;
  },
  mutateStatsObj(state, data) {
    state.statsObj = data;
  },
  mutateUpdateCompanyInCache(state, company) {
    // remove company from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.companies.length; i++) {
      if (state.companies[i].getId() === company.getId()) {
        state.companies[i] = company;
        // Vue.set(state.companies, i, company);
      }
    }
  },
  mutateRemoveLinkInLinks(state, index) {
    state.links.splice(index, 1);
  },
  mutateUpdateLinkInLinks(state, { index, newLink }) {
    state.links.splice(index, 1, newLink);
  },
  mutateAddLinkToLinks(state, newLink) {
    state.links.push(newLink);
  },
  mutateRemoveSkillInSkills(state, index) {
    state.skills.splice(index, 1);
  },
  mutateUpdateSkillInSkills(state, { index, newSkill }) {
    state.skills.splice(index, 1, newSkill);
  },
  mutateAddSkillToSkills(state, newSkill) {
    state.skills.push(newSkill);
  },
  mutateAppendMember(state, member) {
    state.members.push(member);
  },
  mutateRemoveMemberInMembers(state, index) {
    state.members.splice(index, 1);
  },
  mutateUpdateMemberToMembers(state, { index, newMember }) {
    state.members.splice(index, 1, newMember);
  },
  mutateCompanyOpenJobs(state, jobs) {
    const openJobs = [];
    // eslint-disable-next-line
    for (let i = 0; i < jobs.length; i++) {
      const job = jobs[i];
      const url = `/app/companies/${job.getCompanyId()}/jobs/${job.getId()}`;
      openJobs.push({
        ...job.toObject(),
        url,
      });
    }
    state.companyOpenJobs = openJobs;
  },
};

const getters = {
  getCompanyObj(state) {
    if (state.company === null) {
      return null;
    }
    // directly calling toObject occassionally causes issues
    const copy = new Company(state.company.array.slice(0));

    const obj = copy.toObject();
    obj.createdAt = protoTimestampToDate(copy.getCreatedAt());
    obj.updatedAt = protoTimestampToDate(copy.getUpdatedAt());
    return obj;
  },
  getSkills(state) {
    if (!state.skills) {
      return null;
    }

    return state.skills;
  },
  getSkillSlugs(state) {
    const res = [];
    if (state.skills) {
      _.each(state.skills, (sk) => {
        res.push(sk.getSlug());
      });
    }
    return res;
  },
  getError(state) {
    if (state.errCode === null && state.errMsg === null) {
      return null;
    }

    return {
      code: state.errCode,
      msg: state.errMsg,
    };
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
