/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
const namespaced = true;

// initial state
const state = {
  // userID -> data
  cache: {},
};

const actions = {};

const mutations = {
  mutateReset(state) {
    state.cache = {};
  },
  mutateSetCache(state, { key, value }) {
    state.cache[key] = value;
  },
  mutateDeleteCache(state, key) {
    state.cache[key] = null;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
