/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  CreateApplicationRequest,
  Application,
  ApplicationRequest,
  JobRequest,
  UserRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  application: null,

  applications: [],

  pending: false, // overall applications model
  pendingSkills: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  list({ commit, state, rootState }, { jobID, companyID }) {
    commit('mutateReset');
    commit('mutateResetApplications');
    commit('mutatePending', true);

    const req = new JobRequest();
    req.setId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListJobApplications, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateApplications', res.message.getApplicationsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  listDeveloperOpenApplications({ commit, state, rootState }, userID) {
    commit('mutateReset');
    commit('mutateResetApplications');
    commit('mutatePending', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListDeveloperOpenApplications, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateApplications', res.message.getApplicationsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, { id, jobID, companyID }) {
    commit('mutateReset');

    // Only reset if fetching new user. Prevents flicker.
    if (state.application && state.application.getId() !== id) {
      commit('mutateResetApplication');
    }

    // check for cached application
    let cachedApplication = null;
    _.forEach(state.applications, (application) => {
      if (application.getId() === id) {
        // cache hit!
        cachedApplication = application;
      }
    });

    if (cachedApplication) {
      // Data from cache!
      commit('mutateApplication', cachedApplication);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new ApplicationRequest();
    req.setId(id);
    req.setJobId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ReadApplication, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateApplication', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  create({
    commit, state, rootState, dispatch,
  }, req) {
    commit('mutateReset');

    commit('mutateApplication', null);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.CreateJobApplication, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new CreateApplicationRequest(req.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateApplication', res.message);
          commit('mutateAddApplicationToApplications', res.message);
        } else {
          // Fail! Revert to original application!
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({
    commit, state, rootState, dispatch,
  }, application) {
    commit('mutateReset');

    // Cache original val, and revert if save fails.
    // Strategy is to immediately update application, and
    // revert if the update fails.
    let origApplication;
    _.each(state.applications, (psl) => {
      if (psl.getId() === application.getId()) {
        origApplication = new Application(psl.array.slice(0));
      }
    });

    commit('mutateApplication', application);
    commit('mutateUpdateApplicationInCache', application);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateJobApplication, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new Application(application.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update application with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateApplication', res.message);
          commit('mutateUpdateApplicationInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original application!
          commit('mutateApplication', origApplication);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  createSlackGroup({
    commit, state, rootState, dispatch,
  }, { applicationID, jobID, companyID }) {
    commit('mutateReset');

    commit('mutatePending', true);

    const req = new ApplicationRequest();
    req.setId(applicationID);
    req.setJobId(jobID);
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.CreateSlackGroupForJobApplication, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update application with response, in case there were any side changes
          // (e.g. updatedAt time)
          commit('mutateApplication', res.message);
          commit('mutateUpdateApplicationInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // delete assumes that the application is loaded
  delete({
    commit, state, rootState, dispatch,
  }, id) {
    commit('mutateReset');
    commit('mutatePending', true);

    // Copy application and immediately remove it from cache. If update fails,
    // re-add it (reverts the change).
    let orig = null;

    // eslint-disable-next-line
    for (let i = 0; i < state.applications.length; i++) {
      if (state.applications[i].getId() === id) {
        orig = new Application(state.applications[i].array.slice(0));

        // Remove from cache
        commit('mutateRemoveApplicationInApplications', i);
        commit('mutateApplication', null);
      }
    }

    if (!orig) {
      // eslint-disable-next-line no-throw-literal
      throw `unable to find matching application for id ${id}`;
    }

    const req = new ApplicationRequest();
    req.setId(id);
    req.setJobId(orig.getJobId());
    req.setCompanyId(orig.getCompanyId());

    grpc.unary(MoonlightService.DeleteJobApplication, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        // If ok status - then no further action required!

        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });

          commit('mutateAddApplicationToApplications', orig);
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;

    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetApplications(state) {
    state.applications = null;
  },
  mutateResetApplication(state) {
    state.application = null;
    state.skills = null;
  },
  mutateApplications(state, applications) {
    state.applications = applications;
  },
  mutateApplication(state, application) {
    state.application = application;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateUpdateApplicationInCache(state, application) {
    // remove application from cache
    // eslint-disable-next-line
    for (let i = 0; i < state.applications.length; i++) {
      if (state.applications[i].getId() === application.getId()) {
        state.applications[i] = application;
        // Vue.set(state.applications, i, application);
      }
    }
  },
  mutateRemoveApplicationInApplications(state, index) {
    state.applications.splice(index, 1);
  },
  mutateAddApplicationToApplications(state, application) {
    state.applications.push(application);
  },
};

const getters = {
  getApplicationObj(state) {
    if (state.application === null) {
      return null;
    }
    // directly calling toObject occassionally causes issues
    const copy = new Application(state.application.array.slice(0));

    const obj = copy.toObject();
    obj.createdAt = protoTimestampToDate(copy.getCreatedAt());
    obj.updatedAt = protoTimestampToDate(copy.getUpdatedAt());
    return obj;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
