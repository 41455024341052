/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import { CreateUserRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction } from '@/helpers';

const namespaced = true;

const state = {
  email: null,
  fullName: null,
  password: null,
  companySignup: null,
  timezone: null,
  companyName: null,
  landingPage: null,
  referredByUserID: null,
  companyURL: null,
  companyDescription: null,
  heardThrough: null,

  errCode: null,
  errMsg: null,
  pending: false,
};

const mutations = {
  mutateEmail(state, val) {
    if (val) {
      val = val.toLowerCase();
    }
    state.email = val;
  },
  mutateFullName(state, val) {
    state.fullName = val;
  },
  mutateHeardThrough(state, val) {
    state.heardThrough = val;
  },
  mutatePassword(state, val) {
    state.password = val;
  },
  mutateCompanySignup(state, val) {
    state.companySignup = val;
  },
  mutateTimezone(state, val) {
    state.timezone = val;
  },
  mutateCompanyName(state, val) {
    state.companyName = val;
  },
  mutateLandingPage(state, val) {
    state.landingPage = val;
  },
  mutateCompanyURL(state, val) {
    state.companyURL = val;
  },
  mutateCompanyDescription(state, val) {
    state.companyDescription = val;
  },
  mutateReferredByUserID(state, val) {
    state.referredByUserID = val;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  // clears errors and pending state
  mutateReset(state) {
    state.errCode = null;
    state.errMsg = null;
    state.pending = false;
  },
  mutateHardReset(state) {
    // This are intentionally not reset here
    //    but reset within the component beforeUnmount
    //    to avoid a form validation bug where it tries to
    //    show an error after submission...
    // state.email = null;
    // state.fullName = null;
    // state.password = null;
    state.companySignup = null;
    state.timezone = null;
    state.companyName = null;
    state.companyURL = null;
    state.companyDescription = null;
    state.landingPage = null;
    state.referredByUserID = null;
    state.heardThrough = null;

    state.errCode = null;
    state.errMsg = null;
    state.pending = false;
  },
};

const actions = {
  createUser(
    {
      commit, state, getters, rootState, dispatch,
    },
    { recaptchaToken, googleToken, signupPagePath },
  ) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new CreateUserRequest();

    // user info
    req.setFullName(state.fullName);

    if (!googleToken) {
      req.setEmail(state.email);
    }

    if (state.companySignup) {
      req.setCreateCompanyWithoutName(true);
    }

    req.setTimezone(state.timezone);
    // req.setCountry(state.country);

    req.setRecaptchaToken(recaptchaToken);
    req.setCompanyName(state.companyName);
    req.setPassword(state.password);
    req.setCompanyUrl(state.companyURL);
    req.setCompanyDescription(state.companyDescription);
    req.setHeardThrough(state.heardThrough);

    // Attribution
    req.setLandingPage(rootState.referrals.landingPage);
    req.setReferrerUrl(rootState.referrals.referrerURL);
    req.setReferredByUserId(rootState.referrals.referredByUserID);

    req.setSignupPagePath(signupPagePath);

    req.setGoogleToken(googleToken);

    grpc.unary(MoonlightService.CreateUser, {
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('auth/mutateLogin', res.message, { root: true });
          commit('mutateHardReset');
        } else {
          commit('mutateError', { code: res.status, msg: res.statusMessage });
        }
      },
    });
  },
};

const getters = {
  signupUnderway(state) {
    return !!state.email;
  },
  getIsFreeEmailProvider(state) {
    if (!state.email) {
      return false;
    }
    const providers = [
      'gmail.com',
      'googlemail.com',
      'protonmail.com',
      'pm.me',
      'yahoo.com',
      'yahoo.co.uk',
      'outlook.com',
      'hotmail.com',
      'mail.com',
      'aol.com',
      'yandex.ru',
      'icloud.com',
      'fastmail.com',
    ]; // protonmail

    let match = false;
    _.each(providers, (p) => {
      if (state.email.endsWith(`@${p}`)) {
        match = true;
      }
    });
    return match;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
