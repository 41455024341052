// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* eslint-disable import/first */
import './public_path';
import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import * as Sentry from '@sentry/vue';
import Vuex from 'vuex';
import { Field, Form, defineRule } from 'vee-validate';

import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Quill, QuillEditor } from '@vueup/vue-quill';
import Markdown from 'vue3-markdown-it';
import hljs from 'highlight.js';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import VueClipboard from 'vue3-clipboard';
import { VueRecaptcha } from 'vue-recaptcha';
import Cleave from 'cleave.js';
import VueTyper from 'vue3-typer';
import { integer } from '@vee-validate/rules';
import { load as loadIntercom } from './intercom';
import {
  required, email, min, max, confirmed, checked, url,
  validPhonenumber, numeric, minValue, maxValue, float,
} from './formValidation';
import { mnltVueHeadMixin } from './mixins/mnltVueHeadMixin';

import Moonlight from './Moonlight';
import store from './store';
import { createRouter } from './router';
import FormField from './components/global/FormField';
import FormInput from './components/global/FormInput';
import Spinner from './components/shared/Spinner';
import { isLocal } from './helpers';

loadIntercom();

const router = createRouter();

const app = createApp(Moonlight);

app.use(store);
app.use(router);

const head = createHead();
app.mixin(mnltVueHeadMixin);
app.use(head);

if (!isLocal()) {
  Sentry.init({
    app,
    dsn: process.env.SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.SENTRY_ENVIRONMENT,
    enabled: true,
  });
} else {
  Sentry.init({
    app,
    dsn: '',
    release: '',
    environment: '',
    enabled: false,
  });
}

app.config.performance = process.env.NODE_ENV === 'development';

app.use(Vuex);

// app.use(VueIntercom, { appId: 'o8eup5pb' });
app.use(VueHighcharts, { Highcharts });

app.use(VueClipboard);
app.use(VueTyper);

app.config.globalProperties.$cfg = {
  toastCfg: {
    position: 'top',
    duration: 2500,
    variant: 'primary',
  },
};

app.config.globalProperties.$filters = {
  centsToDollars(val) {
    return `$ ${(parseInt(val, 10) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  },
};

defineRule('integer', integer);
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('confirmed', confirmed);
defineRule('checked', checked);
defineRule('url', url);
defineRule('validPhonenumber', validPhonenumber);
defineRule('float', float);
defineRule('numeric', numeric);
defineRule('minValue', minValue);
defineRule('maxValue', maxValue);

const customBulmaCfg = {
  ...bulmaConfig,
  // temporary so we can enforce vue 3 for
  //   components only (and not the rest of our app)
  datepicker: {
    override: true,
    ...bulmaConfig.datepicker,
    monthClass: 'datepicker-table',
    monthBodyClass: 'datepicker-body',
    monthTableClass: 'datepicker-months',
    monthCellClass: 'datepicker-cell',
  },
  inputitems: {
    override: true,
    rootClass: 'taginput control',
    containerClass: 'taginput-container is-focusable',
    itemClass: 'tag',
    closeIcon: 'close-circle mdi-18px',
    closable: true,
    removeOnKeys: ['Backspace'],
  },
};
app.use(Oruga, customBulmaCfg);
app.use(Markdown);

app.component('Field', Field);
// eslint-disable-next-line vue/no-reserved-component-names
app.component('Form', Form);
app.component('FormField', FormField);
app.component('FormInput', FormInput);
app.component('Spinner', Spinner);
app.component('VueRecaptcha', VueRecaptcha);

const globalOptions = {
  modules: {
    toolbar: [['bold', 'italic', 'underline', 'link', 'image', { list: 'bullet' }]],
    syntax: {
      highlight: (text) => hljs.highlightAuto(text).value,
    },
  },
  theme: 'snow',
};

// We overwrite the default link behavior
//  because it wants to treat only http:// or https:// prefixed
//  urls as absoltue paths to open in a new tab.
//  so without this, if someone puts "google.com", it treats it as a
//  relative path, causing weirdness.
const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static sanitize(v) {
    const value = super.sanitize(v);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i += 1) {
        if (value.startsWith(this.PROTOCOL_WHITELIST[i])) {
          return value;
        }
      }
      return `https://${value}`;
    }
    return value;
  }
}
Quill.register(CustomLink);

// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => globalOptions;
app.component('QuillEditor', QuillEditor);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);

const cleave = {
  name: 'cleave',
  beforeMount(el, binding) {
    const input = el.querySelector('input');
    // eslint-disable-next-line no-underscore-dangle
    input._vCleave = new Cleave(input, binding.value);
  },
  updated(el, binding) {
    const input = el.querySelector('input');
    // eslint-disable-next-line no-underscore-dangle
    input._vCleave.destroy();
    // eslint-disable-next-line no-underscore-dangle
    input._vCleave = new Cleave(input, binding.value);
  },
  unmounted(el) {
    const input = el.querySelector('input');
    // eslint-disable-next-line no-underscore-dangle
    input._vCleave.destroy();
  },
};

app.directive('cleave', cleave);

document.addEventListener('DOMContentLoaded', () => {
  app.mount('#app');
});
