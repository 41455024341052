/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  CompanyRequest,
  CompanyPaymentMethod,
  CompanyBankAccountPaymentMethodRequest,
  CompanyCardPaymentMethodRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  methods: [],

  pending: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  list({ commit, state, rootState }, companyID) {
    commit('mutateReset');
    commit('mutateResetMethods');
    commit('mutatePending', true);

    const req = new CompanyRequest();
    req.setCompanyId(companyID);

    grpc.unary(MoonlightService.ListCompanyPaymentMethods, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateMethods', res.message.getMethodsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  setActive({
    commit, state, rootState, dispatch,
  }, { id, active }) {
    let match;
    // eslint-disable-next-line
    for (let i = 0; i < state.methods.length; i++) {
      if (state.methods[i].getId() === id) {
        match = new CompanyPaymentMethod(state.methods[i].array.slice(0));
      }
    }
    match.setActive(active);
    dispatch('update', match);
  },
  update({
    commit, state, rootState, dispatch,
  }, newMethod) {
    commit('mutateReset');

    // Cache this, and revert if save fails.
    // Strategy is to immediately update method in cache, and
    // revert if the update fails.
    let orig;
    let ix;
    // eslint-disable-next-line
    for (let i = 0; i < state.methods.length; i++) {
      if (state.methods[i].getId() === newMethod.getId()) {
        orig = new CompanyPaymentMethod(state.methods[i].array.slice(0));
        ix = i;
        commit('mutateUpdateMethodInMethods', { ix, newMethod });
      }
    }
    if (orig === null) {
      // eslint-disable-next-line
      throw 'companypaymentmethod not in cache';
    }
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateCompanyPaymentMethod, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: new CompanyPaymentMethod(newMethod.array.slice(0)),
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update with response, in case there were any side changes
          commit('mutateUpdateMethodInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original company!
          commit('mutateUpdateMethodInCache', orig);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  createCard({
    commit, state, rootState, dispatch,
  }, { companyID, token }) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new CompanyCardPaymentMethodRequest();
    req.setCompanyId(companyID);
    req.setStripePublicToken(token);

    grpc.unary(MoonlightService.CreateCompanyCardPaymentMethod, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Swap out link with one having ID
          commit('mutateAddMethod', res.message);

          if (window.analytics) {
            window.analytics.ready(() => {
              window.analytics.track('AddPaymentInfo');
            });
          }
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  createBankAccount(
    {
      commit, state, rootState, dispatch,
    },
    { companyID, plaidPublicToken, plaidAccountID },
  ) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new CompanyBankAccountPaymentMethodRequest();
    req.setCompanyId(companyID);
    req.setPlaidPublicToken(plaidPublicToken);
    req.setPlaidAccountId(plaidAccountID);

    grpc.unary(MoonlightService.CreateCompanyBankAccountPaymentMethod, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Swap out link with one having ID
          commit('mutateAddMethod', res.message);

          if (window.analytics) {
            window.analytics.ready(() => {
              window.analytics.track('AddPaymentInfo');
            });
          }
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateResetMethods(state) {
    state.methods = null;
  },
  mutateMethods(state, methods) {
    state.methods = methods;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateUpdateMethodInCache(state, m) {
    // eslint-disable-next-line
    for (let i = 0; i < state.methods.length; i++) {
      if (state.methods[i].getId() === m.getId()) {
        state.methods[i] = m;
        // Vue.set(state.methods, i, m);
      }
    }
  },
  mutateUpdateMethodInMethods(state, { ix, newMethod }) {
    state.methods.splice(ix, 1, newMethod);
  },
  mutateAddMethod(state, newMethod) {
    state.methods.push(newMethod);
  },
};

const getters = {
  getActiveMethods(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && !m.getLegacyStripeAccount()) {
        results.push(m);
      }
    });
    return results;
  },
  getActiveCards(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && !m.getLegacyStripeAccount() && m.getType() === 'card') {
        results.push(m);
      }
    });
    return results.sort((a, b) => {
      if (a.getExpYear() !== b.getExpYear()) {
        return b.getExpYear() - a.getExpYear();
      } if (a.getExpMonth() !== b.getExpMonth()) {
        return b.getExpMonth() - a.getExpMonth();
      }
      // if same month and year
      return b.getId() - a.getId();
    });
  },
  getActiveBankAccounts(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && m.getType() === 'bank_account' && !m.getLegacyStripeAccount()) {
        results.push(m);
      }
    });
    return results;
  },
  getActiveLegacyMethods(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && m.getLegacyStripeAccount()) {
        results.push(m);
      }
    });
    return results;
  },
  getActiveLegacyCards(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && m.getLegacyStripeAccount() && m.getType() === 'card') {
        results.push(m);
      }
    });
    return results;
  },
  getActiveBankLegacyAccounts(state) {
    const results = [];
    _.each(state.methods, (m) => {
      if (m.getActive() && m.getType() === 'bank_account' && m.getLegacyStripeAccount()) {
        results.push(m);
      }
    });
    return results;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
