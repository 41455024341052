/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { grpc } from 'grpc-web-client';
import { DeveloperJobMatchRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';

const namespaced = true;

// initial state
const state = {
  match: null,

  pending: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  read({ commit, rootState }, { userID, jobID }) {
    return new Promise((resolve, reject) => {
      commit('mutateReset');

      const req = new DeveloperJobMatchRequest();
      req.setUserId(userID);
      req.setJobId(jobID);
      grpc.unary(MoonlightService.ReadDeveloperJobMatch, {
        metadata: grpcAuthMetadata(rootState.auth.session),
        debug: !isProduction(),
        request: req,
        host: grpcHost(),
        onEnd: (res) => {
          if (res.status === grpc.Code.OK) {
            commit('mutateDeveloperJobMatch', res.message);
            resolve(res);
          } else {
            commit('mutateError', {
              errCode: res.status,
              errMsg: res.statusMessage,
            });
            reject(res);
          }
        },
      });
    });
  },
  dismiss({
    commit, state, dispatch, rootState,
  }, { userID, jobID, dismissToggleTo }) {
    commit('mutateReset');

    dispatch('read', { userID, jobID }).then(() => {
      commit('mutateDismissMatch', dismissToggleTo);

      grpc.unary(MoonlightService.UpdateDeveloperJobMatch, {
        metadata: grpcAuthMetadata(rootState.auth.session),
        debug: !isProduction(),
        request: state.match,
        host: grpcHost(),
        onEnd: (res) => {
          if (res.status === grpc.Code.OK) {
            commit('mutateDeveloperJobMatch', res.message);
          } else {
            commit('mutateError', {
              errCode: res.status,
              errMsg: res.statusMessage,
            });
          }
        },
      });
    });
  },
};

const mutations = {
  mutateDeveloperJobMatch(state, match) {
    state.match = match;
  },
  mutateDismissMatch(state, dismissToggleTo) {
    state.match.setDeveloperDismissed(dismissToggleTo);
  },
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};
