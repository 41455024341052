/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import EmptyPB from 'google-protobuf/google/protobuf/empty_pb';
import _ from 'lodash';
import { SkillRequest, CreateSkillRequest } from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import { grpcHost, isProduction, grpcAuthMetadata } from '@/helpers';
import { searchSkills } from '@/store/utils/skills';

const namespaced = true;

// initial state
const state = {
  skill: null,
  skills: [],

  suggestionModel: null,

  pending: false,

  notFound: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  create({
    commit, state, rootState, dispatch,
  }, {
    slug, name, abbreviation, sendAlert,
  }) {
    commit('mutateReset');
    commit('mutatePending', true);

    // Immediately add link to cache, without ID
    const skill = new CreateSkillRequest();
    skill.setSlug(slug);
    skill.setName(name);
    skill.setAbbreviation(abbreviation);
    skill.setSendAlert(sendAlert);

    // Insert immediately
    commit('mutateAddSkill', skill);
    const insertID = state.skills.length - 1;

    grpc.unary(MoonlightService.CreateSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: skill,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          commit('mutateUpdateSkillInCache', res.message);
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
          // Remove error
          commit('mutateRemoveSkillByIndex', insertID);
        }
      },
    });
  },
  // listSkills is permission public! Don't forget!
  list({ commit, rootState }) {
    commit('mutateReset');
    commit('mutatePending', true);

    const req = new EmptyPB.Empty();
    grpc.unary(MoonlightService.ListSkills, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkills', res.message.getSkillsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  readSuggestionModel({ commit, rootState }) {
    const req = new EmptyPB.Empty();
    grpc.unary(MoonlightService.ReadSkillSuggestionModel, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateSuggestionModel', res.message.toObject().skillScoresMap);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  read({
    commit, state, rootState, dispatch,
  }, slug) {
    commit('mutateReset');

    // check for cached skill
    let cachedSkill = null;
    _.forEach(state.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        cachedSkill = skill;
      }
    });
    if (cachedSkill) {
      // Data from cache!
      commit('mutateSkill', cachedSkill);
      return;
    }

    // Otherwise - fetch
    commit('mutatePending', true);
    const req = new SkillRequest();
    req.setSlug(slug);

    grpc.unary(MoonlightService.ReadSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);
        if (res.status === grpc.Code.OK) {
          commit('mutateSkill', res.message);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  update({ commit, state, rootState }, skill) {
    commit('mutateReset');

    // You have to run `getSkill` before `updateSkill`, and the IDs must match.
    // The intention is that you're updating the page that you are on, and this
    // adds safety. However, if you can think of a reason to remove this constraint,
    // then go for it!
    if (!state.skill || state.skill.getSlug() !== skill.getSlug()) {
      commit('mutateError', {
        code: 4,
        msg: 'target skill does not match update query',
      });
      return;
    }

    // Cache this, and revert if save fails.
    // Strategy is to immediately update skill, and
    // revert if the update fails.
    const origSkill = state.skill;
    commit('mutateSkill', skill);
    commit('mutateUpdateSkillInCache', skill);
    commit('mutatePending', true);

    grpc.unary(MoonlightService.UpdateSkill, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: skill,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Update skill with response, in case there were any side changes
          // (e.g. email being marked as unconfirmed)
          commit('mutateSkill', res.message);
          commit('mutateUpdateSkillInCache', res.message);
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          // Fail! Revert to original skill!
          commit('mutateSkill', origSkill);
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.notFound = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutateSkills(state, skills) {
    state.skills = skills;
  },
  mutateSuggestionModel(state, model) {
    state.suggestionModel = model;
  },
  mutateSkill(state, skill) {
    state.skill = skill;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
  mutateAddSkill(state, skill) {
    state.skills.push(skill);
  },
  mutateUpdateSkillInCache(state, skill) {
    // updates a skill in the cache
    // eslint-disable-next-line
    for (let i = 0; i < state.skills.length; i++) {
      if (state.skills[i].getSlug() === skill.getSlug()) {
        state.skills[i] = skill;
        // Vue.set(state.skills, i, skill);
      }
    }
  },
  mutateRemoveSkillByIndex(state, index) {
    state.skills.splice(index, 1);
  },
};

const getters = {
  getSkillBySlug: (state) => (slug) => {
    let match;
    _.forEach(state.skills, (skill) => {
      if (skill.getSlug() === slug) {
        // cache hit!
        match = skill;
      }
    });
    return match;
  },
  getSkillCount(state) {
    if (state.skills && state.skills.length) {
      return state.skills.length;
    }
    return 1500;
  },
  getSuggestedSkills: (state) => ({ slugs, count }) => {
    if (!state.suggestionModel) {
      return null;
    }

    const sumScore = {};

    _.each(slugs, (skill) => {
      let skillModel = null;
      _.each(state.suggestionModel, (sm) => {
        if (sm[0] === skill) {
          // eslint-disable-next-line prefer-destructuring
          skillModel = sm[1];
        }
      });
      if (!skillModel) {
        return;
      }

      _.each(skillModel.scoresMap, (match) => {
        const matchSkill = match[0];
        const matchScore = match[1];
        // javascript gets rcommended everywhere - suppress it for better recommendations
        if (
          slugs.indexOf(matchSkill) < 0
          && matchSkill !== 'javascript'
          && matchSkill !== 'amazon-web-services'
        ) {
          if (matchSkill in sumScore) {
            sumScore[matchSkill] += matchScore;
          } else {
            sumScore[matchSkill] = matchScore;
          }
        }
      });
    });

    const sortable = _.toPairs(sumScore);

    sortable.sort((a, b) => b[1] - a[1]);

    return sortable.slice(0, count).map((x) => x[0]);
  },
  getSkillsSortedAlphabetically(state) {
    const skillCopy = _.cloneDeep(state.skills);

    skillCopy.sort((a, b) => {
      const textA = a.getName().toUpperCase();
      const textB = b.getName().toUpperCase();
      // eslint-disable-next-line
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return skillCopy;
  },
  search: (state, getters) => (query = '') => searchSkills(getters.getSkillsSortedAlphabetically, query),
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
