// This module allows a user to request a new email, then confirm
// the change.
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  grpc,
} from 'grpc-web-client';
import {
  UserEmailRequest,
  UserEmailUpdateWithTokenRequest,
} from '@/protoc/moonlight_pb';
import {
  MoonlightService,
} from '@/protoc/moonlight_pb_service';
import {
  grpcHost,
  isProduction,
  grpcAuthMetadata,
} from '@/helpers';

const namespaced = true;

// initial state
const state = {
  pending: false,
  errCode: null,
  errMsg: null,
  success: false, // Watch this to know when an update has completed successfully
};

const actions = {
  requestChange({
    commit,
    state,
    rootState,
    dispatch,
  }, email) {
    // Only request an update to current user's email!

    commit('mutateReset');
    commit('mutatePending', true);

    const req = new UserEmailRequest();
    req.setUserId(rootState.auth.currentUser.id);
    req.setEmail(email);

    grpc.unary(MoonlightService.CreateUserEmailChangeEmail, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  updateWithToken({
    commit,
    state,
    rootState,
    dispatch,
  }, token) {
    // Only updates current user's password! (becuase it requires current password,
    // so only current user should know that . . . )

    commit('mutateReset');
    commit('mutatePending', true);

    const req = new UserEmailUpdateWithTokenRequest();
    req.setToken(token);

    grpc.unary(MoonlightService.UpdateUserEmailWithToken, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePending', false);

        if (res.status === grpc.Code.OK) {
          // Watch the success state to know when the update is complete
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateReset(state) {
    state.pending = false;
    state.errCode = null;
    state.errMsg = null;
    state.success = false;
  },
  mutatePending(state, pending) {
    state.pending = pending;
  },
  mutateError(state, {
    code,
    msg,
  }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateSuccess(state, status) {
    state.success = status;
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
