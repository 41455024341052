import { isValidNumber as isValidPhoneNumber } from 'libphonenumber-js';
import { isUrlWithOrWithoutProtocol } from './helpers';

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_REGEX = /^[0-9]+$/;

const isEmpty = (value) => !value || !value.length;

export const required = (value, _, ctx) => {
  if ((value === null) || (value === undefined)) {
    return `${ctx.field} is required`;
  }

  // cast to string in case we get ints (otherwise for numeric types the isEmpty can fail)
  const v = String(value);
  if (isEmpty(v)) {
    return `${ctx.field} is required`;
  }

  return true;
};

export const checked = (value) => {
  if (value !== true) {
    return 'You must accept';
  }

  return true;
};

const isValidEmail = (value) => {
  const valid = EMAIL_REGEX.test(String(value));
  if (!valid) {
    return 'Please enter a valid email address';
  }
  return true;
};

export const email = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every((val) => isValidEmail(val) === true);
  }

  return isValidEmail(value);
};

export const validPhonenumber = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return isValidPhoneNumber(value);
};

export const url = (value) => {
  if (!value) {
    return true;
  }

  if (isUrlWithOrWithoutProtocol(value)) {
    return true;
  }
  return 'Please enter a valid URL';
};

export const min = (value, [limit], ctx) => {
  // The field is empty so it should pass
  if (isEmpty(value)) {
    return true;
  }

  if (value.length < limit) {
    return `${ctx.field} must be at least ${limit} characters`;
  }

  return true;
};

export const max = (value, [limit], ctx) => {
  // The field is empty so it should pass
  if (isEmpty(value)) {
    return true;
  }

  if (value.length > limit) {
    return `${ctx.field} must be at most ${limit} characters`;
  }

  return true;
};

export const numeric = (value) => {
  if (!value) {
    return true;
  }
  const val = String(value);
  return NUMBER_REGEX.test(val);
};

export const float = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  const v = parseFloat(value);
  return !Number.isNaN(v);
};

export const minValue = (value, [minLimit], ctx) => {
  // The field is empty so it should pass
  if (!value) {
    return true;
  }

  if (Number(value) < Number(minLimit)) {
    return `${ctx.field} must be at least ${minLimit}`;
  }

  return true;
};

export const maxValue = (value, [maxLimit], ctx) => {
  // The field is empty so it should pass
  if (!value) {
    return true;
  }

  if (Number(value) > Number(maxLimit)) {
    return `${ctx.field} must be at most ${maxLimit}`;
  }

  return true;
};

export const confirmed = (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true;
  }

  return 'Passwords must match';
};

export default {
  required,
  email,
  min,
  max,
  confirmed,
  checked,
  numeric,
  float,
  minValue,
  maxValue,
};
